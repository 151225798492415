import {MyComponentsStore} from "marketplace-2/store/components/MyComponentsStore";
import {ProjectsStore} from "../createProjects";
import {autorun} from "mobx";
import {initializeAuthenticated} from "../initializeAuthenticated";

// initialize authenticated user flow
export const runInitAuthenticatedFlow = (componentsStore: MyComponentsStore, store: ProjectsStore) =>
    autorun(() => {
        const isInitialized = componentsStore.isInitialized.get();
        if (!isInitialized) return;
        initializeAuthenticated(store);
    });
