import { useCallback, useState } from "react";

import { DELETE_BOT } from "../mutations/deleteBot";
import { GET_ALL_BOTS } from "../queries/getAllBots";
import { GET_BOTS } from "../queries/getBots";
import { GET_BOT_DASHBOARD } from "../queries/getBotDashboard";
import { SEARCH_BOTS } from "../queries/searchBots";
import { cancelBotSubcription } from "pricing/comm/cancelBotSubcription";
import { useMutation } from "@apollo/client";

export const useDeleteBot = () => {
  const [deleteBotFunc, { data, loading, error }] = useMutation(DELETE_BOT, {
    refetchQueries: [GET_BOTS, GET_ALL_BOTS, GET_BOT_DASHBOARD, SEARCH_BOTS],
    errorPolicy: "all",
  });

  const [deleteError, setDeleteError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const deleteBot = useCallback(
    async (botId: number, subscription?: string) => {
      setIsProcessing(true);
      if (subscription) {
        try {
          await cancelBotSubcription(botId);
        } catch (e) {
          setDeleteError(String(e));
        }
      }
      setIsProcessing(false);
      deleteBotFunc({ variables: { id: botId } });
    },
    []
  );

  return {
    deleteBot,
    loading: loading || isProcessing,
    error: error?.message || deleteError,
  };
};
