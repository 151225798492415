import { useCallback, useEffect, useState } from "react";

import { CocoResponse } from "marketplace-2/pages/hub/chat-window/types";
import { dashboardChannelKey } from "dangerous/staticStrings";
import { syncExchange } from "data/api/rest/requests/syncExchange";
import { user_id } from "analytics/analytics";

export type Exchange = {
  author_id: string; // handle for registered, browser session id for guest
  createdAt?: string;
  recipient: {
    channel_name: "cocohub";
    room_id: string; // dashboard channel id - as Vic
  };
  sender: {
    channel_name: "dashboard"; // ask Victor
    room_id: string; // sessionId
  };
  payload: {
    text: string; // user input
    img?: string;
    ssml?: string;
    media?: string;
    conversation_end?: boolean;
    last_in_sequence?: boolean;
  };
};

export const useSyncExchange = ({
  botId,
  sessionId,
  channelId,
}: {
  botId: string;
  sessionId: string;
  channelId: string;
}) => {
  const [result, setResult] = useState({ done: false, success: false });

  const author_id = user_id;

  const reset = useCallback(() => {
    setResult({ done: false, success: false });
  }, []);

  useEffect(() => {
    reset();
  }, [sessionId, botId]);

  const call = useCallback(
    async (userInput: string) => {
      const r: Exchange[] | { error: any } = await syncExchange({
        author_id,
        recipient: {
          channel_name: "cocohub",
          room_id: channelId,
        },
        sender: {
          channel_name: dashboardChannelKey,
          room_id: sessionId,
        },
        payload: {
          text: userInput,
        },
      });

      if (!Array.isArray(r) || !r.length) {
        return {} as CocoResponse;
      }

      r.find((v) => v.payload.conversation_end) &&
        setResult({ done: true, success: true });

      const processed = {
        response: r[0].payload.text,
        component_done: false,
        component_failed: false,
        updated_context: {},
        confidence: 1,
        idontknow: false,
        responses: r.map((v) => v.payload),
      };

      return processed;
    },
    [botId, sessionId, channelId, author_id]
  );

  return { call, result, reset } as const;
};
