import {NumberDictionary, uniqueNamesGenerator} from "unique-names-generator";

import {positiveAdjectives} from "./positiveAdjectives";
import {positiveNouns} from "./positiveNouns";
import { uuid } from "./uuid";

export const humanReadableId = (isForcedUnique:boolean = false) => {
    return uniqueNamesGenerator({
        separator: "-",
        length: 4,
        dictionaries: [
            positiveAdjectives,
            positiveAdjectives,
            positiveNouns,
            NumberDictionary.generate({min: 0, max: 999})
        ]
    }).concat(isForcedUnique ? uuid() : "");
};
