import {
LogoutOptions,
PopupConfigOptions,
PopupLoginOptions,
RedirectLoginOptions
} from "@auth0/auth0-spa-js";
import { getJwtToken } from "data/api/rest/requests/getJwtToken";
import { setUserData } from "data/api/rest/requests/setUserData";
import {
localStorageKeys,USER_AS_PARTICIPANT_ID
} from "dangerous/staticStrings";
import { action,observable,reaction } from "mobx";
import { defaultUserProfile } from "../user/defaults/defaultUserProfile";
import { initializeUser } from "../user/initializeUser";
import { UserProfileCore } from "../user/types";
import { parseJwt } from "./parseJwt";
import { ENVIRONMENT } from "const";


export type UserAuth0 = {
  email?: string;
  email_verified?: boolean;
  name?: string;
  nickname?: string;
  picture?: string;
  sub?: string;
  updated_at?: string;
};

const createUserStore = () => {
  const updateUserData = async (userData: UserProfileCore) => {
    const r = await setUserData(userData);
    const r2 = await initializeUser(store);
    return r && r2;
  };

  const store = observable.object({
    ...defaultUserProfile(),
    updateUserData,
    refetchData: action(async (user: UserAuth0) => {
      return await initializeUser(store, user);
    }),
    editedHandle: "",
    selectedParticipant: USER_AS_PARTICIPANT_ID,
  });

  return store;
};

export const createAuthStore = () => {
  const user = observable.object({} as UserAuth0);

  const store = observable.object({
    error: "",
    isAuthenticated: false,
    isLoading: false,
    isReady: false,
    jwtToken: "",
    authActionType: "Sign Up" as "Sign Up" | "Log In",
    accessToken: "",
    user,
    handleRedirectCallback: () => Promise.resolve(""),
    getAccessTokenSilently: (
      options?: PopupLoginOptions,
      config?: PopupConfigOptions
    ) => Promise.resolve(""),
    loginWithRedirect: (
      options?: RedirectLoginOptions,
      config?: PopupConfigOptions
    ) => Promise.resolve(""),
    loginWithPopup: (
      options?: PopupLoginOptions,
      config?: PopupConfigOptions
    ) => Promise.resolve(""),
    logout: (opts: LogoutOptions = {}) => {
      store.jwtToken = "";
    },
    userStore: createUserStore(),
    refetchToken: action(async () => {
      const t = await getJwtToken();
      store.jwtToken = t;
      const parsedToken = parseJwt<{ user_id: number }>(t);
      sessionStorage.setItem(
        localStorageKeys["graphql-user-token"],
        String(parsedToken.user_id)
      );
      return t;
    }),
  });

  //@ts-ignore
  window._loq = window._loq || []; // lucky orange que

  // tag as guest user for lucky orange
  reaction(
    () => {
      return {
        isLoggedIn: store.isAuthenticated,
        isAuthComplete: !store.isLoading,
      };
    },
    ({ isLoggedIn, isAuthComplete }) => {
      // @ts-ignore
      isAuthComplete && !isLoggedIn && window._loq?.push(["tag", "guest-user"]);
    }
  );

  const dismissInitUserReaction = reaction(
    () => {
      return {
        isLoading: store.isLoading,
        isAuthenticated: store.isAuthenticated,
        user: store.user,
        accessToken: store.accessToken,
      };
    },
    ({ isLoading, isAuthenticated, user }) => {
      if (
        isLoading ||
        !isAuthenticated ||
        !!store.userStore.profile.handle ||
        !user ||
        !store.accessToken
      )
        return;
      initUser();
    }
  );

  const initUser = async () => {
    dismissInitUserReaction();
    localStorage.removeItem("isNoRedirect");
    await store.userStore.refetchData(store.user);
    await store.refetchToken();
    store.isReady = true;
  };

  // initalize if logged in - set bearer token, initialize user
  reaction(
    () => {
      return { isLoading: store.isLoading };
    },
    async ({ isLoading }) => {
      store.isReady = false;
      if (isLoading) return;

      let token = "";
      try {
        const t = await store.getAccessTokenSilently({
          authorizationParams: {
            audience: ENVIRONMENT.BV_ADDR + '/api'
          }
        });
        token = t;
      } catch (error) {
        // probably refresh token expired, or guest
        store.isAuthenticated = false;
        console.log(error);
      }

      (globalThis as { [key: string]: any }).getToken =
        store.getAccessTokenSilently;
      (globalThis as { [key: string]: any }).isGuest = !token;

      token && (store.accessToken = token);
      if (!token) store.isReady = true; // user is guest
    }
  );

  return store;
};

export type AuthStore = ReturnType<typeof createAuthStore>;
export type UserStore = ReturnType<typeof createUserStore>;
