import {Button, ButtonBase, CircularProgress, makeStyles, Portal, Theme, Typography, useTheme} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DoneIcon from "@material-ui/icons/Done";
import {analytics} from "analytics/analytics";
import {AnalyticType} from "analytics/AnalyticType";
import clsx from "clsx";
import React, {useRef, useState} from "react";
import useOnFirstRender from "utils/hooks/useOnFirstRender";
import {Sparkles} from "utils/sparkle/Sparkles";

export type MainButtonVariant = "primary" | "async";

const getColor = (variant: MainButtonVariant, disabled: boolean, theme: Theme, isHover: boolean) => {
    return theme.custom.palette.d.alt;
};

const getBgColor = (
    variant: MainButtonVariant,
    isLoading: boolean,
    disabled: boolean,
    theme: Theme,
    isHover: boolean
) => {
    if (disabled) return theme.custom.palette.e.alt;
    if (isLoading) return theme.custom.palette.e.main;
    return isHover ? theme.custom.palette.e.main : theme.custom.palette.a.main;
};

const getWidth = (
    isHover: boolean,
    isLoading: boolean,
    expandingContentWidth: number,
    mainContentWidth: number,
    theme: Theme
) => {
    if (isLoading) return expandingContentWidth + 30;
    return isHover ? expandingContentWidth - theme.spacing(1) : mainContentWidth;
};

const useStyles = ({
    expandingContentWidth,
    mainContentWidth,
    isLoading,
    disabled,
    variant,
    isHover,
    isFlat = false
}: {
    expandingContentWidth: number;
    mainContentWidth: number;
    isLoading: boolean;
    disabled: boolean;
    isHover: boolean;
    variant: MainButtonVariant;
    isFlat?: boolean;
}) =>
    makeStyles((theme) => {
        const color = getColor(variant, disabled, theme, isHover);
        const background = getBgColor(variant, isLoading, disabled, theme, isHover);

        const width = getWidth(isHover, isLoading, expandingContentWidth, mainContentWidth, theme);
        const expandingContainerWidth = isHover ? expandingContentWidth - mainContentWidth : "0px";

        return {
            childContainer: {
                whiteSpace: "nowrap"
            },
            container: {
                display: "flex",
                justifyContent: "space-between",
                position: "relative",
                borderRadius: theme.spacing(6)
            },
            contained: {
                background,
                color,
                boxShadow: isFlat ? "" : theme.shadows[3],
                textTransform: "none" as "none",
                borderRadius: theme.spacing(6),
                padding: theme.spacing(1, 4),
                margin: 0,
                left: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                direction: "ltr",
                width,
                position: "relative",
                transition: theme.transitions.create("all", {
                    easing: theme.transitions.easing.easeInOut,
                    duration: theme.transitions.duration.complex
                })
            },
            expandingContainer: {
                transition: theme.transitions.create("all", {
                    easing: theme.transitions.easing.easeInOut,
                    duration: theme.transitions.duration.complex
                }),
                width: expandingContainerWidth,
                transform: isHover ? `translateX(${-theme.spacing(1)}px)` : `translateX(-${mainContentWidth / 2}px)`
            },
            expandingContent: {
                display: "flex",
                alignItems: "center",
                alignContent: "center",
                whiteSpace: "nowrap",
                overflow: "hidden"
            },
            hidden: {visibility: "hidden", position: "absolute", left: 0, top: 0},
            mainContent: {
                transition: theme.transitions.create("all", {
                    easing: theme.transitions.easing.easeInOut,
                    duration: theme.transitions.duration.complex
                }),
                whiteSpace: "nowrap"
            },
            loadingContainer: {
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center"
            },
            divider: {
                width: theme.spacing(1)
            }
        };
    });

export const MainButton: React.FC<{
    variant?: MainButtonVariant;
    hoverContent?: React.ReactNode;
    onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    isLoading?: boolean;
    isAdding?: boolean;
    disabled?: boolean;
    isSparkles?: boolean;
    analytics_event?: AnalyticType;
    analytics_data?: any;
    isFlat?: boolean;
}> = ({
    children,
    variant = "primary",
    hoverContent,
    onClick,
    isLoading = false,
    disabled = false,
    isSparkles = false,
    isAdding = false,
    analytics_event,
    analytics_data,
    isFlat
}) => {
    hoverContent = hoverContent ? (
        hoverContent
    ) : isAdding ? (
        <AddIcon fontSize="small" />
    ) : (
        <DoneIcon fontSize="small" />
    );

    const expandingContent = useRef<HTMLDivElement | null>(null);
    const mainContent = useRef<HTMLDivElement | null>(null);

    const [expandingContentWidth, setExpandingContentWidth] = useState(0);
    const [mainContentWidth, setMainContentWidth] = useState(0);

    useOnFirstRender(() => {
        expandingContent.current && setExpandingContentWidth(expandingContent.current.offsetWidth);
        mainContent.current && setMainContentWidth(mainContent.current.offsetWidth);
    }, [expandingContent.current, mainContent.current, hoverContent, children]);

    const [isHover, setIsHover] = useState(false);

    const theme = useTheme();

    const hoverOn = () => setIsHover(true);
    const hoverOff = () => setIsHover(false);

    const classes = useStyles({
        expandingContentWidth,
        mainContentWidth,
        isLoading,
        disabled,
        isHover,
        variant,
        isFlat: isFlat ?? false
    })();

    const handleOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        onClick && onClick(e);
        const analyticsObject = {} as {type: AnalyticType; data?: any};
        analytics_data && (analyticsObject.data = analytics_data);
        analytics_event && (analyticsObject.type = analytics_event) && analytics(analyticsObject);
        setIsHover(false);
    };

    return (
        <>
            <span style={{position: "relative"}}>
                <Sparkles isOn={isSparkles}>
                    <ButtonBase
                        onMouseOver={hoverOn}
                        onMouseOut={hoverOff}
                        onMouseLeave={hoverOff}
                        disabled={disabled || isLoading}
                        onClick={handleOnClick}
                        className={classes.contained}>
                        <div className={clsx(classes.expandingContent, classes.expandingContainer)}>
                            {hoverContent}
                            <span className={classes.divider} />
                        </div>
                        <div className={classes.childContainer}>
                            {isLoading ? (
                                <div className={classes.loadingContainer}>
                                    <CircularProgress
                                        style={{color: "white", marginRight: theme.spacing(1)}}
                                        thickness={3}
                                        size={19}
                                    />
                                    <Typography component="span">Updating</Typography>
                                </div>
                            ) : (
                                <Typography component="span">{children}</Typography>
                            )}
                        </div>
                    </ButtonBase>
                </Sparkles>
            </span>
            <Portal>
                <div id="invisible-content" className={classes.hidden}>
                    <div ref={expandingContent} className={classes.container}>
                        <ButtonBase style={{width: "fit-content"}} className={classes.contained}>
                            <div className={classes.childContainer}>
                                <Typography component="span">{children}</Typography>
                            </div>
                            <div className={classes.expandingContent}>
                                {hoverContent}
                                <span className={classes.divider} />
                            </div>
                        </ButtonBase>
                    </div>
                </div>
            </Portal>
            <Portal>
                <div id="invisible-content" className={classes.hidden}>
                    <div ref={mainContent} className={classes.container}>
                        <Button style={{width: "fit-content"}} className={classes.contained}>
                            <div className={classes.childContainer}>
                                <Typography component="span">{children}</Typography>
                            </div>
                        </Button>
                    </div>
                </div>
            </Portal>
        </>
    );
};
