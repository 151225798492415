import styled from "styled-components";
import background from "theme/components/styles/background.svg";
import { flexCenter, flexColBetween, flexColCenter } from "ui-kit/components";
import { Box } from "../layout";

// web RTC
export const WebRtcWrapper = styled.div<{ isNaked?: boolean }>`
  background-color: ${({ theme }) => theme.colors.background.marketingDark};
  display: ${({ isNaked }) => (isNaked ? "flex" : "")};
  background-image: url(${background});
  background-repeat: repeat;
  background-size: 74px 74px;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

export const VideoWrapper = styled.div<{ isFullScreen?: boolean }>`
  position: relative;
  position: ${({ isFullScreen }) => (isFullScreen ? "fixed" : "relative")};
  width: ${({ isFullScreen }) => (isFullScreen ? "100vw" : "100%")};
  height: ${({ isFullScreen }) => (isFullScreen ? "100vh" : "")};
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0px;
  top: 0px;
  z-index: 100;
  pointer-events: "none";
  @media (max-width: ${({ theme }) => `${theme.responsive.tablet}px`}) {
    min-height: 120px;
  }
`;

export const VideoWrapperLayout = styled.div`
  display: flex;
  width: fit-content;
  height: 100%;
  position: relative;
  @media (max-width: ${({ theme }) => `${theme.responsive.tablet}px`}) {
    padding: ${({ theme }) => `${theme.spacing(3)}px ${theme.spacing(0)}px`};
    width: 100%;
  }
`;

export const WebRtcVideo = styled.video`
  background: black;
  max-width: 100vw;
  position: relative;
  border-radius: ${({ theme }) => `${theme.spacing(3)}px`};
  @media (min-width: ${({ theme }) => `${theme.responsive.tablet}px`}) {
    height: 100%;
    min-height: 60vh;
  }
  @media (max-width: ${({ theme }) => `${theme.responsive.tablet}px`}) {
    width: 100%;
  }
`;

export const StandaloneVideoScrollContainer = styled.div`
  height: 100%;
  overflow: auto;
`;

export const StandaloneVideoLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

export const StandaloneVideoHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => `${theme.spacing(4)}px`};
  padding: ${({ theme }) => `${theme.spacing(2, 0, 2, 0)}`};
`;

export const VideoHeaderText = styled(Box)`
  align-items: left;
  @media (max-width: ${({ theme }) => `${theme.responsive.tablet}px`}) {
    align-items: center;
  }
`;

export const FullscreenButton = styled.div`
  position: absolute;
  left: ${({ theme }) => `${theme.spacing(1)}px`};
  bottom: ${({ theme }) => `${theme.spacing(3)}px`};
  cursor: pointer;
  pointer-events: "all";
`;

export const BotAvatar = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

// no connection
export const NoConnectionWrapper = styled.div`
  ${flexColBetween}
  background: ${({ theme }) => theme.colors.background.marketingDark};
  position: relative;
  border-radius: ${({ theme }) => theme.spacing(3, 3, 0, 0)};
  height: 100%;
  width: 100%;
`;

export const NoConnectionFrame = styled.div`
 
  ${flexColCenter}
  gap: ${({ theme }) => `${theme.spacing(5)}px`};
  width: 100%;
  border: 2px solid ${({ theme }) => theme.colors.background.light.tint};
  border-radius: ${({ theme }) => `${theme.spacing(3)}px`};
  position: relative;
  height: 100%;

  padding: ${({ theme }) => theme.spacing(8, 2)};
`;

export const ChatBubble = styled.div`
  background: ${({ theme }) => theme.colors.alert.negative};
  border-radius: ${({ theme }) => theme.spacing(3, 3, 3, 0)};
  padding: ${({ theme }) => theme.spacing(1, 2)};
  h4 {
    color: ${({ theme }) => theme.colors.text.light};
    font-size: 28px;
    margin: 0;
  }
  @media (max-width: ${({ theme }) => `${theme.responsive.tablet}px`}) {
    h4 {
      font-size: 16px;
    }
  }
`;

export const SubtitleLink = styled.div`
  display: inline-block;
  margin-left: ${({ theme }) => `${theme.spacing(1)}px`};
  color: ${({ theme }) => theme.colors.primary.blue.main};
  font-weight: 600;
`;
export const VideoOffWrapper = styled.div`
  position: absolute;
  top: -10px;
  left: -10px;
`;
// Reusable components
export const Footer = styled.div`
  ${flexCenter}
  position: absolute;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.background.dark.main};
  width: 100%;
  padding: ${({ theme }) => theme.spacing(1.5, 2)};
  border-radius: ${({ theme }) => `${theme.spacing(3)}px`};
`;

export const MainHeading = styled.h4<{ color: string }>`
  margin: 0;
  font-size: ${({ theme }) => `${theme.typography.base}px`};
  color: ${(props) => props.color};
  font-weight: bold;
`;

export const SubHeading = styled.h6`
  margin: 0;
  font-size: ${({ theme }) => `${theme.typography.base}px`};
  color: ${({ theme }) => theme.colors.text.light};
  font-weight: lighter;
`;

export const BannerSubheader = styled(SubHeading)`
  padding: 0px;
  text-align: left;
  font-size: ${({ theme }) => `14px`}; //TODO - use proper fonts from theme
  @media (max-width: ${({ theme }) => `${theme.responsive.tablet}px`}) {
    padding: ${({ theme }) => `${theme.spacing(0)}px ${theme.spacing(2)}px`};
    text-align: center;
  }
`;

export const Banner = styled.div`
  height: 182px;
  flex-wrap: wrap;
  background: linear-gradient(108.64deg, #141326 -12.08%, #2195f3 135.34%);
  border-radius: ${({ theme }) => `${theme.spacing(3)}px`};
  display: flex;
  justify-content: space-around;
  gap: ${({ theme }) => `${theme.spacing(5)}px`};
  margin: ${({ theme }) => `${theme.spacing(2)}px`};
  min-width: 53vw;
  @media (max-width: ${({ theme }) => `${theme.responsive.tablet}px`}) {
    flex-direction: column-reverse;
    align-items: center;
    gap: 0;
    height: fit-content;
    flex-wrap: nowrap;
  }
`;

export const BannerImage = styled.img`
  max-width: 282px;
  max-height: 182px;
`;

export const PopupTitle = styled.h3`
  font-size: ${({ theme }) => theme.typography.base * 1.2};
  font-weight: bold;
  margin: 0;
`;

export const ImageWrapper = styled.div`
  pointer-events: none;
  transform: translateY(304px);
  width: 254px;
  height: 848px;
  @media (max-width: ${({ theme }) => `${theme.responsive.tablet}px`}) {
    display: none;
  }
`;

// Share Badge component
export const ShareComponent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  width: fit-content;
  height: 324px;
  overflow: hidden;
  padding: ${({ theme }) => theme.spacing(2, 3)};
  background: ${({ theme }) => theme.colors.background.light.tint};
  box-shadow: ${({ theme }) => theme.shadow(3)};
  border-radius: ${({ theme }) => theme.spacing(5, 0, 5, 5)};
  max-width: ${({ theme }) => `calc(100vw - ${theme.spacing(4)}px)`};
  @media (max-width: ${({ theme }) => `${theme.responsive.tablet}px`}) {
    height: 414px;
  }
`;

export const Divider = styled.div`
  border-left: 1px solid rgba(66, 91, 111, 0.2);
  height: 32px;
  margin: ${({ theme }) => theme.spacing(0, 1)};
`;

// Modal popup styles

export const SocialBadge = styled.div`
  cursor: pointer;
  transition: ${({ theme }) => theme.transition()};
  background-color: ${({ theme }) => theme.colors.background.light.main};
  border: ${({ theme }) => `${theme.spacing(2)}px`};
  color: ${({ theme }) => theme.colors.primary.blue.main};
  padding: ${({ theme }) => `${theme.spacing(1)}px`};
  gap: ${({ theme }) => `${theme.spacing(1)}px`};
  border-radius: ${({ theme }) => `${theme.spacing(2)}px`};
  min-width: ${({ theme }) => `${theme.spacing(12)}px`};
  font-size: 14px;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: ${({ theme }) => theme.shadow(0)};
  &:hover {
    background-color: ${({ theme }) => theme.colors.background.landing};
    box-shadow: ${({ theme }) => theme.shadow(5)};
  }
`;

export const Label = styled.span`
  padding-top: ${({ theme }) => `${theme.spacing(1)}px`};
  padding-left: ${({ theme }) => `${theme.spacing(2)}px`};
  color: ${({ theme }) => theme.colors.primary.blue.main};
`;

export const BadgeWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => `${theme.spacing(2)}px`};
  @media (max-width: ${({ theme }) => `${theme.responsive.tablet}px`}) {
    flex-direction: column;
    margin: ${({ theme }) => theme.spacing(3, 0)};
    ${SocialBadge} {
      flex-direction: row;
    }
  }
`;

export const TextBubble = styled.div`
  padding: ${({ theme }) => theme.spacing(2, 3)};
  background: ${({ theme }) => theme.colors.primary.blue.main};
  border-radius: ${({ theme }) => theme.spacing(3, 3, 3, 0)};
  color: ${({ theme }) => theme.colors.text.light};
  width: fit-content;
  @media (max-width: ${({ theme }) => `${theme.responsive.tablet}px`}) {
    display: none;
  }
`;

// temp field
export const ShareFilled = styled.button`
  gap: ${({ theme }) => `${theme.spacing(1.2)}px`};
  border-radius: ${({ theme }) => `${theme.spacing(5)}px`};
  padding: ${({ theme }) => theme.spacing(1, 2)};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 18px;
  background: none;
  border: 1px solid ${({ theme }) => theme.colors.disabledMain};
  color: ${({ theme }) => theme.colors.disabledMain};
  white-space: nowrap;
  max-width: 424px;
  @media (max-width: ${({ theme }) => `${theme.responsive.tablet}px`}) {
    max-width: 100%;
  }
`;
