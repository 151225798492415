import { NavDrawerContext, NavDrawerState } from "./NavDrawerContext";
import { useCallback, useContext } from "react";

export const useNavDrawerContext = () => {
  const { getValue } = useContext(NavDrawerContext);

  const select = useCallback(
    function <T>(callback: (s: NavDrawerState) => T) {
      return getValue(callback);
    },
    [getValue]
  );

  const dispatch = useContext(NavDrawerContext).dispatch;
  const actions = useContext(NavDrawerContext).actions;

  return { select, dispatch, actions } as const;
};
