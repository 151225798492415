import { Typography, makeStyles } from "@material-ui/core";

import React from "react";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      background: "transparent",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: theme.spacing(1, 2),
      height: theme.spacing(8),
      gap: theme.spacing(1),
    },
    bot: {
      background: "transparent",
      display: "flex",
      alignItems: "center",
      height: theme.spacing(8),
      gap: theme.spacing(1),
    },
    vCenter: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    text: {
      fontWeight: 600,
      color: theme.custom.palette.d.alt,
      maxWidth: theme.spacing(18),
    },
  };
});

export type HeaderParams = { buttonsColor?: string };

export const Header: React.FC<{
  name: string;
  avatar?: React.ReactNode;
  header?: HeaderParams;
  rightElement?: React.ReactNode;
}> = ({ name, avatar, rightElement }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.bot}>
        {avatar}
        <div className={classes.vCenter}>
          <Typography noWrap className={classes.text} variant="body1">
            {name}
          </Typography>
        </div>
      </div>
      {rightElement}
    </div>
  );
};
