import {PLAN} from "pricing/plans/choose-plan/ChoosePlan";

export const planToMaxSessions = new Map<PLAN, number>([
    [PLAN.basicMonthly, 2500],
    [PLAN.basicAnnually, 2500],
    [PLAN.proMonthly, 5000],
    [PLAN.proAnnually, 5000],
    [PLAN.test, 1000],
    [PLAN.custom, 0]
]);
