import { Button, Paper, Typography, makeStyles } from "@material-ui/core";

import React from "react";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      padding: theme.spacing(1),
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      width: "100%",
    },
    textContainer: {
      flexBasis: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    buttonsContainer: {
      display: "flex",
      justifyContent: "space-around",
    },
  };
});

export const ErrorNotification: React.FC<{
  error?: Error | string;
  reset: () => void;
}> = ({ error, reset }) => {
  const classes = useStyles();

  if (!error) return null;

  return (
    <Paper className={classes.container}>
      <div className={classes.textContainer}>
        <Typography align="center" color="error">
          {`Could not reach the server, reason ${error}`}
        </Typography>
      </div>
      <div className={classes.buttonsContainer}>
        <Button onClick={reset}>reset session</Button>
      </div>
    </Paper>
  );
};
