import {action, reaction} from "mobx";

import {ProjectsStore} from "../createProjects";
import {getConfig} from "data/api/rest/requests/getConfig";

// fetch project data and update state for published status detection. TODO: see if is reduntant, remove if yes
export const runUpdateCompiledOnSelected = (store: ProjectsStore) =>
    reaction(
        () => ({selected: store.selected}),
        action(async ({selected}) => {
            if (!selected || store.compiledGlues[store.selected] || !store.projects[store.selected]?.componentId)
                return;
            const proj = (await getConfig({humanId: selected})) || {};
            store.compiledGlues[store.selected] = proj.glue_v2 || {};
        })
    );
