import { Typography, makeStyles } from "@material-ui/core";

import { AnalyticType } from "analytics/AnalyticType";
import { CHANNEL_NAME } from "dangerous/staticStrings";
import { ChannelDialogParams } from "channels/channelsMap";
import { ChannelType } from "channels/types";
import { DividerElement } from "utils/elments/DividerElement";
import { MainButton } from "theme/components/elements/buttons/MainButton";
import React from "react";
import { Subtitle } from "theme/components/elements/Subtitle";
import { TextButton } from "theme/components/elements/buttons/TextButton";
import copy from "clipboard-copy";
import { useChannelFunctions } from "data/api/gql/hooks/useChannelFunctions";
import { wordpressInfographics } from "channels/infographics";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      position: "relative",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      marginTop: theme.spacing(4),
    },
    bodyContainer: {
      paddingTop: theme.spacing(3),
      display: "flex",
      height: "100%",
    },
    explanation: {
      width: "50%",
      paddingRight: theme.spacing(8),
    },
    buttonContainer: {
      width: "50%",
      display: "flex",
      justifyContent: "flex-end",
    },
    button: {
      width: "100%",
      alignSelf: "flex-end",
    },
    footerContainer: {
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      paddingTop: theme.spacing(2),
    },
    infographics: {
      height: "auto",
      width: "auto",
      paddingTop: theme.spacing(4),
      textAlign: "center",
      paddingBottom: theme.spacing(2),
    },
  };
});

const newPageUrl = "https://wordpress.org/plugins/cocohub/";

export const WordpressChannelBody: React.FC<ChannelDialogParams> = ({
  botId,
  botName,
  botChannels,
  close,
}) => {
  const classes = useStyles();

  const { create, remove, update, loading } = useChannelFunctions({ botId });

  const channel = botChannels.find(
    (b) => b.jsonConfig.channel_name === ChannelType.wordpress
  );
  const channelId = channel?.id;
  const channelGId = channel?.nodeId ? `02-${channel?.nodeId}` : undefined;

  const copyChannelId = () => {
    if (!channelGId) return;
    copy(String(channelGId));
  };

  const goToPlugin = () => {
    window.open(newPageUrl, "_blank");
  };

  const isConnected = botChannels.some((n) => {
    return n.jsonConfig.channel_name === ChannelType.embedded;
  });

  const confirmDelete = () => {
    if (!channelId) return;
    remove({ variables: { id: channelId } });
    close();
  };

  const confirmCreate = () => {
    create({
      variables: {
        channelName: CHANNEL_NAME.WORDPRESS,
        jsonConfig: { channel_name: ChannelType.wordpress },
        botId: parseInt(botId),
      },
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.bodyContainer}>
        <Typography className={classes.explanation}>
          Publish <b>{botName}</b> to your Wordpress website for your audience
          to start chatting with your virtual human.
        </Typography>
        <div className={classes.buttonContainer}>
          <MainButton
            analytics_event={
              AnalyticType["wordpress-channel-dialog-go-to-plugin"]
            }
            onClick={goToPlugin}
          >
            Go to plugin
          </MainButton>
        </div>
      </div>
      <div>
        <Subtitle title={"How To"} color={"primary"} />
        <div className={classes.infographics}>{wordpressInfographics}</div>
      </div>
      <div className={classes.footerContainer}>
        <MainButton onClick={channelId ? copyChannelId : confirmCreate}>
          {channelId ? "Copy Channel ID" : "Connect"}
        </MainButton>
        <DividerElement w={2} />
        {isConnected ? (
          <TextButton
            variant={"secondary"}
            analytics_event={AnalyticType.wordpress_channel_dialog_disconnect}
            onClick={confirmDelete}
          >
            Disconnect
          </TextButton>
        ) : null}
      </div>
    </div>
  );
};
