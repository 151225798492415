import {analytics} from "analytics/analytics";
import {AnalyticType} from "analytics/AnalyticType";
import {defaultEmailValue, entryId} from "dangerous/staticStrings";
import {cloneDeep, debounce} from "lodash";
import {action, observable, reaction} from "mobx";
import {humanReadableId} from "utils/humanReadableId";
import {CanvasStore} from "../canvas/createCanvasStore";
import {EdgeV2, GlueStateV2, NodeAction, NodeOptions, NodeTypeV2, NodeV2, Position} from "../types";
import {StackEntry, UndoRedoStore} from "../undoRedo/createUndoRedo";
import {makeDefaultActions} from "./makeDefaultActions";

const makeDefaultOptions = (type: NodeTypeV2) => {
    let options = {extraParams: {}, params: {}} as NodeOptions;
    switch (type) {
        case NodeTypeV2.email:
            options.email = {
                email: defaultEmailValue,
                title: "Hi ${context.user.firstName}",
                body: "Dear Mr ${context.user.lastName}, thank you for your input",
                emailUpdated: false
            };
            if (!options.params) options.params = {};
            break;
        case NodeTypeV2.saveInput:
        case NodeTypeV2.saveAggregated:
        case NodeTypeV2.saveParams:
        case NodeTypeV2.saveParamsAggregated:
            break;
        default:
            break;
    }
    return options;
};

export const createGraphStore = (sharedGraph: StackEntry, undoRedo: UndoRedoStore, canvas: CanvasStore) => {
    const addNode = action(
        (p: {
            name?: string;
            type: NodeTypeV2;
            component: string;
            id?: string;
            position?: Position;
            entryPosition?: Position;
            options?: NodeOptions;
            actions?: NodeAction[];
            edges?: string[];
        }) => {
            undoRedo.update();
            const {
                name,
                type,
                component,
                id = humanReadableId(),
                position = {
                    x: canvas.mousePosition.x / canvas.scale,
                    y: canvas.mousePosition.y / canvas.scale
                },
                entryPosition = {x: 0, y: 0},
                options = makeDefaultOptions(type),
                actions = makeDefaultActions(type),
                edges = []
            } = p;
            const node = observable({
                selected: false,
                inspected: false,
                name,
                type,
                component,
                id,
                position,
                entryPosition,
                options,
                actions,
                edges
            });
            store.nodes[id] = node;
            analytics({type: AnalyticType["node_added"]});
            return node;
        }
    );

    const deleteNode = action((id: string) => {
        const node = store.nodes[id];
        if (!node) return;
        undoRedo.update();
        node.edges.forEach((edge) => deleteEdge(edge, true));
        Object.values(store.edges).forEach((e) => {
            e.to.nodeId === node.id && deleteEdge(e.id, true);
        });
        delete store.nodes[id];
    });

    const duplicateNode = action((nodeToCopy?: NodeV2, position?: {x: number; y: number}) => {
        const node = nodeToCopy || store.nodes[store.inspectedNodeId];
        if (!node || node.type === NodeTypeV2.entry) return;

        store.addNode({
            type: node.type,
            component: node.component,
            position: position || {y: node.position.y, x: node.position.x + 250},
            options: cloneDeep(node.options),
            actions: cloneDeep(node.actions)
        });
    });

    const deleteEdge = action((id: string, skipUpdateUndo?: boolean) => {
        !skipUpdateUndo && undoRedo.update();
        const nodeFrom = Object.values(store.nodes).find((node) => node.edges.includes(id));
        if (nodeFrom) {
            nodeFrom.edges = nodeFrom.edges.filter((e) => e !== id);
        }
        delete store.edges[id];
    });

    const addEdge = action(
        (
            p: {fromNode: string; toNode?: string; toPosition?: Position; action: string},
            isSkippingUpdateUndo?: boolean
        ) => {
            !isSkippingUpdateUndo && undoRedo.update();
            const edge = {
                isHighlighted: false,
                action: p.action,
                id: humanReadableId(),
                from: {
                    nodeId: p.fromNode
                },
                to: {
                    nodeId: p.toNode,
                    position: p.toPosition
                }
            };
            const node = store.nodes[p.fromNode];
            if (!node) throw new Error(`Node ${p.fromNode} not found`);
            node.edges.push(edge.id);
            store.edges[edge.id] = edge;

            return edge;
        }
    );

    const addAction = action((node: NodeV2, action: NodeAction) => {
        undoRedo.update();

        if (node.type === NodeTypeV2.navigation && !action.navigationData) {
            action.navigationData = {branch_id: action.id, keywords: []};
        }

        node.actions.push(action);

        return action;
    });

    const deleteAction = action((action: NodeAction, node: NodeV2) => {
        undoRedo.update();
        const edgesToDelete = Object.values(store.edges).filter((e) => e.action === action.id);
        edgesToDelete.forEach((e) => deleteEdge(e.id, true));
        node.actions = node.actions.filter((a) => a.id !== action.id);
    });

    const store = observable({
        copyNodeId: "",
        selectedNodeId: "",
        inspectedNodeId: "",
        copyNode: observable({} as NodeV2),
        get nodes() {
            return sharedGraph.nodes;
        },
        set nodes(nodes: {[key: string]: NodeV2}) {
            sharedGraph.nodes = nodes;
        },
        get edges() {
            return sharedGraph.edges;
        },
        set edges(edges: {[key: string]: EdgeV2}) {
            sharedGraph.edges = edges;
        },
        copySelectedNode: action(() => {
            store.copyNode = store.nodes[store.inspectedNodeId];
        }),
        connectEntryTo: action(({node}: {node: NodeV2}) => {
            const entry = store.nodes[entryId];
            if (!entry) return;
            if (!entry.edges.length) {
                store.addEdge({fromNode: entry.id, toNode: node.id, action: entryId});
            } else {
                const edge = store.edges[entry.edges[0]];
                if (!edge) return;
                edge.to.nodeId = node.id;
            }
        }),
        pasteStoredNode: debounce(
            action(() => {
                const newPosition = {
                    x: canvas.mousePosition.x / canvas.scale,
                    y: canvas.mousePosition.y / canvas.scale
                };
                if (JSON.stringify(store.copyNode) == "{}") return;
                store.duplicateNode(store.copyNode, newPosition);
            }),
            300
        ),
        repopulate: action((state: GlueStateV2) => {
            Object.keys(store.nodes).forEach((k) => delete store.nodes[k]);
            Object.keys(store.edges).forEach((k) => delete store.edges[k]);
            Object.keys(state.nodes).forEach((k) => {
                state.nodes[k].inspected = false;
                store.nodes[k] = state.nodes[k];
            });
            Object.keys(state.edges).forEach((k) => (store.edges[k] = state.edges[k]));
            store.inspectedNodeId = "";
            store.selectedNodeId = "";
        }),
        addNode,
        deleteNode,
        duplicateNode,
        addEdge,
        deleteEdge,
        addAction,
        deleteAction
    });

    reaction(
        () => {
            const inspectedNodes = Object.values(store.nodes).filter((node) => node.inspected);
            return {inspectedNodes};
        },
        ({inspectedNodes}) => {
            if (!inspectedNodes.length) {
                store.inspectedNodeId = "";
            } else if (inspectedNodes.length === 1) {
                store.inspectedNodeId = inspectedNodes[0].id;
            } else {
                action(() => {
                    inspectedNodes.forEach(action((n) => n.id === store.inspectedNodeId && (n.inspected = false)));
                })();
            }
        }
    );

    reaction(
        () => {
            return canvas.addNodeMenu.isOpen;
        },
        (visible) => {
            if (visible || !canvas.addNodeMenu.connectingToEdge) return;
            const edge = store.edges[canvas.addNodeMenu.connectingToEdge];
            if (!edge || edge.to.nodeId) return;
            store.deleteEdge(edge.id);
        }
    );

    return store;
};

export type GraphStore = ReturnType<typeof createGraphStore>;
