import {EmbeddedData} from "../types";

export const makeEmbedCode = (data: EmbeddedData, botName: string) => {
    return `
<div
    id="cocobot"
    bot_name="${botName || ""}"
    fab_right="${data.fab_right || ""}"
    fab_bottom="${data.fab_bottom || ""}"
    channel_id="${data.channel_id || ""}"
    bot_greeting="${data.bot_greeting || ""}"
    source_language_code="${data.source_language_code || ""}"
    is_fabless="${data.is_fabless ? "true" : "false"}"
    is_rtl="${data.is_rtl ? "true" : "false"}"
    is_open_on_start="${data.is_open_on_start ? "true" : "false"}"
    height="${data.height || ""}"
    width="${data.width || ""}"
>
    <script src="https://storage.googleapis.com/coco_public/chatwindow/v4/main.js"></script>
</div>
    `;
};
