import {
  GlueConfigV2,
  GlueTransition,
} from "marketplace-2/store/components/types";
import {
  GlueStateV2,
  NodeAction,
  NodeTypeV2,
  NodeV2,
} from "bots-studio/store/types";

import { toEmailParams } from "./toEmailParams";

const getParameters = (node: NodeV2, glue: GlueStateV2) => {
  const params = {} as { [key: string]: string };
  const acts = [...node.actions];

  if (node.options.email) {
    const email = node.options.email;
    const emailParams = toEmailParams(email);
    Object.keys(emailParams).forEach((key) => (params[key] = emailParams[key]));
  }

  const np = node.options.params || {};
  Object.keys(np).forEach((key) => (params[key] = JSON.stringify(np[key])));

  const navEdges = node.edges
    .map((e) => glue.edges[e])
    .filter((e) => {
      if (!e) return false;
      const act = acts.find((a) => a.id === e.action);
      if (!act || !act.navigationData) return false;
      return true;
    });

  if (navEdges.length) {
    acts.sort((a, b) => a.index - b.index);
    const collectedData = [] as NodeAction[];
    navEdges.forEach((edge) => {
      const navData = acts.find((act) => act.id === edge.action);
      navData && collectedData.push(navData);
    });
    const sortedData = collectedData
      .sort((a, b) => a.index - b.index)
      .map((a) => a.navigationData!);
    params["branches"] = JSON.stringify(sortedData);
  }

  const ep = node.options.extraParams || {};

  Object.keys(ep).forEach((key) => {
    const p = ep[key];
    params[p.name] = JSON.stringify(p.value);
  });

  return params;
};

const getOn = (node: NodeV2, glue: GlueStateV2) => {
  const links = node.edges.map((edge) => glue.edges[edge]).filter((e) => !!e);
  const nodeActions = node.actions;
  const on = links.reduce((acc, cur) => {
    const to = cur.to.nodeId;
    if (!to) return acc;
    const actionId = cur.action;
    const action = nodeActions.find((a) => a.id === actionId);
    if (!action) return acc;
    const transition = {
      target_node_id: to,
    } as GlueTransition;
    if (node.type === NodeTypeV2.navigation) {
      transition.output_name = "control";
      transition.output_value = actionId;
    } else if (action.data === "component_failed") {
      transition.success = false;
    } else {
      transition.success = true;
    }
    acc.push(transition);
    return acc;
  }, [] as GlueTransition[]);

  if (node.type === NodeTypeV2.navigation) {
    on.sort((a, b) => {
      const aa = nodeActions.find(
        (act) => act.navigationData?.branch_id === a.output_value
      );
      const ab = nodeActions.find(
        (act) => act.navigationData?.branch_id === b.output_value
      );
      if (!aa || !ab) return 0;
      return aa.index - ab.index;
    });
  }
  return on;
};

export const toConfig = (glue: GlueStateV2) => {
  const nodes = { ...glue.nodes };
  const entryNode = nodes["entry"];
  if (!entryNode) throw new Error("Invalid project, no entry node detected");
  const newEntryEdgeId =
    entryNode.edges?.[0] ||
    Object.values(nodes).find((n) => n !== entryNode)?.id ||
    "";
  const newEntry = glue.edges[newEntryEdgeId]?.to.nodeId;
  if (!newEntry) {
    console.log(`Glue with no non-entry nodes detected`);
    return {} as GlueConfigV2;
  }

  const nodesKeys = Object.keys(nodes).filter((key) => key !== "entry");

  const result = nodesKeys.reduce((acc, cur, i) => {
    const node = glue.nodes[cur];
    const parameters = getParameters(node, glue);
    const on = getOn(node, glue);
    on.forEach(
      (o) => o.target_node_id === newEntry && (o.target_node_id = "entry")
    );

    acc[cur === newEntry ? "entry" : cur] = {
      call_with_new_input: node.options.call_with_new_input,
      name: node.name,
      component_id: node.component,
      on,
      parameters,
      position: node.position,
      type: node.type,
    };
    return acc;
  }, {} as GlueConfigV2);

  return result;
};
