import {AuthStore} from "marketplace-2/store/auth/createAuthStore";
import {CanvasStore} from "bots-studio/store/canvas/createCanvasStore";
import {ProjectsStore} from "../createProjects";
import {initializeUnauthenticated} from "../initializeUnauthenticated";
import {reaction} from "mobx";

// initialize guest user flow
export const runInitGuestFlow = (auth: AuthStore, store: ProjectsStore, canvas: CanvasStore) =>
    reaction(
        () => ({isReady: !auth.isLoading && auth.isReady, isGuest: !auth.isAuthenticated}),
        ({isReady, isGuest}) => {
            store.isGuest = isGuest;
            if (!isReady) return;
            isGuest && initializeUnauthenticated(store, canvas);
        }
    );
