import React, {createContext} from "react";

import {BotsStudioStore} from "./root/botsStudioStore";
import {useRootStore} from "marketplace-2/store/useRootStore";

export const GlueStoreContext = createContext<BotsStudioStore | null>(null);

export const GlueStoreProvider: React.FC = ({children}) => {
    const store = useRootStore((store) => store.botsStudio);
    return <GlueStoreContext.Provider value={store}>{children}</GlueStoreContext.Provider>;
};
