import {gql} from "@apollo/client";

export const CREATE_CHANNEL = gql`
    mutation createChannel($jsonConfig: JSON!, $botId: Int!, $channelName: ChannelName = DASHBOARD) {
        createChannel(input: {channel: {channelName: $channelName, jsonConfig: $jsonConfig, botId: $botId}}) {
            channel {
                id
                nodeId
            }
        }
    }
`;
