import {COMPONENT_DONE, COMPONENT_FAILED} from "dangerous/staticStrings";
import {GlueConfigV2, GlueNodeV2, NavigationBranch} from "marketplace-2/store/components/types";
import {GlueStateV2, NodeAction, NodeTypeV2} from "bots-studio/store/types";

import {fromEmailParams} from "./fromEmailParams";
import {fromGenericParams} from "./fromGenericParams";
import {getLineFromParams} from "./getLineFromParams";
import {humanReadableId} from "../../utils/humanReadableId";
import {makeDefaultActions} from "bots-studio/store/graph/makeDefaultActions";
import makeDefaultGlueState from "bots-studio/store/root/makeDefaultGlueState";

const NAVIGATION_OUTPUT_NAME = "control";

const nodesWithOnDone = [
    NodeTypeV2.say,
    NodeTypeV2.email,
    NodeTypeV2.saveInput,
    NodeTypeV2.saveAggregated,
    NodeTypeV2.saveParams,
    NodeTypeV2.saveParamsAggregated
];

const repositionNodeToCanvasCenter = (position: {x: number; y: number}) => {
    const canvasCenter = 500000;
    position.x < canvasCenter && (position.x += canvasCenter);
    position.y < canvasCenter && (position.y += canvasCenter);
};

const defaultActionComponents = [NodeTypeV2.component, NodeTypeV2.own, NodeTypeV2.glue];

const getActionsAndEdges = (node: GlueNodeV2, nodeId: string) => {
    const on = node.on || [];
    const branches = JSON.parse((node.parameters?.branches as unknown as string) || "[]") as NavigationBranch[];

    const edges = [] as {isHighlighted: boolean; id: string; action: string; from: string; to: string}[];

    let actions = on.map((transition) => {
        const action = {id: humanReadableId(), exitPosition: {x: 0, y: 0}} as NodeAction;
        if (transition.output_name === NAVIGATION_OUTPUT_NAME) {
            const branch = branches.find((branch) => branch.branch_id === transition.output_value);
            if (branch) {
                action.navigationData = {...branch};
                action.id = branch.branch_id;
                action.index = branches.indexOf(branch);
            }
        }
        edges.push({
            isHighlighted: false,
            id: humanReadableId(),
            action: action.id,
            from: nodeId,
            to: transition.target_node_id
        });
        return action;
    });

    actions.sort((a, b) => a.index - b.index);

    nodesWithOnDone.includes(node.type as NodeTypeV2) &&
        !actions.length &&
        actions.push({id: humanReadableId(), index: 0, exitPosition: {x: 0, y: 0}});

    if (defaultActionComponents.includes(node.type as NodeTypeV2)) {
        const defaultActions = makeDefaultActions(node.type as NodeTypeV2);

        actions.forEach((action, i) => {
            if (on[i].success === true) {
                action.data = COMPONENT_DONE;
            } else if (on[i].success === false) {
                action.data = COMPONENT_FAILED;
            }
        });

        actions = actions.filter((act) => act.data);
        defaultActions.forEach((act) => {
            if (!actions.some((a) => a.data === act.data)) {
                actions.push({...act});
            }
        });
    }

    return {actions, edges};
};

export const fromConfig = (config?: GlueConfigV2, studioVersion?: string, name?: string): GlueStateV2 => {
    const graph = makeDefaultGlueState();
    graph.name = name ? name : graph.id;

    if (!config) return graph;

    const keys = Object.keys(config);

    const entryAlt = humanReadableId();

    const entryNodePosition = {x: 0, y: 0};

    const setEntryNodePosition = (node: GlueNodeV2) => {
        if (node.position) {
            entryNodePosition.x = node.position.x - 100;
            entryNodePosition.y = node.position.y - 100;
        }
    };

    keys.forEach((nodeId, i) => {
        const node = config[nodeId];

        if (node.type === "default") node.type = NodeTypeV2.component;
        if (node.component_id === "email_pv1") node.type = NodeTypeV2.email;
        const newId = nodeId === "entry" ? entryAlt : nodeId;

        const {actions, edges} = getActionsAndEdges(node, nodeId);

        edges.forEach((edge) => {
            edge.to === "entry" && (edge.to = entryAlt);
            edge.from === "entry" && (edge.from = entryAlt);
        });

        if (typeof node.position == "undefined") {
            node.position = {x: 0, y: 0};
        }

        if (!studioVersion || studioVersion < "1.0.0") {
            repositionNodeToCanvasCenter(node.position);
        }

        if (i === 0) setEntryNodePosition(node);

        graph.nodes[newId] = {
            selected: false,
            inspected: false,
            name: node.name,
            type: node.type as NodeTypeV2,
            component: node.component_id,
            id: newId,
            entryPosition: node.position,
            position: node.position,
            options: {
                call_with_new_input: node.call_with_new_input,
                email: fromEmailParams(node),
                params: getLineFromParams(node),
                extraParams: fromGenericParams(node)
            },
            actions,
            edges: edges.map((edge) => edge.id)
        };

        edges.forEach(
            (edge) =>
                (graph.edges[edge.id] = {
                    isHighlighted: false,
                    id: edge.id,
                    from: {
                        nodeId: edge.from
                    },
                    to: {
                        nodeId: edge.to
                    },
                    action: edge.action
                })
        );
    });

    const entryEdgeId = `entry-to-${entryAlt}`;
    const entryActionId = humanReadableId();

    graph.nodes["entry"] = {
        selected: false,
        inspected: false,
        type: NodeTypeV2.entry,
        component: "",
        id: "entry",
        entryPosition: entryNodePosition,
        position: entryNodePosition,
        options: {},
        actions: [{id: entryActionId, index: 0, exitPosition: {x: 0, y: 0}}],
        edges: [entryEdgeId]
    };

    graph.edges[entryEdgeId] = {
        isHighlighted: false,
        id: entryEdgeId,
        from: {
            nodeId: "entry"
        },
        to: {
            nodeId: entryAlt
        },
        action: entryActionId
    };

    graph.position = {x: 0, y: 0};
    return graph;
};
