import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type ModalActionType = "deleteQuestion" | "deleteQuiz" | "quitQuiz" | "";

export const initialUiState = {
  selected: "",
  isHidden: true,
};

export type UiState = typeof initialUiState;

export const uiSlice = createSlice({
  name: "ui",
  initialState: initialUiState,
  reducers: {
    setSelected(state, action: PayloadAction<string>) {
      state.selected = action.payload;
    },
    hide(state) {
      state.isHidden = true;
    },
    show(state) {
      state.isHidden = false;
    },
  },
});
