import {Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import { ForwardRefExoticComponent, RefAttributes, forwardRef } from "react";

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            position: "fixed",
            flexDirection: "column",
            top: "0px",
            left: "0px",
            overflow: "hidden",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 10
        }
    };
});

export const DialogBodyContainer: ForwardRefExoticComponent<RefAttributes<any>> = forwardRef(({children}, ref) => {
    const classes = useStyles();

    return (
        <div id="DialogBodyContainer" className={classes.container} ref={ref}>
            {children}
        </div>
    );
});
