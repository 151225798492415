import React, {useCallback, useEffect, useState} from "react";
import SlidingHighlighter, {HighlightColor, HighlightType} from "./SlidingHighlighter";
import {Theme, makeStyles} from "@material-ui/core";

import {SelectorItem} from "./SelectorItem";

const getPadding = (type: HighlightType, theme: Theme) => {
    let a = theme.spacing(0, 0, 0, 0);

    switch (type) {
        case HighlightType.tab:
            a = theme.spacing(4, 0, 0, 0);
            break;
        case HighlightType.radio:
            a = theme.spacing(0.5, 0.25, 0.5, 0.25);
            break;
        default:
            break;
    }

    return a;
};

const getBorderRadius = (type: HighlightType, theme: Theme) => {
    let a = theme.spacing(0, 0, 0, 0);

    switch (type) {
        case HighlightType.tab:
            a = theme.spacing(0, 0, 0, 0);
            break;
        case HighlightType.radio:
            a = theme.spacing(3, 3, 3, 3);
            break;
        default:
            break;
    }

    return a;
};

const getBorderColor = (color: HighlightColor, theme: Theme) => {
    let a = theme.custom.palette.e.main;

    switch (color) {
        case HighlightColor.white_black:
            a = theme.custom.palette.e.main;
            break;
        case HighlightColor.white_blue:
            a = theme.custom.palette.a.main;
            break;
        default:
            break;
    }

    return a;
};

const getBorderStyles = (type: HighlightType, color: HighlightColor, theme: Theme) => {
    let a = {borderWidth: "1px", borderStyle: "none none solid none", borderColor: getBorderColor(color, theme)};

    switch (type) {
        case HighlightType.tab:
            a = {borderWidth: "1px", borderStyle: "none none solid none", borderColor: getBorderColor(color, theme)};
            break;
        case HighlightType.radio:
            a = {borderWidth: "2px", borderStyle: "solid solid solid solid", borderColor: getBorderColor(color, theme)};
            break;
        default:
            break;
    }

    return a;
};

const useStyles = ({type, color}: {type: HighlightType; color: HighlightColor}) =>
    makeStyles((theme) => {
        const padding = getPadding(type, theme);
        const border = getBorderStyles(type, color, theme);
        const borderRadius = getBorderRadius(type, theme);

        return {
            container: {
                width: "100%",
                position: "relative",
                alignItems: "center",
                padding,
                display: "flex",
                flexDirection: "row",
                borderRadius,
                ...border
            }
        };
    });

export type ItemInfo = {positionX: number; width: number};
export type SelectorDataItem = {key: string; url?: string; title: string; id?: number};

const positionMap = new Map();

export const SlidingSelector: React.FC<{
    item?: number;
    items: SelectorDataItem[];
    onItemChanged?: (item: SelectorDataItem, index: number) => void;
    type?: HighlightType;
    color?: HighlightColor;
}> = ({type = HighlightType.tab, color = HighlightColor.white_black, items, onItemChanged, item = 0, children}) => {
    const classes = useStyles({type, color})();

    const [updateRequired, setUpdateRequired] = useState(false);
    const [selectedItem, setSelectedItem] = useState(item);
    const [sliderWidth, setSliderWidth] = useState(0);
    const [sliderPosition, setSliderPosition] = useState(0);

    useEffect(() => {
        setSelectedItem(item);
    }, [item]);

    useEffect(() => {
        onItemChanged && onItemChanged(items[selectedItem], selectedItem);
    }, [selectedItem]);

    const setChildPosition = useCallback((index: number) => {
        return (data: ItemInfo) => {
            positionMap.set(index, data);
            index === items.length - 1 && setUpdateRequired(true);
        };
    }, []);

    useEffect(() => {
        const menuItemProps = positionMap.get(selectedItem) as ItemInfo;
        if (!menuItemProps) return;
        setSliderPosition(menuItemProps.positionX);
        setSliderWidth(menuItemProps.width);
        setUpdateRequired(false);
    }, [updateRequired, selectedItem]);

    return (
        <div id="SlidingSelector" className={classes.container}>
            {items.map((item, i) => {
                return (
                    <SelectorItem
                        color={color}
                        key={item.key}
                        isSelected={selectedItem === i}
                        setPosition={setChildPosition(i)}
                        onClick={() => {
                            setSelectedItem(i);
                        }}>
                        {item.title}
                    </SelectorItem>
                );
            })}
            {children}
            <SlidingHighlighter {...{type, color}} left={sliderPosition} width={sliderWidth} />
        </div>
    );
};
