const varToString = (variable: string | boolean | undefined): string | undefined => (
  typeof variable === 'string' && !['true', 'false'].includes(variable) 
  ? variable 
  : undefined
)

const varToBoolean = (variable: string | boolean | undefined): boolean => {
  if (typeof variable === 'boolean') {
    return variable
  }
  
  if (variable === 'true') {
    return true;
  }

  return false;
}

export const ENVIRONMENT = {
  BV_ADDR: varToString(import.meta.env.FRONTEND_BV_ADDR),
  MIXPANEL_URL: varToString(import.meta.env.FRONTEND_MIXPANEL_URL),
  SENTRY_DSN: varToString(import.meta.env.FRONTEND_SENTRY_DSN),
  WEBRTC_URL: varToString(import.meta.env.FRONTEND_WEBRTC_URL),
  IS_FAKING: varToBoolean(import.meta.env.FRONTEND_IS_FAKING),
  PUBLIC_URL: varToString(import.meta.env.FRONTEND_PUBLIC_URL),
  CLOUDFRONT_W_URL: varToString(import.meta.env.FRONTEND_CLOUDFRONT_W_URL),
  CLOUDFRONT_WEBRTC_URL: varToString(import.meta.env.FRONTEND_CLOUDFRONT_WEBRTC_URL),
  CDN_GROUWTHBOOK_URL: varToString(import.meta.env.FRONTEND_CDN_GROUWTHBOOK_URL),
  AUDIOCODES_URL: varToString(import.meta.env.FRONTEND_AUDIOCODES_URL),
  DOCS_URL: varToString(import.meta.env.FRONTEND_DOCS_URL),
  ABOUT_URL: varToString(import.meta.env.FRONTEND_ABOUT_URL),
  TTS_URL: varToString(import.meta.env.FRONTEND_TTS_URL),
  STT_URL: varToString(import.meta.env.FRONTEND_STT_URL),
  AUTH0_DOMAIN: varToString(import.meta.env.FRONTEND_AUTH0_DOMAIN),
  AUTH0_CLIENT: varToString(import.meta.env.FRONTEND_AUTH0_CLIENT),
  IS_DEV: import.meta.env.DEV,
  REDIRECT_TO_AUTH: varToBoolean(import.meta.env.FRONTEND_REDIRECT_TO_AUTH),

  //TODO: remove after push feature to prod
  TEST_USER_ROLE: varToString(import.meta.env.FRONTEND_TEST_USER_ROLE),

  //TODO: remove after push feature to prod
  SHOW_UM: varToBoolean(import.meta.env.FRONTEND_SHOW_UM),

  //TODO: remove after bug fixing
  NOTICE_DEV_QA_EXTRAS_OVERWRITING: varToBoolean(import.meta.env.NOTICE_DEV_QA_EXTRAS_OVERWRITING),
}