import {AnalyticsEvents, sendChatAnalytics} from "./sendAnalytics";

import {ComponentProperty} from "marketplace-2/pages/hub/chat-window/types";
import {fetchWrapper} from "data/api/rest/fetchWrapper";
import {randomString} from "utils/randomString";

const apiKey = "master_key";

let sessionId = randomString(8);
let isNewSession = true;
let numTurns = 0;
let isConversation3Turns = false;

const onNewSession = () => {
    isNewSession = true;
    isConversation3Turns = false;
    numTurns = 0;
};

export const resetSession = () => {
    sessionId = randomString(8);
    onNewSession();
};

export const sendMessage = async (p: {
    componentId: string;
    message: string;
    inputParameters?: ComponentProperty[];
    newSessionId?: string;
    url?: string;
}) => {
    const {componentId, message, inputParameters = [], newSessionId} = p;
    numTurns += 1;
    const headers = new Headers({"api-key": apiKey});
    if (newSessionId && sessionId !== newSessionId) {
        sessionId = newSessionId;
        onNewSession();
    }
    if (isNewSession) {
        sendChatAnalytics(
            AnalyticsEvents.StartConversation,
            JSON.stringify({component_id: componentId, session_id: sessionId})
        );
        isNewSession = false;
    } else if (!isConversation3Turns) {
        if (numTurns >= 3) {
            sendChatAnalytics(
                AnalyticsEvents.Conversation3Turns,
                JSON.stringify({component_id: componentId, session_id: sessionId})
            );
            isConversation3Turns = true;
        }
    }
    try {
        const reply = await fetchWrapper(`/api/exchange/${componentId}/${sessionId}`, {
            method: "POST",
            headers,
            body: JSON.stringify({
                user_input: message,
                flatten_context: true,
                context: inputParameters.reduce((acc, cur) => {
                    // @ts-ignore
                    acc[cur.name] = cur.value;
                    return acc;
                }, {})
            })
        });
        const json = await reply.json();
        if (json.component_done) {
            sendChatAnalytics(
                AnalyticsEvents.ConversationComplete,
                JSON.stringify({component_id: componentId, session_id: sessionId, isFailed: !!json.component_failed})
            );
        }
        return json;
    } catch (e) {
        console.error(e);
        return {error: e};
    }
};
