import {FormControl, InputLabel, makeStyles, MenuItem, Select, Theme} from "@material-ui/core";
import React from "react";
import {ConfigEditTool} from "../../../store/components/MyComponentsStore";
import {useRootStore} from "../../../store/useRootStore";

const useStyles = makeStyles((theme: Theme) => ({
    selectMenu: {
        height: "40px",
        minWidth: "160px",
        display: "flex",
        alignItems: "center",
        "&:focus": {
            background: "none"
        }
    }
}));

const titleByTool = new Map([
    [ConfigEditTool.json, "Raw JSON"],
    [ConfigEditTool.actions, "Actions"],
    [ConfigEditTool.qna, "QnA"],
    [ConfigEditTool.quiz, "Quiz/Survey"]
]);

export const ToolSelect = () => {
    const classes = useStyles();

    const {tools, selectedTool, setSelectedTool} = useRootStore((store) => {
        const edit = store.myComponents.editComponent;
        return {
            tools: edit.availableTools,
            selectedTool: edit.selectedTool,
            setSelectedTool: (tool: ConfigEditTool) => (edit.selectedTool = tool)
        };
    });

    const onSelect = (
        event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => {
        const v = event.target.value as ConfigEditTool;
        setSelectedTool(v);
    };

    return (
        <FormControl variant="outlined">
            <InputLabel id="edit-component-configuration-menu-tool-selection-input-label">
                Configuration tool
            </InputLabel>
            <Select
                className={classes.selectMenu}
                classes={{select: classes.selectMenu}}
                labelId="edit-component-configuration-menu-tool-selection-input-label"
                value={selectedTool}
                onChange={onSelect}
                label="Configuration tool">
                {tools.map((tool) => (
                    <MenuItem key={tool} value={tool}>
                        {titleByTool.get(tool) || ""}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
