export const positiveNouns = [
  "academician",
  "acceptor",
  "access",
  "acclaim",
  "accolade",
  "account",
  "accuracy",
  "ace",
  "achiever",
  "acumen",
  "addition",
  "adherent",
  "adjutant",
  "administrator",
  "admirer",
  "adorer",
  "advantage",
  "aesthete",
  "aficionada",
  "aficionado",
  "agent",
  "aide",
  "almsgiver",
  "altruist",
  "ambassador",
  "amity",
  "angel",
  "apostle",
  "appreciator",
  "arbiter",
  "archetype",
  "architect",
  "artisan",
  "artist",
  "artiste",
  "asset",
  "assignee",
  "assigner",
  "athlete",
  "author",
  "authority",
  "avowal",
  "awardee",
  "aye",
  "azure",
  "baby",
  "backer",
  "backup",
  "beatitude",
  "beauty",
  "begetter",
  "being",
  "believer",
  "benchmark",
  "benefaction",
  "benefactor",
  "benefactress",
  "beneficiary",
  "benefit",
  "best",
  "bestower",
  "better",
  "betterment",
  "bigwig",
  "blazing",
  "blessing",
  "bliss",
  "bloom",
  "blossom",
  "blossoming",
  "bodyguard",
  "bonanza",
  "bonus",
  "boost",
  "booster",
  "boss",
  "bound",
  "bounty",
  "brain",
  "brass",
  "brief",
  "brother",
  "buddy",
  "builder",
  "calm",
  "campaigner",
  "capital",
  "captain",
  "care",
  "caretaker",
  "catalyst",
  "cause",
  "celebrant",
  "celebrator",
  "celestial",
  "ceo",
  "cfo",
  "chair",
  "chairperson",
  "chamberlain",
  "champ",
  "champion",
  "charity",
  "charmer",
  "cheer",
  "cheers",
  "chief",
  "chieftain",
  "child",
  "chirpy",
  "choice",
  "chortle",
  "chosen",
  "chuckle",
  "chum",
  "cinch",
  "civility",
  "clairvoyant",
  "classic",
  "clear",
  "climb",
  "climber",
  "climbing",
  "close",
  "closing",
  "coadjutant",
  "coadjutor",
  "coequal",
  "coiner",
  "collaborator",
  "colleague",
  "collector",
  "comfort",
  "comforter",
  "comic",
  "commander",
  "commendatory",
  "compassion",
  "composer",
  "comrade",
  "concierge",
  "condolence",
  "conductor",
  "confederate",
  "confidant",
  "confidence",
  "connoisseur",
  "consciousness",
  "conservator",
  "consoler",
  "constant",
  "constitutional",
  "consul",
  "consultant",
  "contemporary",
  "content",
  "contributor",
  "controller",
  "conversant",
  "cooperator",
  "cope",
  "cornerstone",
  "councillor",
  "counselor",
  "courage",
  "crack",
  "crackerjack",
  "craftsperson",
  "creator",
  "credential",
  "credit",
  "curator",
  "custodian",
  "dainty",
  "dancer",
  "daring",
  "darling",
  "dean",
  "dear",
  "decency",
  "deep",
  "defender",
  "definite",
  "delectable",
  "delight",
  "demulcent",
  "dependable",
  "deserving",
  "designer",
  "desirable",
  "devisee",
  "devisor",
  "devotee",
  "devotional",
  "devout",
  "didactic",
  "director",
  "disciple",
  "discoverer",
  "distributor",
  "doer",
  "doll",
  "donee",
  "donor",
  "doting",
  "doyen",
  "doyenne",
  "dreamboat",
  "dynamic",
  "dynamite",
  "dynamo",
  "earnest",
  "ease",
  "ecstasy",
  "ecstatic",
  "effect",
  "efficiency",
  "efficient",
  "einstein",
  "elder",
  "eligible",
  "employer",
  "enchanter",
  "enchantress",
  "encourager",
  "endorser",
  "enlivening",
  "enough",
  "entertainer",
  "enthusiast",
  "entrepreneur",
  "epicure",
  "epicurean",
  "epitome",
  "equal",
  "equity",
  "equivalent",
  "essence",
  "essential",
  "esteem",
  "eternal",
  "ethic",
  "example",
  "exclusive",
  "exec",
  "executive",
  "exemplar",
  "exemplary",
  "experimenter",
  "expert",
  "exponent",
  "fair",
  "faith",
  "faithful",
  "fame",
  "familiar",
  "fancier",
  "fancy",
  "fantastic",
  "fare",
  "fascinator",
  "fashioner",
  "favor",
  "favour",
  "favorite",
  "felicity",
  "fine",
  "fireball",
  "firm",
  "first",
  "fit",
  "fleet",
  "folks",
  "foodie",
  "fop",
  "forbear",
  "force",
  "forefather",
  "foreman",
  "forerunner",
  "foresight",
  "forever",
  "forgiveness",
  "fortunate",
  "fortune",
  "forward",
  "foundation",
  "founder",
  "fountain",
  "fountainhead",
  "freedom",
  "freethinking",
  "fresh",
  "friend",
  "friendly",
  "fulfilling",
  "full",
  "fun",
  "funny",
  "gag",
  "gaiety",
  "gain",
  "gala",
  "galahad",
  "gale",
  "gallant",
  "gallantry",
  "game",
  "garb",
  "garden",
  "garland",
  "garnish",
  "gastronome",
  "gastronomy",
  "gather",
  "gathering",
  "geek",
  "gem",
  "generativity",
  "generator",
  "generosity",
  "genius",
  "gentle",
  "gentlefolk",
  "gentleman",
  "gentlewoman",
  "gift",
  "gild",
  "gilt",
  "girlfriend",
  "gist",
  "giver",
  "giving",
  "glamour",
  "glance",
  "glare",
  "glaze",
  "gleam",
  "gleaming",
  "glimmer",
  "glimmering",
  "glint",
  "glisten",
  "glister",
  "glitterati",
  "glitz",
  "glory",
  "gloss",
  "glossy",
  "goal",
  "god",
  "goddess",
  "godparent",
  "godsend",
  "golconda",
  "gold",
  "good",
  "goodness",
  "goodwill",
  "goody",
  "gorgeousness",
  "gourmet",
  "governor",
  "grace",
  "gracility",
  "graciousness",
  "grade",
  "graduate",
  "grammy",
  "grandee",
  "grandeur",
  "grandmaster",
  "grant",
  "grantee",
  "grantor",
  "grass",
  "gratefulness",
  "gratification",
  "gratitude",
  "great",
  "greatness",
  "greeting",
  "grin",
  "grit",
  "groove",
  "growing",
  "growth",
  "grubstake",
  "guarantor",
  "guard",
  "guardian",
  "guest",
  "guffaw",
  "guidance",
  "guide",
  "gumption",
  "guru",
  "gush",
  "gusto",
  "gut",
  "gymnastic",
  "hale",
  "handler",
  "happening",
  "harmony",
  "head",
  "headman",
  "heart",
  "heartthrob",
  "heaven",
  "height",
  "heir",
  "heiress",
  "hello",
  "help",
  "helper",
  "helpmate",
  "heritor",
  "heritress",
  "heritrix",
  "hero",
  "heroine",
  "heuristic",
  "highflier",
  "hilarity",
  "holy",
  "honesty",
  "honor",
  "hooray",
  "hope",
  "hopeful",
  "hotshot",
  "humanitarian",
  "humor",
  "husband",
  "icon",
  "idea",
  "ideal",
  "idol",
  "idolizer",
  "improvement",
  "inamorata",
  "inamorato",
  "increase",
  "independent",
  "indivisible",
  "industrialist",
  "infant",
  "infinite",
  "ingenuity",
  "inheritor",
  "initiative",
  "initiator",
  "innocent",
  "innovator",
  "inspiration",
  "institutor",
  "integral",
  "intellect",
  "intent",
  "interest",
  "intimate",
  "inventor",
  "invitation",
  "invite",
  "invitee",
  "jest",
  "jester",
  "jingle",
  "joker",
  "josh",
  "jubilation",
  "juggler",
  "justice",
  "keeper",
  "key",
  "kindred",
  "kingpin",
  "king",
  "kiss",
  "knight",
  "lady",
  "lark",
  "lasting",
  "latitudinarian",
  "laugh",
  "laureate",
  "lead",
  "leader",
  "learning",
  "legatee",
  "legator",
  "legend",
  "legislator",
  "leisure",
  "liberation",
  "libertarian",
  "lieutenant",
  "life",
  "light",
  "like",
  "liking",
  "lionheart",
  "literate",
  "lord",
  "love",
  "lover",
  "luck",
  "lust",
  "luster",
  "lustre",
  "luxury",
  "maestro",
  "magician",
  "magistrate",
  "magnitude",
  "majesty",
  "major",
  "majority",
  "make",
  "maker",
  "mana",
  "manager",
  "manner",
  "marvel",
  "masculine",
  "massage",
  "mastermind",
  "mate",
  "matriarch",
  "matter",
  "mentor",
  "mercy",
  "merit",
  "method",
  "might",
  "minder",
  "minikin",
  "mint",
  "miracle",
  "mirth",
  "model",
  "modern",
  "mom",
  "moppet",
  "morale",
  "most",
  "mother",
  "motivator",
  "motor",
  "mover",
  "moving",
  "much",
  "multitude",
  "must",
  "nabit",
  "nascency",
  "narration",
  "narrator",
  "natation",
  "national",
  "native",
  "natural",
  "negoce",
  "neighbor",
  "neonate",
  "neoteric",
  "nestling",
  "newborn",
  "nicety",
  "nimblewit",
  "nipper",
  "nirvana",
  "noble",
  "nobleman",
  "nod",
  "nonpareil",
  "noon",
  "notable",
  "note",
  "notice",
  "novel",
  "nudge",
  "nurse",
  "nursling",
  "nurture",
  "objective",
  "offer",
  "officer",
  "official",
  "offspring",
  "okay",
  "olympian",
  "one",
  "oodles",
  "oomph",
  "ooze",
  "operator",
  "opportunity",
  "optimist",
  "optimum",
  "orchestrator",
  "organizer",
  "original",
  "originator",
  "ornamental",
  "overflowing",
  "overseer",
  "owner",
  "pacifist",
  "pal",
  "paradigm",
  "paradise",
  "paragon",
  "paramount",
  "pard",
  "pardon",
  "parent",
  "participant",
  "particular",
  "partisan",
  "partner",
  "passion",
  "pathfinder",
  "patience",
  "patient",
  "patriarch",
  "patron",
  "peace",
  "peacekeeper",
  "peach",
  "peak",
  "pearl",
  "peer",
  "pep",
  "perfection",
  "perfectionist",
  "performer",
  "personality",
  "perspective",
  "pet",
  "phenomenon",
  "philanthropist",
  "philanthropy",
  "philosopher",
  "pick",
  "pilot",
  "pioneer",
  "pivot",
  "plan",
  "planner",
  "play",
  "player",
  "playmate",
  "pleasing",
  "pleasure",
  "plenitude",
  "plenty",
  "poet",
  "poise",
  "positive",
  "possessor",
  "possible",
  "postulant",
  "potential",
  "pragmatic",
  "praise",
  "prayer",
  "precious",
  "preemption",
  "premier",
  "premium",
  "presence",
  "present",
  "presenter",
  "president",
  "pretty",
  "primary",
  "prime",
  "primogenitor",
  "prince",
  "princess",
  "principal",
  "prize",
  "pro",
  "proconsul",
  "procreator",
  "procto",
  "prodigy",
  "produce",
  "producer",
  "professional",
  "professor",
  "progenitor",
  "progeny",
  "progress",
  "progressive",
  "prolepsis",
  "promoter",
  "promotion",
  "promulgator",
  "prophet",
  "proponent",
  "proposer",
  "proprietor",
  "prospective",
  "protagonist",
  "protective",
  "protector",
  "provider",
  "provocative",
  "publisher",
  "purveyor",
  "quaff",
  "quaintise",
  "quaintisiness",
  "quality",
  "quantity",
  "quarter",
  "queen",
  "queenhood",
  "querist",
  "quest",
  "quester",
  "question",
  "quick",
  "quickness",
  "quickstep",
  "quiet",
  "quintessence",
  "quillet",
  "quip",
  "quirk",
  "quotation",
  "radiant",
  "rapture",
  "rational",
  "ready",
  "real",
  "reason",
  "receiver",
  "reception",
  "reciprocal",
  "recuperation",
  "regulator",
  "rejoicing",
  "rejuvenation",
  "release",
  "relief",
  "reliever",
  "renovation",
  "reputation",
  "resource",
  "respect",
  "restoration",
  "result",
  "reverence",
  "reward",
  "rhapsody",
  "rich",
  "right",
  "rise",
  "roll",
  "romantic",
  "romeo",
  "rooter",
  "rose",
  "round",
  "rouse",
  "ruler",
  "ruling",
  "run",
  "runner",
  "sage",
  "saint",
  "salient",
  "salubrity",
  "salute",
  "sanctity",
  "sanctuary",
  "satisfaction",
  "savant",
  "saver",
  "savior",
  "savory",
  "savvy",
  "scholar",
  "scholarship",
  "science",
  "scion",
  "script",
  "scripter",
  "sculptor",
  "seeker",
  "select",
  "sensation",
  "sense",
  "sensibility",
  "sentiment",
  "sentinel",
  "serendipity",
  "serene",
  "serenity",
  "server",
  "set",
  "settling",
  "shape",
  "share",
  "sharp",
  "sharpy",
  "shaver",
  "shelter",
  "shine",
  "show",
  "sight",
  "significance",
  "significant",
  "simplicity",
  "sinew",
  "sir",
  "sire",
  "sister",
  "size",
  "skill",
  "skipper",
  "sleek",
  "slick",
  "smash",
  "smile",
  "smooth",
  "smoothie",
  "snap",
  "snuggle",
  "soar",
  "sociable",
  "social",
  "socializer",
  "sol",
  "solid",
  "sophisticate",
  "soul",
  "soulmate",
  "sovereign",
  "spark",
  "sparkling",
  "special",
  "specialist",
  "spectacle",
  "spectacular",
  "speed",
  "spell",
  "spice",
  "spirit",
  "spiritual",
  "splendor",
  "sponsor",
  "sport",
  "sportsmanship",
  "spot",
  "sprite",
  "sprout",
  "squire",
  "stalwart",
  "standard",
  "star",
  "state",
  "steady",
  "steward",
  "stipend",
  "stipendiary",
  "stir",
  "stirring",
  "strategist",
  "strategy",
  "stripling",
  "strive",
  "student",
  "style",
  "stylist",
  "sublime",
  "substance",
  "success",
  "successor",
  "sufficiency",
  "sugar",
  "suitor",
  "summary",
  "super",
  "superintendent",
  "superior",
  "superlative",
  "superman",
  "supervisor",
  "superwoman",
  "supplier",
  "supply",
  "support",
  "surety",
  "surprise",
  "survivor",
  "swain",
  "sweetheart",
  "sweetness",
  "swell",
  "sympathy",
  "synergy",
  "system",
  "tact",
  "talent",
  "taste",
  "teacher",
  "teaching",
  "team",
  "teammate",
  "tender",
  "testament",
  "testator",
  "testimonial",
  "testimony",
  "thank",
  "thanksgiving",
  "therapy",
  "thinker",
  "thrill",
  "timesaver",
  "tiptop",
  "titleholder",
  "today",
  "tootsie",
  "top",
  "tot",
  "total",
  "touch",
  "tout",
  "trailblazer",
  "training",
  "tranquillity",
  "transcendent",
  "transient",
  "travel",
  "treasure",
  "trim",
  "triumph",
  "true",
  "trust",
  "trustee",
  "trusty",
  "truth",
  "try",
  "tuition",
  "tune",
  "tutor",
  "tycoon",
  "uberty",
  "ubiquitary",
  "ultimate",
  "ultimation",
  "understanding",
  "understandingly",
  "underwriter",
  "unique",
  "universal",
  "up",
  "upbeat",
  "upholder",
  "uplift",
  "uplifting",
  "utmost",
  "valedictorian",
  "valiant",
  "validator",
  "valuable",
  "value",
  "vast",
  "vaulter",
  "veracity",
  "vestal",
  "veteran",
  "vip",
  "virtue",
  "virtuosity",
  "visionary",
  "visitor",
  "vitality",
  "vogue",
  "votary",
  "warrantee",
  "warrantor",
  "warden",
  "warmth",
  "welcome",
  "well",
  "wellspring",
  "whip",
  "whiz",
  "whole",
  "wife",
  "will",
  "willpower",
  "win",
  "winner",
  "winning",
  "wisdom",
  "wise",
  "wizard",
  "won",
  "wonder",
  "wonderment",
  "wooer",
  "wordsmith",
  "workhorse",
  "workmate",
  "worshipper",
  "worth",
  "worthy",
  "writer",
  "wunderkind",
  "xenagogue",
  "xenium",
  "xenophile",
  "xenophilia",
  "xesturgy",
  "xfactor",
  "yard",
  "yes",
  "yeve",
  "yield",
  "yift",
  "yoke",
  "young",
  "youngster",
  "youth",
  "deer",
  "bunny",
  "rabbit",
  "aardvark",
  "chinchilla",
  "puppy",
  "kitten",
  "dolphin",
  "koala",
  "kiwi",
  "kangaroo",
  "lion",
  "cheetah",
  "gazelle",
  "antelope",
  "capybara",
  "panda",
  "dragon",
  "unicorn",
  "meerkat",
  "raccoon",
  "penguin",
  "beluga",
  "alpaca",
  "hummingbird",
  "otter",
  "seal",
  "fox",
  "pika",
  "quoll",
  "quokka",
  "klipspringer",
  "jerboa",
  "squirrel",
  "lemur",
  "colugo",
  "bandicoot",
  "margay",
  "ocelot",
  "oncilla",
  "mara",
  "numbat",
  "tarsier",
  "gundi",
  "tanuki",
  "chevrotain",
  "bongo",
  "serval",
  "axolotl",
  "tamandua",
  "ferret",
  "manul",
  "jaguar",
  "cat",
];
