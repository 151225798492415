import CustomTextInputField from "bot-dashboard/ui-kit/CustomTextInputField";
import {QAConfig} from "marketplace-2/store/components/types";
import React from "react";
import {humanReadableId} from "utils/humanReadableId";
import {observable} from "mobx";
import {useRootStore} from "marketplace-2/store/useRootStore";

const AddAnswer = () => {
    const {qna, addQuestion, updateBlueprint} = useRootStore((store) => {
        const conf = store.myComponents.editComponent.updatedBlueprint.json_config.qa_config || ([] as QAConfig);
        return {
            qna: [...conf],
            addQuestion: (answer: string) =>
                conf.unshift(
                    observable({
                        question_id: humanReadableId(),
                        questions: [],
                        answers: [answer]
                    })
                ),
            updateBlueprint: store.myComponents.editComponent.updateBlueprint
        };
    });

    const handleSubmitAnswer = (v: string) => {
        if (v !== null && v !== undefined && v.length > 0) {
            addQuestion(v);
            updateBlueprint();
        }
    };

    return (
        <CustomTextInputField
            onEnter={handleSubmitAnswer}
            onBlur={() => {}}
            dependencies={[]}
            placeholder={"The answer is 42."}
            fullWidth
        />
    );
};

export default AddAnswer;
