import React, {createContext} from "react";
import {useLocalStore} from "mobx-react-lite";
import {RootStore, createRootStore} from "./RootStore";

export const RootStoreContext = createContext<RootStore | null>(null);

export const RootStoreProvider: React.FC = ({children}) => {
    const store = useLocalStore(createRootStore);
    return <RootStoreContext.Provider value={store}>{children}</RootStoreContext.Provider>;
};
