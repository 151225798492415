import {makeStyles, Typography} from "@material-ui/core";
import { ENVIRONMENT } from "const";
import React from "react";

const useStyles = makeStyles((theme) => {
    return {
        instructionsContainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline"
        },
        instructionsUnclear: {
            paddingLeft: (props: {leftPadding: number; boldCta: boolean}) => {
                return theme.spacing(props.leftPadding);
            }
        },
        link: {
            color: theme.custom.palette.a.main,
            fontWeight: (props: {leftPadding: number; boldCta: boolean}) => {
                if (props.boldCta) return "bold";
                return "normal";
            }
        }
    };
});

const tutorialLink = ENVIRONMENT.ABOUT_URL + "/how-to-invite-bots-to-video-meetings/";

export type InstructionsVariant = "body1" | "body2";
const FurtherInstructions: React.FC<{size?: InstructionsVariant; leftPadding?: number; boldCta?: boolean}> = ({
    size = "body1",
    leftPadding = 0,
    boldCta = false
}) => {
    const classes = useStyles({leftPadding, boldCta});

    return (
        <Typography className={classes.instructionsUnclear} variant={size}>
            Not sure how?{" "}
            <a className={classes.link} target="_blank" href={tutorialLink}>
                click here
            </a>
        </Typography>
    );
};

export default FurtherInstructions;
