import { useEffect } from 'react';
import { ConfirmationDialogData } from './ConfirmationDialogsContext';
import { useConfirmationDialogsStore } from './useConfirmationDialogsStore';


const useConfirmationDialog = (data:ConfirmationDialogData) => {
    

    const {setData, openConfirmationDialog} = useConfirmationDialogsStore((store)=>{
        return{
            setData:store.setConfirmationDialog,
            openConfirmationDialog: store.openConfirmationDialog
        }
    })

    useEffect(() => {
        setData(data);
    }, [data])
    
    return openConfirmationDialog;
}

export default useConfirmationDialog
