import {fetchWrapper} from "data/api/rest/fetchWrapper";

let cache = {} as Map<string, string>;

export const getPhoneCountryCodes = async () => {
    try {
        if (cache && cache.size) {
            return {...cache};
        }
        const r = await fetchWrapper(`/v2/bot/channel/phone/available_voice_country_codes`);
        if (!r.ok) throw new Error(r.statusText);
        const data = (await r.json()) as {country_codes: Map<string, string>};
        cache = {...data.country_codes};
        return new Map(Object.entries(data.country_codes));
    } catch (e) {
        console.error(e);
        return {} as Map<string, string>;
    }
};
