import {ProjectsStore} from "../createProjects";
import {reaction} from "mobx";

// update publish sync state
export const runSyncPublished = (store: ProjectsStore) =>
    reaction(
        () => {
            const savedString = JSON.stringify(store.savedGlues[store.selected] || {});
            const compiledString = JSON.stringify(store.compiledGlues[store.selected] || {});
            return savedString === compiledString;
        },
        (isUpdated) => {
            store.isPublshSynced = isUpdated;
        }
    );
