import {PLAN} from "pricing/plans/choose-plan/ChoosePlan";

export const planToMaxMinutes = new Map<PLAN, number>([
    [PLAN.basicMonthly, 600],
    [PLAN.basicAnnually, 600],
    [PLAN.proMonthly, 2500],
    [PLAN.proAnnually, 2500],
    [PLAN.test, 200],
    [PLAN.custom, 0]
]);
