import { Organization, UserProfile } from "marketplace-2/store/user/types";

import { UserAuth0 } from "marketplace-2/store/auth/createAuthStore";
import { defaultUserProfile } from "marketplace-2/store/user/defaults/defaultUserProfile";
import { fetchWrapper } from "data/api/rest/fetchWrapper";

export const getUserProfile = async ({
  user,
  affiliate_code,
}: {
  user?: UserAuth0;
  affiliate_code?: string;
}): Promise<UserProfile> => {
  try {
    // const isFaking = ENVIRONMENT.IS_FAKING_USER === "true";
    // if (isFaking) {
    //     return defaultUserProfile();
    // }
    const response = await fetchWrapper(`/v2/participant/user`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify({
        ...{
          first_name: "",
          family_name: "",
          given_name: "",
          last_name: "",
          nickname: user?.nickname || "",
          name: user?.name || "",
          handle: user?.nickname || "",
          email: user?.email || "",
          image_url: user?.picture || "",
          bio: "",
          picture: user?.picture || "",
          affiliate_code,
        },
      }),
    });
    if (response.status === 401) {
      if (
        window.location.pathname === "/" ||
        window.location.pathname.startsWith("/create-bot") ||
        window.location.pathname.startsWith("/blueprint") ||
        window.location.pathname.startsWith("/builder") ||
        window.location.pathname.startsWith("/auth/confirm_service_account")
      )
        return defaultUserProfile();
      window.location.href = "/";
      return defaultUserProfile();
    }
    const r = await response.json();
    r.organizations = new Map(
      r.organizations.map((org: Organization) => [org.handle, org])
    );
    return r as UserProfile;
  } catch (e) {
    console.error(e);
    return defaultUserProfile();
  }
};
