import BotEmailAddress from "./BotEmailAddress";
import React from "react";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => {
    return {
        container: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(3),
            [theme.breakpoints.up("lg")]: {
                paddingBottom: theme.spacing(2)
            }
        }
    };
});

export const VideoChannelSubheader: React.FC<{
    botName: string;
    email: string;
    initialEmail: string;
    setEmail: (s: string) => void;
    emailSuffix: string;
    channelId?: string;
    isEditable?: boolean;
}> = ({botName, email, initialEmail, channelId, emailSuffix, isEditable, setEmail}) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <BotEmailAddress {...{channelId, botName, initialEmail, email, setEmail, emailSuffix, isEditable}} />
        </div>
    );
};
