import { Divider, Theme, Typography, makeStyles } from "@material-ui/core";
import { QAConfig, QnAEntry } from "../../../../store/components/types";
import React, { useState } from "react";

import AddAnswer from "./AddAnswer";
import AnswerEditor from "./AnswerEditor";
import { FilterActions } from "../actions-tool/FilterActions";
import QnASingleEntry from "./QnASingleEntry";
import { action } from "mobx";
import { useRootStore } from "../../../../store/useRootStore";

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      maxHeight: theme.spacing(70),
    },
    body: {},
    filterContainer: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    vContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    bottomControls: { display: "flex", width: "100%" },
    answersHeader: {
      paddingTop: theme.spacing(2),
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-end",
      justifyContent: "space-between",
      paddingBottom: theme.spacing(0.5),
    },
    answersContainer: {
      marginTop: theme.spacing(1),
      overflow: "auto",
      height: "100%",
    },
  };
});

export const QnATool: React.FC<{ editNameOption?: boolean }> = ({
  editNameOption = true,
}) => {
  const classes = useStyles();

  const { qna, isEditingAnswer, setEditingAnswer } = useRootStore((store) => {
    const conf =
      store.myComponents.editComponent.updatedBlueprint.json_config.qa_config ||
      ([] as QAConfig);
    return {
      qna: [...conf],
      isEditingAnswer: store.myComponents.editComponent.isEditingAnswer,
      setEditingAnswer: action((v: QnAEntry) => {
        store.myComponents.editComponent.isEditingAnswer = true;
        store.myComponents.editComponent.editedQnAEntry = v;
      }),
    };
  });

  const [filter, setFilter] = useState("");

  const [selectedAnswer, setSelectedAnswer] = useState<QnAEntry>();
  const handleAnswerSelected = (v: QnAEntry) => {
    setSelectedAnswer(v);
    setEditingAnswer(v);
  };

  const filterFunction = (entry: QnAEntry) => {
    if (!filter) return true;
    const f = filter.toLowerCase();
    const id = entry.question_id.toLowerCase();
    const questions = entry.questions.map((q) => q.toLowerCase());
    const answers = entry.answers.map((a) => a.toLowerCase());
    return (
      f.includes(id) ||
      id.includes(f) ||
      questions.find((r) => r.includes(f) || f.includes(r)) ||
      answers.find((r) => r.includes(f) || f.includes(r))
    );
  };

  const filteredQnAs = qna.filter(filterFunction);

  if (isEditingAnswer && selectedAnswer)
    return <AnswerEditor entry={selectedAnswer} />;

  return (
    <div className={classes.container}>
      <Typography>
        Give us the answers you want your virtual human to provide to users.
        <br />
        We'll use our magic to create the questions
      </Typography>
      <div className={classes.body}>
        <div className={classes.answersHeader}>
          <Typography>
            <b>Answers</b>
          </Typography>
          <div className={classes.vContainer}>
            <FilterActions {...{ filter, setFilter }} />
          </div>
        </div>

        <Divider></Divider>
        <AddAnswer />
      </div>
      <div className={classes.answersContainer}>
        {filteredQnAs.map((s) => (
          <QnASingleEntry
            entry={s}
            onClick={handleAnswerSelected}
          ></QnASingleEntry>
        ))}
      </div>
    </div>
  );
};
