import {ProjectsStore} from "./createProjects";
import {action} from "mobx";
import {setUserState} from "data/api/rest/requests/setUserState";

export const saveUserProjState = action(async (store: ProjectsStore, projId: string = "") => {
    try {
        const target = projId || store.selected;
        store.isSaving = true;
        await setUserState(target, store.projects[target]);
        action(() => (store.isSaving = false))();
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
});
