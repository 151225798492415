import {PayloadAction, createSlice} from "@reduxjs/toolkit";

export const initialState = {
    deleting: "",
    unsubscribing: ""
};

export type BotState = typeof initialState;

export const botSlice = createSlice({
    name: "bot",
    initialState,
    reducers: {
        deleteBot(state, action: PayloadAction<string>) {
            state.deleting = action.payload;
        },
        cancelDeleteBot(state) {
            state.deleting = "";
        },
        unsubscribe(state, action: PayloadAction<string>) {
            state.unsubscribing = action.payload;
        },
        cancelUnsubscribe(state) {
            state.unsubscribing = "";
        }
    }
});
