import {Paper, Typography, Grid, makeStyles, createStyles, Theme, ListItem, Divider} from "@material-ui/core";
import {Message} from "./types";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        bold: {
            fontWeight: "bold"
        },
        messageText: {textAlign: "center", margin: 5}
    });
});

export const MessageItem: React.FC<{
    message: Message;
    showMetadata: boolean;
}> = ({message, showMetadata}) => {
    const classes = useStyles();

    return (
        <div>
            <ListItem>
                <Grid container spacing={1}>
                    <div>
                        {showMetadata ? (
                            <Grid container xs={12}>
                                <Grid item xs={12}>
                                    <Typography>Massage ID: {message.message_id}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={classes.bold}>Author ID: {message.author_id}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>{message.created_at}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={classes.bold}>
                                        Sender Channel: {message.sender.channel_name}
                                    </Typography>
                                    <Typography>Room ID: {message.sender.room_id}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={classes.bold}>
                                        Recipient Channel:
                                        {message.recipient.channel_name}
                                    </Typography>
                                    <Typography>Room ID: {message.recipient.room_id}</Typography>
                                </Grid>
                            </Grid>
                        ) : null}
                    </div>
                    <Grid item xs={12}>
                        <Paper className={classes.messageText}>
                            {!showMetadata ? <Typography>{message.sender.channel_name}:</Typography> : null}
                            <Typography className={classes.bold}>{message.payload.text}</Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </ListItem>
            <Divider variant="inset" component="li" />
        </div>
    );
};
