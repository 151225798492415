import {makeStyles, Typography} from "@material-ui/core";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import {AnalyticType} from "analytics/AnalyticType";
import copy from "clipboard-copy";
import React from "react";
import {SecondaryButton} from "theme/components/elements/buttons/SecondaryButton";
import {useNotification} from "utils/hooks/useNotification";
import {EmbeddedData} from "../types";
import {makeEmbedCode} from "./makeEmbedCode";

const useStyles = makeStyles((theme) => {
    return {
        container: {
            paddingTop: theme.spacing(3),
            display: "flex",
            width: "100%",
            justifyContent: "space-between"
        },
        explanation: {width: "50%"},
        buttonContainer: {
            display: "flex",
            justifyContent: "center"
        },
        footer: {marginTop: theme.spacing(22)},
        settingsMenu: {
            display: "flex"
        },
        comingSoon: {
            color: theme.custom.palette.e.alt
        },
        divider: {
            borderTop: "0.5px solid",
            borderColor: theme.custom.palette.d.secondary
        },
        infographics: {
            textAlign: "center",
            paddingTop: theme.spacing(6)
        }
    };
});

const Subheader: React.FC<{data: EmbeddedData; botName: string; isConnected: boolean}> = ({
    data,
    botName,
    isConnected
}) => {
    const classes = useStyles();
    const addMessage = useNotification();

    const copyChannelUrl = () => {
        if (!data) return;
        copy(makeEmbedCode(data, botName));
        addMessage("Embed code copied to clipboard", "success");
    };

    return (
        <div className={classes.container}>
            <Typography className={classes.explanation}>
                Publish <b>{botName}</b> on your website. Simply copy the html code to your website and voilà!
            </Typography>
            {isConnected ? (
                <div className={classes.buttonContainer}>
                    <SecondaryButton
                        analytics_event={AnalyticType["embedded-channel-dialog-copy-embed-code"]}
                        onClick={copyChannelUrl}
                        variant={"outlined-inverse"}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <FileCopyOutlinedIcon style={{paddingRight: 4}} />
                            Copy embed code
                        </div>
                    </SecondaryButton>
                </div>
            ) : null}
        </div>
    );
};

export default Subheader;
