import {gql} from "@apollo/client";

export const GET_USER_DATA = gql`
    query getUserData($id: Int!) {
        user(id: $id) {
            email
            firstName
            id
            lastName
            name
        }
    }
`;
