import {Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            padding: theme.spacing(0, 2),
            display: "flex",
            width: "100%",
            justifyContent: "center",
            gap: theme.spacing(4),
            position: "relative"
        }
    };
});

export const DialogFooterContainer: React.FC = ({children}) => {
    const classes = useStyles();

    return (
        <div id="DialogFooterContainer" className={classes.container}>
            {children}
        </div>
    );
};
