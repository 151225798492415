import {EmailParam} from "bots-studio/store/types";

const nodeKeyToParamKey = {email: "target_email", title: "email_subject", body: "email_body"};

const isEmailArrayWithBrackets = (value: string) => {
    return !!value.length && value[0] === "[" && value[value.length - 1] === "]";
};

const isEmailArrayWithoutBrackets = (value: string) => {
    return !!value.length && value.split(",").length > 1;
};

const isEmailArray = (value: string) => isEmailArrayWithBrackets(value) || isEmailArrayWithoutBrackets(value);

export const toEmailParams = (rawEmail: EmailParam) => {
    const email = {...rawEmail};
    delete (email as Partial<EmailParam>).emailUpdated;

    const params = {} as {[key: string]: string};

    const emailKeys = Object.keys(email) as Array<keyof Omit<EmailParam, "emailUpdated">>;

    emailKeys.forEach((emailFieldKey: keyof Omit<EmailParam, "emailUpdated">) => {
        const outKey = nodeKeyToParamKey[emailFieldKey];
        const value = Array.isArray(email[emailFieldKey])
            ? ((email[emailFieldKey] as unknown) as string[]).join(",")
            : email[emailFieldKey];
        if (emailFieldKey === "email" && isEmailArray(value)) {
            const v = isEmailArrayWithBrackets(value) ? value.slice(1, value.length - 1) : value;
            params[outKey] = JSON.parse(
                `[${v
                    .split(",")
                    .map((v) => JSON.stringify(v))
                    .join(",")}]`
            );
        } else {
            params[outKey] = JSON.stringify(email[emailFieldKey]);
        }
    });

    return params;
};
