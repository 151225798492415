import {analytics} from "analytics/analytics";
import {AnalyticType} from "analytics/AnalyticType";
import {action, observable, toJS} from "mobx";
import {EdgeV2, NodeV2} from "../types";

export type StackEntry = {
    nodes: {[key: string]: NodeV2};
    edges: {[key: string]: EdgeV2};
};

export const createUndoRedo = (sharedGraph: StackEntry) => {
    const stack = observable.array([] as Array<StackEntry>);
    const cache = observable(
        {} as {
            [key: string]: Array<StackEntry>;
        }
    );

    let currentId = "";
    const setEntry = action((entry: StackEntry) => {
        sharedGraph.nodes = observable(entry.nodes);
        sharedGraph.edges = observable(entry.edges);
    });

    const store = {
        stack,
        update: action(() => {
            const nodes = toJS(sharedGraph.nodes);
            const edges = toJS(sharedGraph.edges);
            Object.values(edges).forEach((edge) => {
                if (!edge.to.nodeId) delete edges[edge.id];
            });
            stack.push({
                nodes,
                edges
            });
        }),
        undo: action(() => {
            const entry = stack.pop();
            if (!entry) return;
            setEntry(entry);
            analytics({type: AnalyticType.undo});
        }),
        clearUndo: action(() => {
            stack.splice(0, stack.length);
            delete cache[currentId];
        }),
        discardChanges: action(() => {
            if (!stack.length) return;
            const entry = stack[0];
            setEntry(entry);
            store.clearUndo();
        }),
        isCached: (id: string) => {
            return !!cache[id];
        },
        handleGlueIdChange: action((id: string) => {
            if (currentId === id && cache[currentId]) return true;
            store.update();
            const isFound = cache[id];
            cache[currentId] = [...stack];
            stack.replace(cache[id] || []);
            currentId = id;
            store.undo();
            return isFound;
        }),
        redo: () => {},
        get isCanUndo() {
            return !!this.stack.length;
        }
    };

    return store;
};

export type UndoRedoStore = ReturnType<typeof createUndoRedo>;
