import { Configuration } from "marketplace-2/store/components/types";
import { fetchWrapper } from "data/api/rest/fetchWrapper";

const configCache: { [key: string]: Configuration } = {
  oneturn_say_followup: { blueprint_id: "oneturn_say_followup" },
  oneturn_say_v2: { blueprint_id: "oneturn_say_v2" },
  input_aggregator: { blueprint_id: "input_aggregator" },
};

const getRealConfig = async (blueprint: {
  humanId: string;
}): Promise<Configuration> => {
  if (configCache[blueprint.humanId])
    return Promise.resolve(configCache[blueprint.humanId]);
  try {
    const result = await fetchWrapper(`/api/config/${blueprint.humanId}`, {
      method: "GET",
    });
    if (!result.ok) throw new Error(result.statusText);
    const reply = (await result.json()) as Configuration;
    return reply;
  } catch (e) {
    // console.error(e);
    return { blueprint_id: "error" };
  }
};

export const getConfig = async (blueprint: { humanId: string }) => {
  return await getRealConfig(blueprint);
};
