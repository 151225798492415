import {
  getAllBots,
  getAllBots_bots_edges_node,
} from "../queries/__generated__/getAllBots";
import { useEffect, useState } from "react";

import { GET_ALL_BOTS } from "../queries/getAllBots";
import { useQuery } from "@apollo/client";

export const useBots = () => {
  const { loading, error, data, refetch } = useQuery<getAllBots>(GET_ALL_BOTS);

  const [bots, setBots] = useState<getAllBots_bots_edges_node[]>([]);

  useEffect(() => {
    if (loading) return;
    setBots(data?.bots?.edges.map((e) => e.node) || []);
  }, [loading, data]);

  return { loading, error: error?.message, bots, refetch } as const;
};
