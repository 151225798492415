import BuildIcon from "@material-ui/icons/Build";
import { ContainerStore } from "./../../store/container/ContainerStore";
import DashboardIcon from "@material-ui/icons/Dashboard";
import React from "react";
import ViewListIcon from "@material-ui/icons/ViewList";
import { VirtualHumanIcon } from "@a-i/ui";
import WorkIcon from "@material-ui/icons/Work";
import { queryKeys } from "dangerous/staticStrings";
import { UserRoles } from "const";

export enum DrawerOption {
  hub,
  botsStudio,
  dashboard,
  logs,
  myComponents,
  documentation,
  videos,
  feedback,
  config,
  account,
  bots,
}

export const urlToOption = {
  "/": DrawerOption.dashboard,
  "/dashboard": DrawerOption.dashboard,
  "/hub": DrawerOption.hub,
  "/logs": DrawerOption.logs,
  "/builder": DrawerOption.botsStudio,
  "/bots": DrawerOption.bots,
  "/my-components": DrawerOption.myComponents,
  "/account": DrawerOption.account,
} as { [key: string]: DrawerOption };

export const getDrawerOptions = (store: ContainerStore, userRole: UserRoles | null) => {
  return {
    top: [
      {
        key: DrawerOption.dashboard,
        label: "Home",
        icon: <DashboardIcon />,
        url: userRole === UserRoles.Teacher ? "/dashboard" : '/bots',
        isPrivate: true,
        isHideOnMobile: false,
      },
      {
        key: DrawerOption.botsStudio,
        label: "Builder",
        icon: <BuildIcon />,
        url: "/builder",
        isPrivate: false,
        isHideOnMobile: true,
      },
      {
        key: DrawerOption.bots,
        label: "Bots",
        icon: <VirtualHumanIcon />,
        url: "/bots",
        isPrivate: false,
        isHideOnMobile: true,
        onClick: () => {
          const isGuest = (globalThis as { [key: string]: any }).isGuest;
          if (!isGuest) localStorage.removeItem(queryKeys["drawer-nav-item"]);
        },
      },
      {
        key: DrawerOption.myComponents,
        label: "My Components",
        icon: <WorkIcon />,
        url: "/my-components",
        isPrivate: true,
      },
      {
        key: DrawerOption.logs,
        label: "Logs",
        icon: <ViewListIcon />,
        url: "/logs",
        isPrivate: true,
      },
      // {key: DrawerOption.hub, label: "Hub", icon: <StoreIcon />, url: "/hub"},
    ] as DrawerEntry[],
    bottom: [] as DrawerEntry[],
  };
};

export type DrawerEntry = {
  key: DrawerOption;
  label: string;
  icon: JSX.Element;
  onClick?: () => void;
  url?: string;
  isExternalLink?: boolean;
  isPrivate?: boolean;
  isHideOnMobile?: boolean;
};
