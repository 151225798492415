import React, {useEffect, useRef, useState} from "react";

const useOnFirstRender = (func: Function, deps: Array<any>, rendersCount: number = 1) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (count >= rendersCount) func();
        else setCount(count + 1);
    }, [count, ...deps]);
};

export default useOnFirstRender;
