import { DrawerOption, urlToOption } from "./drawer/drawerOptions";
import React, { useEffect } from "react";
import {
  Theme,
  createStyles,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";

import { CocoAppbar } from "./CocoAppbar";
import { NavDrawer } from "nav-drawer/NavDrawer";
import { NavDrawerContextProvider } from "nav-drawer/context/NavDrawerContext";
import { action } from "mobx";
import { useLocation } from "react-router-dom";
import { useRootStore } from "../store/useRootStore";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
      overflow: "hidden",
      background: theme.custom.palette.d.main,
    },
    body: {
      display: "flex",
      flexDirection: "column",
      height: "calc(100% - 48px)",
    },
    content: { height: "100%", display: "flex" },
    contentInner: {
      flex: 1,
      width: `100%`,
      overflow: "auto",
    },
  })
);

export const Container: React.FC = ({ children }) => {
  const classes = useStyles();

  const { setSelectedDrawerOption } = useRootStore((store) => {
    return {
      setSelectedDrawerOption: action(
        (key: DrawerOption) => (store.container.drawer.selected = key)
      ),
    };
  });

  const isSmDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const { pathname } = useLocation();

  useEffect(() => {
    const key =
      pathname === "/"
        ? "/"
        : Object.keys(urlToOption).find(
            (key) => key !== "/" && pathname.startsWith(key)
          );
    const option = urlToOption[key || ""];
    setSelectedDrawerOption(option);
  }, [pathname]);

  return (
    <div className={classes.root}>
      <NavDrawerContextProvider>
        <CocoAppbar />
        <div className={classes.body}>
          <div className={classes.content}>
            <NavDrawer />
            {/* {isSmDown ? <CocoDrawerMobile /> : <CocoDrawer />} */}
            <div className={classes.contentInner}>{children}</div>
          </div>
        </div>
      </NavDrawerContextProvider>
    </div>
  );
};
