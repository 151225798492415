export enum NodeTypeV2 {
    "entry" = "entry",
    "component" = "component",
    "own" = "own",
    "glue" = "glue",
    "say" = "say",
    "saveInput" = "saveInput",
    "saveAggregated" = "saveAggregated",
    "saveParams" = "saveParams",
    "saveParamsAggregated" = "saveParamsAggregated",
    "navigation" = "navigation",
    "email" = "email",
    "audiofile" = "audiofile",
    "scheduleNegotiator" = "scheduleNegotiator",
    "scheduleCommit" = "scheduleCommit",
    "faq" = "faq",
    "out_of_context" = "out_of_context"
}

export type Position = {x: number; y: number};

export type OneTurnFollowup = {
    id: string;
    intent_name?: string;
    keywords?: string[];
    followup_response: string;
};

export type NavigationBranch = {
    branch_id: string;
    intent_name?: string;
    keywords?: string[];
};

export type GlueNodeParam = NavigationBranch[] | OneTurnFollowup[] | string | boolean;

export type GlueNodeParams = {[key: string]: GlueNodeParam};

export type EmailParam = {
    email: string;
    title: string;
    body: string;
    emailUpdated: boolean;
};

export type NavigationActionData = {
    branch_id: string;
    intent_name?: string;
    keywords?: string[];
};

export type NodeAction = {
    id: string;
    index: number;
    exitPosition: Position;
    data?: string;
    navigationData?: NavigationActionData;
};

export type NodeOptions = {
    call_with_new_input?: boolean;
    email?: EmailParam;
    params?: {[key: string]: GlueNodeParam};
    extraParams?: {[key: string]: {name: string; value: string}};
};

export type NodeV2 = {
    selected: boolean;
    inspected: boolean;
    name?: string;
    componentName?: string;
    type: NodeTypeV2;
    component: string;
    id: string;
    position: Position;
    entryPosition: Position;
    options: NodeOptions;
    actions: NodeAction[];
    edges: string[];
};

export type EdgeV2 = {
    id: string;
    from: {
        nodeId: string;
    };
    to: {
        nodeId?: string;
        position?: Position;
    };
    action: string;
    isHighlighted: boolean;
};

export type ProjectNameType = {
    id: string;
    name?: string;
};

export type GlueStateV2 = {
    id: string;
    name?: string;
    componentId?: string;
    position: Position;
    dimensions: {width: number; height: number};
    version?: string;
    scale: number;
    nodes: {
        [id: string]: NodeV2;
    };
    edges: {
        [id: string]: EdgeV2;
    };
};
