import {BotsStore, BotsStorePrivate} from "../createBotsStore";
import {action, reaction} from "mobx";

import {AuthStore} from "marketplace-2/store/auth/createAuthStore";

/** Fetch bot list on start */
export const runPopulateOnStart = ({store, auth}: {store: BotsStore; auth: AuthStore}) =>
    reaction(
        () => {
            return {isReady: auth.isReady, isGuest: !auth.isAuthenticated};
        },
        action(async ({isReady, isGuest}) => {
            if (!isReady) return;

            if (isGuest) {
                store.isBotsLoaded = true;
                return;
            }

            await store.populate();
        })
    );
