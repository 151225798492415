import { Typography, makeStyles } from "@material-ui/core";

import { ActiveIndicator } from "utils/elments/ActiveIndicator";
import { AnalyticType } from "analytics/AnalyticType";
import { ChannelDialogParams } from "channels/channelsMap";
import { MainButton } from "theme/components/elements/buttons/MainButton";
import { PhoneNumberSelect } from "./PhoneNumberSelect";
import React from "react";
import { TextButton } from "theme/components/elements/buttons/TextButton";
import { getBotDashboard_bot } from "data/api/gql/queries/__generated__/getBotDashboard";
import { usePhoneChannel } from "./usePhoneChannel";
import warningIcon from "./images/warning.svg";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      position: "relative",
      marginTop: theme.spacing(4),
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    footer: {
      paddingTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
    },
    explanation: {
      display: "flex",
    },
    alertIcon: {
      paddingRight: theme.spacing(2),
    },
    body: {
      paddingTop: theme.spacing(3),
      height: "100%",
    },
    phoneSelection: {
      paddingTop: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
    },
    buttonContainer: {
      marginTop: theme.spacing(4),
      display: "flex",
      justifyContent: "center",
    },
  };
});

const getDefaultData = (bot?: getBotDashboard_bot | null) => ({
  component_id: bot?.entrypoint?.humanId || "",
  tts_language: "US_EN",
  stt_language: "US_EN",
  polly_voice_id: bot?.avatar.voice || "",
  phone_number: "",
});

export type PhoneData = {
  component_id: string;
  tts_language: string;
  stt_language: string;
  polly_voice_id: string;
  phone_number: string;
};

const defaultPhoneData = {
  component_id: "",
  tts_language: "",
  stt_language: "",
  polly_voice_id: "",
  phone_number: "",
};

export const PhoneBody: React.FC<ChannelDialogParams> = ({
  bot,
  botId,
  botName,
  botChannels,
  close,
}) => {
  const classes = useStyles();

  const {
    loading,
    error,
    connect,
    disconnect,
    setPhoneData,
    phoneData,
    initialNumber,
  } = usePhoneChannel(botId);

  const isConnected = !!initialNumber;

  if (error) {
    return (
      <div className={classes.container}>
        <Typography color="error">{error}</Typography>
      </div>
    );
  }
  return (
    <div className={classes.container}>
      <ActiveIndicator isActive={isConnected} text={"Connected"} />
      <div className={classes.body}>
        <Typography style={{ whiteSpace: "pre-wrap" }}>
          {`To receive calls, give ${botName} a phone number. 
Choosing a phone number will use 1 of your purchased phone numbers.`}
        </Typography>
        <div className={classes.phoneSelection}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <PhoneNumberSelect
              initialNumber={initialNumber}
              key="phone_number"
              isDisabled={loading || isConnected}
              data={phoneData}
              onSelected={(number: string) =>
                setPhoneData({
                  ...defaultPhoneData,
                  ...phoneData,
                  phone_number: number,
                })
              }
            />
          </div>
          <div className={classes.footer}>
            <div className={classes.explanation}>
              <div className={classes.alertIcon}>
                <img src={warningIcon}></img>
              </div>
              <Typography variant="body1">
                When you purchase a phone number, it's yours! To change the
                number, you'll have to delete it and wait until next month.
              </Typography>
            </div>
            <div className={classes.buttonContainer}>
              {!isConnected ? (
                <MainButton
                  isLoading={loading}
                  analytics_event={AnalyticType["phone-dialog-save"]}
                  disabled={loading || isConnected}
                  onClick={connect}
                  variant={"primary"}
                >
                  Save
                </MainButton>
              ) : (
                <TextButton
                  analytics_event={AnalyticType["phone-dialog-disconnect"]}
                  onClick={() => disconnect().then(() => close())}
                  variant={"primary"}
                >
                  Disconnect
                </TextButton>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
