import Infographics from "./assets/twilioInfographics.svg";
import React from "react";
import {Subtitle} from "theme/components/elements/Subtitle";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => {
    return {
        container: {
            textAlign: "center"
        },
        infographics: {
            paddingTop: theme.spacing(2),
            width: "70%",
            [theme.breakpoints.up("xl")]: {
                width: "100%"
            },
            height: "auto"
        }
    };
});

export const TwilioInfographics: React.FC<{isConnected: boolean}> = ({isConnected}) => {
    const classes = useStyles();

    return !isConnected ? (
        <div className={classes.container}>
            <Subtitle title={"How to"} color={"primary"} bold />
            <img className={classes.infographics} src={Infographics}></img>
        </div>
    ) : null;
};
