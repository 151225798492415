import {Theme, Typography, makeStyles} from "@material-ui/core";

import {ComponentDisplayType} from "marketplace-2/store/components/types";
import React from "react";
import builderIcon from "./assets/builderComponentIcon.svg";
import clsx from "clsx";
import customIcon from "./assets/customComponentIcon.svg";

export type InfoBadgeVariant = "primary" | "secondary" | "alt" | "soon" | "billing_plan" | "channel_card-Pro" | "channel_card-Basic" | "test";
export type InfoBadgeSizeVariant = "normal" | "small";

type StyleProps = {variant: InfoBadgeVariant; size: InfoBadgeSizeVariant; width?: number};

const getColor = ({variant}: StyleProps, theme: Theme) => {
    if (variant === "primary" || variant === "billing_plan") return theme.custom.palette.a.main;
    if (variant === "secondary") return theme.custom.palette.c.main;
    if ((variant === "alt" || variant === "channel_card-Pro")) return "#7D43F8";
    if ((variant === "channel_card-Basic")) return "#2195F3";
    if (variant === "soon") return theme.custom.palette.b.alt;
    if (variant === "test") return theme.custom.palette.e.main;
    return theme.custom.palette.a.main;
};

const getTextColor = (d: StyleProps, theme: Theme) => {
    return theme.custom.palette.textColor(getColor(d, theme));
};

const getHeight = (d: StyleProps, theme: Theme) => {
    if (d.variant === "channel_card-Pro" || d.variant === "channel_card-Basic" || d.size === "small") return theme.spacing(2.3);
    if (d.variant === "billing_plan") return theme.spacing(2.3);
    return theme.spacing(3);
};

const useStyles = makeStyles((theme) => {
    return {
        container: {
            fontWeight: "bold",
            width: (d: StyleProps) => (d.width ? `${d.width}px` : "fit-content"),
            background: (d: StyleProps) => getColor(d, theme),
            color: (d: StyleProps) => getTextColor(d, theme),
            display: "flex",
            justifyContent: "center",
            gap: theme.spacing(1),
            height: (d: StyleProps) => getHeight(d, theme),
            padding: `0px ${theme.spacing(1)}px`
        },
        positionRegular: {
            marginLeft: 0,
            borderRadius: theme.spacing(0.5)
        },
        positionTightLeft: {
            marginLeft: theme.spacing(-2) - 1,
            borderRadius: theme.spacing(0, 0.5, 0.5, 0),
            marginRight: theme.spacing(1)
        },
        positionTightRight: {
            borderRadius: theme.spacing(0.5, 0, 0, 0.5)
        },
        content: {
            // transform: "translateY(-1px)",
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
        },
        vAlign: {display: "flex", alignItems: "center", justifyContent: "center"},
        divider: {
            width: theme.spacing(0.6)
        },
        text: {
            fontWeight: "bold",
            textTransform: "uppercase",
            fontSize: "0.8em",
            transform: "translateY(1px)",
            whiteSpace: "nowrap"
        }
    };
});

const convertEngineNameToType = (engineName?: string) => {
    switch (engineName) {
        case "glue_v2":
            return "builder" as ComponentDisplayType;
            break;
        default:
            return "builder" as ComponentDisplayType;
            break;
    }
};

export const getComponentTypeDisplay = (component: {engine_name?: string; type?: ComponentDisplayType}) => {
    let display;
    const typeFromEngine = convertEngineNameToType(component.engine_name);
    const type = typeFromEngine || component.type;
    switch (type) {
        case "builder":
            display = <img width={14} src={builderIcon} />;
            break;
        default:
            display = <img width={14} src={customIcon} />;
            break;
    }

    return display;
};

const getPositionClass = (variant?: InfoBadgeVariant) => {
    if (variant === "billing_plan") return "positionTightLeft" as "positionTightLeft";
    if (variant === "channel_card-Pro" || variant === "channel_card-Basic") return "positionTightRight" as "positionTightRight";
    return "positionRegular" as "positionRegular";
};

export const InfoBadge: React.FC<{
    text: string;
    variant?: InfoBadgeVariant;
    icon?: React.ReactNode;
    size?: InfoBadgeSizeVariant;
    width?: number;
}> = ({text, variant = "primary", icon, size = "normal", width}) => {
    const classes = useStyles({variant, size, width});

    return (
        <Typography
            variant="caption"
            component="span"
            className={clsx(classes.container, classes[getPositionClass(variant)])}>
            <span className={classes.content}>
                <div className={classes.vAlign}>{icon}</div>
                {icon ? <div className={classes.divider} /> : undefined}
                <div className={classes.vAlign}>
                    <Typography align="center" className={classes.text}>
                        {text}
                    </Typography>
                </div>
            </span>
        </Typography>
    );
};

export type infoBadgeType = ReturnType<typeof InfoBadge>;
