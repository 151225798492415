import { fetchWrapper } from "data/api/rest/fetchWrapper";
import { getOwnerId } from "data/utils";
import { session_id } from "analytics/analytics";

export const chatLog = async ({
  chatSessionId,
  componentId,
  text,
  from,
  botId,
}: {
  chatSessionId: string;
  componentId: string;
  text: string;
  from: "bot" | "user";
  botId?: string;
}) => {
  try {
    const r = await fetchWrapper(`/v2/analytics/log_chat_message`, {
      method: "POST",
      body: JSON.stringify({
        created_at: Date.now(),
        author_id: from === "bot" ? componentId || "guest-bot" : session_id,
        sender: { channel_name: "browser", room_id: chatSessionId },
        recipient: { channel_name: "browser", room_id: chatSessionId },
        payload: { text },
        owner_id: getOwnerId(),
        bot_id: botId,
      }),
      mode: "cors",
      credentials: "include",
    });
    return r.ok;
  } catch (e) {
    console.error(e);
    return false;
  }
};
