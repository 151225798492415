/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation createTeacherStudentAssociation($endUserId: BigInt!, $userId: Int!) {\n    association: createUserEndUserAssociation(\n      input: {userEndUserAssociation: {endUserId: $endUserId, userId: $userId}}\n    ) {\n      clientMutationId\n    }\n  }\n": types.CreateTeacherStudentAssociationDocument,
    "\n  mutation createGroup($className: String!, $endUserId: Int!, $userId: Int!) {\n    group: createGroup(\n      input: {group: {className: $className, endUserId: $endUserId, userId: $userId}}\n    ) {\n      group {\n        id\n        className\n        endUserId\n        userId\n      }\n    }\n  }\n": types.CreateGroupDocument,
    "\n  mutation createEndUser($input: CreateEndUserInput!) {\n    createEndUser(input: $input) {\n      endUser {\n        birthday\n        cellPhone\n        email\n        homeAddress\n        firstName\n        lastName\n        id\n        parent1Name\n        parent2Name\n      }\n    }\n  }\n": types.CreateEndUserDocument,
    "\n  mutation UpdateEndUser($patch: EndUserPatch!, $email: String!) {\n    updateEndUserByEmail(input: {patch: $patch, email: $email}) {\n      endUser {\n        birthday\n        cellPhone\n        email\n        homeAddress\n        firstName\n        lastName\n        id\n        parent1Name\n        parent2Name\n      }\n    }\n  }\n": types.UpdateEndUserDocument,
    "\n    query GroupsByName($className: String!) {\n      groups(condition: {className: $className}) {\n        nodes {\n          endUserId\n        }\n      }\n    }\n": types.GroupsByNameDocument,
    "\n    query getBlueprintsByHumanId($lobbyNodeId: Int!, $exerciseHumanId: String!) {\n        lobby_blueprint: blueprint(id: $lobbyNodeId) {\n            id\n            jsonConfig\n        }\n        exercise_blueprint: blueprints(condition: {humanId: $exerciseHumanId}) {\n            nodes {\n                id\n            }\n      }\n    }\n": types.GetBlueprintsByHumanIdDocument,
    "\n    mutation deleteAndUpdateBlueprints($deleteId: Int!, $updateId: Int!, $jsonConfig: JSON!) {\n        deleteBlueprint(input: {id: $deleteId}) {\n            clientMutationId\n        }\n        updateBlueprint(input: {patch: {jsonConfig: $jsonConfig}, id: $updateId}) {\n          blueprint {\n              id\n              humanId\n          }\n      }\n    }\n": types.DeleteAndUpdateBlueprintsDocument,
    "\n    mutation updateUserRole($id: Int!, $userType: Int!) {\n      updateUser(\n        input: {\n          patch: {\n            userType: $userType\n          }, \n          id: $id\n        }\n      ) {\n        clientMutationId\n        user {\n          id\n          userType\n        }\n      }\n    }\n": types.UpdateUserRoleDocument,
    "\n  query Groups {\n    groups {\n      nodes {\n        className\n      }\n    }\n  }\n": types.GroupsDocument,
    "\n  query StudentData($id: Int!) {\n    endUser(id: $id) {\n      exercises {\n        nodes {\n          blueprintId\n          endUserScore\n          endUserEnd\n          createAt\n          name\n        }\n      }\n    }\n  }\n": types.StudentDataDocument,
    "\n  query GetBotExtras {\n    bots {\n      nodes {\n        extras\n      }\n    }\n  }\n": types.GetBotExtrasDocument,
    "\n  query GetBots {\n    bots {\n      nodes {\n        id\n        firstName\n        lastName\n        extras\n      }\n    }\n  }\n": types.GetBotsDocument,
    "\n    query getUserData($id: Int!) {\n        user(id: $id) {\n            email\n            firstName\n            id\n            lastName\n            name\n            userType\n            userOrganizationAssociations {\n              nodes {\n                organizationId\n              }\n            }\n        }\n    }\n": types.GetUserDataDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation createTeacherStudentAssociation($endUserId: BigInt!, $userId: Int!) {\n    association: createUserEndUserAssociation(\n      input: {userEndUserAssociation: {endUserId: $endUserId, userId: $userId}}\n    ) {\n      clientMutationId\n    }\n  }\n"): (typeof documents)["\n  mutation createTeacherStudentAssociation($endUserId: BigInt!, $userId: Int!) {\n    association: createUserEndUserAssociation(\n      input: {userEndUserAssociation: {endUserId: $endUserId, userId: $userId}}\n    ) {\n      clientMutationId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation createGroup($className: String!, $endUserId: Int!, $userId: Int!) {\n    group: createGroup(\n      input: {group: {className: $className, endUserId: $endUserId, userId: $userId}}\n    ) {\n      group {\n        id\n        className\n        endUserId\n        userId\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation createGroup($className: String!, $endUserId: Int!, $userId: Int!) {\n    group: createGroup(\n      input: {group: {className: $className, endUserId: $endUserId, userId: $userId}}\n    ) {\n      group {\n        id\n        className\n        endUserId\n        userId\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation createEndUser($input: CreateEndUserInput!) {\n    createEndUser(input: $input) {\n      endUser {\n        birthday\n        cellPhone\n        email\n        homeAddress\n        firstName\n        lastName\n        id\n        parent1Name\n        parent2Name\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation createEndUser($input: CreateEndUserInput!) {\n    createEndUser(input: $input) {\n      endUser {\n        birthday\n        cellPhone\n        email\n        homeAddress\n        firstName\n        lastName\n        id\n        parent1Name\n        parent2Name\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateEndUser($patch: EndUserPatch!, $email: String!) {\n    updateEndUserByEmail(input: {patch: $patch, email: $email}) {\n      endUser {\n        birthday\n        cellPhone\n        email\n        homeAddress\n        firstName\n        lastName\n        id\n        parent1Name\n        parent2Name\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateEndUser($patch: EndUserPatch!, $email: String!) {\n    updateEndUserByEmail(input: {patch: $patch, email: $email}) {\n      endUser {\n        birthday\n        cellPhone\n        email\n        homeAddress\n        firstName\n        lastName\n        id\n        parent1Name\n        parent2Name\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query GroupsByName($className: String!) {\n      groups(condition: {className: $className}) {\n        nodes {\n          endUserId\n        }\n      }\n    }\n"): (typeof documents)["\n    query GroupsByName($className: String!) {\n      groups(condition: {className: $className}) {\n        nodes {\n          endUserId\n        }\n      }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query getBlueprintsByHumanId($lobbyNodeId: Int!, $exerciseHumanId: String!) {\n        lobby_blueprint: blueprint(id: $lobbyNodeId) {\n            id\n            jsonConfig\n        }\n        exercise_blueprint: blueprints(condition: {humanId: $exerciseHumanId}) {\n            nodes {\n                id\n            }\n      }\n    }\n"): (typeof documents)["\n    query getBlueprintsByHumanId($lobbyNodeId: Int!, $exerciseHumanId: String!) {\n        lobby_blueprint: blueprint(id: $lobbyNodeId) {\n            id\n            jsonConfig\n        }\n        exercise_blueprint: blueprints(condition: {humanId: $exerciseHumanId}) {\n            nodes {\n                id\n            }\n      }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation deleteAndUpdateBlueprints($deleteId: Int!, $updateId: Int!, $jsonConfig: JSON!) {\n        deleteBlueprint(input: {id: $deleteId}) {\n            clientMutationId\n        }\n        updateBlueprint(input: {patch: {jsonConfig: $jsonConfig}, id: $updateId}) {\n          blueprint {\n              id\n              humanId\n          }\n      }\n    }\n"): (typeof documents)["\n    mutation deleteAndUpdateBlueprints($deleteId: Int!, $updateId: Int!, $jsonConfig: JSON!) {\n        deleteBlueprint(input: {id: $deleteId}) {\n            clientMutationId\n        }\n        updateBlueprint(input: {patch: {jsonConfig: $jsonConfig}, id: $updateId}) {\n          blueprint {\n              id\n              humanId\n          }\n      }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation updateUserRole($id: Int!, $userType: Int!) {\n      updateUser(\n        input: {\n          patch: {\n            userType: $userType\n          }, \n          id: $id\n        }\n      ) {\n        clientMutationId\n        user {\n          id\n          userType\n        }\n      }\n    }\n"): (typeof documents)["\n    mutation updateUserRole($id: Int!, $userType: Int!) {\n      updateUser(\n        input: {\n          patch: {\n            userType: $userType\n          }, \n          id: $id\n        }\n      ) {\n        clientMutationId\n        user {\n          id\n          userType\n        }\n      }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Groups {\n    groups {\n      nodes {\n        className\n      }\n    }\n  }\n"): (typeof documents)["\n  query Groups {\n    groups {\n      nodes {\n        className\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query StudentData($id: Int!) {\n    endUser(id: $id) {\n      exercises {\n        nodes {\n          blueprintId\n          endUserScore\n          endUserEnd\n          createAt\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query StudentData($id: Int!) {\n    endUser(id: $id) {\n      exercises {\n        nodes {\n          blueprintId\n          endUserScore\n          endUserEnd\n          createAt\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetBotExtras {\n    bots {\n      nodes {\n        extras\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetBotExtras {\n    bots {\n      nodes {\n        extras\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetBots {\n    bots {\n      nodes {\n        id\n        firstName\n        lastName\n        extras\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetBots {\n    bots {\n      nodes {\n        id\n        firstName\n        lastName\n        extras\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query getUserData($id: Int!) {\n        user(id: $id) {\n            email\n            firstName\n            id\n            lastName\n            name\n            userType\n            userOrganizationAssociations {\n              nodes {\n                organizationId\n              }\n            }\n        }\n    }\n"): (typeof documents)["\n    query getUserData($id: Int!) {\n        user(id: $id) {\n            email\n            firstName\n            id\n            lastName\n            name\n            userType\n            userOrganizationAssociations {\n              nodes {\n                organizationId\n              }\n            }\n        }\n    }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;