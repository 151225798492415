import {IntegrationType} from "integrations/types";
import {action} from "mobx";
import {queryKeys} from "dangerous/staticStrings";
import {useCallback} from "react";
import {useRootStore} from "marketplace-2/store/useRootStore";

export const useGoogleCalendarRedirect = () => {
    const {integration, handleIntegrationData} = useRootStore((store) => {
        const integration = store.bots.editor.current?.integrations[IntegrationType.google]?.id;
        return {
            integration,
            handleIntegrationData: action(({code, botId}: {code: string; botId: string}) => {
                store.bots.inspectByGid(botId);
                localStorage.setItem(queryKeys["google-code"], code);
            })
        };
    });

    const processCode = useCallback(
        async ({code, botId}: {code: string; botId: string}) => {
            if (integration) return;
            handleIntegrationData({code, botId});
        },
        [integration]
    );

    return processCode;
};
