import {ActiveBotContext, ActiveBotState} from "./ActiveBotContext";
import {useCallback, useContext} from "react";

export const useActiveBotContext = () => {
    const {getValue, dispatch, actions} = useContext(ActiveBotContext);

    const select = useCallback(
        function <T>(callback: (s: ActiveBotState) => T) {
            return getValue(callback);
        },
        [getValue]
    );

    return {select, dispatch, actions} as const;
};
