import { ENVIRONMENT } from "const";
import { checkIsTestEnvironment } from "utils/checkIsTestEnvironment";

const h = window.location.hostname;
const isLocal = checkIsTestEnvironment();

export const INFO_TOOLTIPS_ID = "info-tooltips";

export enum CHANNEL_NAME {
  EMBEDDED = "EMBEDDED",
  FACEBOOK = "FACEBOOK",
  TELEPHONE = "TELEPHONE",
  TELEGRAM = "TELEGRAM",
  TWILIO_ASYNC_MSG = "TWILIO_ASYNC_MSG",
  ZOOM = "ZOOM",
  WORDPRESS = "WORDPRESS",
}

export const urls = {
  createBot: "/create-bot",
  dashboard: "/dashboard",
  auth: "/auth",
  onboarding: "/bots/onboarding",
  scheduler_onboarding: "/bots/scheduler_onboarding",
  bots: "/bots",
  account: "/account",
  redirectResult: "/redirectresult",
};

export const queryKeys = {
  "bot-dashboard-active-window": "bdaw",
  "bot-tutorial-data": "bta",
  "drawer-nav-item": "dni",
  "bot-integration-dialog": "bid",
  "google-code": "gcode",
  "google-calendar-tempory-data": "gctd",
  "onboarding-test-type": "ott",
  "onboarding-is-skipping": "ois",
  "plan-purchase-success": "pps",
  stripe_result_data: "p",
  "fancy-avatar": "fav",
  "create-bot-open": "cbo",
  "logs-tab-key": "ltk",
  "skills-edit-key": "sek",
  "refresh-address-key": "rak",
  "quiz-last-tab": "qlt",
};

export const redirectKeys = {
  google_calendar: "google_calendar",
};

export const localStorageKeys = {
  "video-channel-last-sceneId": "video-channel-last-sceneId",
  "phone-channel-last-country": "phone-channel-last-country",
  "phone-channel-last-number": "phone-channel-last-number",
  "google-redirect-url": "google-redirect-url",
  "brain-tmp-data": "brain-tmp-data",
  "access-token": "accstkn",
  "bot-component-id-old": "bcid",
  "graphql-user-token": "graphql-user-token",
};

export const authUserInOnboarding = "auth-user-in-onboarding";
export const report_event = "report_event";
export const onboardingProgressKey = "onboardingProgressKey";
export const videoTestBgKey = "videoTestBgKey";
export const savedUrl = "savedUrl";
export const userWithoutOnboardingKey = "user-without-onboarding-key";
export const dashboardChannelKey = "dashboard";
export const guestBotKey = "guest_bot_key";
export const nodeDisplayRootAttribute = "node-display-root";
export const projListKey = "projects-of-user-list";
export const userProjectsKeys = "user-projects-keys";
export const signUpIconKey = "sign-up-icon-key";
export const gluev1HumanId = "glue_v1";
export const gluev2HumanId = "glue_v2";
export const studioVersion = "1.0.0";
export const oldGlueHumanId = "gluecomp_v1";
export const sayHumanIdv1 = "oneturn_say";
export const sayHumanIdv2 = "oneturn_say_v2";
export const navigation = "navigation";
export const oneTurnHumanIdV1 = "oneturn_followup";
export const oneTurnHumanIdV2 = "oneturn_followup_v2";
export const emailHumanId = "email_pv1";
export const saveToContextId = "save_to_context";
export const scheduler_negotiator_id = "scheduler_negotiate_pv1";
export const scheduler_commit_id = "scheduler_commit_pv1";
export const saveToContextAggregatedId = "aggregate_context";
export const inputAggregatorId = "input_aggregator";
export const sayAndOneTurnId = "oneturn_say_followup";
export const faqId = "qamk2";
export const outOfContextId = "out_of_context";
export const usersBotNames = "users_bot_names";

export const DEFAULT_LANGUAGE = "en-US";
export const DEFAULT_BRAIN = "onboarding_v1";
export const DEFAULT_BOT_ID = "default_bot_id";
export const COMPONENT_DONE = "component_done";
export const COMPONENT_FAILED = "component_failed";
export const OUT_OF_CONTEXT = "out_of_context";
export const componentDefaultActions = [COMPONENT_DONE, COMPONENT_FAILED];

export const temporaryActionId = "temporary";

export const entryId = "entry";

export const saveParamsParamId = "save_params_param_id";

export const defaultEmailValue = "${context.owner.email}";

export const GUEST_DATA_KEY = "guest-data-key";

export const AFFILITE_KEY = "AFFILITE_KEY";

export const LEARNING_CENTER_KEY = "learning-center-key";

export const REMINDERS_KEY = "reminders_key";

export const GUEST_WIZARD_RESULT = "guest_wizard_result";

export const DfToZoomWizardKey = "dfToZoom";

export const USER_AS_PARTICIPANT_ID = "user";

export const NAVIGATION_SOURCE_QUERY_KEY = "nav_source";

export const defaultAvailableIntents = [
  "yes",
  "no",
  "continue",
  "stop",
  "wildcard",
];

export const glueIds = [
  gluev1HumanId,
  gluev2HumanId,
  oldGlueHumanId,
  sayHumanIdv1,
  sayHumanIdv2,
  oneTurnHumanIdV1,
  oneTurnHumanIdV2,
  saveToContextId,
  inputAggregatorId,
  sayAndOneTurnId,
  navigation,
];

export const glueComponentIds = [
  sayHumanIdv1,
  sayHumanIdv2,
  oneTurnHumanIdV1,
  oneTurnHumanIdV2,
  saveToContextId,
  inputAggregatorId,
  navigation,
  sayAndOneTurnId,
];

export const paymeKey = isLocal
  ? "MPL16085-49041MYB-HJ01HIAD-S0JEEEXM"
  : "MPL16068-98251BSG-5WKBKMRZ-NLKL3LCD";
export const marketingSiteUrl = ENVIRONMENT.ABOUT_URL;
export const stagingUrl = "staging.beyondvirtual.ai";
export const cocohubAudiocodesURL = ENVIRONMENT.AUDIOCODES_URL;
