import gql from "graphql-tag";

export const GET_PARTICIPANT = gql`
    query getParticipant($id: Int!) {
        participant(id: $id) {
            handle
            balance
            billingPlanId
            bio
            createdAt
            id
            imageUrl
            phoneSeconds
            portfolio
            sessionsCount
            zoomSeconds
        }
    }
`;
