import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";

import { onError } from "@apollo/client/link/error";
import { relayStylePagination } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";

const REFRESH_TOKEN_LEGROOM = 5 * 60;

const parseJwt = (token: string) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

const isAboutToExpire = (token: string) => {
  try {
    const parsed = parseJwt(token);
    if (!parsed.exp) return false;
    const deadline = parseInt(String(Date.now()).slice(0, -3));
    const exp = Math.round(parsed.exp);
    const delta = exp - deadline;
    return delta < REFRESH_TOKEN_LEGROOM;
  } catch (e) {
    return false;
  }
};

export const client = (token: string, refetchToken: () => Promise<string>) => {
  const defaultLink = ApolloLink.from([
    onError(({ networkError }) => {
      if ((networkError as any)?.statusCode === 401) {
        // refetchToken();
      }
    }),
    setContext(async (_, { headers }) => {
      let authHeader = {};
      if (token) {
        if (isAboutToExpire(token)) {
          token = await refetchToken();
        }
        authHeader = { authorization: `Bearer ${token}` };
      }
      return {
        headers: {
          ...headers,
          ...authHeader,
        },
      };
    }),
    createHttpLink({
      uri: "/graphql",
    }),
  ]);

  const logsLink = ApolloLink.from([
    onError(({ networkError }) => {
      if ((networkError as any)?.statusCode === 401) {
        // refetchToken();
      }
    }),
    setContext(async (_, { headers }) => {
      let authHeader = {};
      if (token) {
        if (isAboutToExpire(token)) {
          token = await refetchToken();
        }
        authHeader = { authorization: `Bearer ${token}` };
      }
      return {
        headers: {
          ...headers,
          ...authHeader,
        },
      };
    }),
    createHttpLink({
      uri: "/graphql-logs",
    }),
  ]);

  const link = ApolloLink.split(
    (operation) => operation.getContext().clientName === "logs",
    logsLink,
    defaultLink
  );

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          bots: relayStylePagination(),
          blueprints: relayStylePagination(),
        },
      },
    },
  });

  return new ApolloClient({
    link,
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "no-cache",
        pollInterval: 1000 * 600,
      },
      query: {
        fetchPolicy: "no-cache",
      },
    },
    connectToDevTools: true,
  });
};
