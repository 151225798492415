import { PLAN } from "pricing/plans/choose-plan/ChoosePlan";

export const planToPlanName = new Map([
  [PLAN.basicMonthly, "Basic"],
  [PLAN.basicAnnually, "Basic"],
  [PLAN.proAnnually, "Pro"],
  [PLAN.proMonthly, "Pro"],
  [PLAN.businessMonthly, "Business"],
  [PLAN.businessAnnually, "Business"],
  [PLAN.custom, "Enterprise"],
]);
