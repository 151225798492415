import { ChannelType } from "channels/types";
import React from "react";

export type LogBot = {
  avatar: string;
  name: string;
  id: string;
  brain: { id: string; name: string };
};

export type LogSessionEntry = {
  id: string;
  bot: LogBot;
  date: string;
  message: string;
  media: string;
  channelIcon: React.ReactNode;
  channelType: ChannelType;
  numTurns: number;
  duration: number;
  firstMsgTime?: string,
  lastMsgTime?: string
};

export type BotGqlEntry = {
  authorId: string;
  bot: string;
  channelType: string;
  createdAt: string;
  msg: string;
  sessionId: string;
};

export type SessionFilters = {
  bots?: string[]; // bot ids
  channels?: string[]; // channel ids
  dates: [Date, Date];
  duration?: string;
};

export type ViableFilters = {
  bots: string[]; // bot ids
  channels: string[]; // channel ids
  durations: string[];
};

export enum SORTING_FIELD {
  "botname" = "botname",
  "channel" = "channel",
  "date" = "date",
  "numberOfTurns" = "numberOfTurns",
  "duration" = "duration",
}

export enum SORTING_DIRECTION {
  "up" = "up",
  "down" = "down",
  "none" = "none",
}

export type Sorting = { type: SORTING_FIELD; direction: SORTING_DIRECTION };

export type SortingProps = {
  current?: Sorting;
  onChange: (type: SORTING_FIELD, direction: SORTING_DIRECTION) => void;
  bots: LogBot[];
};

export type IconMaps = {
  avatars: Map<string, JSX.Element>;
  channels: Map<string, JSX.Element>;
  channelNames: Map<string, string>;
};

export type SessionTurn = {
  id: string;
  isBot: boolean;
  message: string;
  media: string;
  timestamp: string;
};

export type LogsFiltersProps = {
  viableFilters: ViableFilters;
  activeFilters: SessionFilters;
  onFilter: (filters: SessionFilters) => void;
  filterString: string;
  setFilterString: (filterString: string) => void;
} & IconMaps;

export type PaginationProps = {
  rowsPerPage: number;
  page: number;
  total: number;
  setPage: (page: number) => void;
  setRowsPerPage: (rows: number) => void;
  numPages: number;
};

export type LogsTableProps = {
  onSessionView: (id: string, duration: number) => void;
  isLoading?: boolean;
};

export type LogsSessionsProps = LogsFiltersProps &
  LogsTableProps &
  SortingProps &
  IconMaps;

export type SessionDrilldownProps = {
  turns: SessionTurn[];
  onClose: () => void;
  bot: { avatar: string; name: string; brain: string };
  channel: ChannelType;
  sessionId: string;
} & IconMaps;

type SessionFiltersType = {
  bots?: string[];
  channels?: string[];
  dates?: [Date, Date];
  duration?: string[];
};

export type LogsSessionProps = {
  sessions: LogSessionEntry[];
  viableFilters: ViableFilters;
  searchString: string;
  onFilter: (filters: SessionFiltersType) => void;
  onClick: (id: string) => void;
  pagination: {
    rowsPerPage: number;
    page: number;
    total: number;
    setPage: (page: number) => void;
    setRowsPerPage: (rows: number) => void;
    fetchNext: () => void;
  };
};
