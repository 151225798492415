import {ButtonBase, Typography, makeStyles} from "@material-ui/core";
import {TwilioData, WEBHOOK_PREFIX} from "./TwilioChannelBody";

import React from "react";
import {SecondaryButton} from "theme/components/elements/buttons/SecondaryButton";
import copy from "clipboard-copy";
import {useNotification} from "utils/hooks/useNotification";

const useStyles = makeStyles((theme) => {
    return {
        container: {
            paddingTop: theme.spacing(2),
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center"
        },
        divider: {
            paddingRight: theme.spacing(4)
        },
        phoneNumber: {
            color: theme.custom.palette.a.main
        },
        numberDisplay: {
            backgroundColor: theme.custom.palette.d.alt,
            padding: theme.spacing(0.5, 1),
            marginLeft: theme.spacing(1),
            borderRadius: theme.spacing(3),
            cursor: "pointer"
        },
        phoneNumberContainer: {
            display: "flex",
            alignItems: "center"
        }
    };
});

const TWILIO_URL = "https://www.twilio.com/console/phone-numbers/";

const gotoTwilio = () => {
    window.open(TWILIO_URL, "_blank")?.focus();
};

export const TwilioConnectedBody: React.FC<{channelId: string; data: TwilioData}> = ({channelId, data: {from_}}) => {
    const classes = useStyles();
    const addMessage = useNotification();

    const copyHookURL = () => copy(WEBHOOK_PREFIX + channelId);

    return (
        <div className={classes.container}>
            <div className={classes.phoneNumberContainer}>
                <Typography className={classes.phoneNumber}>Phone number:</Typography>
                <ButtonBase
                    className={classes.numberDisplay}
                    onClick={() => {
                        copy(from_);
                        addMessage("Phone number copied to clipboard", "success");
                    }}>
                    <Typography>{from_}</Typography>
                </ButtonBase>
            </div>
            <div className={classes.buttonContainer}>
                <SecondaryButton onClick={copyHookURL} variant={"outlined"} color={"black-black"}>
                    Copy url
                </SecondaryButton>
                <div className={classes.divider} />
                <SecondaryButton onClick={gotoTwilio} variant={"contained"} color={"quinary"}>
                    Go to Twilio
                </SecondaryButton>
            </div>
            <div />
        </div>
    );
};
