import {fetchWrapper} from "data/api/rest/fetchWrapper";

export type SearchResult = {
    total: number;
    hits: SearchResultHit[];
};

export type SearchResultHit = {
    human_id: string;
    goal: string;
    description: string;
    name: string;
    vendor?: {name?: string; display_name?: string; image_url?: string};
    categories?: {name: string}[];
};

export const getSearchResultsElastic: (p: {
    query: string | undefined;
    from?: number;
    size?: number;
}) => Promise<SearchResult> = async ({query, from = 0, size = 10000}) => {
    if (!query) {
        return {total: 0, hits: []};
    }

    try {
        const reply = await fetchWrapper(`/v2/search?query=${query || ""}&offset=${from}&limit=${size}`);
        const json = await reply.json();
        return {
            total: json.total || 0,
            hits: json.results || []
        };
    } catch (e) {
        console.error(e);
        return {total: 0, hits: []};
    }
};
