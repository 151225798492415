import { useMemo, useState, useEffect, useRef } from "react";
import { io, Socket } from "socket.io-client";
import { SendSignalingEventFn, SignalingEvents } from "./types";
import { ENVIRONMENT } from "const";
import { humanReadableId } from "utils/humanReadableId";

export const useSignaling = () => {
  const webrtcUrl = ENVIRONMENT.WEBRTC_URL;
  if (!webrtcUrl) {
    throw new Error('"FRONTEND_WEBRTC_URL" not defined in environment variables');
  }
    
  const [transport, setTransport] = useState<{
    socket: Socket,
    room: string,
  } | null>(null);

  const tasks = useRef<[SignalingEvents, any][]>([]);

  useEffect(() => {
    const room = humanReadableId();
    const socket = io(webrtcUrl, {
      auth: {
        room,
        clientType: 'user',
      },
      autoConnect: false,
    })

    setTransport({
      room,
      socket,
    });
  }, []);

  useEffect(() => {
    if (transport) {
      tasks.current.forEach((task) => transport.socket.emit(...task));
      tasks.current = [];
    }
  }, [!!transport]);


  const signaling = useMemo(() => {
    const sendEvent: SendSignalingEventFn = (...args: [SignalingEvents, any]) => {
      if (transport) {
        transport.socket.emit(...args);
      } else {
        tasks.current.push(args);
      }
    };

    return {
      transport,
      sendEvent,
    }
  }, [transport]);

  return signaling;
}