import { useHistory, useParams } from "react-router";

import { fromb64 } from "utils/query-string/base64";
import { getQueryParam } from "utils/query-string/getQueryParam";
import { redirectKeys } from "dangerous/staticStrings";
import { removeQueryParam } from "utils/query-string/removeQueryParam";
import { useEffect } from "react";
import { useGoogleCalendarRedirect } from "./useGoogleCalendarRedirect";

export const RedirectResultPage = () => {
    const {type} = useParams<{type: string}>();
    const history = useHistory();

    const processGoogleCode = useGoogleCalendarRedirect();

    useEffect(() => {
        if (type === redirectKeys.google_calendar) {
            const code = getQueryParam("code");
            removeQueryParam("code");
            const state = getQueryParam("state");
            removeQueryParam("state");
            if (!code || !state) return;
            try {
                const {botId, url} = JSON.parse(fromb64(state));
                processGoogleCode({code, botId});
                url && history.push(url);
            } catch (e) {
                console.error("Did not receive code and/or state from google calendar redirect");
            }
        }
    }, [type, processGoogleCode]);

    return null;
};
