import { ChannelType, PhoneData } from "channels/types";
import { useEffect, useState } from "react";

import { CHANNEL_NAME } from "dangerous/staticStrings";
import { GET_BOT_DASHBOARD } from "data/api/gql/queries/getBotDashboard";
import { buyAndAssignPhone } from "./functions/buyAndAssignPhone";
import { getBotDashboard } from "data/api/gql/queries/__generated__/getBotDashboard";
import { useChannelFunctions } from "data/api/gql/hooks/useChannelFunctions";
import { useQuery } from "@apollo/client";

export const usePhoneChannel = (botId: string) => {
  const {
    loading: bLoading,
    error: bError,
    data: botDashboard,
  } = useQuery<getBotDashboard>(GET_BOT_DASHBOARD, {
    variables: { id: Number(botId) },
  });

  const [channelId, setChannelId] = useState("");
  const [phoneData, setPhoneData] = useState<PhoneData>();

  const [isLoading, setIsLoading] = useState(false);

  const [initialNumber, setInitialNumber] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (bLoading) return;
    const bot = botDashboard?.bot;
    if (!bot) return;

    const channel = bot.channels.edges.find(
      ({ node }) => node.jsonConfig.channel_name === ChannelType.telephone
    );
    setChannelId(String(channel?.node?.id) || "");
    const nn = channel?.node?.jsonConfig.phone_number || initialNumber;

    setInitialNumber(nn);
    setPhoneData(
      (channel?.node?.jsonConfig || {
        component_id: bot.entrypoint?.humanId || "",
        tts_language: "US_EN",
        stt_language: "US_EN",
        polly_voice_id: bot.avatar.voice || "",
        phone_number: "",
      }) as PhoneData
    );
  }, [botDashboard, bLoading]);

  const {
    create,
    remove,
    loading: channelLoading,
  } = useChannelFunctions({ botId });

  const connect = async () => {
    if (!phoneData) {
      setError("No phone number selected");
      return;
    }

    setIsLoading(true);
    const createResult = await create({
      variables: {
        channelName: CHANNEL_NAME.TELEPHONE,
        botId: parseInt(botId),
        jsonConfig: { channel_name: ChannelType.telephone, ...phoneData },
      },
    });

    const cId = createResult.data?.createChannel?.channel?.id;

    if (!cId || createResult.errors?.length) {
      setIsLoading(false);
      return;
    }
    const buyError = await buyAndAssignPhone({
      phone: phoneData?.phone_number || "",
      channelId: cId,
      botId,
    });

    setError(buyError);

    setIsLoading(false);
  };

  const disconnect = async () => {
    await remove({ variables: { id: parseInt(channelId) } });
    close();
  };

  const loading = isLoading || channelLoading;

  return {
    loading,
    error,
    connect,
    disconnect,
    initialNumber,
    phoneData,
    setPhoneData,
  } as const;
};
