import {makeStyles, Typography} from "@material-ui/core";
import React from "react";
const useStyles = makeStyles((theme) => {
    return {
        container: {},
        title: {
            paddingTop: theme.spacing(3),
            display: "flex",
            alignItems: "center"
        }
    };
});

const WhatsappTitle = () => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={classes.title}>
                <Typography variant={"h5"}>
                    Connect your bot to <b>Whatsapp</b>
                </Typography>
            </div>
        </div>
    );
};

export default WhatsappTitle;
