import {useEffect} from "react";
import {useIsGuest} from "utils/hooks/useIsGuest";
import {useSignUpOrLogin} from "utils/hooks/useSignUpOrLogin";

export const Signup = () => {
    const isGuest = useIsGuest();
    const {signUp} = useSignUpOrLogin();

    useEffect(() => {
        isGuest && signUp();
    }, [isGuest]);

    return null;
};
