export const CHR_Male_01_NEW = {
    version: 3,
    packedSlotDataList: [],
    slotsV2: [],
    slotsV3: [
        {
            id: "CHR_Male_Mouth_Diego",
            scale: 100,
            copyIdx: -1,
            overlays: [
                {
                    id: "CHR_Male_Mouth_Diego_Overlay",
                    colorIdx: 2,
                    rect: [0.0, 0.0, 0.0, 0.0],
                    isTransformed: false,
                    scale: {x: 1.0, y: 1.0, z: 1.0},
                    rotation: 0.0
                }
            ],
            Tags: [],
            Races: []
        },
        {
            id: "CHR_Male_Face_Diego",
            scale: 100,
            copyIdx: -1,
            overlays: [
                {
                    id: "CHR_Male_Face_Diego_Overlay",
                    colorIdx: 2,
                    rect: [0.0, 0.0, 0.0, 0.0],
                    isTransformed: false,
                    scale: {x: 1.0, y: 1.0, z: 1.0},
                    rotation: 0.0
                }
            ],
            Tags: [],
            Races: []
        },
        {
            id: "CHR_Male_Eyelashes_Diego",
            scale: 100,
            copyIdx: -1,
            overlays: [
                {
                    id: "CHR_Male_Eyelashes_Diego_Overlay",
                    colorIdx: 2,
                    rect: [0.0, 0.0, 0.0, 0.0],
                    isTransformed: false,
                    scale: {x: 1.0, y: 1.0, z: 1.0},
                    rotation: 0.0
                }
            ],
            Tags: [],
            Races: []
        },
        {
            id: "CHR_Male_EyeMoisture_Diego",
            scale: 100,
            copyIdx: -1,
            overlays: [
                {
                    id: "CHR_Male_EyeMoisture_Diego_Overlay",
                    colorIdx: 2,
                    rect: [0.0, 0.0, 0.0, 0.0],
                    isTransformed: false,
                    scale: {x: 1.0, y: 1.0, z: 1.0},
                    rotation: 0.0
                }
            ],
            Tags: [],
            Races: []
        },
        {
            id: "Eye_URP.L_Diego",
            scale: 100,
            copyIdx: -1,
            overlays: [
                {
                    id: "Eye_URP.L_Diego_Overlay",
                    colorIdx: 2,
                    rect: [0.0, 0.0, 0.0, 0.0],
                    isTransformed: false,
                    scale: {x: 1.0, y: 1.0, z: 1.0},
                    rotation: 0.0
                }
            ],
            Tags: [],
            Races: []
        },
        {
            id: "Eye_URP.R_Diego",
            scale: 100,
            copyIdx: -1,
            overlays: [
                {
                    id: "Eye_URP.R_Diego_Overlay",
                    colorIdx: 2,
                    rect: [0.0, 0.0, 0.0, 0.0],
                    isTransformed: false,
                    scale: {x: 1.0, y: 1.0, z: 1.0},
                    rotation: 0.0
                }
            ],
            Tags: [],
            Races: []
        },
        {
            id: "CHR_Male_Neck_Diego",
            scale: 100,
            copyIdx: -1,
            overlays: [
                {
                    id: "CHR_Male_Neck_Diego_Overlay",
                    colorIdx: 2,
                    rect: [0.0, 0.0, 0.0, 0.0],
                    isTransformed: false,
                    scale: {x: 1.0, y: 1.0, z: 1.0},
                    rotation: 0.0
                }
            ],
            Tags: [],
            Races: []
        },
        {
            id: "CHR_Male_Hands_Diego",
            scale: 100,
            copyIdx: -1,
            overlays: [
                {
                    id: "CHR_Male_Hands_Diego_Overlay",
                    colorIdx: 2,
                    rect: [0.0, 0.0, 0.0, 0.0],
                    isTransformed: false,
                    scale: {x: 1.0, y: 1.0, z: 1.0},
                    rotation: 0.0
                }
            ],
            Tags: [],
            Races: []
        },
        {
            id: "CHR_Male_Arms_Diego",
            scale: 100,
            copyIdx: -1,
            overlays: [
                {
                    id: "CHR_Male_Arms_Diego_Overlay",
                    colorIdx: 2,
                    rect: [0.0, 0.0, 0.0, 0.0],
                    isTransformed: false,
                    scale: {x: 1.0, y: 1.0, z: 1.0},
                    rotation: 0.0
                }
            ],
            Tags: [],
            Races: []
        },
        {
            id: "Male_01_Hair_Stylized",
            scale: 100,
            copyIdx: -1,
            overlays: [
                {
                    id: "Male_01_Hair_Stylized_Overlay",
                    colorIdx: 2,
                    rect: [0.0, 0.0, 0.0, 0.0],
                    isTransformed: false,
                    scale: {x: 1.0, y: 1.0, z: 1.0},
                    rotation: 0.0
                }
            ],
            Tags: [],
            Races: []
        },
        {
            id: "Male_01_Shirt",
            scale: 100,
            copyIdx: -1,
            overlays: [
                {
                    id: "Male_01_Shirt_Overlay",
                    colorIdx: 2,
                    rect: [0.0, 0.0, 0.0, 0.0],
                    isTransformed: false,
                    scale: {x: 1.0, y: 1.0, z: 1.0},
                    rotation: 0.0
                }
            ],
            Tags: [],
            Races: []
        }
    ],
    colors: [],
    fColors: [
        {
            name: "Skin",
            colors: [
                255, 255, 255, 255, 0, 0, 0, 0, 255, 255, 255, 255, 0, 0, 0, 0, 255, 255, 255, 255, 0, 0, 0, 54, 255,
                255, 255, 255, 0, 0, 0, 0
            ],
            ShaderParms: [],
            alwaysUpdate: false
        },
        {
            name: "Eyes",
            colors: [
                176, 110, 0, 255, 0, 0, 0, 0, 255, 255, 255, 255, 0, 0, 0, 0, 255, 255, 255, 255, 0, 0, 0, 0, 255, 255,
                255, 255, 0, 0, 0, 0
            ],
            ShaderParms: [],
            alwaysUpdate: false
        },
        {name: "-", colors: [255, 255, 255, 255, 0, 0, 0, 0], ShaderParms: [], alwaysUpdate: false}
    ],
    sharedColorCount: 2,
    race: "CHR_Male_Diego",
    packedDna: [
        {
            dnaType: "DynamicUMADna",
            dnaTypeHash: 271414191,
            packedDna:
                '{"bDnaAsset":{"instanceID":32214},"bDnaAssetName":"CHR_Male_DNA","bDnaSettings":[{"name":"Body Fat","value":128},{"name":"Body BodyBuilder","value":128},{"name":"Face Square","value":128},{"name":"Skin Tone","value":128},{"name":"Face Pooping","value":128},{"name":"Clothes Shirt R","value":128},{"name":"Clothes Shirt G","value":128},{"name":"Clothes Shirt B","value":128},{"name":"Clothes Pants R","value":128},{"name":"Clothes Pants G","value":128},{"name":"Clothes Pants B","value":128},{"name":"General Logo R","value":128},{"name":"General Logo G","value":128},{"name":"General Logo B","value":128},{"name":"Hair Mullet","value":128},{"name":"Eyes Cross","value":128},{"name":"Face Khalan","value":128},{"name":"Body Khalan","value":128},{"name":"Torso Khalan","value":128},{"name":"Face Diego","value":153},{"name":"Body Diego","value":255},{"name":"Shoulders Fix","value":191}]}'
        }
    ],
    wardrobeSet: [
        {slot: "Hair", recipe: "CHR_Male_Hair_01_Stylized"},
        {slot: "Chest", recipe: "CHR_Male_01_Shirt"}
    ],
    packedRecipeType: "Standard"
};
