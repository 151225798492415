import { gql } from "@apollo/client";

export const SEARCH_INTENTS = gql`
  query searchIntents($query: String = "", $ownerId: Int) {
    searchIntents(query: $query, ownerId: $ownerId) {
      edges {
        node {
          body
          createdAt
          description
          hash
          id
          name
          nodeId
          ownerId
          public
          threshold
          type
          owner {
            handle
          }
        }
      }
      totalCount
    }
  }
`;
