import {IObservableValue, action, autorun, observable, toJS} from "mobx";

import {ContainerStore} from "../container/ContainerStore";
import {createComponentFromUrl} from "data/api/rest/requests/components/createComponentFromUrl";
import {getIsHumanIdAvailable} from "data/api/rest/requests/components/getIsHumanIdAvailable";

export const createOptionalUrlComponentParams = () =>
    observable({
        description: "",
        name: "",
        goal: "",
        image_url: "",
        categories: [] as string[],
        input_language: "en",
        output_language: "en"
    });

export const createObligatoryUrlComponentParams = () =>
    observable({
        engine_url: "",
        human_id: ""
    });

export const createCreateFromUrl = (
    selectedHandle: IObservableValue<string>,
    container: ContainerStore,
    initialize: (id: string) => void
) => {
    const fromUrl = observable({
        humanIdError: observable.box(""),
        isOpen: observable.box(false),
        obligatory: createObligatoryUrlComponentParams(),
        optional: createOptionalUrlComponentParams(),
        get isCanSave() {
            return !this.humanIdError.get() && !!this.obligatory.human_id && !!this.obligatory.engine_url;
        },
        create: action(async () => {
            const isOk = await createComponentFromUrl({
                ...toJS(fromUrl.obligatory),
                ...toJS(fromUrl.optional)
            });
            container.messages.addMessage(
                isOk ? "Component created" : "Could not create component",
                isOk ? "success" : "error"
            );
            if (!isOk) return;
            fromUrl.obligatory = createObligatoryUrlComponentParams();
            fromUrl.optional = createOptionalUrlComponentParams();
            fromUrl.isOpen.set(false);
            initialize(selectedHandle.get());
        })
    });

    autorun(async () => {
        const id = fromUrl.obligatory.human_id;
        if (!id) {
            fromUrl.humanIdError.set("Insert a unique ID");
            return;
        }
        fromUrl.humanIdError.set(await getIsHumanIdAvailable(id));
    });

    return fromUrl;
};

export type ObligatoryParamsCreateComponentUrl = ReturnType<typeof createObligatoryUrlComponentParams>;
export type OptionalParamsCreateComponentUrl = ReturnType<typeof createOptionalUrlComponentParams>;
export type CreateFromUrlParams = ObligatoryParamsCreateComponentUrl & OptionalParamsCreateComponentUrl;
