import {orange, yellow} from "@material-ui/core/colors";

import {createTheme} from "@material-ui/core/styles";

declare module "@material-ui/core/styles/createTheme" {
    interface Theme {
        appbar: {
            height: number;
            logo: {
                height: number;
            };
        };
        cocoColors: {
            navbar: string;
            searchBar: string;
            blue: string;
            hoverBlue: string;
            error: string;
            warning: string;
            secondary: string;
            hoverSecondary: string;
            special: string;
            hoverSpecial: string;
        };
        selection: {
            draggedShadow: string;
            droppedShadow: string;
        };
        cocoPalette: {
            background: {
                primary: string;
                secondary: string;
                action: string;
            };
            selection: {
                primary: string;
                secondary: string;
                action: string;
            };
            text: {
                primary: string;
                secondary: string;
                action: string;
            };
        };
    }
}

export const makeMarketplaceTheme = (isDark: boolean) =>
    createTheme({
        // @ts-ignore
        transition: "all 0.2s ease",
        transitionSlow: "all 0.4s ease-out",
        typography: {
            fontFamily: "Montserrat"
        },
        zIndex: {
            drawer: 90
        },
        mixins: {
            toolbar: {
                minHeight: 40,
                maxHeight: 40,
                height: 40,
                width: "100%",
                padding: 0,
                "@media (min-width:0px) and (orientation: landscape)": {
                    minHeight: "40px"
                },
                "@media (min-width:600px)": {
                    minHeight: "40px"
                }
            }
        },
        palette: {
            primary: {
                main: "#2195F3",
                dark: "#2195F3",
                light: "#ffffff"
            },
            secondary: {
                main: "#424242",
                dark: "#424242",
                light: "#fafafa"
            },
            background: {
                default: "#1E1E1E",
                paper: "#1E1E1E"
                // dark: "#1E1E1E"
            },
            type: isDark ? "dark" : "light"
        },
        cocoColors: {
            searchBar: "rgba(0,0,0,0.3)",
            navbar: "#2195F3",
            blue: "#2195F3",
            hoverBlue: "#42a5f5",
            secondary: "#062B49",
            hoverSecondary: "#37556d",
            warning: yellow[400],
            error: orange[400],
            special: "#8146FF",
            hoverSpecial: "#9a6bff"
        },
        cocoPalette: {
            background: {
                primary: "#fcfdff",
                secondary: "#F5F9FF",
                action: "#000000"
            },
            selection: {
                primary: "#2195F3",
                secondary: "#062B49",
                action: "#FAD851"
            },
            text: {
                primary: "#FFFFFF",
                secondary: "#000000",
                action: "#000000"
            }
        },
        appbar: {
            height: 48,
            logo: {
                height: 22
            }
        },
        selection: {
            draggedShadow: "4px 4px 4px 4px rgba(0,0,0,0.25)",
            droppedShadow: "0px 0px 0px 0px rgba(0,0,0,0.25)"
        },
        overrides: {
            MuiCssBaseline: {
                "@global": {
                    "*::-webkit-scrollbar": {
                        width: "0.4em",
                        height: "0.4em"
                    },
                    "*::-webkit-scrollbar-track": {
                        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.25)"
                    },
                    "*::-webkit-scrollbar-thumb": {
                        backgroundColor: "rgba(255,255,255,0.5)",
                        borderRadius: "8px"
                    }
                }
            }
        }
    });
