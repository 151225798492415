import {analytics} from "analytics/analytics";
import {AnalyticType} from "analytics/AnalyticType";
import {getUserState} from "data/api/rest/requests/getUserState";
import {setUserState} from "data/api/rest/requests/setUserState";
import {GUEST_DATA_KEY, LEARNING_CENTER_KEY} from "dangerous/staticStrings";
import {AuthStore} from "marketplace-2/store/auth/createAuthStore";
import {action, autorun, IReactionDisposer, observable, reaction} from "mobx";
import {GraphStore} from "../graph/createGraphStore";
import {ProjectsStore} from "../projects/createProjects";
import {completionFunctions} from "./completionFunctions";
import {defaultCompletionFunc} from "./defaultCompletionFunction";
import {Tutorial} from "./tutorials/types";
import {tutorialsData} from "./tutorialsData";

let timeoutToken: ReturnType<typeof setTimeout>;
const CELEBRATION_DELAY = 5000;

const projToAnalyticsMap = {
    navigation: AnalyticType.tut_clicked_navigation,
    unhappy_path: AnalyticType.tut_clicked_unhappy_path,
    parameters: AnalyticType.tut_clicked_parameters,
    multipleProjects: AnalyticType.tut_clicked_multi_project
} as {[key: string]: AnalyticType};

const initialize = action(async (store: LearningCenterStore) => {
    const guestData = localStorage.getItem(GUEST_DATA_KEY);

    let data = (await getUserState(LEARNING_CENTER_KEY)) as {
        tuts: Tutorial[];
        isCenterOpen: boolean;
        currentTutorial: string;
        isTutorialHidden: boolean;
        isIntroFinished: boolean;
    };

    if (!data || !data.tuts) {
        data = {
            tuts: [],
            isCenterOpen: false,
            currentTutorial: "",
            isTutorialHidden: false,
            isIntroFinished: false
        };
    }

    store.currentTutorial = data.currentTutorial;
    store.isCenterOpen = false;
    store.isTutorialHidden = data.isTutorialHidden;
    store.isIntroFinished = guestData ? false : data.isIntroFinished;

    const tutKeys = ["id", "isCompleted", "lastStage", "completions"];

    data.tuts.forEach((t) => {
        const tt = store.tutorials.find((st) => st.id === t.id);
        if (!tt) return;
        tutKeys.forEach((k) => {
            // @ts-ignore
            tt[k] = t[k];
        });
    });
});

export const createLearningCenterStore = (graph: GraphStore, projects: ProjectsStore, auth: AuthStore) => {
    let currentCompletionFunction = undefined as IReactionDisposer | undefined;

    const store = observable({
        isIntroFinished: true,
        isCenterOpen: false,
        currentTutorial: "",
        isTutorialHidden: false,
        tutorials: Object.keys(tutorialsData).map((key) => ({
            id: key,
            isCompleted: false,
            lastStage: 0,
            completions: []
        })) as Tutorial[]
    });

    autorun(() => {
        auth.isReady && initialize(store);
    });

    reaction(
        () => {
            return store.isCenterOpen;
        },
        (isOpen) => {
            isOpen && (store.isIntroFinished = true);
        }
    );

    reaction(
        () => {
            return {
                finishedStages: store.tutorials.flatMap((t) => t.completions).filter((c) => c.isCompleted).length,
                finishedTuts: store.tutorials.filter((t) => t.isCompleted).length,
                isCenterOpen: store.isCenterOpen,
                currentTutorial: store.currentTutorial,
                isTutorialHidden: store.isTutorialHidden,
                isIntroFinished: store.isIntroFinished
            };
        },
        (data) => {
            if (projects.isGuest || !store.isCenterOpen) return;
            setUserState(LEARNING_CENTER_KEY, {
                tuts: store.tutorials,
                currentTutorial: store.currentTutorial,
                isTutorialHidden: store.isTutorialHidden,
                isIntroFinished: store.isIntroFinished
            });
        }
    );

    reaction(
        () => {
            const ct = store.currentTutorial;
            const tut = store.tutorials.find((t) => t.id === store.currentTutorial);
            return {ct, tut};
        },
        ({ct, tut}) => {
            currentCompletionFunction && currentCompletionFunction();
            if (!ct || !tut) {
                currentCompletionFunction = undefined;
                return;
            }
            const func = completionFunctions[ct] || defaultCompletionFunc;
            currentCompletionFunction = func(graph, tut);
        }
    );

    reaction(
        () => {
            const t = store.tutorials.find((t) => t.id === store.currentTutorial);
            return [...(t?.completions || [])][t?.lastStage || -1];
        },
        (completion) => {
            clearTimeout(timeoutToken);
            if (!completion) return;
            if (completion.isCompleted && !completion.isCelebrated) {
                timeoutToken = setTimeout(() => {
                    completion.isCelebrated = true;
                }, CELEBRATION_DELAY);
            }
        }
    );

    reaction(
        () => {
            return store.currentTutorial;
        },
        (currentTutorial) => {
            if (!currentTutorial) return;
            const id = projToAnalyticsMap[currentTutorial];
            if (!id) return;
            analytics({type: id});
        }
    );

    reaction(
        () => {
            return store.isCenterOpen;
        },
        (isCenterOpen) => {
            if (!isCenterOpen) return;
            analytics({type: AnalyticType.learning_center_opened});
        }
    );

    return store;
};

export type LearningCenterStore = ReturnType<typeof createLearningCenterStore>;
