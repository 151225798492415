import "./index.css";

import 'webrtc-adapter';
import * as React from "react";
import * as ReactDOM from "react-dom";

import { ApolloWrapper } from "data/api/gql/client";

window.process = window.process || {};
window.process.env = { //Define process.env globally for @a-i/agent
  ...window.process?.env,
  BV_URL: import.meta.env.FRONTEND_BV_URL,
  INTENTS_URL: import.meta.env.FRONTEND_INTENTS_URL,
}; 

/** this is for solving the 100vh issue on iIOS. Second part of solution is in index.css */
const appHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty("--app-height", `${window.innerHeight}px`);
};
window.addEventListener("resize", appHeight);
appHeight();
/** end of 100vh iOS solution */

ReactDOM.render(
  <React.StrictMode>
    <ApolloWrapper />
  </React.StrictMode>,
  document.getElementById("root")
);
