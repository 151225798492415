import { useCallback, useEffect, useState } from "react";

import { getQueryData } from "./getQueryData";
import { removeQueryParam } from "./removeQueryParam";
import { tob64 } from "./base64";
import { upsertQueryParam } from "./upsertQueryParam";
import { useParams } from "react-router";

export const useQueryData = <T extends {}>(id: string) => {
  const p = useParams();
  const [data, setDataFromQuery] = useState(getQueryData<T>(id));

  const setData = useCallback((d?: T) => {
    if (d) {
      upsertQueryParam(id, tob64(JSON.stringify(d)));
    } else {
      removeQueryParam(id);
    }

    setDataFromQuery(d || ({} as T));
  }, []);

  useEffect(() => {
    const d = getQueryData<T>(id);
    
    (!d || JSON.stringify(d) !== JSON.stringify(data)) && setDataFromQuery(d); //TODO: Check and refactor
  }, [p]);

  return [data, setData] as const;
};
