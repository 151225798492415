import {Accordion, AccordionDetails, AccordionSummary, Button, Grid, List} from "@material-ui/core";

import Loader from "utils/elments/loader/Loader";
import {Message} from "./types";
import {MessageItem} from "./MessageItem";
import {getWholeConversation} from "./getWholeConversation";
import {useState} from "react";

const PAGE_SIZE = 10;

export const Conversation: React.FC<{
    conversationKey: string;
    conversationMessages: Array<Message>;
    showMetadata: boolean;
}> = ({conversationKey, conversationMessages, showMetadata}) => {
    const [convKey, setConvKey] = useState<string>(conversationKey);
    const [messages, setMessages] = useState<Array<Message>>(conversationMessages);
    const [isFetchingMessages, setIsFetchingMessages] = useState<boolean>(false);

    const handleLoadConversation = async (conversationId: string) => {
        setIsFetchingMessages(true);
        var offset = 0;
        var total = 0;

        var [allMessages, totalMessages] = await getWholeConversation(conversationId, offset, PAGE_SIZE);
        if (!totalMessages) {
            setIsFetchingMessages(false);
            return;
        }

        offset = offset + PAGE_SIZE;
        total = totalMessages;

        while (offset < total) {
            var [pageMessages, totalMessages] = await getWholeConversation(conversationId, offset, PAGE_SIZE);

            offset = offset + PAGE_SIZE;
            allMessages = allMessages.concat(pageMessages);
        }

        setMessages(allMessages.reverse());
        setIsFetchingMessages(false);
    };

    const produceConversationReadableKey = (messages: Array<Message>) => {
        const firstMessage = messages[0];
        const lastMessage = messages[messages.length - 1];

        return `${firstMessage.sender.channel_name} - ${firstMessage.recipient.channel_name}. From: ${firstMessage.created_at} To: ${lastMessage.created_at}`;
    };

    return (
        <Accordion>
            <AccordionSummary>{produceConversationReadableKey(messages)}</AccordionSummary>
            <AccordionDetails>
                <Grid container>
                    <Grid item xs={12}>
                        <Button
                            color={"primary"}
                            fullWidth
                            onClick={async () => {
                                await handleLoadConversation(convKey);
                            }}>
                            Load The Whole Conversation
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <List>
                            {messages.map((message) => {
                                return <MessageItem {...{message, showMetadata}} />;
                            })}
                        </List>
                    </Grid>
                </Grid>
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        width: "100%",
                        height: "100vh",
                        overflow: "hidden",
                        pointerEvents: "none"
                    }}>
                    {isFetchingMessages ? <Loader title={"Fetching Data..."} /> : null}
                </div>
            </AccordionDetails>
        </Accordion>
    );
};
