import { MenuItem, Select, Typography, makeStyles } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";

import { countryCodes } from "./countryCodes";
import { localStorageKeys } from "dangerous/staticStrings";

export const getFlagEmoji = (countryCode: string) => {
  // @ts-ignore
  return String.fromCodePoint(
    ...[...countryCode.toUpperCase()].map((x) => 0x1f1a5 + x.charCodeAt(0))
  );
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: theme.spacing(6),
    },
    flagContainer: {
      width: theme.spacing(3),
      fontSize: theme.spacing(3),
    },
    menuItem: {
      paddingLeft: theme.spacing(1),
      position: "relative",
      display: "flex",
      justifyContent: "center",
    },
    adornment: {
      color: theme.custom.palette.e.alt,
    },
  };
});

const CountryList: React.FC<{
  countryId: string;
  setCountryId: (d: string) => void;
  filter?: string[];
  disabled?: boolean;
}> = ({ countryId, setCountryId, filter, disabled }) => {
  const classes = useStyles();

  const [countries, setCountries] = useState(
    countryCodes.filter((c) => !filter || filter.includes(c.code))
  );
  const [currentValue, setCurrentValue] = useState(countryId);

  useEffect(() => {
    setCountries(
      countryCodes.filter((c) => {
        return !filter || filter.includes(c.code);
      })
    );
  }, [filter]);

  const handleChange = useCallback((event: any) => {
    const cc = event.target.value as string;

    setCountryId(cc);
    sessionStorage.setItem(localStorageKeys["phone-channel-last-country"], cc);
    setCurrentValue(cc);
  }, []);

  const isMac =
    window.navigator.userAgent.includes("Macintosh") ||
    window.navigator.userAgent.includes("Linux") ||
    window.navigator.userAgent.includes("like Mac");

  return (
    <div className={classes.container}>
      <Select
        disabled={disabled}
        disableUnderline
        onChange={handleChange}
        value={currentValue}
        renderValue={(c: unknown) => {
          const cCode = c as string;
          const country = countries.find((c) => c.code === cCode);
          if (!country) return null;
          return isMac ? (
            <div className={classes.flagContainer}>
              {getFlagEmoji(country.code)}
            </div>
          ) : (
            <div className={classes.flagContainer}>
              <img
                width="24px"
                src={`https://flagcdn.com/${country.code.toLowerCase()}.svg`}
              />
            </div>
          );
        }}
      >
        {countries.map((option, i) => (
          <MenuItem key={option.code} value={option.code}>
            <div className={classes.menuItem}>
              <span style={{ width: "0px", height: "0px", overflow: "hidden" }}>
                {option.name}
              </span>
              {isMac ? (
                <div className={classes.flagContainer}>
                  {getFlagEmoji(option.code)}
                </div>
              ) : (
                <div className={classes.flagContainer}>
                  <img
                    width="24px"
                    src={`https://flagcdn.com/${option.code.toLowerCase()}.svg`}
                  />
                </div>
              )}
            </div>
            <Typography>
              &nbsp;&nbsp;{option.name}&nbsp;({option.dial_code})
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default CountryList;
