import {action} from "mobx";
import {authUserInOnboarding} from "dangerous/staticStrings";
import {isUrlOnboarding} from "utils/url_navigation/isUrlOnboarding";
import {useRootStore} from "marketplace-2/store/useRootStore";

export const useSignUpOrLogin = () => {
    const {logIn, signUp} = useRootStore((store) => {
        return {
            logIn: action(() => {
                store.auth.authActionType = "Log In";
                store.auth.loginWithRedirect({
                    appState: {returnTo: window.location.pathname + window.location.search}
                });
            }),
            signUp: action(() => {
                if (isUrlOnboarding(window.location.pathname)) localStorage.setItem(authUserInOnboarding, "true");

                store.auth.authActionType = "Sign Up";
                store.auth.loginWithRedirect({
                    appState: {
                        returnTo: window.location.pathname + window.location.search
                    },
                    authorizationParams: {
                        screen_hint: "signup",
                    }
                });
            })
        };
    });

    return {logIn, signUp} as const;
};
