import { useState, useRef, useEffect } from "react";
import { usePcConfig } from "./usePcConfig";
import { useMediaStream } from "./useMediaStream";
import { useRemoteStream } from "./useRemoteStream";

const RICH_VIDEO_BITRATE_THRESHOLD = 80;

export const usePeerConnection = () => {
  const { getConfig } = usePcConfig();
  const { getMediaStream, destroyMediaStream, isReady } = useMediaStream();
  const { remoteStream, getRemoteStream, destroyRemoteStream } = useRemoteStream();

    const [iceCandidate, setIceCandidate] = useState<RTCIceCandidate | null>(null);
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    const syncConnection = useRef<RTCPeerConnection | null>(null);
    const syncError = useRef<Error | null>(null);

    const checkBitrateInterval = useRef<ReturnType<typeof setInterval> | null>(null);

    useEffect(() => {
      if (error) {
        console.log('PEER ERROR: ', error.message);
      }
    }, [error]);
    
    const connect = () => {
      setIsLoading(true);

      const createConnection = async () => {
        const {
          config,
          error: configError,
        } = await getConfig();

        const { mediaStream, error: streamError } = await getMediaStream();

        if (configError || !config || !mediaStream || streamError) {
          throw new Error(configError?.message || streamError?.message || 'Unknown error');
        }

        const remoteStream = getRemoteStream();

        const pc = new RTCPeerConnection(config);
        
        pc.addTransceiver("audio");
        pc.addTransceiver("video");

        pc.ontrack = (event) => {
          console.log('PEER: Remote track');
          
          remoteStream.addTrack(event.track);

          if (event.track.kind === 'video') {
            let previousBytes = 0;
            let previousTimeStamp = 0;
            
            checkBitrateInterval.current = setInterval(() => {
              pc.getStats(event.track).then((stats) => {
                stats.forEach(report => {
                  if (report.type === "inbound-rtp" && checkBitrateInterval.current) {
          
                      const currentBytes = report.bytesReceived;
                      const currentTimeStamp = report.timestamp;
          
                      if (previousBytes) {
                        const deltaBytes = currentBytes - previousBytes;
                        const deltaTime = currentTimeStamp - previousTimeStamp;
                        const bitrate = deltaBytes / deltaTime;

                        if (bitrate > RICH_VIDEO_BITRATE_THRESHOLD) {
                          clearInterval(checkBitrateInterval.current);
                          checkBitrateInterval.current = null;

                          console.log('enable', mediaStream.getTracks());
                          mediaStream.getTracks().forEach((track) => {
                            track.enabled = true;
                          });

                          setIsLoading(false);
                        }
                      }
                      
                      previousBytes = currentBytes;
                      previousTimeStamp = currentTimeStamp;
          
                  }
                });
              })
            }, 200)
          }
          
        };

        pc.onicecandidate = (event) => {
            setIceCandidate(event.candidate);
        }

        console.log('disable', mediaStream.getTracks());
        mediaStream.getTracks().forEach((track) => {
          track.enabled = false;
          
          pc.addTrack(track, mediaStream);
        });

        return pc;
      }

      const task = createConnection();

      task
      .then((pc) => {
          syncConnection.current = pc;
      })
      .catch((err) => {
          const preparedErr = err instanceof Error ? err : new Error('Unknown error');
          setError(preparedErr);
          syncError.current = preparedErr;
      });

      return task;
  }

    const connectionPromise = useRef<Promise<RTCPeerConnection> | null>(null);

    const getConnection = async (): Promise<{
      connection: RTCPeerConnection | null;
      error: Error | null;
    }> => {
        if (syncConnection.current && !['closed', 'failed'].includes(syncConnection.current.iceConnectionState)) {
          return {
            connection: syncConnection.current,
            error: syncError.current,
          };
        }

        if (connectionPromise.current) {
          await connectionPromise.current;
        } else {
          connectionPromise.current = connect();
          await connectionPromise.current;
          connectionPromise.current = null;
        }

        return {
          connection: syncConnection.current,
          error: syncError.current,
        };
    }

    const disconnect = () => {
      if (syncConnection.current) {
        syncConnection.current.close();

        syncConnection.current = null;

        destroyMediaStream();
        destroyRemoteStream();

        if (checkBitrateInterval.current) {
          clearInterval(checkBitrateInterval.current);
          checkBitrateInterval.current = null;
        }

        setIsLoading(false);
      }
    }
    
    return {
        getConnection,
        isLoading,
        error,
        disconnect,
        remoteStream,
        iceCandidate,
        isReady,
    }
};
