import { InfoBadge } from "theme/components/elements/InfoBadge";
import React from "react";
import { TextButton } from "theme/components/elements/buttons/TextButton";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const disabledOpacity = 0.5;

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      alignItems: "center",
      paddingTop: theme.spacing(1),
    },
  };
});

export const UpgradeToEditEmailCTA: React.FC<{
  isEditable?: boolean;
}> = ({ isEditable }) => {
  const classes = useStyles();
  const history = useHistory();

  if (isEditable) return null;

  return (
    <div className={classes.container}>
      <InfoBadge variant="alt" text="PRO" />
      <TextButton
        onClick={() => {
          history.push("/pricing");
        }}
        variant="primary-thin"
      >
        Want to customize your virtual human's email?
      </TextButton>
    </div>
  );
};
