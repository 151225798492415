import React, {useState} from "react";
import {Tooltip, makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => {
    return {
        container: {
            padding: theme.spacing(2, 4),
            borderRadius: theme.spacing(3),
            shadow: theme.shadows[5],
            background: theme.custom.palette.a.main
        }
    };
});

const useTTStyles = (placement: Placement) =>
    makeStyles((theme) => {
        if (placement === Placement.bottom) {
            return {
                tooltip: {
                    borderRadius: theme.spacing(3),
                    padding: "0px",
                    maxWidth: "100vw"
                },
                arrow: {
                    marginTop: "-1.5em !important",
                    marginLeft: "1.41em !important",
                    width: "3em !important",
                    height: "1.5em !important",
                    color: theme.custom.palette.a.main
                }
            };
        } else {
            return {
                tooltip: {
                    borderRadius: theme.spacing(3),
                    padding: "0px",
                    maxWidth: "600px"
                },
                arrow: {
                    marginBottom: "-1.5em !important",
                    marginRight: (props: {arrowMargin: number}) => {
                        return props.arrowMargin.toString() + "em !important";
                    },
                    width: "2.5em !important",
                    height: "1.5em !important",
                    color: theme.custom.palette.a.main
                }
            };
        }
    });

export enum Placement {
    bottom = "bottom",
    top = "top",
    left = "left"
}

export type ActionTooltipParams = {
    title: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
    placement?: Placement;
    arrowMargin?: number;
    isAlwaysOn?: boolean;
};

const ActionTooltip: React.FC<ActionTooltipParams> = ({
    children,
    title,
    placement = "bottom" as Placement,
    arrowMargin = 0,
    isAlwaysOn = false
}) => {
    const classes = useStyles();
    const ttClasses = useTTStyles(placement)({arrowMargin});

    const [isOpen, setIsOpen] = useState(isAlwaysOn);

    return (
        <Tooltip
            open={isOpen}
            onPointerEnter={isAlwaysOn ? undefined : () => setIsOpen(true)}
            onMouseEnter={isAlwaysOn ? undefined : () => setIsOpen(true)}
            onClose={isAlwaysOn ? undefined : () => setIsOpen(false)}
            arrow
            leaveDelay={0}
            classes={ttClasses}
            interactive
            placement={placement as Placement}
            title={<div className={classes.container}>{title}</div>}>
            <span style={{height: "fit-content"}}>{children}</span>
        </Tooltip>
    );
};

export default ActionTooltip;
