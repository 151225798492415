import {BotsStore, BotsStorePrivate} from "../createBotsStore";

import {createBot} from "data/api/rest/requests/bots/v2/createBot";
import {updateBot} from "data/api/rest/requests/bots/v2/updateBot";

/** On edit finish, update bot if needed */
export const updateBotOnFinish = async (store: BotsStore, _private: BotsStorePrivate, isAuthenticated: boolean) => {
    if (_private.editor.current.extras.isFirstEdit) {
        _private.editor.current.extras.isFirstEdit = false;

        if (_private.editor.current.gid && store.bots[_private.editor.current.gid]) {
            await updateBot(_private.editor.current);
        } else {
            const result = await createBot(_private.editor.current);
            if (!!result) {
                _private.editor.current.gid = result.bot_id;
                isAuthenticated && (store.bots[_private.editor.current.gid] = {..._private.editor.current});
            }
        }

        _private.editor.prevState = JSON.stringify(_private.editor.current);
    }
    if (!_private.editor.prevState) return;

    if (_private.editor.prevState !== JSON.stringify(store.editor.current)) {
        store.update();
    } else if (!_private.editor.current.extras.isFirstEdit) {
        const result = await updateBot(_private.editor.current);
    }
};
