import { theme, useResponsive } from "@a-i/ui";
import { Box } from "ui-kit/components";
import { BotImage } from "./assets/BotImage";
import { VideoOffIcon } from "./assets/VideoOffIcon";
import { NoConnectionCreatorProblem } from "./NoConnectionCreatorProblem";
import { NoConnectionNoMic } from "./NoConnectionNoMic";
import {
  ChatBubble,
  NoConnectionFrame,
  NoConnectionWrapper,
  VideoOffWrapper,
} from "./share.styled";

const NoConnection = ({ error }: { error?: string }) => {
  const isNoPermissions = error === "invalid permissions";

  return (
    <NoConnectionWrapper>
      <Box
        flexDirection="column"
        height="100%"
        width="100%"
        padding={[theme.spacing(3), theme.spacing(10)]}>
        <NoConnectionFrame>
          <VideoOffWrapper>
            <VideoOffIcon />
          </VideoOffWrapper>
          <BotImage />
          <ChatBubble>
            <h4>
              {isNoPermissions
                ? "Did someone drop the mic?"
                : "Botston we have a problem"}
            </h4>
          </ChatBubble>
          {isNoPermissions ? (
            <NoConnectionNoMic />
          ) : (
            <NoConnectionCreatorProblem />
          )}
        </NoConnectionFrame>
      </Box>
    </NoConnectionWrapper>
  );
};

export default NoConnection;
