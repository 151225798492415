import { CHR_Female_01 } from "./presets/CHR_Female_01";
import { CHR_Female_01_NEW } from "./newPresets/CHR_Female_01";
import { CHR_Female_02 } from "./presets/CHR_Female_02";
import { CHR_Female_02_NEW } from "./newPresets/CHR_Female_02";
import { CHR_Female_03 } from "./presets/CHR_Female_03";
import { CHR_Female_03_NEW } from "./newPresets/CHR_Female_03";
import { CHR_Male_01 } from "./presets/CHR_Male_01";
import { CHR_Male_01_NEW } from "./newPresets/CHR_Male_01";
import { CHR_Male_02 } from "./presets/CHR_Male_02";
import { CHR_Male_02_NEW } from "./newPresets/CHR_Male_02";
import { CHR_Male_03 } from "./presets/CHR_Male_03";
import { CHR_Male_03_NEW } from "./newPresets/CHR_Male_03";
import { tob64 } from "utils/query-string/base64";

const naomiB64 = tob64(JSON.stringify(CHR_Female_01));
const roseB64 = tob64(JSON.stringify(CHR_Female_02));
const saraB64 = tob64(JSON.stringify(CHR_Female_03));
const dylanB64 = tob64(JSON.stringify(CHR_Male_01));
const milesB64 = tob64(JSON.stringify(CHR_Male_02));
const marcusB64 = tob64(JSON.stringify(CHR_Male_03));

const simoneB64 = tob64(JSON.stringify(CHR_Female_01_NEW));
const allisonB64 = tob64(JSON.stringify(CHR_Female_02_NEW));
const angelinaB64 = tob64(JSON.stringify(CHR_Female_03_NEW));
const fernandoB64 = tob64(JSON.stringify(CHR_Male_01_NEW));
const deandreB64 = tob64(JSON.stringify(CHR_Male_02_NEW));
const colinB64 = tob64(JSON.stringify(CHR_Male_03_NEW));

export const presetsMap = {
  Naomi: naomiB64,
  Rose: roseB64,
  Sara: saraB64,
  Dylan: dylanB64,
  Miles: milesB64,
  Marcus: marcusB64,
  Simone: simoneB64,
  Allison: allisonB64,
  Angelina: angelinaB64,
  Fernando: fernandoB64,
  DeAndre: deandreB64,
  Colin: colinB64,
} as { [key: string]: string };

export const encodedPresetsToID = new Map([
  [naomiB64, "Naomi"],
  [roseB64, "Rose"],
  [saraB64, "Sara"],
  [dylanB64, "Dylan"],
  [milesB64, "Miles"],
  [simoneB64, "Simone"],
  [allisonB64, "Allison"],
  [angelinaB64, "Angelina"],
  [fernandoB64, "Fernando"],
  [deandreB64, "DeAndre"],
  [colinB64, "Colin"],
]);
