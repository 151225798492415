import { ChannelType } from "./types";
import audiocodes from "./icons/audiocodes.svg";
import embedded from "./icons/embedded.svg";
import { imageContainer } from "./ImageLogo";
import messenger from "./icons/messenger.svg";
import phone from "./icons/phone.svg";
import telegram from "./icons/telegram.svg";
import twilio from "./icons/twilio.svg";
import video from "./icons/video.svg";
import whatsapp from "./icons/whatsapp.svg";
import wordpress from "./icons/wordpress.svg";
import dashboardChat from "./icons/dashboardChat.svg";

export const TelegramIcon = imageContainer(telegram);
export const WhatsappIcon = imageContainer(whatsapp);

export const WordpressIcon = imageContainer(wordpress);
export const VideoChannelIcon = imageContainer(video);
export const VideoChannelTestIcon = imageContainer(video);

export const PhoneIcon = imageContainer(phone);
export const MessengerIcon = imageContainer(messenger);
export const TwilioIcon = imageContainer(twilio);
export const EmbedIcon = imageContainer(embedded);
export const AudioCodesIcon = imageContainer(audiocodes);
export const DashboardChatIcon = imageContainer(dashboardChat);

export const channelToIcon = (channel: ChannelType) => {
  let a = TelegramIcon;

  switch (channel) {
    case ChannelType.telegram:
      a = TelegramIcon;
      break;
    case ChannelType.wordpress:
      a = WordpressIcon;
      break;
    case ChannelType.whatsapp:
      a = WhatsappIcon;
      break;
    case ChannelType.videoChannel:
      a = VideoChannelIcon;
      break;
    case ChannelType.telephone:
      a = PhoneIcon;
      break;
    case ChannelType.facebook:
      a = MessengerIcon;
      break;
    case ChannelType.embedded:
      a = EmbedIcon;
      break;
    case ChannelType.audiocodes:
      a = AudioCodesIcon;
      break;
    case ChannelType.twilio:
      a = TwilioIcon;
      break;
      case ChannelType.dashboard:
      a = DashboardChatIcon;
      break;
    default:
      break;
  }

  return a;
};
