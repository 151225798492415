import {
  FullscreenButton,
  StandaloneVideoLayoutContainer,
  StandaloneVideoScrollContainer,
  VideoWrapper,
  VideoWrapperLayout,
  WebRtcVideo,
  WebRtcWrapper,
} from "ui-kit/components/share/share.styled";
import React, { useState } from "react";

import { AnalyticType } from "analytics/AnalyticType";
import { BuilderFabIcon } from "ui-kit/components/share/assets/BuilderFabIcon";
import Loader from "utils/elments/loader/Loader";
import NoConnection from "ui-kit/components/share/NoConnection";
import { StandaloneDisplayBanner } from "./StandaloneDisplayBanner";
import { StandaloneDisplayHeader } from "./StandaloneDisplayHeader";
import { analytics } from "analytics/analytics";

export const StandaloneDisplay = React.forwardRef<
  HTMLVideoElement,
  { name: string; error?: string; loading?: boolean }
>(({ children, name, error, loading }, ref) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  if (!!error)
    return (
      <WebRtcWrapper>
        <NoConnection error={error} />
      </WebRtcWrapper>
    );

  return (
    <WebRtcWrapper id="WebRtcWrapper">
      <StandaloneVideoScrollContainer id="StandaloneVideoScrollContainer">
        <StandaloneVideoLayoutContainer id="StandaloneVideoLayoutContainer">
          <StandaloneDisplayHeader name={name} />
          <VideoWrapper isFullScreen={isFullScreen} id="VideoWrapper">
            <VideoWrapperLayout>
              {loading && <Loader isLarge />}
              <WebRtcVideo playsInline autoPlay loop ref={ref} />
              <FullscreenButton
                onClick={() => {
                  analytics({
                    type: AnalyticType.video_fullscreen,
                    data: { isOn: isFullScreen ? "off" : "on" },
                  });
                  setIsFullScreen((f) => !f);
                }}
              >
                <BuilderFabIcon />
              </FullscreenButton>
            </VideoWrapperLayout>
          </VideoWrapper>
          {isFullScreen ? null : <StandaloneDisplayBanner />}
        </StandaloneVideoLayoutContainer>
      </StandaloneVideoScrollContainer>
    </WebRtcWrapper>
  );
});