import {useRootStore} from "marketplace-2/store/useRootStore";
import React from "react";

export const useDeleteDialog = () => {
    const {isOpen, text, open, close, onConfirm} = useRootStore((store) => {
        return {
            isOpen: store.container.messages.deletion.isOpen,
            open: store.container.messages.deletion.open,
            close: store.container.messages.deletion.close,
            text: store.container.messages.deletion.text,
            onConfirm: store.container.messages.deletion.onConfirm
        };
    });
    return {isOpen, open, close, onConfirm, text};
};
