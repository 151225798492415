import React, {useContext, useState} from "react";

const data = {
    setActions: (actions: JSX.Element[]) => {},
    actions: [] as JSX.Element[]
};

export const EditorContext = React.createContext(data);
export const EditorContextProvider: React.FC = ({children}) => {
    const [actions, setActions] = useState<JSX.Element[]>([]);

    return (
        <EditorContext.Provider
            value={{
                actions,
                setActions
            }}>
            {children}
        </EditorContext.Provider>
    );
};

export const useEditorContext = () => {
    return useContext(EditorContext);
};
