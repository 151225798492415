import {SavedProjectKeys} from "bots-studio/store/projects/initializeAuthenticated";
import {action} from "mobx";
import {fetchWrapper} from "data/api/rest/fetchWrapper";
import {getUserState} from "./getUserState";
import {setUserState} from "./setUserState";
import {userProjectsKeys} from "dangerous/staticStrings";

export const deleteBlueprint = action(async (human_id: string) => {
    try {
        const result = await fetchWrapper(`/v2/blueprint/${human_id}`, {
            method: "DELETE",
            mode: "cors",
            credentials: "include"
        });

        if (result.ok) {
            const dataFromProjKeys = (await getUserState(userProjectsKeys)) as SavedProjectKeys;
            let index = dataFromProjKeys?.projects.findIndex((p) => p.componentId === human_id);
            if (index >= 0) {
                dataFromProjKeys.projects.splice(index, 1);
                await setUserState(userProjectsKeys, dataFromProjKeys);
                console.log("project deletion happened");
            }
        }
        return result.ok;
    } catch (e) {
        console.error(e);
        return false;
    }
});
