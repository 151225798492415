import {gql} from "@apollo/client";

export const GET_BOT_DASHBOARD = gql`
    query getBotDashboard($id: Int = 0) {
        bot(id: $id) {
            avatar
            extras
            firstName
            id
            nodeId
            languageCode
            entrypoint {
                id
                humanId
                name
            }
            createdAt
            channels {
                edges {
                    node {
                        id
                        createdAt
                        jsonConfig
                        nodeId
                    }
                }
            }
            integrations {
                edges {
                    node {
                        id
                        type
                        nodeId
                    }
                }
            }
            phoneSeconds
            sessionsCount
            zoomSeconds
            botsSubscription {
                planName
            }
            botUsages(last: 1) {
                edges {
                    node {
                        hour
                    }
                }
            }
        }
    }
`;
