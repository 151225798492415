import React, { useEffect, useState } from "react";

import { ActiveIndicator } from "utils/elments/ActiveIndicator";
import { AnalyticType } from "analytics/AnalyticType";
import { CHANNEL_NAME } from "dangerous/staticStrings";
import { ChannelDialogParams } from "channels/channelsMap";
import { ChannelType } from "channels/types";
import { MainButton } from "theme/components/elements/buttons/MainButton";
import { TextButton } from "theme/components/elements/buttons/TextButton";
import { TwilioConnectedBody } from "./TwilioConnectedBody";
import { TwilioDisconnectedBody } from "./TwilioDisconnectedBody";
import TwilioIcon from "./assets/icon_twilio.svg";
import { TwilioInfographics } from "./TwilioInfographics";
import copy from "clipboard-copy";
import { makeStyles } from "@material-ui/core";
import { useChannelFunctions } from "data/api/gql/hooks/useChannelFunctions";
import useConfirmationDialog from "./confirmationDialogs/store/useConfirmationDialog";
import { validatePhone } from "utils/validatePhone";
import { ENVIRONMENT } from "const";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      marginTop: theme.spacing(3),
      justifyContent: "space-between",
      height: "100%",
      overflow: "auto",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
    },
  };
});

export const WEBHOOK_PREFIX =
  ENVIRONMENT.BV_ADDR + "/v2/bot/webhook/twilio_async_msg/channel/";

const defaultData = { from_: "", account_sid: "", token: "", countryId: "" };

export type TwilioData = typeof defaultData;

const validate = (d: TwilioData) => {
  return validatePhone(d.from_) && !!d.account_sid && !!d.token;
};

export const TwilioChannelBody: React.FC<ChannelDialogParams> = ({
  botId,
  botName,
  botChannels,
  close,
}) => {
  const classes = useStyles();

  const channel = botChannels.find(
    (e) => e.jsonConfig.channel_name === ChannelType.twilio
  );
  const channelId = `02-${channel?.nodeId}`;
  const isConnected = channel?.id !== undefined;

  const [data, setData] = useState(channel?.jsonConfig || { ...defaultData });
  const [isValid, setIsValid] = useState(validate(data));

  const openConfirmationDialog = useConfirmationDialog({
    icon: TwilioIcon,
    title: "channel created",
    description: "copy the webhook url to start recieving messages",
    buttonText: "copy url",
    notification: {
      message: "webhook URL copied to clipboard",
      type: "success",
    },
    onClick: () => copy(WEBHOOK_PREFIX + channelId),
  });

  const { create, remove, loading } = useChannelFunctions({ botId });

  const connect = () => {
    create({
      variables: {
        channelName: CHANNEL_NAME.TWILIO_ASYNC_MSG,
        botId: parseInt(botId),
        jsonConfig: { channel_name: ChannelType.twilio, ...data },
      },
    }).then(openConfirmationDialog);
  };

  const disconnect = () => {
    setData({ ...defaultData });
    remove({ variables: { id: channel?.id } });
  };

  useEffect(() => {
    setIsValid(validate(data));
  }, [data]);

  return (
    <div className={classes.container}>
      <div>
        <ActiveIndicator
          isActive={isConnected}
          text={"connected"}
        ></ActiveIndicator>
        {isConnected ? (
          <TwilioConnectedBody {...{ channelId, data }} />
        ) : (
          <TwilioDisconnectedBody {...{ data, setData }} />
        )}
      </div>
      <TwilioInfographics isConnected={isConnected} />
      <div className={classes.buttonContainer}>
        {!isConnected ? (
          <MainButton
            analytics_event={AnalyticType["twilio-channel-dialog-connect"]}
            variant={"async"}
            disabled={!isValid}
            isLoading={loading}
            onClick={connect}
          >
            Connect
          </MainButton>
        ) : (
          <TextButton
            disabled={loading}
            variant={"secondary"}
            analytics_event={AnalyticType["twilio-channel-dialog-disconnect"]}
            onClick={disconnect}
          >
            Disconnect
          </TextButton>
        )}
      </div>
    </div>
  );
};
