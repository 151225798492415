import {
  GUEST_DATA_KEY,
  NAVIGATION_SOURCE_QUERY_KEY,
  queryKeys,
} from "dangerous/staticStrings";
import { getCookie, setCookie } from "utils/cache/cookies";

import { AnalyticType } from "./AnalyticType";
import { fetchWrapper } from "data/api/rest/fetchWrapper";
import { getConversionValue } from "./getConversionValue";
import { checkIsTestEnvironment } from "utils/checkIsTestEnvironment";
import { getQueryParam } from "utils/query-string/getQueryParam";
import { humanReadableId } from "utils/humanReadableId";
import mixpanel from "mixpanel-browser";
import queryString from "querystring";
import { uuid } from "utils/uuid";

const savedState = localStorage.getItem(GUEST_DATA_KEY) || "";

export let session_id = humanReadableId(true);

export let user_id = getCookie("user_id") || setCookie("user_id", uuid());

try {
  session_id =
    (savedState && JSON.parse(savedState).session_id) || humanReadableId(true);
} catch (e) {
  console.error(e);
}

//@ts-ignore
window._loq = window._loq || []; // lucky orange que

const parsed = queryString.parse((window.location.search || "").slice(1));
const navSource = parsed[NAVIGATION_SOURCE_QUERY_KEY];
// @ts-ignore
navSource && window._loq?.push(["nav_source", navSource]);

// @ts-ignore
const gtag = window.gtag;

const onboardingTestType = getQueryParam(queryKeys["onboarding-test-type"]);
const isNoSkipping = getQueryParam(queryKeys["onboarding-is-skipping"]);

let last = 0;

const sendAnalytic = async ({
  type: t,
  data,
}: {
  type: AnalyticType | string;
  data?: any;
}) => {
  const type = t as AnalyticType;

  const isLocal = checkIsTestEnvironment();

  if (isLocal) {
    if (!last) {
      const time = Date.now();
      console.log(`sending first analytic ${t} at ${time}`);
      last = time;
    } else {
      const time = Date.now();
      console.log(`sending analytic ${t} ${time - last}ms after last`);
    }
  }

  if (isLocal) return;

  const conversionData = getConversionValue(type);

  if (!session_id) session_id = humanReadableId(true);
  const user_handle = (globalThis as { [key: string]: any }).user_handle;

  // @ts-ignore
  const windowConf = window.Config || { version: "" };

  const unordered = { onboardingTestType, isNoSkipping };

  const analyticsData = {
    type,
    ...data,
    session_id,
    user_id: user_handle,
    version: windowConf.version || "",
    onboardingTestType,
    isNoSkipping,
    conversionValue: conversionData?.value || 0,
    onboardingFlow: JSON.stringify(
      Object.keys(unordered)
        .sort()
        .reduce((obj, key) => {
          const k = key as keyof typeof unordered;
          obj[k] = unordered[k];
          return obj;
        }, {} as any)
    ),
  };

  const analyticsDataString = JSON.stringify(analyticsData);

  gtag("event", type, analyticsData);

  conversionData &&
    gtag("event", "conversion", {
      send_to: conversionData.send_to,
      value: conversionData.value,
      currency: "ILS",
    });

  try {
    mixpanel.track(type, analyticsData);
  } catch (e) {
    console.info(e);
  }

  try {
    const r = await fetchWrapper(`/v2/analytics/log_message`, {
      method: "POST",
      body: analyticsDataString,
      mode: "cors",
      credentials: "include",
    });
    return r.ok;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const queue = [] as Array<() => Promise<any>>;
const delay = 100;
let t: any = 0;

const sendNext = () => {
  const current = queue.shift();
  current?.();
  t = current ? setTimeout(sendNext, delay) : undefined;
};

export function analytics(props: { type: AnalyticType | string; data?: any }) {
  queue.push(() => sendAnalytic(props));
  !t && sendNext();
}
