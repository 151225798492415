export const DEFAULT_SPACE = 8;

export const SHADOWS = [
  "0 0 #0000",
  "0 1px 2px 0 rgb(0 0 0 / 0.05)",
  "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
  "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
  "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
  "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
  "0 25px 50px -12px rgb(0 0 0 / 0.25)",
];


export const BREAKPOINTS = {
  mobile: 640,
  tablet: 768,
  laptop: 1024,
  laptopL: 1280,
  desktop: 1536,
}