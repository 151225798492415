import {CanvasStore} from "bots-studio/store/canvas/createCanvasStore";
import {GraphStore} from "bots-studio/store/graph/createGraphStore";
import {TutorialStore} from "../createTutorialStore";
import {markComplete} from "../utils/markComplete";
import {namerHumanId} from "../tutorialStagesData";
import {reaction} from "mobx";

export const stage2 = (store: TutorialStore, graph: GraphStore, canvas: CanvasStore) => {
    // stage 2-0 completion reaction
    reaction(
        () => {
            const isCompleted =
                canvas.addNodeMenu.isOpen || Object.values(graph.nodes).some((v) => v.component === namerHumanId);

            return {stage: store.stage, substage: store.substage, isCompleted};
        },
        ({stage, substage, isCompleted}) => {
            if (stage === 2 && substage === 0 && isCompleted) {
                markComplete(store, stage, 0);
            }
        }
    );

    // stage 2-1 completion reaction
    reaction(
        () => {
            const isNamerOnStage = Object.values(graph.nodes).some((v) => v.component === namerHumanId);
            const isPrevComplete = store.completions.get(2)?.stages.get(0)?.isFinished || false;
            return {stage: store.stage, substage: store.substage, isPrevComplete, isNamerOnStage};
        },
        ({stage, substage, isPrevComplete, isNamerOnStage}) => {
            if (stage === 2 && substage === 1 && isPrevComplete && isNamerOnStage) {
                markComplete(store, stage, 1);
            }
        }
    );

    // stage 2-2 completion reaction
    reaction(
        () => {
            const node = Object.values(graph.nodes).find((n) => n.component === namerHumanId);
            const edge = node && Object.values(graph.edges).some((e) => e && e.to.nodeId === node.id);
            const isPrevComplete = store.completions.get(2)?.stages.get(1)?.isFinished || false;

            return {stage: store.stage, isPrevComplete, isAdded: !!edge};
        },
        ({stage, isAdded, isPrevComplete}) => {
            if (stage === 2 && isPrevComplete && isAdded) {
                markComplete(store, stage, 2);
            }
        }
    );

    // stage 2-3 completion reaction
    reaction(
        () => {
            const isPrevComplete = store.completions.get(2)?.stages.get(2)?.isFinished || false;
            return {stage: store.stage, substage: store.substage, isPrevComplete, isChatDone: store.isChatDone};
        },
        ({stage, substage, isPrevComplete, isChatDone}) => {
            if (stage === 2 && substage === 3 && isPrevComplete && isChatDone) {
                markComplete(store, stage, 3);
                const stageRef = store.completions.get(store.stage);
                stageRef && (stageRef.isFinished = true);
            }
        }
    );
};
