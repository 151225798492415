import {fetchWrapper} from "data/api/rest/fetchWrapper";

export const fetchPages = async () => {
    const response = await fetchWrapper("/v2/bot/channel/facebook/fetch_pages", {
        method: "GET",
        mode: "cors",
        cache: "no-cache"
    });

    const pages = await response.json();
    return pages["pages"];
};
