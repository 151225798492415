import {action, observable} from "mobx";

export const createAudioStore = () => {
    const store = observable({
        playingAudioID: "",
        fetchingAudioRequest: false
    });

    return store;
};

export type AudioStore = ReturnType<typeof createAudioStore>;
