import React, { Suspense } from "react"
import Loader from "utils/elments/loader/Loader";
import { Redirect, Route, Switch } from "react-router-dom";
import { Login } from "Login";
import { RedirectToMarketing } from "../pages/redirects/RedirectToMarketing";
import { Signup } from "general-onboarding/Signup";
import { ENVIRONMENT } from "const";

const DfWizard = React.lazy(() => import("wizards/df-wizard/DfWizard"));
const Pricing = React.lazy(() => import("pricing/Pricing"));

export const GuestSwitch: React.FC = () => {
  return (
    <Suspense fallback={<Loader contained title="Loading..." />}>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/builder/wizard/dfToZoom" component={DfWizard} />
        <Route path={`/onboarding`} component={Signup} />
        <Route path="/pricing" component={Pricing} />
        <Route component={ENVIRONMENT.REDIRECT_TO_AUTH ? () => <Redirect to="/login" /> : RedirectToMarketing} />
      </Switch>
    </Suspense>
  )
}