import {DEFAULT_BRAIN, DEFAULT_LANGUAGE} from "dangerous/staticStrings";

import {Bot} from "bots-studio/store/bots/createBotsStore";
import {BotAvatar} from "./updateBot";
import {fetchWrapper} from "data/api/rest/fetchWrapper";
import {presetsMap} from "bots-studio/store/bots/data/nameToPreset";

export async function createBot(bot: Bot) {
    const botsAvatar = {id: bot.avatar.image, voice_id: bot.avatar.voice} as BotAvatar;
    if (!!bot.avatar.presetId) botsAvatar.preset = presetsMap[bot.avatar.presetId];

    try {
        const r = await fetchWrapper(`/v2/bot`, {
            method: "POST",
            body: JSON.stringify({
                first_name: bot.name,
                entrypoint: bot.componentId || DEFAULT_BRAIN,
                language_code: bot.language_code || DEFAULT_LANGUAGE,
                avatar: botsAvatar,
                extras: bot.extras,
                integrations: bot.integrations
            }),
            mode: "cors",
            credentials: "include"
        });
        if (!r.ok) return false;
        const d = (await r.json()) as {bot_id: string; dashboard_channel_id: string; bot_id_v2: string};
        // upsertQueryParam(guestBotKey, tob64(JSON.stringify({...bot, gid: d.bot_id})));
        return d;
    } catch (e) {
        console.error(e);
        return false;
    }
}
