import React from "react";
import {Divider, IconButton, Input, makeStyles, Theme, Tooltip, Typography} from "@material-ui/core";
import {useRootStore} from "../../../../store/useRootStore";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            width: "100%",
            display: "flex",
            flexDirection: "column"
        },
        header: {
            paddingTop: theme.spacing(0.5),
            width: "100%",
            display: "flex",
            justifyContent: "space-between"
        }
    };
});

export const ActionEditor: React.FC<{actionName: string}> = ({actionName}) => {
    const classes = useStyles();

    const {action} = useRootStore((store) => {
        const actions = store.myComponents.editComponent.updatedBlueprint.json_config.action_config;
        const action = actions?.[actionName];
        const length = action?.length || 0;
        return {
            action
        };
    });

    const handleActionBlur = (index: number) => (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (!action) return;
        action[index] = event.currentTarget.value;
        !action[index] && action.splice(index, 1);
    };

    const handleResponseAdd = () => {
        if (!action || action.includes("")) return;
        action.push("");
    };

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <Typography variant="caption">{actionName}</Typography>
                <Tooltip title="Add a response">
                    <IconButton color="primary" size="small" onClick={handleResponseAdd}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            </div>
            {(action || []).map((act, index) => (
                <Input
                    key={act || "Empty"}
                    onBlur={handleActionBlur(index)}
                    autoFocus={act === ""}
                    multiline
                    fullWidth
                    defaultValue={act}
                />
            ))}
            <Divider />
        </div>
    );
};
