import {
  MainHeading,
  StandaloneVideoHeader,
  SubHeading,
  VideoHeaderText,
} from "ui-kit/components/share/share.styled";

import { Divider } from "ui-kit/components";
import { Typography } from "@a-i/ui";
import { useTheme } from "styled-components";
import { BVLogo } from "shared-components/logo/BVLogo";
import { Styles } from "ui-kit/wrappers";

export const StandaloneDisplayHeader = ({ name }: { name: string }) => {
  const theme = useTheme();

  return (
    <>
      <StandaloneVideoHeader id="StandaloneVideoHeader">
        <Styles color="#FCFDFF">
          <BVLogo />
        </Styles>
        <Divider height={theme.spacing(8) as number} />
        <VideoHeaderText flexDirection="column" gap={theme.spacing(0.2)}>
          <MainHeading color={theme.colors.primary.yellow.shade}>
            Bots, with a human touch
          </MainHeading>
          <SubHeading>{`Talk to ${name} on video`}</SubHeading>
        </VideoHeaderText>
      </StandaloneVideoHeader>
      <Typography color={theme.colors.text.light} mb={theme.spacing(0.5)}>
        Say "Wake Up" to wake up {name}
      </Typography>
    </>
  );
};
