export type IFunction = {
  duration?: number,
  easing?: string
}
export type ITransition = {
  props?: Array<string> | string
  options?: IFunction
}

export const transitionDefault: ITransition = {
  props: 'all',
  options: { duration: 0.3, easing: 'linear'}
};

export type ContentPosition = "center" | "flex-end" | "flex-start" | "stretch";

export type ContentDistribution = "space-around" | "space-between" | "space-evenly" | "center" | "flex-end" | "flex-start";
