import { Typography, makeStyles } from "@material-ui/core";

import React from "react";
import computerScreen from "./assets/computer_screen.svg";
import { getAvatar } from "utils/getAvatar";
import { getScene } from "utils/getScene";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
    imageContainer: {
      display: "flex",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
    },
    pcImage: { zIndex: 2 },
    avatar: {
      position: "absolute",
      top: 40,
      left: 150,
      width: theme.spacing(26),
      zIndex: 1,
    },
    background: {
      position: "absolute",
      top: 12,
      left: 60,
      width: theme.spacing(48),
      height: theme.spacing(30),
      zIndex: 0,
      clipPath: "inset(2% 0% 0% 2%)",
    },
    description: { color: "#2195F3", paddingBottom: 16 },
  };
});

export const BotZoomDisplay: React.FC<{
  background: string;
  avatar: string;
}> = ({ background, avatar }) => {
  const classes = useStyles();

  const image = getAvatar(avatar)?.profile || "";
  const scene = getScene(background)?.scene || "";

  return (
    <div className={classes.container}>
      <Typography className={classes.description} variant={"body1"}>
        How will it look
      </Typography>
      <div className={classes.imageContainer}>
        <img className={classes.pcImage} src={computerScreen}></img>
        <img className={classes.avatar} src={image}></img>
        <img className={classes.background} src={scene}></img>
      </div>
    </div>
  );
};
