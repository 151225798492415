import { UserRoles } from "const";
import { gql } from "../readonly_generated";
import {useMutation} from "@apollo/client";
import { getOwnerId } from "data/utils";

const UPDATE_USER_ROLE = gql(`
    mutation updateUserRole($id: Int!, $userType: Int!) {
      updateUser(
        input: {
          patch: {
            userType: $userType
          }, 
          id: $id
        }
      ) {
        clientMutationId
        user {
          id
          userType
        }
      }
    }
`);


export const useUserRoleMutation = () => {
  const [mutate, { loading, error }] = useMutation(UPDATE_USER_ROLE);

  const setUserRole = (role: UserRoles) => {
    mutate({
      variables: {
        id: getOwnerId(),
        userType: role
      }
    })
  }

  return {
    setUserRole,
    loading,
    error,
  }
};
