import {GUEST_DATA_KEY} from "dangerous/staticStrings";
import {ProjectsStore} from "../createProjects";
import makeGuestGlueState from "bots-studio/store/root/makeGuestGlueState";

export const createGuestProject = ({store, isReady}: {store: ProjectsStore; isReady: boolean}) => {
    if (
        !isReady ||
        localStorage.getItem(GUEST_DATA_KEY) ||
        Object.keys(store.projects).length ||
        Object.keys(store.projToComponentMap).length
    )
        return;
    const created = makeGuestGlueState();
    store.projToComponentMap[created.id] = {id: created.componentId || ""};
    store.projects[created.id] = created;
    store.selected = created.id;
};
