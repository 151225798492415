export type PageInfo = {
    endCursor: string | null;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string | null;
};

export type PaginationResult = {
    pageInfo: PageInfo;
};

export const pagination = `pageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
}`;
