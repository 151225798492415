import {emailHumanId, entryId, sayHumanIdv2} from "dangerous/staticStrings";
import stage1 from "./assets/tut-stage-1.gif";
import stage2 from "./assets/tut-stage-2.gif";
import stage3 from "./assets/tut-stage-3.gif";
import stage4 from "./assets/tut-stage-4.gif";
import stage5 from "./assets/tut-stage-5.svg";

export const namerHumanId = "namer_vp3";
export const blenderHumanId = "blender_pv1";
export const signUpId = "sign-up";
export const chatId = "test-chat";
export const channelsButtonId = "channels-button";

export const tutorialStagesData = [
    {
        text: `
#### Follow this 5 minute tutorial to create a facebook/voice-phone/alexa/website bot/zoom-3D-bot

A project starts at the <span class="clickable-text-item" highlight-entity-id="${entryId}">**Entry**</span> node`,
        stages: [
            `Drag & drop from <span class="clickable-text-item" highlight-entity-id="${entryId}">**Entry**</span> circle to <span class="clickable-text-item" highlight-entity-id="${sayHumanIdv2}">**Say**</span>. This will create a transition between the nodes`
        ],
        image: stage1,
        header: "Welcome to Beyond Virtual Studio"
    },
    {
        text: `
Now let's test it`,
        stages: [
            `Use the <span class="clickable-text-item" highlight-entity-id="${chatId}">**Chat Window**</span> to test`
        ],
        image: stage2,
        header: "Test your bot"
    },
    {
        text: `
Every node represents a component`,
        stages: [
            `Click anywhere on the canvas to pop the *Search-Menu*`,
            `Type in "get name" and select the *BV Name* component`,
            `Connect <span class="clickable-text-item" highlight-entity-id="${sayHumanIdv2}">**Say**</span> to the newly created component <span class="clickable-text-item" highlight-entity-id="${namerHumanId}">**Node**</span> `,
            `<span class="clickable-text-item" highlight-entity-id="${chatId}">**Test**</span> the project to completion`
        ],
        image: stage3,
        header: "Adding a component"
    },
    {
        text: `
Some components are utilities that perform actions without responding`,
        stages: [
            `Select the *Send email* component from the *Search-Menu*`,
            `<span class="clickable-text-item" highlight-entity-id="${emailHumanId}">Click on **Email**</span> and enter your own email`,
            `Connect *done* from <span class="clickable-text-item" highlight-entity-id="${namerHumanId}">**namer_vp3**</span> to <span class="clickable-text-item" highlight-entity-id="${emailHumanId}">**Email**</span>`,
            `Don't forget to <span class="clickable-text-item" highlight-entity-id="${chatId}">**Test**</span> - you should receive an email`
        ],
        image: stage4,
        header: "Utility components"
    },
    {
        text: `
        follow these steps to connect to channels.`,
        stages: [`substage0`],
        image: stage5,
        header: "Channel Connections"
    }
];
