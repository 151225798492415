import React, { useReducer } from "react";
import { UiState, initialUiState, uiSlice } from "./uiSlice";

import { combineReducers } from "@reduxjs/toolkit";

export type NavDrawerState = {
  ui: UiState;
};

export const NavDrawerContext = React.createContext({
  getValue: function <T>(callback: (s: NavDrawerState) => T) {
    return callback({ ui: initialUiState });
  },
  dispatch: (() => {}) as React.Dispatch<{
    type: string;
    payload: any;
  }>,
  actions: {
    ui: uiSlice.actions,
  },
});

const reduce = combineReducers({
  [uiSlice.name]: uiSlice.reducer,
});

export const NavDrawerContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reduce, {
    ui: { ...initialUiState },
  });

  const dispatchWithMw = (action: { type: string; payload: any }) => {
    dispatch(action);
  };

  const getValue = function <T>(callback: (s: NavDrawerState) => T) {
    return callback(state);
  };

  return (
    <NavDrawerContext.Provider
      value={{
        getValue,
        dispatch: dispatchWithMw,
        actions: { ui: uiSlice.actions },
      }}
    >
      {children}
    </NavDrawerContext.Provider>
  );
};
