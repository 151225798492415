import {fetchWrapper} from "data/api/rest/fetchWrapper";

export const getBillingPlan = async () => {
    try {
        const result = await fetchWrapper(`/v2/billing/plan`, {method: "GET"});
        if (!result.ok) throw new Error(result.statusText);
        const reply = await result.json();
        return reply;
    } catch (e: any) {
        return e.message;
    }
};
