export const BotImage = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5015 41.7927C22.5015 40.386 23.6208 39.2456 25.0015 39.2456H75.0015C76.3822 39.2456 77.5015 40.386 77.5015 41.7927V86.6985C77.5015 88.1052 76.3822 89.2456 75.0015 89.2456H25.0015C23.6208 89.2456 22.5015 88.1052 22.5015 86.6985V41.7927ZM27.5015 44.3398V84.1514H72.5015V44.3398H27.5015Z"
        fill="#FCFDFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.0015 74.2456C35.0015 72.8649 36.1208 71.7456 37.5015 71.7456H62.5015C63.8822 71.7456 65.0015 72.8649 65.0015 74.2456C65.0015 75.6263 63.8822 76.7456 62.5015 76.7456H37.5015C36.1208 76.7456 35.0015 75.6263 35.0015 74.2456Z"
        fill="#FCFDFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.5029 56.7456C78.8836 56.7456 80.0029 57.8649 80.0029 59.2456V64.2456C80.0029 65.6263 78.8836 66.7456 77.5029 66.7456C76.1222 66.7456 75.0029 65.6263 75.0029 64.2456V59.2456C75.0029 57.8649 76.1222 56.7456 77.5029 56.7456Z"
        fill="#FCFDFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5 56.7456C23.8807 56.7456 25 57.8649 25 59.2456V64.2456C25 65.6263 23.8807 66.7456 22.5 66.7456C21.1193 66.7456 20 65.6263 20 64.2456V59.2456C20 57.8649 21.1193 56.7456 22.5 56.7456Z"
        fill="#FCFDFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.0015 26.7456C51.3822 26.7456 52.5015 27.8514 52.5015 29.2156V41.7757C52.5015 43.1398 51.3822 44.2456 50.0015 44.2456C48.6208 44.2456 47.5015 43.1398 47.5015 41.7757V29.2156C47.5015 27.8514 48.6208 26.7456 50.0015 26.7456Z"
        fill="#FCFDFF"
      />
      <path
        d="M54.5234 54.7412L63.2954 61.7588"
        stroke="#FCFDFF"
        strokeWidth="3.50877"
        strokeLinecap="round"
      />
      <path
        d="M36.9805 54.7412L45.7524 61.7588"
        stroke="#FCFDFF"
        strokeWidth="3.50877"
        strokeLinecap="round"
      />
      <path
        d="M54.3867 61.5781L63.4355 54.9214"
        stroke="#FCFDFF"
        strokeWidth="3.50877"
        strokeLinecap="round"
      />
      <path
        d="M36.8398 61.5781L45.8886 54.9214"
        stroke="#FCFDFF"
        strokeWidth="3.50877"
        strokeLinecap="round"
      />
      <path
        d="M59.6498 20.6491C59.6498 21.9163 59.4002 23.171 58.9153 24.3417C58.4304 25.5124 57.7196 26.5761 56.8236 27.4721C55.9276 28.3681 54.8639 29.0788 53.6932 29.5637C52.5226 30.0487 51.2678 30.2982 50.0007 30.2982C48.7335 30.2982 47.4788 30.0487 46.3081 29.5637C45.1374 29.0788 44.0737 28.3681 43.1777 27.4721C42.2817 26.5761 41.571 25.5124 41.0861 24.3417C40.6011 23.171 40.3516 21.9163 40.3516 20.6491L50.0007 20.6491H59.6498Z"
        fill="#FCFDFF"
      />
      <path
        d="M54.3867 20.6491V14.5088"
        stroke="#FCFDFF"
        strokeWidth="3.50877"
        strokeLinecap="round"
      />
      <path
        d="M45.6133 20.6491V14.5088"
        stroke="#FCFDFF"
        strokeWidth="3.50877"
        strokeLinecap="round"
      />
    </svg>
  );
};
