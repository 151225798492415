import {ButtonBase, makeStyles, Typography} from "@material-ui/core";
import React, {useState} from "react";
import DeleteIcon from "../assets/DeleteIcon";
import DotIcon from "../assets/DotIcon";

const useStyles = makeStyles((theme) => {
    return {
        container: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: theme.spacing(1, 2)
        },
        data: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
        },
        divider: {
            paddingRight: theme.spacing(0.5)
        },
        actionButtons: {
            display:(props:{hover:Boolean})=>{
                if(props.hover) return "flex"
                else return "none"
            }
        },
        deleteButton: {
            paddingLeft: theme.spacing(1)
        }
    };
});

export type colorVariant = "blue" | "purple";
const EntryDisplay: React.FC<{text: string; color: colorVariant; onDelete?: () => void}> = ({
    text,
    color,
    onDelete
}) => {
    const [hover, setHover] = useState(false);
    const classes = useStyles({hover});

    return (
        <div
            className={classes.container}
            onMouseEnter={() => {
                setHover(true);
            }}
            onMouseLeave={() => {
                setHover(false);
            }}>
            <div className={classes.data}>
                <DotIcon color={color}></DotIcon>
                <div className={classes.divider}></div>
                <Typography>{text}</Typography>
            </div>
            <div className={classes.actionButtons}>
                <ButtonBase onClick={onDelete} className={classes.deleteButton}>
                    <DeleteIcon />
                </ButtonBase>
            </div>
        </div>
    );
};

export default EntryDisplay;
