import {DialogContext, DialogState} from "./DialogContext";
import {useCallback, useContext} from "react";

export const useDialogContext = () => {
    const {getValue, dispatch, actions} = useContext(DialogContext);

    const select = useCallback(
        function <T>(callback: (s: DialogState) => T) {
            return getValue(callback);
        },
        [getValue]
    );

    return {select, dispatch, actions} as const;
};
