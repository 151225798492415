import {fetchWrapper} from "data/api/rest/fetchWrapper";

export type BlueprintType = {human_id: string; name?: string};
export const createComponentCopy = async (fromId: string, id: string, name?: string) => {
    try {
        const dataObject = {human_id: id} as BlueprintType;
        if (name) dataObject.name = name;
        const r = await fetchWrapper(`/v2/blueprint/${fromId}/copy`, {
            method: "POST",
            body: JSON.stringify(dataObject),
            mode: "cors",
            credentials: "include"
        });
        const reason = r.ok ? "" : await r.text();
        return {isOk: r.ok, reason};
    } catch (e) {
        return {isOk: false, reason: e};
    }
};
