/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ChannelName {
  ALEXA = "ALEXA",
  API = "API",
  AUDIOCODES = "AUDIOCODES",
  DASHBOARD = "DASHBOARD",
  EMBEDDED = "EMBEDDED",
  FACEBOOK = "FACEBOOK",
  TELEGRAM = "TELEGRAM",
  TELEPHONE = "TELEPHONE",
  TWILIO_ASYNC_MSG = "TWILIO_ASYNC_MSG",
  WORDPRESS = "WORDPRESS",
  ZOOM = "ZOOM",
}

export enum Intenttypes {
  KEYWORDS = "KEYWORDS",
  LEARNED = "LEARNED",
  PATTERN = "PATTERN",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
