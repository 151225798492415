import {SubscriptionData} from "marketplace-2/store/billing-plans/BillingPlansStore";
import {fetchWrapper} from "data/api/rest/fetchWrapper";

const makeDefaultResult = () => {
    return {
        nextBilling: "Always free",
        firstBilling: "--/--/--",
        status: "",
        planGid: ""
    } as SubscriptionData;
};

const parseDate = (date: string) => {
    const dateObject = new Date(date);
    const parsedDate =  (dateObject.getMonth() + 1)  + "/" + dateObject.getDate() + "/" + dateObject.getFullYear(); //prints expected format.
    return parsedDate;
};

export const getSubscriptionData = async (plan: string) => {
    if (plan === "Not Found" || plan === "") return makeDefaultResult();
    try {
        const result = await fetchWrapper(`/v2/billing/subscription`, {method: "GET"});
        if (!result.ok) throw new Error(result.statusText);
        const reply = await result.json();

        return {
            nextBilling: parseDate(reply.current_period_end),
            firstBilling: parseDate(reply.current_period_start),
            status: reply.status,
            planGid: reply.billing_plan_gid
        } as SubscriptionData;
    } catch (e) {
        return makeDefaultResult();
    }
};
