import {
  AudioCodesIcon,
  EmbedIcon,
  MessengerIcon,
  PhoneIcon,
  TelegramIcon,
  TwilioIcon,
  VideoChannelIcon,
  WhatsappIcon,
  WordpressIcon,
  DashboardChatIcon,
} from "./channelIcons";
import {
  AudioCodesLogo,
  EmbedLogo,
  MessengerLogo,
  PhoneLogo,
  TelegramLogo,
  TwilioLogo,
  VideoChannelLogo,
  WhatsappLogo,
  WordpressLogo,
} from "./channelLogos";
import {
  getBotDashboard_bot,
  getBotDashboard_bot_channels_edges_node,
} from "data/api/gql/queries/__generated__/getBotDashboard";

import { AudiocodesBody } from "./audiocodes/AudiocodesBody";
import { ChannelType } from "./types";
import { EmbeddedBody } from "./embedded/EmbeddedBody";
import { FacebookBody } from "./facebook/FacebookBody";
import { ImageVariant } from "./ImageLogo";
import { PLAN } from "pricing/plans/choose-plan/ChoosePlan";
import { PhoneBody } from "./phone/PhoneBody";
import React from "react";
import { TelegramBody } from "./telegram/TelegramBody";
import { TwilioChannelBody } from "./twilio/TwilioChannelBody";
import { VideoChannelBody } from "./video/VideoChannelBody";
import { WhatsappBody } from "./whatsapp/WhatsappBody";
import { WordpressChannelBody } from "./wordpress/WordpressChannelBody";

const proPlanAndHigher = [
  PLAN.businessMonthly,
  PLAN.businessAnnually,
  PLAN.proAnnually,
  PLAN.proMonthly,
  PLAN.custom,
];
const basicPlanAndHigher = [
  ...proPlanAndHigher,
  PLAN.basicAnnually,
  PLAN.basicMonthly,
];

export type ChannelDialogParams = {
  botChannels: getBotDashboard_bot_channels_edges_node[];
  botId: string;
  botName: string;
  bot?: getBotDashboard_bot | null;
  close: (isConfirm?: boolean) => void;
};

export const channelsMap = new Map<
  ChannelType,
  {
    src?: string;
    icon: (variant: ImageVariant) => JSX.Element;
    smallIcon: (variant: ImageVariant) => JSX.Element;
    description: string;
    alt: string;
    name: string;
    dialog?: React.FC<ChannelDialogParams>;
    subscription?: PLAN[];
    planRequired?: string;
  }
>([
  [
    ChannelType.videoChannel,
    {
      icon: VideoChannelLogo,
      smallIcon: VideoChannelIcon,
      description:
        "Zoom, Google Meet or Facetime. Invite your virtual human to video calls.",
      alt: "videoChannel",
      name: "Video",
      dialog: VideoChannelBody,
      subscription: basicPlanAndHigher,
      planRequired: "Basic",
    },
  ],
  [
    ChannelType.dashboard,
    {
      icon: DashboardChatIcon,
      smallIcon: DashboardChatIcon,
      description:
        "Use chat on bot dashboard",
      alt: "chat",
      name: "Text chat",
      subscription: basicPlanAndHigher,
      planRequired: "Basic",
    },
  ],
  [
    ChannelType.telephone,
    {
      icon: PhoneLogo,
      smallIcon: PhoneIcon,
      description:
        "Give your virtual human a phone number to interact with users 24/7.",
      alt: "phone",
      name: "Phone",
      dialog: PhoneBody,
      subscription: basicPlanAndHigher,
      planRequired: "Basic",
    },
  ],
  [
    ChannelType.facebook,
    {
      icon: MessengerLogo,
      smallIcon: MessengerIcon,
      description:
        "Allow your virtual human to interact with users using text, gifs, emojis and more on Messenger from Meta.",
      alt: "facebook",
      name: "Messenger",
      dialog: FacebookBody,
      subscription: basicPlanAndHigher,
      planRequired: "Basic",
    },
  ],
  [
    ChannelType.twilio,
    {
      icon: TwilioLogo,
      smallIcon: TwilioIcon,
      description: "Send messages with Twilio.",
      alt: "twilio",
      name: "Twilio",
      dialog: TwilioChannelBody,
      subscription: proPlanAndHigher,
      planRequired: "Pro",
    },
  ],
  [
    ChannelType.telegram,
    {
      icon: TelegramLogo,
      smallIcon: TelegramIcon,
      description:
        "Use your virtual human to interact with users using text, photos, stickers and more on Telegram.",
      alt: "telegram",
      name: "Telegram",
      dialog: TelegramBody,
      subscription: basicPlanAndHigher,
      planRequired: "Basic",
    },
  ],
  [
    ChannelType.whatsapp,
    {
      icon: WhatsappLogo,
      smallIcon: WhatsappIcon,
      description:
        "Let your virtual human engage with your users using text, photos, audio, video, and more on WhatsApp.",
      alt: "whatsapp",
      name: "Whatsapp",
      dialog: WhatsappBody,
      subscription: proPlanAndHigher,
      planRequired: "Pro",
    },
  ],
  [
    ChannelType.wordpress,
    {
      icon: WordpressLogo,
      smallIcon: WordpressIcon,
      description:
        "Connect your virtual human to your WordPress website to allow it to interact with users through a chatbox.",
      alt: "wordpress",
      name: "Wordpress",
      dialog: WordpressChannelBody,
      subscription: basicPlanAndHigher,
      planRequired: "Basic",
    },
  ],
  [
    ChannelType.embedded,
    {
      icon: EmbedLogo,
      smallIcon: EmbedIcon,
      description:
        "Connect your virtual human to any website to allow it to interact with users through a chatbox.",
      alt: "embedded",
      name: "Embedded",
      dialog: EmbeddedBody,
      subscription: basicPlanAndHigher,
      planRequired: "Basic",
    },
  ],
  [
    ChannelType.audiocodes,
    {
      icon: AudioCodesLogo,
      smallIcon: AudioCodesIcon,
      description:
        "Give your virtual human a phone number to interact with users 24/7.",
      alt: "audiocodes",
      name: "Audiocodes",
      dialog: AudiocodesBody,
      subscription: basicPlanAndHigher,
      planRequired: "Basic",
    },
  ],
]);
