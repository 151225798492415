import {Intent} from "./types";
import {fetchWrapper} from "data/api/rest/fetchWrapper";

export const getMyIntents = async () => {
    try {
        const reply = await fetchWrapper(`/v2/intent`);
        if (!reply.ok) throw new Error(`Could not get my intents because ${reply.statusText}`);
        const data = await reply.json();
        return data as Intent[];
    } catch (e) {
        console.error(e);
        return;
    }
};
