import { gql } from "@apollo/client";
import { pagination } from "./pagination";

export const SEARCH_BOTS = gql`
    query searchBots($ownerId: Int, $cursor: Cursor, $query: String!) {
        searchBots(first: 1000, after: $cursor, ownerId: $ownerId, query: $query) {
            edges {
                node {
                    id
                    nodeId
                    firstName
                    avatar
                    extras
                    entrypoint {
                        name
                        id
                        humanId
                    }
                    phoneSeconds
                    sessionsCount
                    zoomSeconds
                    botsSubscription {
                        planName
                    }
                    channels {
                        edges {
                            node {
                                id
                                createdAt
                                jsonConfig
                                nodeId
                            }
                        }
                    }
                }
            }
            ${pagination}
        }
    }
`;
