import React, {useEffect} from "react";
import {Snackbar, IconButton, Tooltip, Slide} from "@material-ui/core";
import {action} from "mobx";
import MuiAlert from "@material-ui/lab/Alert";
import DeleteIcon from "@material-ui/icons/Delete";
import {TransitionProps} from "@material-ui/core/transitions";
import {useRootStore} from "../store/useRootStore";

let timeout = 0;
const timeoutDuration = 3000;

export const Messages = () => {
    const {message, clearMessage} = useRootStore((store) => {
        return {
            message: store.container.messages.currentMessage,
            clearMessage: store.container.messages.onMessageTimeout
        };
    });

    const handleClose = action((event?: React.SyntheticEvent | MouseEvent, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }
        clearMessage();
    });

    useEffect(() => {
        if (!message) return;
        if (timeout) clearTimeout(timeout);
        timeout = (setTimeout(clearMessage, timeoutDuration) as unknown) as number;
    }, [message, clearMessage]);

    return (
        <Snackbar
            style={{pointerEvents: message ? "all" : "none"}}
            TransitionComponent={(props: TransitionProps) => <Slide {...props} direction="up" />}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
            }}
            open={!!message}>
            {message && (
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    action={
                        <>
                            <Tooltip title="Dismiss">
                                <IconButton size="small" color="inherit" onClick={handleClose}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    }
                    severity={(message.type as "error" | "success" | "info" | "warning" | undefined) || "info"}>
                    {message.value}
                </MuiAlert>
            )}
        </Snackbar>
    );
};
