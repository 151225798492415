export enum IntentType {
  "learned" = "learned",
  "keywords" = "keywords",
}

export type FindIntentsParams = {
  offset: number; // page number
  query: string; // search query
  limit: number; // num entries per page
  tags?: string[]; // tags to filter by
  categories?: Category[]; // categories to fiter by
  owner_handle?: string; // handle of maker to filter by
};

export type IntentBody = {
  examples: string[];
  keywords: string[];
};

export type IntentCore = {
  name: string;
  type: IntentType;
  description: string;
  tags: string[];
  body: IntentBody;
};

export type Intent = IntentCore & {
  public: boolean;
  owner_handle: string;
};

export type Category = {
  gid: string;
  name: string;
  icon?: string;
};
