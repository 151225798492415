import React, {useEffect, useRef, useState} from "react";
import {TextField, makeStyles} from "@material-ui/core";

import {handleTextFieldChange} from "utils/handleTextFieldChange";
import useOnFirstRender from "utils/hooks/useOnFirstRender";

const useStyles = makeStyles((theme) => {
    return {
        container: {
            width: (props: {fullWidth: boolean}) => {
                if (props.fullWidth) return "100%";
                else return "90%";
            },
            alignSelf: "center"
        }
    };
});

const useInputStyles = makeStyles((theme) => {
    return {
        root: {borderRadius: theme.spacing(3), background: "transparent"}
    };
});

const CustomTextInputField: React.FC<{
    defaultValue?: string;
    onEnter: (v: string) => void;
    onBlur: (v: string) => void;
    dependencies?: any[];
    focused?: boolean;
    placeholder?: string;
    fullWidth?: boolean;
}> = ({defaultValue, onEnter, onBlur, dependencies = [], focused = false, placeholder = "", fullWidth = false}) => {
    const classes = useStyles({fullWidth});
    const inputClasses = useInputStyles();
    const [value, setValue] = useState(defaultValue);
    const ref = useRef<HTMLInputElement>();

    const setFocus = () => {
        ref.current && ref.current.focus();
    };

    const handleEnter = (v: string) => {
        ref.current && ref.current.blur();
        onEnter && onEnter(v);
    };

    useEffect(() => {
        setValue(defaultValue);
    }, [...dependencies]);

    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Escape") {
            e.preventDefault();
            e.stopPropagation();
            ref.current && ref.current.blur();
        }

        e.key === "Enter" && handleEnter(value || "");
    };

    useOnFirstRender(() => {
        // setFocus();
    }, [focused]);

    return (
        <TextField
            className={classes.container}
            onKeyDown={handleKeyPress}
            inputRef={ref}
            margin="dense"
            value={value}
            placeholder={placeholder}
            variant="outlined"
            autoComplete="off"
            id="custom-text-field"
            fullWidth
            onChange={handleTextFieldChange(setValue)}
            onBlur={handleTextFieldChange(onBlur)}
            InputProps={{
                classes: inputClasses
            }}
        />
    );
};

export default CustomTextInputField;
