import {DEFAULT_BRAIN, DEFAULT_LANGUAGE} from "dangerous/staticStrings";

import {Bot} from "bots-studio/store/bots/createBotsStore";
import {fetchWrapper} from "data/api/rest/fetchWrapper";
import {presetsMap} from "bots-studio/store/bots/data/nameToPreset";
import { ENVIRONMENT } from "const";

export type BotAvatar = {
    id: string;
    voice_id: string;
    preset?: string;
};

export async function updateBot(bot: Bot) {

    //TODO: remove after bug fixing
    if (Object.keys(bot.extras || {}).length < 3) {
        if (ENVIRONMENT.NOTICE_DEV_QA_EXTRAS_OVERWRITING) {
            alert('Dangerously bot updating has been stopped!');
            console.info(bot);
        }
        return false;
    }
    //

    const botsAvatar = {id: bot.avatar.image, voice_id: bot.avatar.voice} as BotAvatar;
    if (!!bot.avatar.presetId) {
        botsAvatar.preset = presetsMap[bot.avatar.presetId]
    };

    try {
        const r = await fetchWrapper(`/v2/bot/${bot.gid}`, {
            method: "PUT",
            body: JSON.stringify({
                first_name: bot.name,
                entrypoint: bot.componentId || DEFAULT_BRAIN,
                language_code: bot.language_code || DEFAULT_LANGUAGE,
                avatar: botsAvatar,
                extras: bot.extras,
                integrations: bot.integrations
            }),
            mode: "cors",
            credentials: "include"
        });
        if (!r.ok) return false;
        return true;
    } catch (e) {
        return false;
    }
}
