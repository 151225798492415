import {getBotDashboard, getBotDashboard_bot_channels_edges_node} from "../queries/__generated__/getBotDashboard";
import {useEffect, useState} from "react";

import {GET_BOT_DASHBOARD} from "../queries/getBotDashboard";
import {useQuery} from "@apollo/client";

export const useBotChannels = ({botId}: {botId: string}) => {
    const {loading, error, data} = useQuery<getBotDashboard>(GET_BOT_DASHBOARD, {
        variables: {id: Number(botId)}
    });

    const [botChannels, setBotChannels] = useState(
        data?.bot?.channels.edges.map(({node}) => node) || ([] as getBotDashboard_bot_channels_edges_node[])
    );

    useEffect(() => {
        setBotChannels(
            data?.bot?.channels.edges.map(({node}) => node) || ([] as getBotDashboard_bot_channels_edges_node[])
        );
    }, [data]);

    return {botChannels, loading, error};
};
