import {IObservableValue, action, autorun, observable} from "mobx";

import {ContainerStore} from "../container/ContainerStore";
import {DrawerOption} from "../../container/drawer/drawerOptions";
import {TableFilter} from "./MyComponentsStore";
import {createComponentCopy} from "data/api/rest/requests/components/createComponentCopy";
import {getIsHumanIdAvailable} from "data/api/rest/requests/components/getIsHumanIdAvailable";

export const createCrateCopy = (
    selectedHandle: IObservableValue<string>,
    container: ContainerStore,
    initialize: (id: string) => void,
    updateBlueprintsList: () => void,
    tableFilter: TableFilter
) => {
    const copy = observable({
        humanIdError: observable.box(""),
        isOpen: observable.box(false),
        isInStudio: false,
        onCreate: (component?: string) => {},
        isShowAfterCreate: {
            isAllowed: false,
            isOn: true,
            isRedirecting: false
        },
        fromComponent: observable.box(""),
        human_id: observable.box(""),
        custom_name: observable.box(""),
        get isCanSave() {
            return !!this.fromComponent.get() && !!this.human_id.get() && !this.humanIdError.get();
        },
        create: action(async () => {
            const newId = copy.human_id.get();
            const name = copy.custom_name.get();
            const {isOk} = await createComponentCopy(copy.fromComponent.get(), newId, name);
            container.messages.addMessage(
                isOk ? "Component created" : "Could not create component",
                isOk ? "success" : "error"
            );
            if (!isOk) {
                copy.onCreate();
                return;
            }
            copy.fromComponent.set("");
            copy.human_id.set("");
            copy.onCreate(newId);
            copy.isOpen.set(false);
            initialize(selectedHandle.get());
            if (copy.isShowAfterCreate.isOn) {
                container.drawer.selected = DrawerOption.myComponents;
                tableFilter.query = newId;
                copy.isShowAfterCreate.isRedirecting = true;
            }
        })
    });

    autorun(async () => {
        const id = copy.human_id.get();
        if (!id) {
            copy.humanIdError.set("Insert a unique ID");
            return;
        }
        copy.humanIdError.set(await getIsHumanIdAvailable(id));
    });

    autorun(() => {
        if (!copy.isOpen.get()) {
            copy.onCreate = (component?: string) => {};
            copy.isInStudio = false;
            return;
        }
        updateBlueprintsList();
    });

    return copy;
};
