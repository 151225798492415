import {IntentCore} from "./types";
import {fetchWrapper} from "data/api/rest/fetchWrapper";

export async function updateIntent(data: IntentCore) {
    try {
        const r = await fetchWrapper(`/v2/intent/${data.name}`, {
            method: "PUT",
            body: JSON.stringify(data),
            mode: "cors",
            credentials: "include"
        });
        if (!r.ok) throw new Error(r.statusText);
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
}
