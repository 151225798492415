import {fetchWrapper} from "data/api/rest/fetchWrapper";

export type PaymentCard = {brand: string; exp_month: string; exp_year: string; last4: string};

export const getPaymentMethods = async () => {
    try {
        const result = await fetchWrapper(`/v2/billing/payment_methods`, {method: "GET"});
        if (!result.ok) throw new Error(result.statusText);
        const reply = (await result.json()) as PaymentCard[];
        return reply;
    } catch (e: any) {
        return new Error(e.message || "Unknown error");
        // console.error(e);
        // return [
        //     {
        //         brand: "none",
        //         exp_month: "--/--/--",
        //         exp_year: "--/--/--",
        //         last4: "****"
        //     }
        // ] as PaymentCard[];
    }
};
