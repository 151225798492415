import React, { useContext } from "react";
import { action, observable } from "mobx";

import { AnalyticType } from "analytics/AnalyticType";
import { ChannelType } from "channels/types";
import { IntegrationType } from "integrations/types";
import { NavTab } from "bots-studio/store/bots/data/data";
import { analytics } from "analytics/analytics";
import { createNameStore } from "bot-dashboard/experts/dialog/createNameStore";
import { queryKeys } from "dangerous/staticStrings";
import { setQueryData } from "utils/query-string/setQueryData";

export enum WindowTrigger {
  "brains" = "brains",
  "integrations" = "integrations",
  "channels" = "channels",
  "experts_explanation" = "experts_explanation",
  "none" = "",
}

const _private = observable({
  isShowingChat: false,
  shownChannel: ChannelType.none,
  shownIntegration: IntegrationType.none,
  activeWindow: WindowTrigger.none,
  onboarding: {
    stage: 0,
    celebratedStage: 0,
    isCompleted: false,
  },
  experts: {
    isShowingConfirmation: false,
    isDialogOpen: false,
  },
});

export const slidingWindows = [WindowTrigger.experts_explanation];

const nameStore = createNameStore();

const data = observable({
  nameStore,
  isShowingBots: false,
  isTestingVideo: false,
  isTestingPhone: false,
  isDisplayBotInvited: false,
  isDisplayWhatsappConfirmation: false,
  invitationDetails: {} as { status: boolean; message: string } | null,
  positions: {} as { [key: string]: number },
  activeTab: NavTab.avatar,
  botsSearchFilter: "",
  setActiveTab: (tab: NavTab) => {
    data.activeTab = tab;
  },
  get activeWindow() {
    return _private.activeWindow;
  },
  set activeWindow(v: WindowTrigger) {
    _private.activeWindow = v;
    setQueryData({
      data: { activeWindow: this.activeWindow },
      id: queryKeys["bot-dashboard-active-window"],
    });
  },
  get isShowingChat() {
    return _private.isShowingChat;
  },
  get shownChannel() {
    return _private.shownChannel;
  },
  get shownIntegration() {
    return _private.shownIntegration;
  },
  setIsShowingChat: action((isOn: boolean) => {
    analytics({
      type: isOn
        ? AnalyticType.dashboard_chat_opened
        : AnalyticType.dashboard_chat_closed,
    });
    _private.isShowingChat = isOn;
  }),
  setShownChannel: action((channel: ChannelType) => {
    _private.shownChannel = channel;
    if (channel === ChannelType.none) {
      data.isTestingVideo = false;
      data.isTestingPhone = false;
    }
  }),
  setShownIntegration: action((integration: IntegrationType) => {
    const int = integration || IntegrationType.none;
    _private.shownIntegration = int;
    setQueryData({
      id: queryKeys["bot-integration-dialog"],
      data: { id: int },
    });
  }),
  experts: {
    get isDialogOpen() {
      return _private.experts.isDialogOpen;
    },
    set isDialogOpen(isDialogOpen: boolean) {
      _private.experts.isDialogOpen = isDialogOpen;
      if (isDialogOpen) return;
      const T = typeof data.experts.data;
      Object.keys(data.experts.data).forEach(
        (k) => (data.experts.data[k as keyof typeof data.experts.data] = "")
      );
    },
    get isShowingConfirmation() {
      return _private.experts.isShowingConfirmation;
    },
    set isShowingConfirmation(v: boolean) {
      _private.experts.isShowingConfirmation = v;
      if (v) return;
      data.setIsShowingChat(false);
    },
    selectedPlan: "",
    data: {
      name: "",
      email: "",
      industry: "",
      website: "",
      message: "",
    },
  },
});

export type DrawerStore = typeof data;
export type NameStore = ReturnType<typeof createNameStore>;

export const DrawerContext = React.createContext(data);

export const NameContext = React.createContext(data.nameStore);

export const DrawerContextProvider: React.FC = ({ children }) => {
  return (
    <DrawerContext.Provider value={data}>{children}</DrawerContext.Provider>
  );
};
export const useDrawerContext = () => useContext(DrawerContext);
