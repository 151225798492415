import {analytics} from "analytics/analytics";
import {action, observable, reaction} from "mobx";
import {CanvasStore} from "../canvas/createCanvasStore";
import {GraphStore} from "../graph/createGraphStore";
import {ProjectsStore} from "../projects/createProjects";
import {stage0} from "./completions/stage0";
import {stage1} from "./completions/stage1";
import {stage2} from "./completions/stage2";
import {stage3} from "./completions/stage3";
import {stage4} from "./completions/stage4";
import {tutorialStagesData} from "./tutorialStagesData";

export const TUTORIAL_ARROW_DELAY = 5000;

export type CompletionData = {
    isFinished: boolean;
    isCelebrated: boolean;
    stages: Map<number, {isFinished: boolean; isCelebrated: boolean}>;
};

export const createTutotialStore = (graph: GraphStore, canvas: CanvasStore, projects: ProjectsStore) => {
    const store = observable({
        sparkleId: "",
        isChannelSaved: false,
        isShowingSingup: false,
        isChatDone: false,
        stage: 0,
        substage: 0,
        numStages: 0,
        completions: new Map<number, CompletionData>(),
        isTutorialHidden: true,
        tutorialStages: [] as {text: string; image: string; header: string; stages: string[]}[]
    });

    /** substage completion reactions **/
    stage0(store, graph, canvas);

    stage1(store, graph, canvas);

    stage2(store, graph, canvas);

    stage3(store, graph, canvas);

    stage4(store, graph, canvas);

    // populate completions
    action(() => {
        store.completions = new Map(
            Array.from(tutorialStagesData, (data, i) => [
                i,
                {
                    isFinished: false,
                    isCelebrated: false,
                    stages: new Map(
                        Array.from(tutorialStagesData[i].stages, (stage, i) => [
                            i,
                            {isFinished: false, isCelebrated: false}
                        ])
                    )
                }
            ])
        );
        store.tutorialStages = tutorialStagesData;
        store.numStages = tutorialStagesData.length;
    })();

    // mark finished tutorial stages
    reaction(
        () => {
            const values = Array.from(store.completions.values());
            const completedUnfinished = values.filter(
                (c) => !c.isFinished && !Array.from(c.stages.values()).some((v) => !v.isFinished)
            );
            return {completedUnfinished, values};
        },
        ({completedUnfinished, values}) => {
            completedUnfinished.forEach((c) => {
                c.isFinished = true;
                analytics({type: `tutorial_stage_${values.indexOf(c) + 1}_completed`});
            });
        }
    );

    // reset isChatDone on stage change
    reaction(
        () => store.stage,
        () => {
            store.isChatDone = false;
        }
    );

    // update current substage
    reaction(
        () => {
            const substages = Array.from(store.completions.get(store.stage)?.stages.values() || []);
            let lastCompleted = -1;
            for (let index = 0; index < substages.length; index++) {
                if (substages[index].isFinished) lastCompleted = index;
                else break;
            }
            return lastCompleted;
        },
        (lastCompleted) => {
            store.substage = lastCompleted + 1;
        }
    );

    return store;
};

export type TutorialStore = ReturnType<typeof createTutotialStore>;
