import { AvatarContainer, ChatContainer } from "./standalone.text.styled";

import { ChatBody } from "bots-studio/components/container/chatWindow/newChat/ChatBody";
import { ErrorNotification } from "bots-studio/components/container/chatWindow/ErrorNotification";
import { Footer } from "bots-studio/components/container/chatWindow/newChat/footer/Footer";
import { Header } from "bots-studio/components/container/chatWindow/newChat/header/Header";
import { getAvatar } from "utils/getAvatar";
import poweredByCocohub from "/assets/powered_by.png";
import { useBotChat } from "bot-dashboard/test-bot/chat-window/hooks/useBotChat";
import { useResponsive } from "@a-i/ui";
import { useState } from "react";
import { uuid } from "utils/uuid";
import { ENVIRONMENT } from "const";

const sessionId = uuid();

export const StandaloneChatWindow: React.FC<{
  channelId: string;
  botId: string;
  name: string;
  botAvatar: string;
}> = ({ botId, channelId, name, botAvatar }) => {
  const [error, setError] = useState("");

  const {
    onInput,
    onSubmit: onUseInputSubmit,
    chat,
    isDisabled,
    reset,
    result,
  } = useBotChat({
    sessionId,
    botId,
    onError: (e) => setError(e.message),
    channelId,
  });

  const { isMobile } = useResponsive();

  return (
    <ChatContainer id="StandaloneChatWindow" isMobile={isMobile}>
      <Header
        {...{
          name,
          avatar: (
            <AvatarContainer>
              <img
                height="100%"
                src={getAvatar(botAvatar)?.profile || botAvatar}
              />
            </AvatarContainer>
          ),
          // rightElement: <StandaloneChatControls {...{ reload: reset }} />,
        }}
      />
      <ChatBody
        {...{
          params: {
            direction: "rtl",
            chat,
            otherAvatar: botAvatar,
            ownAvatar: "",
          },
          style: { height: "calc(100% - 139px)" },
        }}
      />
      <Footer
        {...{
          direction: "rtl",
          isShowingChat: true,
          convoEndMessage: "Session ended",
          sessionId,
          disabled: isDisabled,
          onSubmit: (v: string) => {
            onUseInputSubmit(v);
          },
          onInput,
          result,
          reset,
          style: { paddingBottom: "0px" },
        }}
      />
      <a
        href={ENVIRONMENT.BV_ADDR}
        target="_blank"
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => {}}
      >
        <img src={poweredByCocohub} width="100%" />
      </a>
      <ErrorNotification
        {...{
          reset: () => {
            reset();
            setError("");
          },
          error,
        }}
      />
    </ChatContainer>
  );
};
