import { useState, useRef } from "react";
import {fetchWrapper} from "data/api/rest/fetchWrapper";
import { WebRTCConfig } from "./types";

export const usePcConfig = () => {
    const [config, setConfig] = useState<WebRTCConfig | null>(null);
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    const syncConfig = useRef<WebRTCConfig | null>(null);
    const syncError = useRef<Error | null>(null);

    const getConfig = async () => {
        if (syncConfig.current || syncError.current) {
            return {
                config: syncConfig.current,
                error: syncError.current,
            }
        }

        try {
            const res = await fetchWrapper("/v2/stun_turn");
            const conf: WebRTCConfig = await res.json();

            setConfig(conf);
            syncConfig.current = conf;
        } catch (err) {
            const preparedErr = err instanceof Error ? err : new Error('Unknown error');
            
            setError(preparedErr);
            syncError.current = preparedErr;
        } finally {
            setIsLoading(false);
        }
        
        return {
            config: syncConfig.current,
            error: syncError.current,
        }
    }
    
    return {
        config,
        error,
        isLoading,
        getConfig,
    }
};
