import { AlertCircleFill, Icon, theme, Typography } from "@a-i/ui";
import React from "react";

export const UnsubscribeBotDialogHeader = () => {
  return (
    <div
      style={{ display: "flex", alignContent: "center", gap: theme.spacing(1) }}
    >
      <Icon size={3}>
        <AlertCircleFill />
      </Icon>
      <Typography variant="h4" fontWeight={700}>
        Cancel plan?
      </Typography>
    </div>
  );
};
