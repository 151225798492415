import {
  Category,
  FindIntentsParams,
  Intent,
  IntentCore,
  IntentType,
} from "data/api/rest/requests/intents/types";
import {
  NavigationActionData,
  NavigationBranch,
  NodeAction,
  NodeTypeV2,
  NodeV2,
} from "bots-studio/store/types";
import { action, observable, reaction, toJS } from "mobx";

import { AnalyticType } from "analytics/AnalyticType";
import { ContainerStore } from "../container/ContainerStore";
import { GraphStore } from "bots-studio/store/graph/createGraphStore";
import { UserStore } from "../auth/createAuthStore";
import { analytics } from "analytics/analytics";
import { createIntent } from "data/api/rest/requests/intents/createIntent";
import { deleteIntent } from "data/api/rest/requests/intents/deleteIntent";
import { findIntents } from "data/api/rest/requests/intents/findIntents";
import { getCategories } from "data/api/rest/requests/intents/getCategories";
import { getIntent } from "data/api/rest/requests/intents/getIntent";
import { getMyIntents } from "data/api/rest/requests/intents/getMyIntents";
import { humanReadableId } from "utils/humanReadableId";
import { publishIntent } from "data/api/rest/requests/intents/publishIntent";
import { repopulateIntents } from "./repopulateIntents";
import { updateIntent } from "data/api/rest/requests/intents/updateIntent";

export const createIntentsStore = (
  user: UserStore,
  container: ContainerStore,
  graph: GraphStore
) => {
  const _private = observable({
    categories: observable.array([] as Category[]),
    isCategoriesFetched: false,
    isAllIntents: true,
    searchResults: observable.array([] as Intent[]),
    isInitialized: false,
    isLoading: false,
    isSearch: true,
    selectedCategory: "",
    isUserIntentsFetched: false,
    userIntents: observable.array([] as Intent[]),
    isCoEOpen: false,
    editedIntent: {} as Intent | undefined,
    isCustomizeable: false,
    isCustomizing: false,
  });

  const store = observable({
    filter: "",
    filterTags: observable.array([] as string[]),
    reset: action(() => {
      store.filter = "";
      store.searchResults.clear();
      store.filterTags.clear();
      store.ui.explore.selectedCategory = "";
      _private.isInitialized = false;
    }),
    updateAction: action(
      async ({
        nodes,
        updateUndo,
        intentName = "",
      }: {
        nodes: { [key: string]: NodeV2 };
        updateUndo?: () => void;
        intentName?: string;
      }) => {
        const ur = store.ui.editedAction;

        const foundIntent = await getIntent(intentName);
        if (!foundIntent) throw new Error(`Intent ${intentName} was not found`);
        const intentType = foundIntent?.type || IntentType.learned;
        const keywords = foundIntent?.body.keywords || ([] as string[]);

        const close = action(() => {
          ur.clear();
          store.ui.close();
        });

        if (!ur.actionId) {
          const node = graph.nodes[ur.nodeId];
          if (!node) return close();

          const action = graph.addAction(node, {
            id: humanReadableId(),
            index: node.actions.length,
            exitPosition: { x: 0, y: 0 },
          });

          store.ui.populateEditData({ node, action });
        }
        const actionId = ur.actionId;
        const branches = ur.branches;

        const branch = branches.find(
          (f) => f.branch_id === actionId
        ) as NavigationBranch;
        if (!branch || branch.intent_name === intentName) return close();
        updateUndo && updateUndo();

        const node = nodes[ur.nodeId];
        if (!node) return close();
        const actionRef = node.actions.find((act) => act.id === ur.actionId);
        if (!actionRef) return close();
        const bBranch = actionRef.navigationData;
        if (!bBranch) return close();
        bBranch.intent_name = intentName;

        if (intentType === IntentType.keywords) {
          bBranch.keywords = [...keywords];
        } else {
          delete bBranch.keywords;
        }

        node.actions = observable.array([...node.actions]);
        updateUndo && updateUndo();
        close();
      }
    ),
    searchResults: {
      clear: action(() => {
        store.searchResults.intents.length &&
          store.searchResults.intents.replace([]);
        store.searchResults.total_count = 0;
      }),
      get intents() {
        if (!_private.isInitialized) {
          const isAllIntents = store.ui.isAllIntents;
          _private.isInitialized = true;
          repopulateIntents({
            store,
            filter: store.filter,
            filterTags: store.filterTags,
            owner_handle: isAllIntents ? "" : user.profile.handle,
            selectedCategory: store.ui.explore.selectedCategory,
          });
        }
        return _private.searchResults;
      },
      total_count: 0,
    },
    get userIntents() {
      if (_private.isLoading) return _private.userIntents;

      if (!_private.isUserIntentsFetched) {
        action(async () => {
          _private.isLoading = true;
          const intents = await getMyIntents();
          _private.isLoading = false;
          if (!intents) return [] as Intent[];
          _private.isUserIntentsFetched = true;
          _private.userIntents.replace(intents);
        })();
      }

      return _private.userIntents;
    },
    get categories() {
      if (!_private.isCategoriesFetched) {
        action(async () => {
          _private.isLoading = true;
          const categories = await getCategories();
          _private.isLoading = false;
          if (!categories) return [] as Category[];
          _private.isCategoriesFetched = true;
          _private.categories.replace(categories);
        })();
      } else {
        _private.isLoading = false;
      }

      return [..._private.categories];
    },
    createIntent: action(async (intent: IntentCore) => {
      const isSuccess = await createIntent(intent);
      analytics({
        type: AnalyticType["intent-created"],
        data: { intent: intent.name, type: intent.type },
      });
      if (isSuccess) {
        const fullIntent = {
          ...intent,
          public: false,
          owner_handle: user.profile.handle,
        };
        _private.userIntents.push(fullIntent);
        !store.searchResults.intents.some((i) => i.name === intent.name) &&
          store.searchResults.intents.push(fullIntent);
      }
      return isSuccess;
    }),
    updateIntent: action(async (intent: IntentCore) => {
      const isSuccess = await updateIntent(intent);
      analytics({
        type: AnalyticType["intent-edited"],
        data: { intent: intent.name, type: intent.type },
      });

      Object.values(graph.nodes).forEach((node) => {
        if (node.type !== NodeTypeV2.navigation) return;
        node.actions.forEach((action) => {
          const bBranch = action.navigationData;
          if (!bBranch || bBranch.intent_name !== intent.name) return;

          intent.type === IntentType.keywords &&
            (bBranch.keywords = [...intent.body.keywords]);
        });
        node.actions = observable.array([...node.actions]);
      });

      return isSuccess;
    }),
    deleteIntent: action(async (intent: Intent) => {
      const isSuccess = await deleteIntent(intent.name);
      analytics({
        type: AnalyticType["intent-deleted"],
        data: { intent: intent.name, type: intent.type },
      });
      if (isSuccess) {
        _private.userIntents.remove(intent);
        const i = store.searchResults.intents;
        const index = i.findIndex((int) => int.name === intent.name);
        if (index === -1) return isSuccess;
        i.splice(index, 1);
      }
      return isSuccess;
    }),
    findIntents: action(async (params: FindIntentsParams) => {
      return await findIntents(params);
    }),
    ui: {
      populateEditData: action(
        ({ node, action }: { node: NodeV2; action?: NodeAction }) => {
          const branches = node.actions
            .filter((act) => act.navigationData)
            .map((a) => a.navigationData) as NavigationActionData[];
          store.ui.editedAction.nodeId = node.id;
          store.ui.editedAction.actionId = action ? action.id : "";
          store.ui.editedAction.branches.replace(branches);
        }
      ),
      open: action(
        ({
          anchorEl,
          node,
          action,
        }: {
          anchorEl?: HTMLElement | undefined;
          node?: NodeV2;
          action?: NodeAction;
        }) => {
          store.ui.anchorEl = anchorEl;
          if (!node) return;
          store.ui.populateEditData({ node, action });
        }
      ),
      close: action(() => {
        store.ui.anchorEl = undefined;
      }),
      anchorEl: undefined as HTMLElement | undefined,
      get isAllIntents() {
        return _private.isAllIntents;
      },
      set isAllIntents(isOn: boolean) {
        if (_private.isAllIntents === isOn) return;
        store.ui.isLoading = true;
        _private.isAllIntents = isOn;
      },
      isLoading: false,
      get isSearch() {
        return _private.isSearch;
      },
      set isSearch(isSearch: boolean) {
        if (_private.isSearch === isSearch) return;
        store.ui.isLoading = true;
        store.filterTags.replace([]);
        _private.isSearch = isSearch;
      },
      handle_filter: "",
      editedAction: {
        nodeId: "",
        actionId: "",
        branches: observable.array([] as NavigationActionData[]),
        clear: action(() => {
          store.ui.editedAction.actionId = "";
          store.ui.editedAction.branches.replace([] as NavigationActionData[]);
        }),
      },
      explore: {
        get selectedCategory() {
          return _private.selectedCategory;
        },
        set selectedCategory(selectedCategory: string) {
          store.ui.isLoading = true;
          store.filterTags.replace([]);
          _private.selectedCategory = selectedCategory;
        },
      },
      createOrEdit: {
        get intentType() {
          if (store.ui.createOrEdit.data.body.examples?.length)
            return IntentType.learned;
          return store.ui.createOrEdit.data.type;
        },
        set intentType(type: IntentType) {
          store.ui.createOrEdit.data.type = type;
        },
        get isCreating() {
          return !(_private.editedIntent && _private.editedIntent.name);
        },
        isSelectingIntentType: false,
        isToPublish: false, // whether this session of editing results in a publish request
        isPublished: false, // whether the intent being edited is already published
        get isEdit() {
          return (
            !!_private.editedIntent &&
            !!_private.editedIntent.name &&
            !_private.isCustomizeable &&
            !_private.isCustomizing
          );
        },
        get isCustomizeable() {
          return _private.isCustomizeable;
        },
        set isCustomizeable(isAC: boolean) {
          _private.isCustomizing = false;
          _private.isCustomizeable = isAC;
        },
        customize: () => {
          _private.isCustomizing = true;
          _private.isCustomizeable = false;
          store.ui.createOrEdit.errors["name"] = "Please provide a new name";
        },
        setIntentToEdit: async (intent: Intent) => {
          if (!intent) return;
          store.ui.isLoading = true;
          store.ui.createOrEdit.isCustomizeable =
            intent.owner_handle !== user.profile.handle;
          action(async () => {
            const intentFromDb = await getIntent(intent.name);
            if (!intentFromDb) {
              store.ui.isLoading = false;
              return;
            }
            _private.editedIntent = intent;
            const coe = store.ui.createOrEdit;
            coe.isPublished = intent.public;
            const data = coe.data;
            data.name = intentFromDb ? intentFromDb.name : intent.name;
            data.description = intent.description;
            data.tags.replace(intentFromDb ? intentFromDb.tags : intent.tags);
            data.body.examples.replace(
              intentFromDb ? intentFromDb.body.examples : intent.body.examples
            );
            data.body.keywords.replace(
              (intentFromDb
                ? intentFromDb.body.keywords
                : intent.body.keywords) || ([] as string[])
            );
            data.type = intentFromDb ? intentFromDb.type : intent.type;
            coe.isOpen = true;
            store.ui.isLoading = false;
          })();
        },
        isLoading: false,
        get isOpen() {
          return _private.isCoEOpen;
        },
        set isOpen(isOpen: boolean) {
          !isOpen && store.ui.createOrEdit.clear();
          _private.isCoEOpen = isOpen;
        },
        get isValid() {
          const coe = store.ui.createOrEdit;
          return !Object.keys(coe.errors).length;
        },
        errors: {} as { [key: string]: string },
        data: {
          name: "",
          type: IntentType.learned,
          description: "",
          tags: observable.array([] as string[]),
          categories: observable.array([] as string[]),
          body: {
            keywords: observable.array([] as string[]),
            examples: observable.array([] as string[]),
          },
        },
        deleteIntent: action(async () => {
          if (!_private.editedIntent) return;
          store.ui.isLoading = true;
          const isOk = await store.deleteIntent(_private.editedIntent);
          container.messages.addMessage(
            isOk ? "Intent deleted" : "Could not delete intent",
            isOk ? "success" : "error"
          );
          _private.editedIntent = {} as Intent;
          store.ui.isLoading = false;
          isOk && (store.ui.createOrEdit.isOpen = false);
          return isOk;
        }),
        save: action(async () => {
          const coe = store.ui.createOrEdit;
          if (!coe.isValid) return false;
          store.ui.isLoading = true;
          const isEdit = coe.isEdit;
          const func = isEdit ? store.updateIntent : store.createIntent;
          const isOk = await func(coe.data);
          isOk && coe.isToPublish && publishIntent(coe.data);

          container.messages.addMessage(
            (() => {
              if (isEdit) {
                return isOk
                  ? `Intent upated ${
                      coe.isToPublish ? ", publish request sent" : ""
                    }`
                  : "Could not update intent";
              } else {
                return isOk
                  ? `Intent created ${
                      coe.isToPublish ? ", publish request sent" : ""
                    }`
                  : "Could not create intent";
              }
            })(),
            isOk ? "success" : "error"
          );
          store.ui.isLoading = false;
          if (!isOk) return isOk;

          if (_private.isCustomizing) {
            store.ui.isAllIntents = false;
            store.filter = coe.data.name;
            store.searchResults.intents.replace([
              {
                ...toJS(coe.data),
                public: false,
                owner_handle: user.profile.handle,
              },
            ]);
            store.updateAction({
              nodes: graph.nodes,
              intentName: coe.data.name,
            });
            _private.isCoEOpen = false;
            _private.isCustomizing = false;
          } else if (store.ui.editedAction) {
            // the intent was just added, so we need to update the action
            store.updateAction({
              nodes: graph.nodes,
              intentName: coe.data.name,
            });
          }

          coe.isOpen = false;

          return isOk;
        }),
        clear: action(() => {
          const coe = store.ui.createOrEdit;
          coe.isSelectingIntentType = false;
          coe.isPublished = false;
          coe.isToPublish = false;
          coe.errors = {};
          coe.data.name = "";
          coe.data.description = "";
          coe.data.tags.clear();
          coe.data.categories.clear();
          coe.data.body.examples.clear();
          coe.data.body.keywords.clear();
          coe.errors = {};
          coe.isCustomizeable = false;
          _private.editedIntent = {} as Intent;
        }),
      },
    },
  });

  // reset state when closing
  reaction(
    () => {
      return { isOpen: !!store.ui.anchorEl };
    },
    ({ isOpen }) => {
      if (!isOpen) store.reset();
    }
  );

  // clear filters when switching to myIntents
  reaction(
    () => {
      return { isAllIntents: store.ui.isAllIntents };
    },
    ({ isAllIntents }) => {
      if (isAllIntents) return;
      store.filterTags.replace([]);
    }
  );

  // detect intents desired state change and repopulate intents
  reaction(
    () => {
      return {
        filter: store.filter,
        filterTags: [...store.filterTags],
        chosen_owner_handle: store.ui.handle_filter,
        isAllIntents: store.ui.isAllIntents,
        selectedCategory: store.ui.isSearch
          ? ""
          : store.ui.explore.selectedCategory,
        isSearch: store.ui.isSearch,
      };
    },
    async ({
      filter,
      filterTags,
      chosen_owner_handle,
      isAllIntents,
      isSearch,
      selectedCategory,
    }) => {
      if (_private.isCustomizing) {
        store.ui.isLoading = false;
        return;
      }

      const owner_handle = isAllIntents
        ? chosen_owner_handle
        : user.profile.handle;
      repopulateIntents({
        store,
        filter,
        filterTags,
        owner_handle,
        selectedCategory,
      });
    }
  );

  return store;
};

export type IntentsStore = ReturnType<typeof createIntentsStore>;
