import {DialogHeaderContainer} from "../kit/DialogHeaderContainer";
import React from "react";
import {Typography} from "@material-ui/core";

export const DialogHeader = ({
    title,
    Icon
}: {
    title: string;
    Icon?: React.ComponentType<{width?: number; height?: number}>;
}) => {
    return (
        <DialogHeaderContainer>
            {Icon && <Icon width={32} height={32} />}
            <Typography variant="h6">
                <b>{title}</b>
            </Typography>
        </DialogHeaderContainer>
    );
};
