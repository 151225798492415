import {TutorialData} from "./tutorials/types";
import {multipleProjects} from "./tutorials/multiple-projects/multipleProjects";
import {navigationData} from "./tutorials/navigation/navigationData";
import {parametersData} from "./tutorials/parameters/parametersData";
import {unhappyPathData} from "./tutorials/unhappy-path/unhappyPathData";

export const tutorialsData = {
    navigation: navigationData,
    unhappy_path: unhappyPathData,
    parameters: parametersData,
    multipleProjects: multipleProjects
} as {
    [key: string]: TutorialData;
};
