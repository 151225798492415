import {fetchWrapper} from "data/api/rest/fetchWrapper";

export async function deleteIntent(intentName: string) {
    try {
        const r = await fetchWrapper(`/v2/intent/${intentName}`, {
            method: "DELETE"
        });
        if (!r.ok) throw new Error(r.statusText);
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
}
