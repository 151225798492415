import {ResourcesReport} from "marketplace-2/store/billing-plans/BillingPlansStore";
import {fetchWrapper} from "data/api/rest/fetchWrapper";

export const getResources = async () => {
    try {
        const result = await fetchWrapper(`/v2/usage`, {method: "GET"});
        if (!result.ok) throw new Error(result.statusText);
        const reply = await result.json();
        return {
            total: {
                seconds: reply.total.zoom_seconds,
                conversations: reply.total.api_calls,
                phone_numbers: reply.total.phone_purchases
            },
            used: {
                seconds: reply.used.zoom_seconds,
                conversations: reply.used.api_calls,
                phone_numbers: reply.used.phone_purchases
            }
        } as ResourcesReport;
    } catch (e) {
        return {
            total: {
                seconds: 0,
                conversations: 0,
                phone_numbers: 0
            },
            used: {
                seconds: 0,
                conversations: 0,
                phone_numbers: 0
            }
        } as ResourcesReport;
    }
};
