import {fetchWrapper} from "data/api/rest/fetchWrapper";

export const getUserState = async (key: string) => {
    try {
        const r = await fetchWrapper(`/v2/participant/user/state/${key}`);
        if (!r.ok) {
            return null;
        }
        const reply = await r.json();
        return reply;
    } catch (e) {
        console.error(e);
        return null;
    }
};
