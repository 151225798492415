import { Box, PrimaryButton } from "ui-kit/components";

import ArrowLeft from "assets/arrow-left.png";
import ArrowRight from "assets/arrow-right.png";
import PersonIcon from "assets/person.png";
import styled from "styled-components";

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #80425b6f;
  padding-bottom: 8px;
  h4 {
    margin: 0;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  background-color: #243441;
  padding: 3px 7px;
  border-radius: 4px;
  align-items: center;
  button {
    border: none;
    background: none;
    cursor: pointer;
    height: 100%;
  }
  span {
    color: #fff;
    height: 100%;
    margin-top: 12px;
    margin: 12px 8px 0 8px;
  }
`;

const Header = () => {
  return (
    <HeaderWrapper>
      <Box alignItems="center">
        <img src={PersonIcon} alt="person" width={40} />
        <h4>Receptionist</h4>
      </Box>
      <ButtonWrapper>
        <button>
          <img src={ArrowLeft} alt="ArrowLeft" width={8} />
        </button>
        <span>1/5</span>
        <button>
          <img src={ArrowRight} alt="ArrowLeft" width={8} />
        </button>
      </ButtonWrapper>
    </HeaderWrapper>
  );
};

const TempalteInput = styled.input`
  width: 100%;
  border-radius: 24px;
  padding: 8px 16px;
  border: 1px solid #243441;
  color:  ${({ theme }) => theme.colors.disabledMain};
  font-size: 28px;
  font-weight: 300;
  background: transparent;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-bottom: 8px;
`;
const Hint = styled.span`
  display: block;
  color: #2195f3;
  font-weight: 300;
  font-size: 14px;
`;
const Heading = styled.h3`
  margin: 0;
  font-weight: 300;
  color: #243441;
`;
const SubHeading = styled.h3`
  margin-top: 4px;
  font-weight: 700;
  color: #243441;
`;

const Template = () => {
  return (
    <div style={{ paddingTop: 100, height: "100%" }}>
      <Box flexDirection="column" justifyContent="space-between" height="100%">
        <div>
          <Header />
          <div style={{ marginTop: 150 }}>
            <Heading>Give your new employee your business details</Heading>
            <SubHeading>What’s your business name?</SubHeading>
            <TempalteInput placeholder="Hi! Welcome to my place." />
            <Hint>
              Describe the virtual human’s persona in first person, short
              sentence.
            </Hint>
            <Hint>
              The virtual human’s brain is AI based, it’ll do the rest of the
              work.
            </Hint>
          </div>
        </div>
        <Box mb={40} justifyContent="flex-end">
          <PrimaryButton
            onClick={() => {
              console.log("template click");
            }}
          >
            Enter
          </PrimaryButton>
        </Box>
      </Box>
    </div>
  );
};

export default Template;
