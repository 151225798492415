import {Button, Switch, Typography} from "@material-ui/core";
import {useEffect, useState} from "react";

import {Conversation} from "./Conversation";
import Loader from "utils/elments/loader/Loader";
import {Message} from "./types";
import {getMessageLogs} from "./getMessageLogs";
import {groupMessages} from "./groupMessages";

const PAGE_SIZE = 500;

export function LogsView() {
    const [messages, setMessages] = useState<Array<Message>>([]);
    const [conversations, setConversations] = useState<Map<string, Array<Message>>>(new Map());
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [offset, setOffset] = useState<number>(PAGE_SIZE);
    const [showMetadata, setShowMetadata] = useState<boolean>(false);

    useEffect(() => {
        (async function func() {
            await fetchMessages();
        })();
    }, []);

    useEffect(() => {
        groupMessages(messages).then((conversations) => {
            setConversations(conversations);
        });
    }, [messages]);

    const fetchMessages = async () => {
        setIsFetching(true);
        const [messages, total] = await getMessageLogs(0, PAGE_SIZE);
        setOffset(0);
        setMessages(messages);
        setIsFetching(false);
    };

    const fetchOlderMessages = async () => {
        setIsFetching(true);
        const [messages, total] = await getMessageLogs(offset, PAGE_SIZE);
        setOffset(offset + PAGE_SIZE);
        setMessages(messages.concat(messages));
        setIsFetching(false);
    };

    const metadataToggleChecked = () => {
        setShowMetadata(!showMetadata);
    };

    return (
        <div>
            <Typography>
                Show Metadata: <Switch checked={showMetadata} onChange={metadataToggleChecked} />
            </Typography>
            <Button fullWidth color={"primary"} style={{textAlign: "center"}} onClick={fetchMessages}>
                Load Last Updated Conversations
            </Button>
            {Array.from(conversations.keys())
                .reverse()
                .map((key: string) => {
                    {
                        const conversationMessages = conversations.get(key) || [];
                        return <Conversation conversationKey={key} {...{conversationMessages, showMetadata}} />;
                    }
                })}
            <Button fullWidth color={"primary"} style={{textAlign: "center"}} onClick={fetchOlderMessages}>
                Load More Messages
            </Button>
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    width: "100%",
                    height: "100vh",
                    overflow: "hidden",
                    pointerEvents: "none"
                }}>
                {isFetching ? <Loader title="Fetching Data..." /> : null}
            </div>
        </div>
    );
}
