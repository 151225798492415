import React, { useState } from "react";

import CustomInputField from "./CustomInputField";
import PhoneNumberInput from "utils/elments/phone/PhoneNumberInput";
import { TwilioData } from "./TwilioChannelBody";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      paddingTop: theme.spacing(2),
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      gridTemplateRows: "repeat(2, 1fr)",
      gridGap: theme.spacing(3),
    },
  };
});

const placeHolderSID = "AC733afffde55930a90c68060fa818f4e2";
const placeHolderToken = "8f7ec1ab11d230ad2ff060771dc0b6d5";

const getHelperText = (label: string) => {
  return `Provide your ${label} to create twilio channel`;
};

export const TwilioDisconnectedBody: React.FC<{
  data: TwilioData;
  setData: (d: TwilioData) => void;
}> = ({ data, setData }) => {
  const classes = useStyles();
  const [phoneError, setPhoneError] = useState("");

  return (
    <div className={classes.container}>
      <div style={{ gridArea: "1/1/1/1" }}>
        <PhoneNumberInput
          {...{
            phone: data.from_,
            countryId: data.countryId || "US",
            setPhone: (p: string) => {
              setData({ ...data, from_: p });
            },
            setCountryId: (p: string) => {
              setData({ ...data, countryId: p });
            },
            setError: setPhoneError,
          }}
        />
      </div>
      <div style={{ gridArea: "2/2/2/1" }}>
        <CustomInputField
          label={"Account SID"}
          onChange={(v) => setData({ ...data, account_sid: v })}
          helperText={getHelperText("account SID")}
          placeHolder={data.account_sid || placeHolderSID}
        ></CustomInputField>
      </div>
      <div style={{ gridArea: "2/2/2/2" }}>
        <CustomInputField
          label={"Auth token"}
          onChange={(v) => setData({ ...data, token: v })}
          helperText={getHelperText("auth token")}
          placeHolder={data.token || placeHolderToken}
        ></CustomInputField>
      </div>
    </div>
  );
};
