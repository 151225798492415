import {TutorialData} from "../types";
import stage1 from "./assets/unhappy-path-1.gif";
import stage2 from "./assets/unhappy-path-2.gif";

export const unhappyPathData: TutorialData = {
    card: {
        text: `How to handle uncooperative users and sub-goal failures`,
        image: stage1,
        header: "Unhappy Path"
    },
    steps: [
        {
            text: `
Most components have 2 possible outcomes: success and failure
- Success: the component achieved it's goal, such as getting a user's name
- Failure: the compomnent failed to acheive it's goal

Success flow is called the happy path, and failure flow is called the unhappy path`,
            image: stage1,
            header: "Unhappy Path - Intro"
        },
        {
            text: `
1. Click anywhere on the canvas to pop the *Search-Menu*
2. Type in "get name" and select the *BV Name* component
3. Connect **Entry** node to the newly created node
4. Add 2 SAY nodes with a different line for each
5. Connect "done" action to one and "failed" action to the other`,
            image: stage1,
            header: "Unhappy Path - Setup"
        },
        {
            text: `Let's test this flow
1. Test once to flow completion, cooperating with the component
2. Test again to flow completion, this time refusing to cooperate

That's it, now you have a flow that handles non-cooperative users`,
            image: stage2,
            header: "Unhappy Path - Test"
        }
    ]
};
