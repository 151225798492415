import styled from "styled-components";

export const AvatarBody = styled.div`
  display: flex;
  background-color: #356;
  height: 370px;
  width: 100%;
`;

export const Avatars = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 370px;
  overflow-y: scroll;
  position: relative;
  background-color: #356;
`;

export const Card = styled.div`
  font-size: 1em;
  margin: 1em;
  border: 2px solid red;
  border-radius: 3px;
  width: 160px;
`;

export const Input = styled.input`
  font-size: 18px;
  padding: ${({ theme }) => `${theme.spacing(1.25)}px`};
  background: papayawhip;
  border-radius: 3px;
  min-width: 300px;
  height: 40px;
  width: 100%;
  ::placeholder {
    color: palevioletred;
  }
`;

export const Navigation = styled.div`
  display: flex;
  background-color: #000;
  height: 64px;
  width: 100%;
`;
