// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {useEffect, useState} from "react";

import {narrate} from "coco-with-voice";
import { ENVIRONMENT } from "const";

const ttsUrl = ENVIRONMENT.TTS_URL;

export const useVoiceNarrator = (text: string | {text: string}, isVoice: boolean) => {
    const [audioBufferSourceNode, setAudioBufferSourceNode] = useState<AudioBufferSourceNode>();
    const [currentText, setCurrentText] = useState<string | {text: string}>("");
    const [stack, setStack] = useState<Array<string | {text: string}>>([]);
    const [isWaitingForBuffer, setIsWaitingForBuffer] = useState(false);

    useEffect(() => {
        setStack((s) => [...s, text]);
    }, [text]);

    useEffect(() => {
        if (!isWaitingForBuffer && !audioBufferSourceNode && stack.length) {
            const entry = stack.pop();
            if (!entry) return;
            setCurrentText(entry);
            setStack((s) => [...s]);
        }
    }, [isWaitingForBuffer, audioBufferSourceNode, stack]);

    useEffect(() => {
        if (!currentText || !isVoice) return;
        const narrText = typeof currentText === "string" ? currentText : currentText.text;

        const narr = async () => {
            setIsWaitingForBuffer(true);
            const buffer = await narrate(narrText, true, ttsUrl);
            buffer.onended = popNext;
            setAudioBufferSourceNode(buffer);
            setIsWaitingForBuffer(false);
        };

        narr();
    }, [currentText, isVoice]);

    const stop = () => {
        audioBufferSourceNode && audioBufferSourceNode.stop();
        setAudioBufferSourceNode(undefined);
    };

    const popNext = () => {
        audioBufferSourceNode && audioBufferSourceNode.stop();
        setAudioBufferSourceNode(undefined);
    };

    const resetNarr = () => {
        setStack([]);
        setCurrentText("");
        stop();
    };

    return {stop, resetNarr};
};
