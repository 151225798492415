import {fetchWrapper} from "data/api/rest/fetchWrapper";

export const getMessageLogs = async (fromEntry: number, size: number) => {
    try {
        const r = await fetchWrapper(`/v2/msg_log_v3?from_entry=${fromEntry}&size=${size}`, {
            method: "GET",
            mode: "cors",
            credentials: "include"
        });
        const reply = await r.json();
        return [reply.messages, reply.total];
    } catch (e) {
        console.error(e);
        return "";
    }
};
