import { Box as MUIBox, BoxProps } from "@material-ui/core";
import { forwardRef } from "react";

type CustomCenterProps = {
  boxSize?: string | number;
}

export const Center: React.FC<BoxProps & CustomCenterProps> = forwardRef(({ boxSize, ...props}, ref) => (
<MUIBox
  {...{
    width: boxSize ?? '100%',
    height: boxSize ??'100%',
    flex: 1,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...props,
    ref,
  }}
/>
))