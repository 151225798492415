import {Auth0Provider} from "@auth0/auth0-react";
import { ENVIRONMENT } from "const";
import Routing from "Routing";

export const AuthWrapper = () => {
    // const onRedirectCallback = ({returnTo}: {returnTo?: string}) => {
    //     window.history?.replace(returnTo ?? "");
    // };

    return (
        <Auth0Provider
            domain={ENVIRONMENT.AUTH0_DOMAIN ?? ''}
            clientId={ENVIRONMENT.AUTH0_CLIENT ?? ''}
            cacheLocation="localstorage"
            useRefreshTokens={true}
            authorizationParams={{
                redirect_uri: window.location.origin,
                audience: ENVIRONMENT.BV_ADDR + '/api'
            }}
            // onRedirectCallback={onRedirectCallback}
            >
            <Routing />
        </Auth0Provider>
    );
};
