import {makeStyles, useTheme} from "@material-ui/core";
import React from "react";
import {Subtitle} from "theme/components/elements/Subtitle";
import {facebookInfographics} from "../infographics";

const useStyles = makeStyles((theme) => {
    return {
        container: {textAlign: "center"},
        infographics: {marginTop: theme.spacing(3)}
    };
});
const HowTo = () => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <Subtitle title={"How To"} color={"primary"}  />
            <div className={classes.infographics}>{facebookInfographics}</div>
        </div>
    );
};

export default HowTo;
