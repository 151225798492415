import React from "react";
import {Subtitle} from "theme/components/elements/Subtitle";
import {makeStyles} from "@material-ui/core";
import {telegramInfographics} from "channels/infographics";

interface Props {}
const useStyles = makeStyles((theme) => {
    return {
        container: {
            textAlign: "center"
        },
        infoGraphics: {
            marginTop: theme.spacing(5)
        }
    };
});
const HowTo = (props: Props) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Subtitle title={"How To"} color={"primary"} />
            <div className={classes.infoGraphics}>{telegramInfographics}</div>
        </div>
    );
};

export default HowTo;
