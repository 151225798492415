import {GlueStateV2, NodeTypeV2} from "../types";

import {humanReadableId} from "utils/humanReadableId";
import {studioVersion} from "dangerous/staticStrings";
import {observable} from "mobx";
import {makeDefaultDimesions} from "../canvas/createCanvasStore";

export const makeGuestGlueState = (componentId?: string) =>
    observable({
        componentId,
        id: humanReadableId(),
        position: {x: 0, y: 0},
        scale: 1,
        dimensions: makeDefaultDimesions(),
        version: studioVersion,
        nodes: {
            entry: {
                selected: false,
                inspected: false,
                type: NodeTypeV2.entry,
                component: "",
                id: "entry",
                entryPosition: {x: 500650, y: 500400},
                position: {x: 500650, y: 500400},
                options: {},
                actions: [{id: "entry", index: 0, exitPosition: {x: 0, y: 0}}],
                edges: []
            },
            "welcome-entry": {
                selected: false,
                inspected: false,
                type: NodeTypeV2.say,
                component: "oneturn_say_v2",
                id: "welcome-entry",
                entryPosition: {x: 500800, y: 500400},
                position: {x: 500800, y: 500400},
                options: {params: {line: "Hello and welcome to Beyond Virtual!"}},
                actions: [{id: "welcome-entry-on-done-action", index: 0, exitPosition: {x: 0, y: 0}}],
                edges: []
            }
        },
        edges: {}
    }) as GlueStateV2;

export default makeGuestGlueState;
