import {makeStyles} from "@material-ui/core";
import {ChatControls} from "bots-studio/components/container/chatWindow/ChatControls";
import {ErrorNotification} from "bots-studio/components/container/chatWindow/ErrorNotification";
import {ChatBody} from "bots-studio/components/container/chatWindow/newChat/ChatBody";
import {Footer} from "bots-studio/components/container/chatWindow/newChat/footer/Footer";
import {Header} from "bots-studio/components/container/chatWindow/newChat/header/Header";
import {useVoiceNarrator} from "marketplace-2/pages/hub/chat-window/hooks/useVoiceNarrator";
import {useVoiceRecorder} from "marketplace-2/pages/hub/chat-window/hooks/useVoiceRecorder";
import {useRootStore} from "marketplace-2/store/useRootStore";
import React, {useCallback, useEffect, useState} from "react";
import {uuid} from "utils/uuid";
import {useHubChat} from "./useHubChat";

const useStyles = makeStyles((theme) => {
    return {
        container: {
            background: theme.custom.palette.gradient.main,
            borderRadius: theme.spacing(3),
            pointerEvents: "all",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            height: "100%"
        }
    };
});

export const HubChatWindow = ({
    header,
    componentId,
    name,
    toggleChat,
    voice,
    botAvatar,
    ownAvatar,
    convoEndMessage,
    onSubmitCallback,
    onCompleteCallback
}: {
    header?: {buttonsColor?: string};
    componentId: string;
    name: string;
    toggleChat?: () => void;
    voice?: string;
    botAvatar?: string;
    ownAvatar?: string;
    convoEndMessage?: string;
    onSubmitCallback?: (v?: string) => void;
    onCompleteCallback?: (v?: string) => void;
}) => {
    const classes = useStyles();

    const {addMessage} = useRootStore((store) => {
        return {
            addMessage: store.container.messages.addMessage
        };
    });

    const [isVoiceOn, setIsVoiceOn] = useState(false);
    const [lastBotText, setLastBotText] = useState("");
    const [lastBotEntryId, setLastBotEntryId] = useState("");
    const [selectedBubbleId, setSelectedBubbleId] = useState("");
    const [hoverBubbleId, setHoverBubbleId] = useState("");
    const [isCanRecord, setIsCanRecord] = useState(false);
    const [isFooterDisabled, setIsFooterDisabled] = useState(false);
    const [sessionId, setSessionId] = useState(uuid() as string);
    const [error, setError] = useState<Error>();

    const handleError = (e: Error) => {
        setError(e);
    };

    const {
        onInput,
        onSubmit: onUseInputSubmit,
        chat,
        isDisabled,
        reset,
        result
    } = useHubChat(sessionId, componentId, handleError);

    const {stop, resetNarr} = useVoiceNarrator(lastBotText, isVoiceOn);

    const onSubmit = useCallback(
        (v: string) => {
            stop();
            onUseInputSubmit(v);
        },
        [stop, onUseInputSubmit]
    );

    const {onVoiceDown, onVoiceConfirm, checkIfCanRecord, cancelRecording} = useVoiceRecorder(onInput, onSubmit);

    const reload = () => {
        resetNarr();
        setIsFooterDisabled(false);
        setLastBotText("");
        setSelectedBubbleId("");
        setHoverBubbleId("");
        setSessionId(uuid());
        reset();
    };

    useEffect(() => {
        reload();
    }, [componentId]);

    useEffect(() => {
        setIsFooterDisabled(isDisabled);
    }, [isDisabled]);

    useEffect(() => {
        const last = chat.length ? chat[chat.length - 1] : undefined;
        if (!last || last?.isLoading || last?.isOwn || last.id === lastBotEntryId) {
            setLastBotText("");
            return;
        }
        setLastBotEntryId(last.id);
        setLastBotText(last.text);
    }, [chat]);

    useEffect(() => {
        if (!isVoiceOn || isCanRecord) return;
        const updateIsCanRecord = async () => {
            const isVoiceAllowed = await checkIfCanRecord();
            setIsCanRecord(isVoiceAllowed);
            if (!isVoiceAllowed) {
                addMessage("Please enable microphone to use voice", "warning");
                setIsVoiceOn(false);
            }
        };
        updateIsCanRecord();
    }, [isVoiceOn, isCanRecord]);

    useEffect(() => {
        result.done && onCompleteCallback && onCompleteCallback();
    }, [result]);

    const toggleVoice = () => {
        setIsVoiceOn(!isVoiceOn);
    };

    return (
        <div className={classes.container}>
            <ChatControls {...{isVoiceOn, close: toggleChat, reload, toggleVoice, header}} />
            <Header {...{name, header}} />
            <ChatBody
                {...{
                    params: {
                        chat,
                        selectedBubbleId,
                        hoverBubbleId,
                        otherAvatar: botAvatar,
                        ownAvatar: ownAvatar
                    }
                }}
            />
            <Footer
                {...{
                    convoEndMessage,
                    sessionId,
                    disabled: isFooterDisabled,
                    cancelRecording,
                    onSubmit: (v: string) => {
                        onSubmit(v);
                        onSubmitCallback && onSubmitCallback(v);
                    },
                    onInput,
                    onVoiceDown,
                    onVoiceConfirm,
                    isVoiceOn,
                    result,
                    reset: reload
                }}
            />
            <ErrorNotification
                {...{
                    reset: () => {
                        reset();
                        setError(undefined);
                    },
                    error
                }}
            />
        </div>
    );
};
