import {Bot} from "bots-studio/store/bots/createBotsStore";
import {fetchWrapper} from "data/api/rest/fetchWrapper";
import {makeDefaultBot} from "bots-studio/store/bots/data/makeDefaultBot";

export const getBots = async () => {
    try {
        const result = await fetchWrapper(`/v2/bot`, {method: "GET"});
        if (!result.ok) throw new Error(result.statusText);
        const reply = ((await result.json()) as Array<Bot>).reduce((acc, cur) => {
            cur.channels = {};
            cur.integrations = {};
            if (!cur.avatar) cur.avatar = makeDefaultBot().avatar;
            if (!cur.extras) cur.extras = makeDefaultBot().extras;
            else {
                !cur.avatar.image && (cur.avatar.image = (cur.avatar as any).id);
                !cur.avatar.voice && (cur.avatar.voice = (cur.avatar as any).voice_id);
            }
            cur.componentId = (cur as any).entrypoint;
            acc[cur.gid] = cur;
            return acc;
        }, {} as {[key: string]: Bot});
        return reply;
    } catch (e: any) {
        console.error(`error on fetching bots list: ${e?.message}`);
        return {};
    }
};
