import { Button, IconButton, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";

import SendIcon from "@material-ui/icons/Send";
import clsx from "clsx";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      background: theme.custom.palette.d.alt,
      borderRadius: theme.spacing(0, 0, 3, 3),
      paddingBottom: theme.spacing(1),
      overflow: "hidden",
    },
    active: {
      color: theme.custom.palette.a.main,
      transition: theme.transitions.create("color", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    inactive: {
      color: "grey !important",
      transition: theme.transitions.create("color", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    completionBanner: {
      borderRadius: "0px",
      height: `calc(100% + ${theme.spacing(1)}px)`,
    },
  };
});

const textInputStyle = (isRtl: boolean, disabled: boolean) => ({
  direction: (isRtl ? "rtl" : "ltr") as "rtl" | "ltr",
  borderBottomLeftRadius: "10px",
  borderBottomRightRadius: "10px",
  width: "100%",
  border: "none",
  boxShadow: "none",
  boxSizing: "border-box" as "border-box",
  display: "inline-block" as "inline-block",
  verticalAlign: "bottom",
  fontSize: "16px",
  lineHeight: "16px",
  fontFamily: "Montserrat",
  scrollbarWidth: "thin" as "thin",
  opacity: disabled ? 0.5 : 1,
  outline: "none" as "none",
  overflow: "auto" as "auto",
  resize: "none" as "none",
  padding: isRtl ? "16px 14px 0px 16px" : "16px 55px 0px 14px",
  // @ts-ignore
  "&:disabled": {
    background: "#fff",
  },
});

const getEndMessage = (
  result: { success: boolean },
  convoEndMessage?: string
) => {
  if (convoEndMessage) return convoEndMessage;
  return result.success
    ? "Finished, goal achieved"
    : "Finished, goal not achieved";
};

export const Footer: React.FC<{
  direction?: "rtl" | "ltr";
  isShowingChat?: boolean;
  sessionId: string;
  onSubmit: (v: string) => void;
  onInput?: (v: string) => void;
  onVoiceDown?: () => void;
  onVoiceConfirm?: () => Promise<void>;
  cancelRecording?: () => void;
  isVoiceOn?: boolean;
  disabled?: boolean;
  isFinished?: boolean;
  isFailed?: boolean;
  result: { done: boolean; success: boolean };
  reset: () => void;
  convoEndMessage?: string;
  style?: React.CSSProperties;
}> = ({
  direction,
  isShowingChat,
  sessionId,
  reset,
  onSubmit,
  result,
  onInput = (v: string) => {},
  disabled = false,
  convoEndMessage,
  style,
}) => {
  const classes = useStyles();

  const [value, setValue] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const textRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    setValue("");
  }, [sessionId]);

  useEffect(() => {
    !disabled && textRef.current && textRef.current.focus();
  }, [disabled, textRef.current]);

  useEffect(() => {
    isShowingChat && textRef.current && textRef.current.focus();
  }, [isShowingChat, textRef.current]);

  useEffect(() => setIsButtonDisabled(disabled || !value), [value, disabled]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setValue(value);
    onInput(value);
  };

  const handleSubmit = () => {
    onSubmit(value);
    setValue("");
  };

  const onKeyPress = (event: React.KeyboardEvent<any>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    } else {
      setValue(event.currentTarget.value);
    }
  };

  if (result.done) {
    return (
      <div className={classes.container}>
        <Button
          className={classes.completionBanner}
          color="primary"
          fullWidth
          variant="contained"
          onClick={reset}
        >
          {getEndMessage(result, convoEndMessage)}
        </Button>
      </div>
    );
  }

  return (
    <div className={classes.container} style={style}>
      <textarea
        ref={textRef}
        style={textInputStyle(direction === "rtl", disabled)}
        disabled={!!disabled}
        placeholder={direction === "rtl" ? "הקלד כאן" : "Type here..."}
        value={value}
        onKeyPress={onKeyPress}
        onChange={onChangeHandler}
        autoFocus={true}
      />
      <IconButton
        disabled={isButtonDisabled}
        className={clsx({
          [classes.inactive]: isButtonDisabled,
          [classes.active]: !isButtonDisabled,
        })}
        onClick={handleSubmit}
        style={{ transform: `rotate(${direction === "rtl" ? 180 : 0}deg)` }}
      >
        <SendIcon />
      </IconButton>
    </div>
  );
};
