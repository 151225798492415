import {  ReactNode, cloneElement, forwardRef, isValidElement } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export type CustomStyleProps = {
  boxSize?: string | number,
  flexAndCenter?: boolean,
  styles?: CSSProperties,
}

type StyleProps = CSSProperties & CustomStyleProps;

type StyledWrapperProps = StyleProps & {
  children: ReactNode;
};

const useStyles = makeStyles(() => {
  return createStyles<string, StyleProps>({
    additional: ({ 
      boxSize, 
      flexAndCenter,
      styles,
      ...rules
    }) => ({
      '&.additional' : {
        ...(flexAndCenter && { display: 'flex', alignItems: 'center', justifyContent: 'center' }),
        ...(boxSize !== undefined && { width: boxSize, height: boxSize }),
        ...rules,
        ...styles,
      }
    }),
  });
});

export const Styles: React.FC<StyledWrapperProps> = forwardRef(({ children, ...props }, ref) => {
  const additionalClassName = useStyles(props).additional;

  const isElement = !!(
    children 
      && !Array.isArray(children) 
      && isValidElement(children) 
      && typeof children === 'object'
    );

  if (!isElement) {
    console.error(`Cannot add custom styles to ${
      Array.isArray(children) ? 'array of ReactNodes' : `"${typeof children}" ReactNode`
    }`);
    return <>{children}</>;
  } 

  const ownClassName = (children.props as { className?: string }).className;
  const className = ownClassName 
    ? `${ownClassName} ${additionalClassName} additional` 
    : `${additionalClassName} additional` ;

  const child = cloneElement(children, { ...children.props as any, className, ref })

  return child;
});

