import {action, reaction} from "mobx";

import {BotsStore} from "../createBotsStore";
import {updateBot} from "data/api/rest/requests/bots/v2/updateBot";

export const runUpateBotOnSelected = ({store}: {store: BotsStore}) =>
    reaction(
        () => {
            return {currentId: store.currentId};
        },
        action(async ({currentId}) => {
            const bot = store.editor.current;
            if (bot.gid && !store.bots[bot.gid]) {
                store.bots[bot.gid] = {...bot};
            }
            bot.extras.lastUpdate = Date.now();
            updateBot(bot);
        })
    );
