export enum SignalingEvents {
  UserConnected = 'user_connected',
  UserDisconnected = 'user_disconnected',
  BotConnected = 'bot_connected',
  BotDisconnected = 'bot_disconnected',
  State = 'state',
  Offer = 'offer',
  Answer = 'answer',
  Candidate = 'candidate',
  Info = 'info',
  Error = 'error',
  Ping = 'ping',
  UserLeft = 'user_left',
  CheckUser = 'check_user',
}

export type SignalingMessage = {
  message: string;
}

export type SendSignalingEventFn = {
  (
      ev: 
          | SignalingEvents.Info 
          | SignalingEvents.Error,
      payload: SignalingMessage
  ): void;
  (ev: SignalingEvents.Candidate, payload: RTCIceCandidate): void;
  (ev: SignalingEvents.Offer | SignalingEvents.Answer, payload: RTCSessionDescriptionInit): void;
}

export type WebRTCConfig = {
  username: string,
  date_updated: string,
  account_sid: string,
  ttl: number,
  date_created: string,
  password: string,
  iceServers: {
      url: string,
      urls: string,
      username?: string,
      credential?: string,
  }[],
}