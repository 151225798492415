import {PaymentCard, getPaymentMethods} from "data/api/rest/requests/billing/getPaymentMethods";
import {action, observable} from "mobx";

import {AuthStore} from "../auth/createAuthStore";

export const createBillingStore = (auth: AuthStore) => {
    const _private = observable({
        methods: [] as PaymentCard[],
        isInitialized: false
    });

    const store = observable.object({
        isLoading: false,
        get methods() {
            if (!_private.isInitialized && auth.isReady && auth.isAuthenticated) {
                store.refetch();
                _private.isInitialized = true;
            }
            return _private.methods;
        },
        refetch: action(async () => {
            store.isLoading = true;
            try {
                // @ts-ignore
                _private.methods = await getPaymentMethods();
            } catch (e: any) {
                console.error(e.message);
            }
            store.isLoading = false;
        })
    });

    return store;
};

export type BillingStore = ReturnType<typeof createBillingStore>;
