import {ButtonBase, makeStyles} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import React from "react";

const useStyles = makeStyles((theme) => {
    return {
        container: {
            zIndex: 40000,
            position: "absolute",
            right: `${theme.spacing(2.5)}px`,
            top: `${-theme.spacing(1.5)}px`,
            width: "0px",
            height: "0px",
            overflow: "visible"
        },
        button: {
            display: "absolute",
            right: `0px`,
            top: `0px`,
            borderRadius: `${theme.spacing(4)}px`,
            background: theme.custom.palette.e.secondary,
            transition: theme.transitions.create("all"),
            width: theme.spacing(4),
            height: theme.spacing(4),
            "&:hover": {
                right: `${theme.spacing(0.5)}px`,
                top: `${-theme.spacing(0.5)}px`,
                width: theme.spacing(5),
                height: theme.spacing(5),
                background: theme.custom.palette.e.main
            }
        },
        icon: {
            borderRadius: `${theme.spacing(4)}px`,
            color: theme.custom.palette.d.alt
        }
    };
});

export const CloseButton: React.FC<{onClick: () => void}> = ({onClick}) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <ButtonBase className={classes.button} onClick={onClick}>
                <CloseIcon className={classes.icon} />
            </ButtonBase>
        </div>
    );
};
