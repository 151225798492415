import { useQuery } from "@apollo/client";
import { gql } from "../readonly_generated";
import { getOwnerId } from "data/utils";

const GET_USER_DATA = gql(`
    query getUserData($id: Int!) {
        user(id: $id) {
            email
            firstName
            id
            lastName
            name
            userType
            userOrganizationAssociations {
              nodes {
                organizationId
              }
            }
        }
    }
`);

export const useUserData = () => {
  const { loading, error, data } = useQuery(GET_USER_DATA, {
    variables: { id: getOwnerId() },
    errorPolicy: "all",
    fetchPolicy: 'cache-first',
  });

  return ({
    user: data?.user ?? null,
    error,
    loading,
  });
};
