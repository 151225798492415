import { ButtonVariant, PrimaryButtonProps } from "./api/types";
import styled, { DefaultTheme, css } from "styled-components";

const baseBtnCss = css`
  font-size: ${({ theme }) => `${theme.typography.base}px`};
  line-height: 1.5rem;
  font-weight: 300;
  cursor: pointer;
  box-sizing: border-box;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InnerContent = styled.span`
  transition: ${({ theme }) => theme.transition()};
`;

export const HoverableContent = styled.div`
  position: absolute;
  transition: none;
  left: ${({ theme }) => `-${theme.spacing(1.2)}px`}; ;
`;

const getButtonBackground = (
  variant: ButtonVariant = "blue",
  theme: DefaultTheme
) => {
  let a = theme.colors.primary.blue.main;
  switch (variant) {
    case "share":
      a =
        "linear-gradient(to right, #B88EFC -13.2%, #7D43F8 27.1%, #4621B2 93.47%)";
      break;
    default:
      break;
  }
  return a;
};

const getButtonHoverBackground = (
  variant: ButtonVariant = "blue",
  theme: DefaultTheme
) => {
  let a = theme.colors.background.dark.shade;
  switch (variant) {
    case "share":
      a = "linear-gradient(to right, #2195F3, #7D43F8 )";
      break;
    default:
      break;
  }
  return a;
};

export const PrimaryStyledButton = styled.button<PrimaryButtonProps>`
  ${baseBtnCss}
  position: relative;
  white-space: nowrap;
  gap: ${({ theme }) => `${theme.spacing(1.2)}px`};
  padding: ${({ theme }) => theme.spacing(1, 2)};
  color: ${({ theme }) => theme.colors.text.light};
  background: ${({ theme, variant }) => getButtonBackground(variant, theme)};
  border-radius: ${({ theme }) => `${theme.spacing(5)}px`};
  box-shadow: ${({ theme }) => theme.shadow(3)};
  transition: ${({ theme }) => theme.transition(["background-color"])};

  &:hover {
    background: ${({ variant, theme }) =>
      getButtonHoverBackground(variant, theme)};
  }
  &:disabled {
    background: ${({ theme }) => theme.colors.background.dark.tint};
    color: ${({ theme }) => `${theme.colors.disabled}`};
  }

  &.blue {
    background: ${({ theme }) => theme.colors.text.light};
    color: ${({ theme }) => theme.colors.primary.blue.main};
    &:hover {
      background: ${({ theme }) => theme.colors.background.dark.shade};
    }
  }
`;

export const SecondaryStyledButton = styled.button`
  ${baseBtnCss}
  white-space: nowrap;
  position: relative;
  gap: ${({ theme }) => `${theme.spacing(1.2)}px`};
  background: none;
  border: 1px solid ${({ theme }) => theme.colors.primary.blue.main};
  color: ${({ theme }) => theme.colors.primary.blue.main};
  border-radius: ${({ theme }) => `${theme.spacing(5)}px`};
  padding: ${({ theme }) => theme.spacing(1, 2)};
  box-shadow: ${({ theme }) => theme.shadow(0)};
  transition: ${({ theme }) => theme.transition(["background", "color"])};

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.blue.main};
    color: ${({ theme }) => theme.colors.text.light};
  }

  &:disabled {
    border: 1px solid ${({ theme }) => theme.colors.background.dark.tint};
    color: ${({ theme }) => theme.colors.background.dark.tint};
    background: none;
  }
  &.black {
    color: ${({ theme }) => theme.colors.text.light};
    background-color: ${({ theme }) => theme.colors.primary.blue.main};
    &:hover {
      background-color: ${({ theme }) => theme.colors.background.light.tint};
      color: ${({ theme }) => theme.colors.primary.blue.main};
      border: none;
    }
  }
  &.blue {
    background-color: ${({ theme }) => theme.colors.background.light.tint};
    color: ${({ theme }) => theme.colors.primary.blue.main};
    border: none;
    box-shadow: ${({ theme }) => theme.shadow(3)};
    &:hover {
      transition: 0.3 all;
      background-color: ${({ theme }) => theme.colors.background.dark.main};
    }
  }
`;

export const GhostStyledButton = styled.button`
  border: none;
  background: none;
  font-size: ${({ theme }) => `${theme.typography.base}px`};
  font-weight: 300;
  padding: ${({ theme }) => `${theme.spacing(1)}px`};
  color: ${({ theme }) => theme.colors.primary.blue.main};
  cursor: pointer;
  &:hover {
    font-weight: 700;
  }
`;

export const Loader = styled.div`
  animation: loading-spinner 1170ms linear infinite;
  border-color: #fff #0000 #fff #fff;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  height: 18px;
  width: 18px;
  @keyframes loading-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(1turn);
    }
  }
`;

export const Checkmark = styled.div`
  display: inline-block;
  transform: rotate(45deg);
  height: 12px;
  width: 6px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.text.light};
  border-right: 2px solid ${({ theme }) => theme.colors.text.light};
`;

export const HiddenContent = styled.div`
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 0;
`;
