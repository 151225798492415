import React, { Suspense } from "react";
import { createStyles, makeStyles } from "@material-ui/core";

import { Container } from "../container/Container";
import { CreateComponentCopyDialog } from "../pages/my-components/create/CreateComponentCopyDialog";
import { DeleteDialog } from "../common/DeleteDialog";
import { EditComponentConfigDialog } from "../pages/my-components/edit/EditComponentConfigDialog";
import { EditorContextProvider } from "../pages/my-components/edit/EditorContext";
import { HiddenOptionsDialog } from "dialogs/HiddenOptionsDialog";
import { HiddenTokenDialog } from "dialogs/HiddenTokenDialog";
import Loader from "utils/elments/loader/Loader";

const BugReportDialog = React.lazy(() => import("../container/BugReportDialog"));
const OptionsDialog = React.lazy(
  () => import("../container/options/OptionsDialog")
);

const useStyles = makeStyles((theme) =>
  createStyles({
    suspenseWrapper: {
      height: "100%",
      overflow: "hidden",
    },
  })
);

export const MainSwitchContainer: React.FC<{}> = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <Container>
        <div className={classes.suspenseWrapper}>
          <Suspense fallback={<Loader contained title="Loading..." />}>
            {children}
            <OptionsDialog />
            <BugReportDialog />
            <EditorContextProvider>
              <EditComponentConfigDialog />
            </EditorContextProvider>
            <CreateComponentCopyDialog />
            <DeleteDialog />
          </Suspense>
        </div>
      </Container>
      <HiddenOptionsDialog />
      <HiddenTokenDialog />
    </>
  );
};
