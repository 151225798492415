import {fetchWrapper} from "data/api/rest/fetchWrapper";

export const getJwtToken = async () => {
    try {
        const result = await fetchWrapper(`/v2/postgraphile/token`, {method: "POST"});
        if (!result.ok) throw new Error(result.statusText);
        const reply = await result.json();
        return reply.access_token as string;
    } catch (e) {
        console.error(e);
        return "";
    }
};
