import {encodedPresetsToID} from "bots-studio/store/bots/data/nameToPreset";
import {fetchWrapper} from "data/api/rest/fetchWrapper";
import {makeDefaultBot} from "bots-studio/store/bots/data/makeDefaultBot";

export type PublicBot = {
    name: string;
    entrypoint: string; // gid
    created_at: Date;
    language_code: string;
    channels: any[];
    integrations: any[];
    avatar: {
        id: string;
        voice_id: string;
        preset: string;
    };
    gid: string;
    extras: Object;
};

export const getBot = async (id: string) => {
    try {
        const result = await fetchWrapper(`/v2/bot/${id}`, {method: "GET"});
        if (!result.ok) throw new Error(result.statusText);
        const reply = await result.json();
        if (!reply.avatar) reply.avatar = makeDefaultBot().avatar;
        if (!reply.extras) reply.extras = makeDefaultBot().extras;
        if (reply.avatar && !reply.avatar.presetId && reply.avatar.preset) {
            reply.avatar.presetId = encodedPresetsToID.get(reply.avatar.preset);
            delete reply.avatar.preset;
        } else {
            !reply.avatar.image && (reply.avatar.image = (reply.avatar as any).id);
            !reply.avatar.voice && (reply.avatar.voice = (reply.avatar as any).voice_id);
        }
        return reply as PublicBot;
    } catch (e) {
        return false;
    }
};
