// @ts-ignore
/*
import LinkedInTag from "react-linkedin-insight";
LinkedInTag.init(1592785);
const analyticsEventsToLinkedin = new Map([
    ["StartConversation", 1575841],
    ["Conversation3Turns", 1575849]
]);

TODO - reinstate LinkedInTag
*/

import { ENVIRONMENT } from "const";

export enum AnalyticsEvents {
    "StartConversation" = "StartConversation",
    "Conversation3Turns" = "Conversation3Turns",
    "ConversationComplete" = "ConversationComplete",
    "MarketplaceOpened" = "MarketplaceOpened",
    "BlueprintTry" = "BlueprintTry",
    "BlueprintOpened" = "BlueprintOpened",
    "BlueprintCodeSelected" = "BlueprintCodeSelected",
    "LoginClicked" = "LoginClicked",
    "LogoutClicked" = "LogoutClicked",
    "ProfileOpened" = "ProfileOpened",
    "CategoryOpened" = "CategoryOpened",
    "CollectionAdded" = "CollectionAdded",
    "ComponentAdded" = "ComponentAdded",
    "ComponentDeleted" = "ComponentDeleted",
    "ConfigureOpened" = "ConfigureOpened",
    "ComponentSaved" = "ComponentSaved"
}

const analyticsEventsToFB = new Map([
    ["StartConversation", "StartConversation"],
    ["Conversation3Turns", "Conversation3Turns"]
]);

export const sendChatAnalytics = (id: AnalyticsEvents, data: string) => {
    sendAnalytics({id, data, category: "chatwindow"});
};

export const sendAnalytics = (p: {id: AnalyticsEvents; category?: string; data?: string}) => {
    if (ENVIRONMENT.IS_FAKING) {
        return;
    }
    const gdata: {event_category?: string; event_label?: string} = {};
    p.category && (gdata.event_category = p.category);
    p.data && (gdata.event_label = p.data);
    // @ts-ignore
    window.gtag("event", p.id, gdata);
    const fb = analyticsEventsToFB.get(p.id);
    // @ts-ignore
    fb && window.fbq("trackCustom", p.id);

    // const ln = analyticsEventsToLinkedin.get(p.id);
    // ln && LinkedInTag.track();
};
