import {BotsStudioStore} from "../botsStudioStore";
import {GraphStore} from "bots-studio/store/graph/createGraphStore";
import {ProjectsStore} from "bots-studio/store/projects/createProjects";
import {UndoRedoStore} from "bots-studio/store/undoRedo/createUndoRedo";
import {reaction} from "mobx";

export const runRepopulateGraphOnSelected = (
    store: BotsStudioStore,
    undoRedo: UndoRedoStore,
    projects: ProjectsStore,
    graph: GraphStore
) =>
    reaction(
        () => {
            return {
                selected: store.projects.selected
            };
        },
        ({selected}) => {
            undoRedo.handleGlueIdChange(selected);
            const proj = projects.projects[selected];
            proj && graph.repopulate(proj);
        }
    );
