import { BREAKPOINTS, DEFAULT_SPACE, SHADOWS } from "./constants";
import { IFunction, ITransition, transitionDefault } from "./type";

// #region Global Imports
import { BaseTheme } from "styled-components";

// #endregion Global Imports
// constant

const theme: BaseTheme = {
  fonts: {
    default: {
      name: "Montserrat, sans-serif",
    },
    style: {
      italic: "italic",
      oblique: "oblique",
      normal: "normal",
      inherit: "inherit",
    },
  },
  typography: {
    base: 18,
  },
  colors: {
    primary: {
      blue: {
        main: "#2195F3",
        shade: "#1056AE",
        tint: "#79CFFB",
      },
      purple: {
        main: "#7D43F8",
        shade: "#4621B2",
        tint: "#B88EFC",
      },
      yellow: {
        main: "#FAD851",
        shade: "#F8BD00",
        tint: "#FDF796",
      },
      share: {
        main: "linear-gradient(to right, #2195F3 0.19%, #161326 198.16%)",
        shade: "linear-gradient(to right, #2195F3, #7D43F8 )",
        tint: "linear-gradient(to right, #B88EFC -13.2%, #7D43F8 27.1%, #4621B2 93.47%)",
      },
    },
    background: {
      light: {
        main: "#EDF4FC",
        shade: "#F5F9FF",
        tint: "#FCFDFF",
      },
      dark: {
        main: "#243441",
        shade: "#425B6F",
        tint: "#90B4C5",
      },
      marketingDark: "#141326",
      landing: "#B3B3B3",
    },
    alert: {
      negative: "#17B19B",
      positive: "#F4556F",
    },
    text: {
      light: "#FCFDFF",
      dark: "#243441",
      highlighted: "#2195F3",
    },
    disabled: "#0d425b29",
    toggleBorder: "#ABB7C4",
    gradient: "linear-gradient(#39598A, #79D7ED)",
  },
  responsive: BREAKPOINTS,
  breakpoints: Object.values(BREAKPOINTS).map((point) => `${point}px`),
  spacing: (...rest: number[]) => {
    if (rest.length === 0 || rest.length > 4) {
      return 0;
    }
    if (rest.length === 1) {
      return rest[0] * DEFAULT_SPACE;
    }
    let space: string = "";
    for (let i of rest) {
      space += `${i * DEFAULT_SPACE}px `;
    }
    return space.trim();
  },
  shadow: (size: number) => {
    if (size > 6) {
      return "none";
    }
    return SHADOWS[size];
  },
  transition: (props?: Array<string> | string, options?: IFunction) => {
    const transition: ITransition = {
      props: props ? props : transitionDefault.props || "all",
      options: {
        ...transitionDefault.options,
        ...options,
      },
    };

    let { props: transitionProps, options: transitionOptions } = transition;
    if (Array.isArray(transitionProps)) {
      transitionProps = transitionProps.join(", ");
    }
    return `${transitionProps} ${transitionOptions?.duration || "0px"}s ${
      transitionOptions?.easing || "linear"
    }`;
  },
};

export { theme };
