import {Typography, useTheme} from "@material-ui/core";

import {DividerElement} from "utils/elments/DividerElement";
import quality from "./assets/quality.svg";

export const HolidayBadge = () => {
    const theme = useTheme();

    return (
        <div
            style={{
                position: "absolute",
                left: theme.spacing(0),
                top: theme.spacing(1),
                background: "#FFE515",
                color: "#E1151F",
                zIndex: 1000,
                display: "flex",
                alignItems: "center",
                borderRadius: theme.spacing(0, 1, 1, 0)
            }}>
            <img src={quality} />
            <Typography align="center" variant="caption">
                <b>HOLIDAY</b>
            </Typography>
            <DividerElement w={1} />
        </div>
    );
};
