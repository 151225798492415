import {
  ChannelGroupInfo,
  ChannelInfo,
} from "./analytics/engagement-trend/types";
import { LogSessionEntry, SORTING_DIRECTION, SORTING_FIELD } from "./types";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";

import { ChannelType } from "channels/types";
import { channelsMap } from "channels/channelsMap";

export const defaultD3Data = {
  xMax: 0,
  yMax: 0,
  stackData: [] as { [key: string]: number }[],
  channels: [] as string[],
  maxHeight: [] as number[],
  colorScale: scaleOrdinal({
    domain: [""],
    range: [""],
  }),
  yScale: scaleLinear<number>({
    domain: [0, 0],
    range: [0, 0],
  }),
  xScale: scaleBand<string>({
    domain: [""],
    padding: 0,
    range: [0, 0],
  }),
  getXAxisLabel: (d: { [key: string]: number }) => "",
  width: 0,
  height: 0,
};

export type StackData = { [key: string]: number }[];

export type EngagementD3Data = typeof defaultD3Data;

export const durations = [
  "< 1 min",
  "1-5 min",
  "5-10 min",
  "10-30 min",
  "> 30 min",
];

export type GroupBucketKey = "text" | "phone" | "video";

export const channelGroupBuckets: {
  [key in GroupBucketKey]: ChannelType[];
} = {
  text: [
    ChannelType.embedded,
    ChannelType.whatsapp,
    ChannelType.telegram,
    ChannelType.sms,
    ChannelType.facebook,
    ChannelType.twilio,
    ChannelType.dashboard,
  ],
  video: [ChannelType.videoChannel],
  phone: [ChannelType.telephone, ChannelType.audiocodes],
};

export const iconMaps = {
  channels: new Map(
    Array.from(channelsMap.entries()).map(([type, { smallIcon }]) => [
      type,
      smallIcon("box"),
    ])
  ),
  channelNames: new Map(
    Array.from(channelsMap.entries()).map(([type, { name }]) => [type, name])
  ),
};

export const getGroups = (): ChannelGroupInfo[] =>
  ["text", "phone", "video"].map((group) => {
    return {
      id: group,
      title: group,
      total: 0,
      trend: 0,
      prevTotal: 0,
      channelIds: [] as ChannelType[],
    };
  });

export const getChannels = (): ChannelInfo[] =>
  Object.values(ChannelType).map((channel) => {
    const c = channelsMap.get(channel);
    return {
      id: channel,
      title: c?.name || channel,
      total: 0,
      trend: 0,
      prevTotal: 0,
      icon: c?.smallIcon("box") || "",
    };
  });

const channelsOrder = Object.values(ChannelType);

export const sortingFunctionsMap = new Map<
  SORTING_FIELD,
  (
    direction: SORTING_DIRECTION
  ) => (a: LogSessionEntry, b: LogSessionEntry) => number
>([
  [
    SORTING_FIELD.botname,
    (direction: SORTING_DIRECTION) => (a, b) =>
      a.bot.name.localeCompare(b.bot.name) *
      (direction === SORTING_DIRECTION.up ? 1 : -1),
  ],
  [
    SORTING_FIELD.channel,
    (direction: SORTING_DIRECTION) => (a, b) =>
      (channelsOrder.indexOf(a.channelType) -
        channelsOrder.indexOf(b.channelType)) *
      (direction === SORTING_DIRECTION.up ? 1 : -1),
  ],
  [
    SORTING_FIELD.date,
    (direction: SORTING_DIRECTION) => (a, b) =>
      a.date.localeCompare(b.date) *
      (direction === SORTING_DIRECTION.up ? 1 : -1),
  ],
  [
    SORTING_FIELD.numberOfTurns,
    (direction: SORTING_DIRECTION) => (a, b) =>
      (a.numTurns - b.numTurns) * (direction === SORTING_DIRECTION.up ? 1 : -1),
  ],
  [
    SORTING_FIELD.duration,
    (direction: SORTING_DIRECTION) => (a, b) =>
      (a.duration - b.duration) * (direction === SORTING_DIRECTION.up ? 1 : -1),
  ],
]);
