import React, { Suspense } from "react"
import { Redirect, Route, Switch } from "react-router-dom";

import Loader from "utils/elments/loader/Loader";
import { UserRoles } from "const";
import { useUserRole } from "data/hooks";
import { RedirectResultPage } from "../pages/redirects/RedirectResultPage";
import { urls } from "dangerous/staticStrings";

const Homepage = React.lazy(() => import("homepage"));
const BotsStudioPage = React.lazy(() => import("bots-studio/BotsStudioPage"));
const CategoryPage = React.lazy(
  () => import("../pages/categories/CategoryPage")
);
const Hub = React.lazy(() => import("../pages/hub/Hub"));
const DfWizard = React.lazy(() => import("wizards/df-wizard/DfWizard"));
const Builder = React.lazy(() => import("builder/Builder"));
const GeneralOnboarding = React.lazy(
  () => import("general-onboarding/GeneralOnboarding")
);
const MyComponentsPage = React.lazy(
  () => import("../pages/my-components/MyComponentsPage")
);

const Logs = React.lazy(() => import("logs/Logs"));
const BotDashboard = React.lazy(() => import("bot-dashboard/BotDashboard"));
const Account = React.lazy(() => import("account/Account"));
const ExpertsExplanationWindow = React.lazy(
  () => import("bot-dashboard/experts/explanation/ExpertsExplanationWindow")
);
const Pricing = React.lazy(() => import("pricing/Pricing"));
const ExercisesOverview = React.lazy(() => import("exercises/ExercisesOverview"));
const AddExercise = React.lazy(() => import("exercises/AddExercise"));
const Classes = React.lazy(() => import("classes/Classes"));
const Students = React.lazy(() => import("classes/Students"));
const RegistrationSecondStep = React.lazy(() => import("registration-second-step"));

const visitorRoutes = [
  <Route path="/bots" exact component={Homepage} key="/bots"/>
]

const teacherRoutes = [
  <Route path="/dashboard" exact component={Homepage} key="/dashboard"  />,
  <Route path="/dashboard/exercises" exact component={ExercisesOverview} key="/dashboard/exercises" />,
  <Route path="/dashboard/exercises/add" exact component={AddExercise} key="/dashboard/exercises/add" />,
  <Route path="/dashboard/classes" exact component={Classes} key="/dashboard/classes" />,
  <Route path="/dashboard/classes/:classId" exact component={Students} key="/dashboard/classes/:classId" />,
]

export const AuthSwitch: React.FC = () => {
  const { role, loading } = useUserRole();

  if (loading) {
    return null;
  }

  if (role === null) {
    return (
      <Suspense fallback={<Loader contained title="Loading..." />}>
        <Switch>
          <Route path="/" component={RegistrationSecondStep} />
        </Switch>
      </Suspense>
    )
  }

  return (
    <Suspense fallback={<Loader contained title="Loading..." />}>
      <Switch>
        <Route path={`/builder-test`} component={Builder} />
        <Route path={`/onboarding`} component={GeneralOnboarding} />
        <Route
          path={`${urls.redirectResult}/:type`}
          component={RedirectResultPage}
        />
        <Route path="/" exact component={Homepage} />
        <Route
          path="/my-components"
          exact
          component={MyComponentsPage}
        />
        <Route path="/logs" component={Logs} />
        {
          role === UserRoles.Teacher
            ? teacherRoutes
            : visitorRoutes
        }
        <Route
          path="/bots/:botId/:window?/:item?"
          component={BotDashboard}
        />
        <Redirect
          from={urls.createBot}
          exact
          to={`bots/${window.location.search}`}
        />
        <Route path="/builder" exact component={Builder} />
        <Route
          path="/builder/audiocodes"
          exact
          component={BotsStudioPage}
        />
        <Route
          path="/bots-builder/audiocodes"
          exact
          component={BotsStudioPage}
        />
        <Route path="/builder/wizard/dfToZoom" component={DfWizard} />
        <Route path="/builder/:projectId" component={BotsStudioPage} />
        <Route path="/categories/:cat" component={CategoryPage} />
        <Route path="/blueprint/:id/*" component={Hub} />
        <Route path="/account/:tab" component={Account} />
        <Route path="/account" component={Account} />
        <Route
          path="/pricing/experts/:plan"
          component={ExpertsExplanationWindow}
        />
        <Route
          path="/pricing/experts/"
          component={ExpertsExplanationWindow}
        />
        <Route path="/pricing" component={Pricing} />
        <Route path="/hub" component={Hub} />
        <Redirect to={`/${window.location.search}`} />
      </Switch>
    </Suspense>
  )
}