import * as Sentry from "@sentry/react";

import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import { useEffect, useState } from "react";

import { AudioServiceProvider } from "services/audio/AudioServiceProvider";
import { AuthWrapper } from "AuthWrapper";
import { CaptureConsole } from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";
import { MuiThemeProvider } from "@material-ui/core";
import { configure } from "mobx";
import { checkIsTestEnvironment } from "utils/checkIsTestEnvironment";
import { makeCocoTheme } from "theme/makeCocoTheme";
import mixpanel from "mixpanel-browser";
import { ENVIRONMENT } from "const";

const theme = makeCocoTheme(false);

export const ApolloWrapper = () => {
  const [growthbook] = useState(
    new GrowthBook({
      trackingCallback: function (experiment, result) {
        mixpanel.track("$experiment_started", {
          "Experiment name": experiment.key,
          "Variant name": result.variationId,
        });
      },
    })
  );

  useEffect(() => {
    // @ts-ignore
    const version = (window.Config || { version: "" }).version;

    const isLocal = checkIsTestEnvironment();

    if (!isLocal) {
      Sentry.init({
        dsn: ENVIRONMENT.SENTRY_DSN,
        integrations: [
          new Integrations.BrowserTracing(),
          new CaptureConsole({ levels: ["error"] }),
        ],
        release: version,

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
      });
    }

    configure({ isolateGlobalState: true, enforceActions: "observed" });

    mixpanel.init("e4f73fcb11ebe878d0b086525267e5f6", {
      api_host: ENVIRONMENT.MIXPANEL_URL,
      loaded: function (mixpanel) {
        const growthbookConfig = growthbook.getAttributes();
        const mixpanelId = mixpanel.get_distinct_id();

        growthbook.setAttributes({
          ...growthbookConfig,
          id: mixpanelId,
        });
      },
    });

    if (!ENVIRONMENT.CDN_GROUWTHBOOK_URL) {
      throw new Error('"FRONTEND_CDN_GROUWTHBOOK_URL" not defined in environment variables');
    }

    fetch(
        ENVIRONMENT.CDN_GROUWTHBOOK_URL
    )
      .then((res) => res.json())
      .then((parsed) => {
        growthbook.setFeatures(parsed.features);
      });
  }, []);

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <MuiThemeProvider theme={theme}>
        <AudioServiceProvider>
          <AuthWrapper />
        </AudioServiceProvider>
      </MuiThemeProvider>
    </GrowthBookProvider>
  );
};
