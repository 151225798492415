import {Button, Theme, Typography, createStyles, makeStyles} from "@material-ui/core";

import Loader from "../utils/elments/loader/Loader";
import React from "react";
import {useGetServiceAccount} from "./useGetServiceAccount";
import {usePuppetAuth} from "./usePuppetAuth";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        container: {
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center"
        },
        inner: {
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
        },
        line: {
            minWidth: "300px",
            display: "flex",
            justifyContent: "space-between",
            padding: theme.spacing(1)
        },
        title: {
            paddingRight: theme.spacing(1)
        },
        button: {
            margin: theme.spacing(4)
        }
    });
});

export type ServiceAccount = {
    name: "string";
    client_id: "string";
    client_secret: "string";
};

export const PuppetAuth = () => {
    const classes = useStyles();

    const {serviceAccount, error, confirm, isWaitingForConfirm, confirmationMessage} = useGetServiceAccount();
    const {isReady, isAuthenticated, login} = usePuppetAuth(serviceAccount);

    if (isWaitingForConfirm) {
        return (
            <div className={classes.container}>
                <Loader title="Confirming..." size={64} />
            </div>
        );
    }

    if (confirmationMessage) {
        return (
            <div className={classes.container}>
                <div className={classes.inner}>
                    <Typography variant="h3" color="primary" style={{pointerEvents: "none"}}>
                        {confirmationMessage}
                    </Typography>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className={classes.container}>
                <div className={classes.inner}>
                    <Typography variant="h3" color="error" style={{pointerEvents: "none"}}>
                        {error}
                    </Typography>
                </div>
            </div>
        );
    }

    if (!isReady) {
        return <Loader title="initializing" size={64} />;
    }

    return (
        <div className={classes.container}>
            <div className={classes.inner}>
                {isAuthenticated ? (
                    <div className={classes.inner}>
                        <div className={classes.line}>
                            <Typography className={classes.title} color="primary">
                                Name
                            </Typography>
                            <Typography>{serviceAccount?.name}</Typography>
                        </div>
                        <div className={classes.line}>
                            <Typography className={classes.title} color="primary">
                                Client ID
                            </Typography>
                            <Typography>{serviceAccount?.client_id}</Typography>
                        </div>
                        <div className={classes.line}>
                            <Typography className={classes.title} color="primary">
                                Secret
                            </Typography>
                            <Typography>
                                {Array.from({length: serviceAccount?.client_secret.length || 0}).join("*")}
                            </Typography>
                        </div>
                        <Button
                            onClick={confirm}
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            size="large">
                            Confirm Authentication
                        </Button>
                    </div>
                ) : (
                    <Button
                        onClick={() => {
                            login({appState: {returnTo: window.location.pathname + window.location.search}});
                        }}
                        variant="contained"
                        color="primary"
                        size="large">
                        Sign in
                    </Button>
                )}
            </div>
        </div>
    );
};
