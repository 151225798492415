import React, {useContext, useState} from "react";
import {VoiceRecordError, useRecordMicrophone} from "./useRecordMicrophone";

const data = {
    playingAudioId: "",
    setPlayingAudioId: (id: string) => {},
    isFetchingAudioRequest: false,
    setIsFethingAudioRequest: (v: boolean) => {},
    startRecording: (onResult: (text: string, error?: VoiceRecordError | undefined) => void) => {},
    stopRecoding: (onResult: (text: string, error?: VoiceRecordError | undefined) => void) => {},
    isRecording: false,
    isProcessing: false
};

export const AudioServiceContext = React.createContext(data);

export const AudioServiceProvider: React.FC = ({children}) => {
    const [playingAudioId, setPlayingAudioId] = useState("");
    const [isFetchingAudioRequest, setIsFethingAudioRequest] = useState(false);

    const {isRecording, isProcessing, startRecording, stopRecoding} = useRecordMicrophone();

    return (
        <AudioServiceContext.Provider
            value={{
                playingAudioId,
                isFetchingAudioRequest,
                isRecording,
                isProcessing,
                setPlayingAudioId,
                setIsFethingAudioRequest,
                startRecording,
                stopRecoding
            }}>
            {children}
        </AudioServiceContext.Provider>
    );
};

export const useAudioService = () => useContext(AudioServiceContext);
