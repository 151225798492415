import {GlueStateV2, NodeV2} from "../types";
import {action, observable, reaction} from "mobx";

const SCALE_STEP = 0.1;
const MIN_SCALE = 0.3;
const MAX_SCALE = 10;

export const makeDefaultDimesions = () => {
    return {width: 1000000, height: 1000000};
};

const calculatePositionOnCanvas = (store: CanvasStore, posX: number, posY: number) => {
    const canvasWidth = store.dimensions.width;
    const canvasHeight = store.dimensions.height;

    const xByScale = posX * store.scale;
    const yByScale = posY * store.scale;

    const canvasCenterX = canvasWidth / 2;
    const canvasCenterY = canvasHeight / 2;

    const x = posX >= canvasCenterX ? -1 * (xByScale - canvasCenterX) : canvasWidth - xByScale - canvasCenterX;
    const y = posY >= canvasCenterY ? -1 * (yByScale - canvasCenterY) : canvasHeight - yByScale - canvasCenterY;

    return [x, y];
};

export const createCanvasStore = () => {
    const store = observable({
        wScale: 1, // window scale, needed because positions on canvas are incorrectly handled
        dimensions: makeDefaultDimesions(),
        version: "1.0.0",
        mousePosition: {
            x: 0,
            y: 0
        },
        addNodeMenu: {
            connectingToEdge: "",
            isOpen: false,
            escaped: false,
            position: {x: 0, y: 0}
        },
        canvasContextMenu: {
            isOpen: false,
            position: {top: 0, left: 0}
        },
        mouseHoverOver: {
            dragElement: false,
            nodeElement: false
        },
        position: {x: 0, y: 0},
        overExitPointId: "",
        draggedNodeId: "",
        draggedEdgeId: "",
        scale: 1,
        scaleUp: action(() => {
            if (store.scale <= MIN_SCALE) return;
            const newScale = store.scale - SCALE_STEP;
            store.scale = newScale;
        }),
        scaleDown: action(() => {
            if (store.scale >= MAX_SCALE) return;
            const newScale = store.scale + SCALE_STEP;
            store.scale = newScale;
        }),
        zoomIn: () => {},
        zoomOut: () => {},
        setTransform: (x: number, y: number) => {},
        cameraControls: {
            zoomIn: () => {
                store.zoomIn();
            },
            zoomOut: () => {
                store.zoomOut();
            },
            moveCameraTo: (x: number, y: number) => {
                store.setTransform(x, y);
            },
            focusOnNode: action((entryNode: NodeV2) => {
                const adjustToScreenCenter = 200 + 80 / store.scale;
                const [centerX, centerY] = calculatePositionOnCanvas(store, entryNode.position.x, entryNode.position.y);
                store.cameraControls.moveCameraTo(centerX + adjustToScreenCenter, centerY + adjustToScreenCenter);
            })
        }
    });

    reaction(
        () => {
            return {visible: store.addNodeMenu.isOpen, escaped: store.addNodeMenu.escaped};
        },
        ({visible, escaped}) => {
            if (!escaped || !visible) return;

            action(() => {
                store.addNodeMenu.isOpen = false;
                store.addNodeMenu.escaped = false;
            })();
        }
    );

    return store;
};

export type CanvasStore = ReturnType<typeof createCanvasStore>;
