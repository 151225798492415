import {BotState, initialState as bot, botSlice} from "./botSlice";
import React, {useReducer, useRef} from "react";

import {combineReducers} from "redux";

export type ActiveBotState = {
    bot: BotState;
};

export const ActiveBotContext = React.createContext({
    getValue: function <T>(callback: (s: ActiveBotState) => T) {
        return callback({ 
            bot,
        });
    },
    dispatch: (() => {}) as React.Dispatch<{
        type: string;
        payload: any;
    }>,
    actions: {
        bot: botSlice.actions
    }
});

const reduce = combineReducers({
    [botSlice.name]: botSlice.reducer
});

export const ActiveBotContextProvider: React.FC<{
    initial?: {
        bot?: Partial<BotState>;
    };
}> = ({children, initial}) => {
    const initialState = {
        bot: {...bot, ...initial?.bot}
    };

    const [state, dispatch] = useReducer(reduce, initialState);
    const stateRef = useRef<ActiveBotState>(state);
    stateRef.current = state;

    const dispatchWithMw = (action: {type: string; payload: any}) => {
        dispatch(action);
    };

    const getValue = function <T>(callback: (s: ActiveBotState) => T) {
        return callback(stateRef.current);
    };

    return (
        <ActiveBotContext.Provider
            value={{
                getValue,
                dispatch: dispatchWithMw,
                actions: {
                    bot: botSlice.actions
                }
            }}>
            {children}
        </ActiveBotContext.Provider>
    );
};
