import {positiveAdjectives} from "./positiveAdjectives";
import {positiveNouns} from "./positiveNouns";
import {uniqueNamesGenerator} from "unique-names-generator";

const allWords = [...positiveAdjectives, ...positiveNouns];

export const randomGoodWords = (numWords?: number) => {
    return uniqueNamesGenerator({
        separator: "-",
        length: numWords || 1,
        dictionaries: Array.from({length: numWords || 1}, () => allWords)
    }).concat("");
};
