import background from "theme/components/styles/background.svg";
import styled from "styled-components";

export const BackgroundContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.background.marketingDark};
  background-image: url(${background});
  background-repeat: repeat;
  background-size: 74px 74px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ChatContainer = styled.div<{ isMobile?: boolean }>`
  direction: rtl;
  position: relative;
  width: ${({ isMobile }) => (isMobile ? "100%" : "300px")};
  height: ${({ isMobile }) => (isMobile ? "100%" : "600px")};
  background: linear-gradient(to right, #2195f3 0.19%, #161326 198.16%);
  width: ${({ isMobile }) => (isMobile ? "100%" : "300px")};
  border-radius: ${({ isMobile, theme }) => theme.spacing(isMobile ? 0 : 3)}px;
`;

export const AvatarContainer = styled.div<{ isWithBorder?: boolean }>`
  height: ${({ theme }) => `${theme.spacing(4)}px`};
  width: ${({ theme }) => `${theme.spacing(4)}px`};
  overflow: hidden;
  box-shadow: ${({ theme }) => `${theme.shadow(3)}`};
  background-color: ${({ theme }) => theme.colors.background.light.shade};
  border-radius: ${({ theme }) => `${theme.spacing(4)}px`};
  border: ${({ isWithBorder, theme }) =>
    `${isWithBorder ? "0.5px solid rgba(0,0,0,0.5)" : ""}`};
`;
