import {CanvasStore} from "bots-studio/store/canvas/createCanvasStore";
import {GraphStore} from "bots-studio/store/graph/createGraphStore";
import {NodeTypeV2} from "bots-studio/store/types";
import {TutorialStore} from "../createTutorialStore";
import {markComplete} from "../utils/markComplete";
import {reaction} from "mobx";

export const stage0 = (store: TutorialStore, graph: GraphStore, canvas: CanvasStore) => {
    // stage 0-0 completion reaction
    reaction(
        () => {
            return {
                stage: store.stage,
                isConnected: Object.values(graph.edges).some(
                    (e) =>
                        e.from.nodeId === "entry" &&
                        !e.to.position &&
                        graph.nodes[e.to.nodeId || ""]?.type === NodeTypeV2.say
                )
            };
        },
        ({stage, isConnected}) => {
            if (!stage && isConnected) {
                markComplete(store, stage, 0);
            }
        }
    );
};
