import { EmailParam } from "bots-studio/store/types";
import { GlueNodeV2 } from "marketplace-2/store/components/types";
import { defaultEmailValue } from "dangerous/staticStrings";
import { isEqual } from "lodash";

export const emailParams = [
  "target_email",
  "email_subject",
  "email_body",
].sort();

export const fromEmailParams = (node: GlueNodeV2) => {
  const params = node.parameters || {};
  const keys = Object.keys(params);
  const isEmail = isEqual(keys.sort(), emailParams);
  if (!isEmail) return;
  try {
    const emailData = params["target_email"];
    const email = Array.isArray(emailData)
      ? emailData.join(",")
      : JSON.parse(emailData as string);
    return {
      email,
      title: JSON.parse(params["email_subject"] as string),
      body: JSON.parse(params["email_body"] as string),
      emailUpdated: email !== defaultEmailValue,
    } as EmailParam;
  } catch (e) {
    throw new Error((e as { message: string }).message);
  }
};
