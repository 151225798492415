import React, { useState } from "react";
import { Typography, makeStyles } from "@material-ui/core";

import AddAlternate from "./AddAlternate";
import EntryDisplay from "../questionsEditor/EntryDisplay";
import { SecondaryButton } from "theme/components/elements/buttons/SecondaryButton";
import { action } from "mobx";
import { useRootStore } from "marketplace-2/store/useRootStore";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      minHeight: theme.spacing(30),
      paddingTop: theme.spacing(2),
      height: "100%",
    },
    noQuestionsDisplay: {
      paddingTop: theme.spacing(3),
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
    },
    buttonContainer: {
      paddingTop: theme.spacing(3),
    },
    questionsContainer: {
      paddingTop: theme.spacing(1),
      overflow: "auto",
      maxHeight: theme.spacing(40),
    },
  };
});

const AlternatesEditor: React.FC = () => {
  const classes = useStyles();
  const [addDisplay, setAddDisplay] = useState(false);

  const { answers, addAlternate, removeAlternate, updateBlueprint } =
    useRootStore((store) => {
      const answers = store.myComponents.editComponent.editedQnAEntry.answers;
      return {
        addAlternate: action((answer: string) => {
          if (answers.indexOf(answer) !== -1) return;
          store.myComponents.editComponent.editedQnAEntry.answers = [
            answers[0],
            answer,
            ...answers.slice(1, answers.length),
          ];
        }),
        removeAlternate: action((item: string) => {
          const index = answers.indexOf(item);
          store.myComponents.editComponent.editedQnAEntry.answers = [
            ...answers.slice(0, index),
            ...answers.slice(index + 1),
          ];
          updateBlueprint();
        }),
        updateBlueprint: store.myComponents.editComponent.updateBlueprint,
        answers: answers.slice(1, answers.length),
      };
    });

  const handleSubmitAnswer = (v: string) => {
    if (v !== null && v !== undefined && v.length > 0) {
      addAlternate(v);
      updateBlueprint();
    }
  };

  return (
    <div className={classes.container}>
      {addDisplay || answers?.length ? (
        <AddAlternate onAdd={handleSubmitAnswer} />
      ) : null}
      {!answers?.length && !addDisplay ? (
        <div className={classes.noQuestionsDisplay}>
          <Typography align={"center"}>
            Alternatives are variations of the same answer, phrased differently.
            <br /> You can create alternatives to give your virtual human a more
            humanlike experience.
          </Typography>
          <div className={classes.buttonContainer}>
            <SecondaryButton
              onClick={() => {
                setAddDisplay(true);
              }}
              color={"primary"}
              variant={"outlined-inverse"}
            >
              Add alternate
            </SecondaryButton>
          </div>
        </div>
      ) : null}
      <div className={classes.questionsContainer}>
        {answers?.map((item) => {
          const onDelete = () => {
            removeAlternate(item);
          };
          return (
            <EntryDisplay
              text={item}
              color={"purple"}
              onDelete={onDelete}
            ></EntryDisplay>
          );
        })}
      </div>
    </div>
  );
};

export default AlternatesEditor;
