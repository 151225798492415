import {useState, useEffect} from "react";

const getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

const getHeight = () => window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

export const useWindowSize = () => {
    let [size, setSize] = useState({width: getWidth(), height: getHeight()});

    useEffect(() => {
        const resizeListener = () => {
            setSize({width: getWidth(), height: getHeight()});
        };
        window.addEventListener("resize", resizeListener);

        return () => window.removeEventListener("resize", resizeListener);
    }, []);

    return size;
};
