import {gql} from "@apollo/client";

export const GET_ALL_BOTS = gql`
    query getAllBots {
        bots {
            edges {
                node {
                    id
                    nodeId
                    firstName
                    avatar
                    extras
                    entrypoint {
                        name
                        id
                        humanId
                    }
                    phoneSeconds
                    sessionsCount
                    zoomSeconds
                    botsSubscription {
                        planName
                    }
                }
            }
        }
    }
`;
