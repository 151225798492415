import {useMediaQuery, useTheme} from "@material-ui/core";

import React from "react";

export const useScreenSizes = () => {
    const theme = useTheme();

    const isSm = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const isMd = useMediaQuery(theme.breakpoints.between("md", "lg"));
    const isLg = useMediaQuery(theme.breakpoints.between("lg", "xl"));
    const isXl = useMediaQuery(theme.breakpoints.up("xl"));

    const isSmShort = useMediaQuery("(max-height: 400px");
    const isMdShort = useMediaQuery("(max-height: 800px");
    const isLgShort = useMediaQuery("(max-height: 1000px");
    const isXlShort = useMediaQuery("(max-height: 2000px");

    const reply = {
        isSm,
        isMd: isMd && !isSm,
        isLg: isLg && !isMd && !isXl,
        isXl,
        isSmShort,
        isMdShort: isMdShort && !isSmShort,
        isLgShort: isLgShort && !isMdShort,
        isXlShort: isXlShort && !isLgShort
    };

    return reply;
};
