import {
  IconButton,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";

import ChannelsTooltip from "channels/ChannelsTooltip";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import GuestSignInBadge from "theme/components/elements/GuestSignInBadge";
import { UpgradeToEditEmailCTA } from "./UpgradeToEditEmailCTA";
import copy from "clipboard-copy";
import { handleTextFieldChange } from "utils/handleTextFieldChange";
import informationIcon from "./assets/information.svg";
import { useIsGuest } from "utils/hooks/useIsGuest";
import { useNotification } from "utils/hooks/useNotification";

const disabledOpacity = 0.5;

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "left",
      flexDirection: "column",
    },
    explanation: {
      paddingTop: theme.spacing(1),
      opacity: (props: { isGuest: boolean }) => {
        if (props.isGuest) return disabledOpacity;
        return 1;
      },
    },
    emailForm: {
      paddingTop: theme.spacing(2),
      width: "50%",
    },
    endAdornment: {
      color: theme.custom.palette.e.alt,
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(0.2),
    },
    emailFormTitleContainer: {
      display: "flex",
      color: theme.custom.palette.a.main,
      fontWeight: "bold",
    },
    emailFormTitle: {
      opacity: (props: { isGuest: boolean }) => {
        if (props.isGuest) return disabledOpacity;
        return 1;
      },
      pointerEvents: (props: { isGuest: boolean }) => {
        if (props.isGuest) return "none";
        return "auto";
      },
    },
    emailInputForm: {
      opacity: (props: { isGuest: boolean }) => {
        if (props.isGuest) return disabledOpacity;
        return 1;
      },
      display: "flex",
      flexDirection: "column",
    },
    inputStyling: { paddingBottom: theme.spacing(0) },
    iSymbol: {
      color: theme.custom.palette.a.main,
      padding: 0,
      paddingLeft: theme.spacing(0.4),
      paddingBottom: theme.spacing(0.2),
    },
    boldText: {
      fontWeight: "bold",
    },
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
    },
    moreInfo: {
      padding: theme.spacing(1),
    },
    copyEmailIcon: {
      padding: 0,
      paddingBottom: theme.spacing(0.5),
    },
  };
});

const emailReg = /^[a-zA-Z0-9_-]{1,}$/;

const getIsEmailTaken = async (e: string) => Promise.resolve(false);

const BotEmailAddress: React.FC<{
  isEditable?: boolean;
  channelId?: string;
  email: string;
  initialEmail: string;
  botName: string;
  emailSuffix: string;
  setEmail: (s: string) => void;
}> = ({
  email,
  initialEmail,
  emailSuffix,
  setEmail,
  botName,
  channelId,
  isEditable,
}) => {
  const isGuest = useIsGuest();
  const classes = useStyles({ isGuest });
  const addMessage = useNotification();
  const emailFormRef = useRef<any>(null);

  const copyBotEmail = () => {
    copy((email || "") + "@bot.beyondvirtual.ai");
    addMessage("Virtual Human's email copied to clipboard", "success");
  };

  const [error, setError] = useState("");

  useEffect(() => {
    (async () => {
      if (!emailReg.test(email)) {
        setError(
          "Please provide a valid handle (Just the first part of the email address)"
        );
      } else if (email !== initialEmail && (await getIsEmailTaken(email))) {
        setError(
          "Virtual Human email already in use, please choose a unique one"
        );
      } else setError("");
    })();
  }, [email, initialEmail]);

  const getHelperText = () => {
    if (!email)
      return "We need a handle here (Just the first part of the email address)";
    return error;
  };

  return (
    <div className={classes.container}>
      <div className={classes.explanation}>
        <Typography>
          Create an email for <b>{botName}</b> and choose a scene to invite your
          virtual human to a meeting.
        </Typography>
      </div>
      <div className={classes.emailForm}>
        <div className={classes.emailFormTitleContainer}>
          <div className={classes.emailFormTitle}>
            Virtual Human Email address
            <ChannelsTooltip
              isDisabled={isGuest}
              arrowMargin={0}
              description={`Paste this email address in the meeting invite,
                             or add to invite list on your video call`}
            >
              <IconButton className={classes.iSymbol}>
                <img src={informationIcon}></img>
              </IconButton>
            </ChannelsTooltip>
          </div>
          <GuestSignInBadge
            title={"Custom virtual human's Email address"}
            description={`You'll have to sign in in order to invite ${botName} to meetings`}
          />
        </div>
        <div className={classes.emailInputForm}>
          <TextField
            error={!!error}
            helperText={getHelperText()}
            inputRef={emailFormRef}
            disabled={isGuest || !isEditable}
            placeholder={`Enter your virtual human's email`}
            value={email}
            fullWidth={true}
            onBlur={handleTextFieldChange(setEmail)}
            InputProps={{
              className: classes.inputStyling,
              endAdornment: (
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className={classes.endAdornment}>{emailSuffix}</div>
                  <Tooltip title="Copy email" placement="top">
                    <IconButton
                      onClick={copyBotEmail}
                      disabled={isGuest || !!error}
                      className={classes.copyEmailIcon}
                    >
                      <FileCopyOutlinedIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                </div>
              ),
            }}
            onChange={handleTextFieldChange(setEmail)}
          ></TextField>
        </div>
        <UpgradeToEditEmailCTA {...{ isEditable }} />
      </div>
    </div>
  );
};

export default BotEmailAddress;
