export const BuilderFabIcon = () => {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="21" cy="21" r="20" stroke="#2195F3" strokeWidth="2" />
      <path
        d="M24.3335 10.9998L30.9995 10.9998L30.9995 17.6665"
        stroke="#2195F3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.3345 31L31.0005 31L31.0005 24.3333"
        stroke="#2195F3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.667 10.9998L11.001 10.9998L11.001 17.6665"
        stroke="#2195F3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.667 31L11.001 31L11.001 24.3333"
        stroke="#2195F3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
