import {
  VideoWrapper,
  VideoWrapperLayout,
  WebRtcVideo,
  WebRtcWrapper,
} from "ui-kit/components/share/share.styled";

import Loader from "utils/elments/loader/Loader";
import NoConnection from "ui-kit/components/share/NoConnection";
import { useStandaloneVideo } from "./useStandaloneVideo";

export const StandaloneNaked = () => {
  const { botName, error, loading, videoRef } = useStandaloneVideo();

  if (!!error)
    return (
      <WebRtcWrapper>
        <NoConnection error={error} />
      </WebRtcWrapper>
    );

  return (
    <WebRtcWrapper isNaked id="WebRtcWrapper">
      <VideoWrapper isFullScreen={true} id="VideoWrapper">
        <VideoWrapperLayout>
          {loading && <Loader isLarge />}
          <WebRtcVideo playsInline autoPlay loop ref={videoRef} />
        </VideoWrapperLayout>
      </VideoWrapper>
    </WebRtcWrapper>
  );
};
