import React from "react";

const DeleteIcon: React.FC<{ height?: number; width?: number }> = ({
  height = 16,
  width = 16,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0003 22C8.06218 22 5.29976 20.8558 3.22198 18.7781C1.14437 16.7005 0 13.9384 0 11.0004C0 8.06212 1.14439 5.29968 3.22198 3.222C5.29976 1.14426 8.06218 0 11.0003 0C13.9383 0 16.7004 1.14425 18.7782 3.222C20.856 5.29978 22 8.06222 22 11.0004C22 13.9383 20.856 16.7004 18.7782 18.7781C16.7004 20.8558 13.9383 22 11.0003 22ZM8.07066 7.33327C7.88301 7.3333 7.70593 7.41235 7.55856 7.56185C7.21951 7.90084 7.30191 8.33888 7.55856 8.59637L9.97084 11.0078L7.55856 13.4201C7.41286 13.5663 7.33297 13.7514 7.33398 13.9413C7.33498 14.1303 7.41472 14.3123 7.55856 14.4538C7.69771 14.5929 7.88116 14.6699 8.07518 14.6708C8.26988 14.6708 8.45401 14.5937 8.59316 14.4538L11.0048 12.0424L13.4171 14.4538C13.554 14.5915 13.7378 14.6673 13.934 14.6673C14.1303 14.6673 14.3137 14.5915 14.4507 14.4538C14.59 14.3164 14.6667 14.1326 14.6665 13.9361C14.6665 13.7402 14.5898 13.5569 14.4507 13.4201L12.039 11.0078L14.4507 8.59637C14.5885 8.4586 14.6642 8.2746 14.664 8.07825C14.6638 7.88235 14.5881 7.69895 14.4507 7.56185C14.3134 7.42457 14.1296 7.34898 13.9334 7.34898C13.7372 7.34898 13.5537 7.42457 13.4171 7.56185L11.0048 9.97413L8.59316 7.56185C8.44546 7.41444 8.25982 7.33327 8.07066 7.33327Z"
        fill="#F4556F"
      />
    </svg>
  );
};

export default DeleteIcon;
