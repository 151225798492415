import {ProjectsStore, updateSaved} from "../createProjects";
import {action, reaction} from "mobx";

import {AuthStore} from "marketplace-2/store/auth/createAuthStore";
import {GraphStore} from "bots-studio/store/graph/createGraphStore";
import {UndoRedoStore} from "bots-studio/store/undoRedo/createUndoRedo";
import {saveUserProjState} from "../saveUserProjState";

// on relevant changes, sync stores and save data
export const runSyncStoresOnChanges = (
    graph: GraphStore,
    store: ProjectsStore,
    auth: AuthStore,
    undoRedo: UndoRedoStore
) =>
    reaction(
        () => {
            const nodes = Object.values(graph.nodes);
            const edges = Object.values(graph.edges);
            const nodeComponents = nodes.map((n) => n.component);
            const edgesTo = edges.map((e) => ({...e.to}));
            const params = nodes.map((node) => {
                const ep = node.options.extraParams || {};
                return [
                    ...Object.values(node.options.params || {}),
                    ...Object.values(node.options.email || {}),
                    ...Object.keys(ep).map((k) => Object.values(ep[k]))
                ];
            });
            const actions = nodes.map((node) => [
                ...node.actions.map((act) => ({data: act.data, index: act.index, nav: {...act.navigationData}}))
            ]);
            return {
                selected: store.selected,
                project: store.projects[store.selected],
                isLoading: store.isLoading,
                nodes,
                nodeComponents,
                edgesTo,
                edges,
                params,
                actions
            };
        },
        action(({selected, edges, project, isLoading}) => {
            if (
                !auth.isReady ||
                isLoading ||
                !project ||
                !undoRedo.isCanUndo ||
                !selected ||
                edges.some((e) => e.to.position)
            )
                return;
            store.updateFromCanvas();
            store.updateFromGraph();
            selected && updateSaved(store);
            !store.isGuest && saveUserProjState(store);
        })
    );
