import {fetchWrapper} from "data/api/rest/fetchWrapper";

export type ReportType =
    | "bug_report"
    | "feature_request"
    | "support_request"
    | "enterprise_subscription_request"
    | "expert_request"
    | "whatsapp_channel_request";

export const sendBugReport = async (p: {email: string; reportType: ReportType; report: string}): Promise<any> => {
    try {
        const result = await fetchWrapper(`/api/user_feedback`, {
            method: "POST",
            mode: "cors",
            body: JSON.stringify({type: p.reportType, user_email: p.email, text: p.report})
        });
        const reply = await result.json();
        return reply || true;
    } catch (e) {
        console.error(e);
        return false;
    }
};
