import { Box as MUIBox, BoxProps } from "@material-ui/core";

type CustomBoxProps = {
  boxSize?: string | number;
}

export const Box: React.FC<BoxProps & CustomBoxProps> = ({ boxSize, ...props}) => (
<MUIBox
  {...{
    width: boxSize,
    height: boxSize,
    ...props,
  }}
/>
)