import {GlueStateV2, ProjectNameType} from "../types";
import {action, observable} from "mobx";
import {gluev2HumanId, studioVersion, userProjectsKeys} from "dangerous/staticStrings";

import {AnalyticType} from "analytics/AnalyticType";
import {AuthStore} from "marketplace-2/store/auth/createAuthStore";
import {CanvasStore} from "../canvas/createCanvasStore";
import {Errors} from "./errors";
import {GlueConfigV2} from "marketplace-2/store/components/types";
import {GraphStore} from "../graph/createGraphStore";
import {MyComponentsStore} from "marketplace-2/store/components/MyComponentsStore";
import {UndoRedoStore} from "../undoRedo/createUndoRedo";
import {analytics} from "analytics/analytics";
import {checkIfComponentExistsStatus} from "./checkIfComponentExistsStatus";
import {cloneDeep} from "lodash";
import {createComponentCopy} from "data/api/rest/requests/components/createComponentCopy";
import {createGuestProject} from "./functions/createGuestProject";
import {initializeAuthenticated} from "./initializeAuthenticated";
import {runInitAuthenticatedFlow} from "./reactions/runInitiAuthenticatedFlow";
import {runInitGuestFlow} from "./reactions/runInitGuestFlow";
import {runSetLoadingOnAuth} from "./reactions/runSetLoadingOnAuth";
import {runSwitchSelectedData} from "./reactions/runSwitchSelectedData";
import {runSyncPublished} from "./reactions/runSyncPublished";
import {runSyncStoresOnChanges} from "./reactions/runSyncStoresOnChanges";
import {runUpdateCompiledOnSelected} from "./reactions/runUpdateCompiledOnSelected";
import {runUpdateProjectKeysOnSelected} from "./reactions/runUpdateProjectKeysOnSelected";
import {saveGuestProjState} from "./saveGuestProjState";
import {saveUserProjState} from "./saveUserProjState";
import {setConfig} from "data/api/rest/requests/setConfig";
import {setUserState} from "data/api/rest/requests/setUserState";
import {toConfig} from "bots-studio/schema/toConfig";

export type ProjectsList = {
    lastOpened: string;
    projects: GlueStateV2[];
};

export type BotData = {
    name: string;
    entrypoint: string;
    gid: string;
};

export const updateSaved = action((store: ProjectsStore) => {
    const proj = store.projects[store.selected];
    if (!proj) return;
    store.savedGlues[store.selected] = toConfig(proj);
});

export let lastOpened = "";

export const updateUserProjectsKeysState = async (store: ProjectsStore, target: string = "") => {
    lastOpened = target || store.selected;
    const projects = Object.keys(store.projToComponentMap).map((id) => ({
        id,
        componentId: store.projToComponentMap[id].id,
        name: store.projToComponentMap[id]?.name || id
    }));
    await setUserState(userProjectsKeys, {
        lastOpened,
        projects
    });
};

export const createProjects = (
    graph: GraphStore,
    auth: AuthStore,
    componentsStore: MyComponentsStore,
    undoRedo: UndoRedoStore,
    canvas: CanvasStore
) => {
    const store = observable({
        error: undefined as Errors | undefined,
        isLoading: true,
        isInitializing: true,
        isSaving: false,
        isGuest: false,
        isPublshSynced: false,
        forcedChannel: "",
        activeInspectorTab: "Inspector",
        selected: "",
        projToComponentMap: {} as {[key: string]: ProjectNameType},
        projects: {} as {[key: string]: GlueStateV2},
        compiledGlues: {} as {[key: string]: GlueConfigV2},
        savedGlues: {} as {[key: string]: GlueConfigV2},
        creation: {
            template: {} as GlueStateV2,
            isAddingTemplate: false,
            isFromBuilder: false,
            ownerBot: "",
            isCouldNotCreateProject: false
        },
        createGuestProject: () => createGuestProject({isReady: auth.isReady, store}),
        updateGuestState: (data?: {forcedChannel?: string; projState?: GlueStateV2}) => {
            saveGuestProjState(store, data);
        },
        publish: action(async (projectId?: string) => {
            const target = projectId || store.selected;
            if (!target) return false;
            const proj = store.projects[target];
            const projName = proj.name;
            if (!proj) return false;

            if (!proj.componentId) {
                if (!componentsStore.allOrgsComponents.find((c) => c.human_id === target)) {
                    const {isOk, reason} = await createComponentCopy(gluev2HumanId, target, projName);
                    if (!isOk && !checkIfComponentExistsStatus(reason as any)) return false;
                }
                proj.componentId = target;
                store.projToComponentMap[target] = {id: target, name: projName} || "";
                store.updateFromGraph();
            }
            saveUserProjState(store, target);
            updateUserProjectsKeysState(store, target);

            const glue_v2 = toConfig(proj);
            const isOk = await setConfig(target, {
                config: {blueprint_id: gluev2HumanId, studio_version: studioVersion, glue_v2: {...glue_v2}}
            });

            if (isOk) {
                const proj = store.projects[target];
                if (!proj) return;
                const conf = toConfig(proj);
                store.compiledGlues[target] = {...conf};
                store.savedGlues[target] = {...conf};
            }
            return isOk;
        }),

        updateFromGraph: action(async () => {
            const proj = store.projects[store.selected];
            if (!proj) return;
            proj.nodes = observable({...graph.nodes});
            proj.edges = observable({...graph.edges});
            Object.values(proj.nodes).forEach((p) => (p.selected = false));
        }),

        updateFromCanvas: action(async () => {
            const proj = store.projects[store.selected];
            if (!proj) return;
            proj.dimensions = {...canvas.dimensions};
            proj.version = canvas.version;
        }),
        add: action(async (template: GlueStateV2) => {
            store.isLoading = true;
            const created = cloneDeep(template);
            store.projects[created.id] = created;
            store.projToComponentMap[created.id] = {id: created.componentId || created.id, name: created.name};
            await updateUserProjectsKeysState(store);
            analytics({type: AnalyticType.project_created, data: {project: created.id}});
            await store.publish(created.id);
            store.selected = created.id;
            store.isLoading = false;
        }),
        delete: action(async (projectId: string) => {
            store.isLoading = true;

            const proj = store.projects[projectId];

            delete store.projToComponentMap[projectId];
            delete store.projects[projectId];

            if (!proj) {
                console.error(`Tried to delete nonexistent project ${projectId}`);
                updateUserProjectsKeysState(store);
                store.isLoading = false;
                return;
            }

            if (proj.componentId) {
                componentsStore.delete.id.set(proj.componentId);
                await componentsStore.delete.onConfirm();
            }

            await updateUserProjectsKeysState(store);

            analytics({type: AnalyticType.project_deleted, data: {project: projectId}});

            store.isLoading = false;

            if (store.selected === projectId) {
                store.selected = Object.keys(store.projToComponentMap)?.[0] || "";
            }
        }),
        initialize: () => {
            componentsStore.isInitialized.get() && initializeAuthenticated(store);
        }
    });

    // on proj selected, get project data and populate stores with it
    runSwitchSelectedData(auth, store, graph, canvas);

    // update project keys with lastOpened
    runUpdateProjectKeysOnSelected(store);

    // fetch project data and update state for published status detection. TODO: see if is reduntant, remove if yes
    runUpdateCompiledOnSelected(store);

    // initialize guest user flow
    runInitGuestFlow(auth, store, canvas);

    // initialize authenticated user flow
    runInitAuthenticatedFlow(componentsStore, store);

    // force isLoading on auth -- TODO: see if this is still needed, remove if not
    runSetLoadingOnAuth(auth, store);

    // on relevant changes, sync stores and save data
    runSyncStoresOnChanges(graph, store, auth, undoRedo);

    // create a project for guest user
    // runCreateGuestProject(auth, store);

    // update publish sync state
    runSyncPublished(store);

    return store;
};

export type ProjectsStore = ReturnType<typeof createProjects>;
