import {session_id} from "analytics/analytics";
import {GUEST_DATA_KEY} from "dangerous/staticStrings";
import {action, toJS} from "mobx";
import {GlueStateV2} from "../types";
import {ProjectsStore} from "./createProjects";

export const saveGuestProjState = action(
    async (store: ProjectsStore, data: {projState?: GlueStateV2; [key: string]: any} = {}) => {
        try {
            store.isSaving = true;
            const proj = data.projState || store.projects[store.selected];
            if (proj) {
                const dataToSave = {...{session_id, proj: toJS(proj), ...data, projState: undefined}};
                Object.values(dataToSave.proj.nodes).forEach((node) => (node.inspected = false));
                localStorage.setItem(GUEST_DATA_KEY, JSON.stringify(dataToSave));
            }
            action(() => (store.isSaving = false))();
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }
);
