import {GUEST_DATA_KEY, projListKey, userProjectsKeys} from "dangerous/staticStrings";
import {ProjectsList, ProjectsStore} from "./createProjects";
import {action, observable} from "mobx";

import {deleteUserState} from "data/api/rest/requests/deleteUserState";
import {getUserState} from "data/api/rest/requests/getUserState";
import {setUserState} from "data/api/rest/requests/setUserState";

export type SavedProjectKeys = {lastOpened: string; projects: ProjectMetadata[]};
export type ProjectMetadata = {id: string; componentId: string; name: string};

export const initializeAuthenticated = action(async (store: ProjectsStore) => {
    store.isLoading = true;
    store.isInitializing = true;
    const dataFromProjKeys = (await getUserState(userProjectsKeys)) as SavedProjectKeys;
    const dataFromProjListKey = dataFromProjKeys ? null : ((await getUserState(projListKey)) as ProjectsList);

    const projects = observable.array([] as ProjectMetadata[]);
    let lastOpened = "";

    // old data found, need to update new data and delete old key
    if (dataFromProjListKey) {
        lastOpened = dataFromProjListKey.lastOpened;
        action(() =>
            dataFromProjListKey.projects.map((proj) => {
                store.projToComponentMap[proj.id] = {id: proj.componentId || "", name: proj.name || ""};
            })
        )();
        await deleteUserState(projListKey);
        await setUserState(userProjectsKeys, {
            lastOpened,
            projects: Object.keys(store.projToComponentMap).map((key) => ({
                id: store.projToComponentMap[key].id,
                name: store.projToComponentMap[key].name
            }))
        });
    }

    if (dataFromProjKeys) {
        action(() => {
            dataFromProjKeys.projects.forEach(
                (proj) => (store.projToComponentMap[proj.id] = {id: proj.componentId, name: proj.name})
            );
            lastOpened = dataFromProjKeys.lastOpened;
            projects.replace(dataFromProjKeys.projects);
        })();
    }

    const guestProjString = localStorage.getItem(GUEST_DATA_KEY);
    if (guestProjString) {
        try {
            action(async () => {
                const d = JSON.parse(guestProjString);
                store.forcedChannel = d.forcedChannel || "";
                localStorage.removeItem(GUEST_DATA_KEY);
                const p = d.proj;
                lastOpened = p?.id || "";

                if (p) {
                    if (!store.projects[p.id]) {
                        store.projects[p.id] = observable(p);
                    }
                    store.projToComponentMap[p.id] = {id: p.componentId, name: p.name};
                }

                await store.publish(lastOpened);
            })();
        } catch (e) {
            console.error("Could not process saved guest glue");
        }
    } else {
        store.forcedChannel = "";
    }

    store.error = undefined;
    store.isLoading = false;
    store.isInitializing = false;
    if (!projects.length) return;
});
