import {Blueprint} from "marketplace-2/store/components/types";
import {fetchWrapper} from "data/api/rest/fetchWrapper";
import {makeErrorBlueprint} from "./makeErrorBlueprint";

export const getBlueprint = async (id: string) => {
    try {
        const result = await fetchWrapper(`/v2/blueprint/${id}`, {
            method: "GET",
            headers: {Authorization: (globalThis as {authentication?: string}).authentication || ""}
        });
        if (!result.ok) throw new Error(result.statusText);
        const reply = (await result.json()) as Blueprint;
        return reply;
    } catch (e) {
        console.error(e);
        return makeErrorBlueprint();
    }
};
