import {Checkbox, DialogContentText, FormControl, FormControlLabel, FormHelperText, Tooltip} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import {Autocomplete} from "@material-ui/lab";
import React, {useState} from "react";
import {Redirect} from "react-router-dom";
import {handleTextFieldChange} from "../../../../utils/handleTextFieldChange";
import {useRootStore} from "../../../store/useRootStore";

export const CreateComponentCopyDialog = () => {
    const {
        redirect,
        isOpen,
        isInStudio,
        close,
        isCanSave,
        setFromComponent,
        fromComponent,
        validBlueprints,
        humanIdError,
        toComponent,
        setToComponent,
        save,
        name,
        setName,
        isShowAfterCreateAllowed,
        isShowAfterCreateOn,
        toggleIsShowAfterCreateOn
    } = useRootStore((store) => {
        const c = store.myComponents.createComponent.copy;
        const redirect = c.isShowAfterCreate.isRedirecting ? "/my-components" : "";
        return {
            redirect,
            isInStudio: c.isInStudio,
            isOpen: c.isOpen.get(),
            isShowAfterCreateAllowed: c.isShowAfterCreate.isAllowed,
            isShowAfterCreateOn: c.isShowAfterCreate.isOn,
            toggleIsShowAfterCreateOn: () => (c.isShowAfterCreate.isOn = !c.isShowAfterCreate.isOn),
            validBlueprints: store.myComponents.createComponent.blueprintsList.map((c) => c),
            humanIdError: c.humanIdError.get(),
            toComponent: c.human_id.get(),
            name: c.custom_name.get(),
            setToComponent: (v: string) => c.human_id.set(v),
            setName: (v: string) => c.custom_name.set(v),
            close: () => c.isOpen.set(false),
            isCanSave: c.isCanSave,
            setFromComponent: (v: string) => c.fromComponent.set(v),
            fromComponent: c.fromComponent.get(),
            save: () => c.create()
        };
    });

    const [isValidName, setisValidName] = useState(false);

    const handleFromSelected = (event: React.ChangeEvent<{}>, value: string | null) => {
        setFromComponent(value || "");
    };

    const update = (value: string) => {
        setToComponent(value);
    };

    const updateName = (value: string) => {
        if (value == "") setisValidName(false);
        else setisValidName(true);
        setName(value);
    };

    const confirm = () => {
        save();
    };

    if (redirect) return <Redirect to={redirect} />;

    return (
        <Dialog open={isOpen} onClose={close}>
            <DialogTitle>Copy A Component From The Hub</DialogTitle>
            <DialogContent>
                {isInStudio ? (
                    <DialogContentText>{`This will create a copy of ${fromComponent} in My Components, under the name ${toComponent}`}</DialogContentText>
                ) : null}
                <FormControl fullWidth error={!fromComponent}>
                    <Autocomplete
                        value={fromComponent}
                        onChange={handleFromSelected}
                        options={validBlueprints}
                        disabled={isInStudio}
                        renderInput={(params) => <TextField {...params} label="Copy from" />}
                    />
                    <FormHelperText>{fromComponent ? "" : "Select a component to copy"}</FormHelperText>
                </FormControl>
                <TextField
                    error={!isValidName}
                    onChange={handleTextFieldChange(updateName)}
                    helperText={isValidName ? "" : "Provide component a name"}
                    value={name}
                    margin="dense"
                    label={"NAME"}
                    fullWidth
                />
                <TextField
                    error={!!humanIdError}
                    onChange={handleTextFieldChange(update)}
                    value={toComponent}
                    margin="dense"
                    label={"ID"}
                    helperText={humanIdError}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                {!isInStudio && isShowAfterCreateAllowed ? (
                    <Tooltip title={"Whether to isolate and show this component in My Components after creation"}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={isShowAfterCreateOn}
                                    onChange={toggleIsShowAfterCreateOn}
                                />
                            }
                            label="Show alone"
                        />
                    </Tooltip>
                ) : null}
                <Button onClick={close} color="primary">
                    Cancel
                </Button>
                <Button disabled={!isCanSave || !isValidName} onClick={confirm} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
