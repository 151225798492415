import {makeStyles, Typography} from "@material-ui/core";
import {useRootStore} from "marketplace-2/store/useRootStore";
import {action} from "mobx";
import React, {useState} from "react";
import {SecondaryButton} from "theme/components/elements/buttons/SecondaryButton";
import AddQuestion from "./AddQuestion";
import EntryDisplay from "./EntryDisplay";

const useStyles = makeStyles((theme) => {
    return {
        container: {
            minHeight: theme.spacing(30),
            paddingTop: theme.spacing(2),
            height: "100%"
        },
        noQuestionsDisplay: {
            paddingTop: theme.spacing(3),
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between"
        },
        buttonContainer: {
            paddingTop: theme.spacing(3)
        },
        questionsContainer: {
            paddingTop: theme.spacing(1),
            overflow: "auto",
            maxHeight: theme.spacing(40)
        }
    };
});
const QuestionExamplesEditor: React.FC = () => {
    const classes = useStyles();
    const [addDisplay, setAddDisplay] = useState(false);

    const {questions, addQuestion, removeQuestion, updateBlueprint} = useRootStore((store) => {
        const questions = store.myComponents.editComponent.editedQnAEntry.questions;
        return {
            addQuestion: action((question: string) => {
                store.myComponents.editComponent.editedQnAEntry.questions = [question, ...questions];
            }),
            removeQuestion: action((item: string) => {
                if(questions.indexOf(item) !== -1) return
                const index = questions.indexOf(item);
                store.myComponents.editComponent.editedQnAEntry.questions = [
                    ...questions.slice(0, index),
                    ...questions.slice(index + 1)
                ];
                updateBlueprint();
            }),
            updateBlueprint: store.myComponents.editComponent.updateBlueprint,
            questions: questions
        };
    });
    const handleSubmitAnswer = (v: string) => {
        if (v !== null && v !== undefined && v.length > 0) {
            addQuestion(v);
            updateBlueprint();
        }
    };

    return (
        <div className={classes.container}>
            {addDisplay || questions?.length ? <AddQuestion onAdd={handleSubmitAnswer} /> : null}
            {!questions?.length && !addDisplay ? (
                <div className={classes.noQuestionsDisplay}>
                    <Typography align={"center"}>
                        You can type in an example of what users will ask to get the answer you <br /> provided up
                        here☝🏼 (It's optiona l. We can work our magic ourseleves)
                    </Typography>
                    <div className={classes.buttonContainer}>
                        <SecondaryButton
                            onClick={() => {
                                setAddDisplay(true);
                            }}
                            color={"primary"}
                            variant={"outlined-inverse"}>
                            Add a question
                        </SecondaryButton>
                    </div>
                </div>
            ) : null}
            <div className={classes.questionsContainer}>
                {questions?.map((item) => {
                    const onDelete = () => {
                        removeQuestion(item);
                    };
                    return <EntryDisplay text={item} color={"blue"} onDelete={onDelete}></EntryDisplay>;
                })}
            </div>
        </div>
    );
};

export default QuestionExamplesEditor;
