import {
  getUserData,
  getUserData_user,
} from "../queries/__generated__/getUserData";

import { GET_USER_DATA } from "../queries/getUserData";
import { getOwnerId } from "data/utils/getOwnerId";
import { useQuery } from "@apollo/client";

export const useUserData = () => {
  const { loading, error, data } = useQuery<getUserData>(GET_USER_DATA, {
    variables: { id: getOwnerId() },
    errorPolicy: "all",
  });

  return (
    data?.user ||
    ({
      email: "",
      firstName: "",
      id: -1,
      lastName: "",
      name: "",
    } as getUserData_user)
  );
};
