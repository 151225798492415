import {makeStyles, TextField, Typography} from "@material-ui/core";
import React, {useRef} from "react";
import {handleTextFieldChange} from "utils/handleTextFieldChange";
import informationSymbol from "./assets/informationIcon.svg";
const useStyles = makeStyles((theme) => {
    return {
        container: {
            display: "flex",
            flexDirection: "column",
            width: "100%"
        },
        label: {
            color: theme.custom.palette.a.main
        },
        labelContainer: {
            display: "flex",
            alignItems: "center"
        },
        informationIcon: {
            width: "fit-content",
            height: "fit-content",
            borderRadius: theme.spacing(5)
        }
    };
});
{
    /*explanation - for future use - displayed on hover*/
}
const CustomInputField: React.FC<{
    onChange?: (val: string) => void;
    label?: string;
    explanation?: string;
    placeHolder?: string;
    helperText?: string;
}> = ({onChange, label, explanation, placeHolder, helperText}) => {
    const classes = useStyles();
    const ref = useRef<HTMLInputElement>(null);

    const getHelperText = () => {
        if (!helperText) return;
        if (ref.current !== null && !ref?.current!.value) return helperText;
        return " ";
    };

    return (
        <div className={classes.container}>
            <div className={classes.labelContainer}>
                <Typography className={classes.label} variant={"body2"}>
                    {label}
                </Typography>
                &nbsp;
                {explanation ? (
                    <div className={classes.informationIcon}>
                        <img src={informationSymbol}></img>
                    </div>
                ) : null}
            </div>
            <TextField
                inputRef={ref}
                helperText={getHelperText()}
                placeholder={placeHolder}
                onChange={handleTextFieldChange((val: string) => {
                    onChange && onChange(val);
                })}></TextField>
        </div>
    );
};

export default CustomInputField;
