import { Divider, IconButton, Typography, useTheme } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";

import CachedIcon from "@material-ui/icons/Cached";
import CountryList from "utils/elments/phone/CountryList";
import { DividerElement } from "utils/elments/DividerElement";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import Loader from "utils/elments/loader/Loader";
import { PhoneData } from "../types";
import { SecondaryButton } from "theme/components/elements/buttons/SecondaryButton";
import copy from "clipboard-copy";
import { getAvailableNumbers } from "./functions/getAvailableNumbers";
import { getPhoneCountryCodes } from "./functions/getPhoneCountryCodes";
import { randomInt } from "utils/randomInt";
import { useNotification } from "utils/hooks/useNotification";

export const PhoneNumberSelect: React.FC<{
  initialNumber: string;
  isDisabled?: boolean;
  onSelected: (number: string) => void;
  data: PhoneData | undefined;
}> = ({ onSelected, initialNumber, data, isDisabled = false }) => {
  const theme = useTheme();
  const [numbers, setNumbers] = useState<string[]>([]);
  const [countries, setCountries] = useState<string[]>([]);
  const [countryId, setCountryId] = useState("US");
  const [selectedNumber, setSelectedNumber] = useState(
    data?.phone_number || ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const notify = useNotification();

  const regenerate = useCallback(() => {
    numbers.length &&
      setSelectedNumber(numbers[randomInt(0, numbers.length - 1)]);
  }, [numbers, numbers.length]);

  useEffect(() => {
    setIsLoading(true);
    getPhoneCountryCodes().then((codes) => {
      setIsLoading(false);
      setCountries([...codes.values()]);
    });
  }, []);

  useEffect(() => {
    data && setSelectedNumber(data.phone_number);
  }, [data]);

  useEffect(() => {
    countries.length && !countryId && setCountryId("US");
  }, [countries, countryId]);

  useEffect(() => {
    if (!countryId) return;
    setIsLoading(true);
    getAvailableNumbers(countryId).then((numbers) => {
      setNumbers(numbers);
      setIsLoading(false);
    });
  }, [countries, countryId]);

  useEffect(() => {
    if (initialNumber) return;
    regenerate();
  }, [regenerate, initialNumber]);

  useEffect(() => {
    selectedNumber && onSelected(selectedNumber);
  }, [selectedNumber]);

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        alignItems: "center",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100%",
          alignItems: "center",
          padding: theme.spacing(1),
          borderRadius: theme.spacing(3),
          background: theme.custom.palette.d.secondary,
        }}
      >
        <CountryList
          {...{
            countryId: countryId,
            setCountryId,
            filter: countries,
            disabled: isDisabled || !!initialNumber,
          }}
        />
        <Divider variant="fullWidth" orientation="vertical" />
        <DividerElement w={1} />
        {isLoading ? (
          <div style={{ width: "140px", height: "40px" }}>
            <Loader isLarge contained />
          </div>
        ) : (
          <Typography style={{ opacity: 0.6 }}>{selectedNumber}</Typography>
        )}
        <DividerElement w={1} />
        <Divider variant="fullWidth" orientation="vertical" />
        <DividerElement w={1} />
        <IconButton
          disabled={!selectedNumber}
          style={{
            color: !selectedNumber ? "" : theme.custom.palette.a.main,
            padding: theme.spacing(1),
          }}
          size="small"
          onClick={() => {
            copy(selectedNumber);
            notify("Number copied");
          }}
        >
          <FileCopyOutlinedIcon />
        </IconButton>
      </div>
      {!initialNumber && (
        <SecondaryButton
          disabled={isDisabled}
          onClick={regenerate}
          icon={<CachedIcon />}
          variant="text"
          color="primary"
        >
          Regenerate
        </SecondaryButton>
      )}
    </div>
  );
};
