import {BotsStudioStore} from "../botsStudioStore";
import {ProjectsStore} from "bots-studio/store/projects/createProjects";
import {reaction} from "mobx";

export const runUpdateProjectsFromGraph = (store: BotsStudioStore, projects: ProjectsStore) =>
    reaction(
        () => {
            return {
                stackLength: store.undoRedo.stack.length
            };
        },
        ({stackLength}) => {
            stackLength && projects.updateFromGraph();
        }
    );
