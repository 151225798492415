import styled from "styled-components";

export const SelectWrapper = styled.div``;

export const VoiceSelectWrapper = styled.div`
  display: flex;
  background-color: hsl(0, 0%, 100%);
  border-radius: 30px;
  .lng-select {
    max-width: 70px;
    .lng-select__control {
      border: none;
      border-radius: 0;
      background: none;
      box-shadow: none;
    }
    .lng-select__indicators {
      position: relative;
      .lng-select__indicator-separator {
        position: absolute;
        height: 22px;
        right: 0;
      }
      .lng-select__indicator {
        padding-left: 0;
      }
    }
    .lng-select__menu {
      border-radius: 20px;
      overflow: hidden;
      width: 417px;
      left: 0;
    }
  }
  .voice-select {
    flex: 1;
    .voice-select__control {
      border: none;
      border-radius: 0;
      background: none;
      box-shadow: none;
    }
    .voice-select__menu {
      border-radius: 20px;
      overflow: hidden;
      width: 417px;
      right: 0;
    }
  }
`;
