import {makeStyles, Typography} from "@material-ui/core";
import React from "react";
import VideocamIcon from "@material-ui/icons/Videocam";

const useStyles = makeStyles((theme) => {
    return {
        container: {
            display: "flex",
            alignItems: "center"
        },
        icon: {
            color: theme.custom.palette.a.main
        }
    };
});
const ZoomIcon = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <span>&nbsp;</span>
            <VideocamIcon className={classes.icon} />
            <span>&nbsp;</span>
            <Typography>Zoom</Typography>
        </div>
    );
};

export default ZoomIcon;
