import {IObservableValue, action, autorun, observable, toJS} from "mobx";

import {ContainerStore} from "../container/ContainerStore";
import {createComponentFromDialogflow} from "data/api/rest/requests/components/createComponentFromDialogflow";
import {getIsHumanIdAvailable} from "data/api/rest/requests/components/getIsHumanIdAvailable";
import {humanReadableId} from "utils/humanReadableId";

export const createOptionalDfComponentParams = () =>
    observable({
        description: "",
        name: "",
        goal: "",
        image_url: "",
        categories: [] as string[],
        input_language: "en",
        output_language: "en"
    });

export const createObligatoryDfComponentParams = () =>
    observable({
        platform_connection_info: "",
        human_id: ""
    });

export const createCreateFromDialogflow = (
    selectedHandle: IObservableValue<string>,
    container: ContainerStore,
    initialize: (id: string) => Promise<void>
) => {
    const fromDf = observable({
        humanIdError: observable.box(""),
        agentFileError: observable.box(""),
        isOpen: observable.box(false),
        obligatory: createObligatoryDfComponentParams(),
        optional: createOptionalDfComponentParams(),
        get isCanSave() {
            return !this.humanIdError.get() && !!this.obligatory.human_id && !!this.obligatory.platform_connection_info;
        },
        create: action(async () => {
            const isOk = await createComponentFromDialogflow({
                ...toJS(fromDf.obligatory),
                ...toJS(fromDf.optional),
                component_states_mapping: {}
            });
            container.messages.addMessage(
                isOk ? "Component created" : "Could not create component",
                isOk ? "success" : "error"
            );
            if (!isOk) return false;
            fromDf.obligatory = createObligatoryDfComponentParams();
            fromDf.optional = createOptionalDfComponentParams();
            fromDf.isOpen.set(false);
            await initialize(selectedHandle.get());
            return true;
        })
    });

    autorun(async () => {
        const id = fromDf.obligatory.human_id;
        if (!id) {
            fromDf.humanIdError.set("Insert a unique ID");
            return;
        }
        fromDf.humanIdError.set(await getIsHumanIdAvailable(id));
    });

    autorun(async () => {
        const id = fromDf.obligatory.platform_connection_info;
        if (!id) {
            fromDf.agentFileError.set("Select a Dialogflow service-account JSON file");
            return;
        }
        fromDf.agentFileError.set("");
    });

    return fromDf;
};

export type ObligatoryParamsCreateComponentDialogflow = ReturnType<typeof createObligatoryDfComponentParams>;
export type OptionalParamsCreateComponentDialogflow = ReturnType<typeof createOptionalDfComponentParams>;
export type CreateFromDialogflowParams = ObligatoryParamsCreateComponentDialogflow &
    OptionalParamsCreateComponentDialogflow;
