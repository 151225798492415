import { ENVIRONMENT } from "const";
import {useEffect} from "react";

export const RedirectToMarketing = () => {
    useEffect(() => {
        if (ENVIRONMENT.ABOUT_URL) {
            window.location.href = ENVIRONMENT.ABOUT_URL;
        }
    }, []);
    return null;
};
