import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ChannelType, FacebookData } from "../types";
import FacebookLogin, { ReactFacebookLoginProps } from "react-facebook-login";
import React, { useEffect, useState } from "react";

import { ActiveIndicator } from "utils/elments/ActiveIndicator";
import { CHANNEL_NAME } from "dangerous/staticStrings";
import { ChannelDialogParams } from "channels/channelsMap";
import HowTo from "./HowTo";
import Loader from "utils/elments/loader/Loader";
import { SyncUserPages } from "channels/facebook/comm/syncFacebookUserPages";
import { fetchPages } from "channels/facebook/comm/getFacebookPages";
import { useChannelFunctions } from "data/api/gql/hooks/useChannelFunctions";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginTop: theme.spacing(4),
      position: "relative",
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    body: {
      marginTop: theme.spacing(3),
      display: "flex",
      height: "50%",
    },
    explanation: { width: "50%" },
    buttonContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    pagesDisplay: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "space-between",
    },
    listTextItem: {
      padding: 10,
    },
  };
});

const defaultData = {
  userID: "",
  accessToken: "",
  name: "",
};

type FacebookAuthData = typeof defaultData;

type Page = {
  id: string;
  name: string;
  picture: any;
  access_token: string;
};

const facebookSettings = {
  appId: "1596271860507432",
  autoLoad: false,
  fields: "name,email,picture",
  scope:
    "pages_show_list,pages_messaging,pages_messaging_subscriptions,pages_manage_metadata",
  size: "small",
} as ReactFacebookLoginProps;

export const FacebookBody: React.FC<ChannelDialogParams> = ({
  bot,
  botId,
  botName,
  botChannels,
  close,
}) => {
  const classes = useStyles();

  const channel = botChannels.find(
    (e) => e.jsonConfig.channel_name === ChannelType.facebook
  );
  const channelId = String(channel?.id);
  const isConnected = channel?.id !== undefined;

  const { create, loading } = useChannelFunctions({ botId });

  const [facebookAuthData, setFacebookAuthData] = useState<FacebookAuthData>({
    userID: "",
    accessToken: "",
    name: "",
  } as FacebookAuthData);
  const [facebookPages, setFacebookPages] = useState<Page[]>([]);

  const [isPagesLoaded, setIsPagesLoaded] = useState(false);

  async function fetchUsersFacebookPages() {
    const pages = (await fetchPages()) as Page[];
    setFacebookPages(pages);
  }

  useEffect(() => {
    setIsPagesLoaded(false);
    fetchUsersFacebookPages();
    setIsPagesLoaded(true);
  }, []);

  const handleFacebookReponse = (response: any) => {
    setFacebookAuthData(response);
  };

  useEffect(() => {
    (async function updatePages() {
      if (facebookAuthData["userID"] && facebookAuthData["accessToken"]) {
        const pages = await SyncUserPages(
          facebookAuthData["userID"],
          facebookAuthData["accessToken"]
        );
        setFacebookPages(pages);
      }
    })();
  }, [facebookAuthData]);

  const handleConnect = async (
    pageId: string,
    pageAccessToken: string,
    pagePictureUrl: string,
    pageName: string
  ) => {
    const fbData = {
      page_id: pageId,
      page_auth_token: pageAccessToken,
      component_id: bot?.entrypoint?.humanId || "",
      page_picture_url: pagePictureUrl,
      page_name: pageName,
    } as FacebookData;

    create({
      variables: {
        channelName: CHANNEL_NAME.FACEBOOK,
        botId: parseInt(botId),
        jsonConfig: { channel_name: ChannelType.facebook, ...fbData },
      },
    });
  };

  const facebookLoginButton = (
    <FacebookLogin
      {...facebookSettings}
      textButton={
        !channelId && facebookPages?.length
          ? "Update Facebook Permissions"
          : "Continue With Facebook"
      }
      callback={handleFacebookReponse}
    />
  );

  if (!isPagesLoaded) return <Loader title="Fetching pages..." />;

  return (
    <div className={classes.container}>
      <ActiveIndicator isActive={isConnected} text={"Connected"} />
      {!facebookPages?.length ? (
        <>
          <div className={classes.body}>
            <div className={classes.explanation}>
              <Typography>Allow us to use Facebook permissions</Typography>
            </div>
            <div className={classes.buttonContainer}>{facebookLoginButton}</div>
          </div>
          <HowTo />
        </>
      ) : (
        <div className={classes.pagesDisplay}>
          <div>
            <Typography style={{ paddingTop: 16 }}>
              Publish <b>{botName}</b> to your page. This will enable your
              audience to chat with your virtual human easily on Facebook.
            </Typography>
            <Typography
              variant={"h6"}
              style={{ paddingTop: 16, paddingBottom: 16 }}
            >
              Your pages
            </Typography>
            <List>
              {facebookPages?.map((page: Page) => (
                <div>
                  <ListItem key={page.id}>
                    <ListItemAvatar>
                      <img src={page.picture["data"]["url"]} alt="new" />
                    </ListItemAvatar>
                    <ListItemText className={classes.listTextItem}>
                      {page.id}
                    </ListItemText>
                    <ListItemText className={classes.listTextItem}>
                      {page.name}
                    </ListItemText>
                    <Button
                      color="primary"
                      onClick={() =>
                        handleConnect(
                          page.id,
                          page.access_token,
                          page.picture["data"]["url"],
                          page.name
                        )
                      }
                    >
                      Connect
                    </Button>
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </div>
              ))}
            </List>
          </div>

          <div className={classes.buttonContainer}>{facebookLoginButton}</div>
        </div>
      )}
    </div>
  );
};
