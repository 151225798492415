import { EmbeddedData } from "../types";

export enum EmbeddableSettinType {
  "number" = "number",
  "string" = "string",
  "boolean" = "boolean",
}

export const keyToDescription = new Map<
  keyof EmbeddedData,
  {
    type: EmbeddableSettinType;
    title: string;
    default: string | number | boolean;
  }
>([
  [
    "bot_name",
    {
      title: "The name of your virtual human",
      type: EmbeddableSettinType.string,
      default: "",
    },
  ],
  [
    "fab_right",
    {
      title: "Distance of fab from right edge (in pixels)",
      type: EmbeddableSettinType.number,
      default: 20,
    },
  ],
  [
    "fab_bottom",
    {
      title: "Distance of fab from bottom edge (in pixels)",
      type: EmbeddableSettinType.number,
      default: 20,
    },
  ],
  [
    "bot_greeting",
    {
      title: "The first thing the virtual human says before user input",
      type: EmbeddableSettinType.string,
      default: "Type anything to get started",
    },
  ],
  [
    "source_language_code",
    {
      title: "Conversation language (ISO language code)",
      type: EmbeddableSettinType.string,
      default: "en_us",
    },
  ],
  [
    "is_fabless",
    {
      title: "Whether chat window is permanently open",
      type: EmbeddableSettinType.boolean,
      default: false,
    },
  ],
  [
    "is_rtl",
    {
      title: "Whether text direction is right-to-left",
      type: EmbeddableSettinType.boolean,
      default: false,
    },
  ],
  [
    "is_open_on_start",
    {
      title: "Whether chat window starts open",
      type: EmbeddableSettinType.boolean,
      default: true,
    },
  ],
  [
    "height",
    {
      title: "Chat window height (in pixels)",
      type: EmbeddableSettinType.number,
      default: 500,
    },
  ],
  [
    "width",
    {
      title: "Chat window width (in pixels)",
      type: EmbeddableSettinType.number,
      default: 300,
    },
  ],
]);

export const makeDefaultEmbeddedData = (): EmbeddedData => {
  return {
    bot_name: "Beyond Virtual Demo virtual human",
    fab_right: 30,
    fab_bottom: 30,
    channel_id: "",
    bot_greeting: "Hello",
    is_fabless: false,
    is_rtl: false,
    is_open_on_start: true,
    height: 400,
    width: 300,
  };
};
