export async function fetchWrapper(input: RequestInfo, init?: RequestInit): Promise<Response> {
    const obj = init || {};
    const headers = obj.headers || ({"Content-Type": "application/json"} as any);
    let token = "";
    const isGuest = (globalThis as {[key: string]: any}).isGuest;

    if (!isGuest) {
        try {
            const getToken = (globalThis as {getToken?: () => Promise<string>}).getToken;
            if (!getToken) {
                throw new Error("Auth0 not initialized!");
            }
            token = await getToken();
            (globalThis as {[key: string]: any}).accessToken = token;
        } catch (e) {
            // TODO - find out why this causes and infinite loop
            // analytics({type: AnalyticType["auth0-bug"]});
        }
    }

    token && (headers.Authorization = `Bearer ${token}`);
    if (!headers["Content-Type"]) headers["Content-Type"] = "application/json";

    obj.headers = headers;

    return new Promise((resolve, reject) => {
        fetch(input, obj).then(
            async (response) => {
                if (!response.ok) {
                    // fetch request failed, check for new token & resend request.
                    if (token !== "" && token !== (globalThis as {[key: string]: any}).accessToken) {
                        headers.Authorization = `Bearer ${(globalThis as {[key: string]: any}).accessToken}`;
                        obj.headers = headers;
                        fetch(input, obj).then((newResponse) => {
                            return resolve(newResponse);
                        });
                    }
                    if (response.status >= 300 || response.status <= 499) {
                        let err = String(response.status);
                        try {
                            err = await response.text();
                        } catch (e) {}
                        console.error(err);
                        return reject(err);
                    }
                }
                return resolve(response);
            },
            () => {
                return reject();
            }
        );
    });
}
