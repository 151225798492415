import {Intent} from "./types";
import {fetchWrapper} from "data/api/rest/fetchWrapper";

export const getIntent = async (name: string) => {
    try {
        const reply = await fetchWrapper(`/v2/intent/${name}`);
        if (!reply.ok) throw new Error(`Could not get intent because ${reply.statusText}`);
        const data = await reply.json();
        return data as Intent;
    } catch (e) {
        console.error(e);
        return;
    }
};
