import {Organization} from "../types";
import {observable} from "mobx";

export const defaultUserProfileCore = () => ({
    first_name: "",
    last_name: "",
    name: "",
    handle: "",
    email: "",
    image_url: "",
    bio: ""
});

export const defaultUserProfile = () =>
    observable({
        profile: defaultUserProfileCore(),
        organizations: observable.map({} as Map<string, Organization>)
    });
