import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import {fade, makeStyles, Theme, createStyles} from "@material-ui/core/styles";
import React from "react";
import {handleTextFieldChange} from "../../../../../utils/handleTextFieldChange";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        search: {
            position: "relative",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            borderRadius: theme.spacing(3),
            backgroundColor: theme.custom.palette.d.main,
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: "100%",
            [theme.breakpoints.up("sm")]: {
                marginLeft: theme.spacing(3),
                width: "auto"
            }
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: "100%",
            position: "absolute",
            pointerEvents: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        inputRoot: {
            color: theme.custom.palette.e.main
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create("width"),
            width: "100%",
            [theme.breakpoints.up("md")]: {
                width: "20ch"
            }
        }
    })
);

export const FilterActions: React.FC<{filter: string; setFilter: (f: string) => void}> = ({filter, setFilter}) => {
    const classes = useStyles();

    return (
        <div className={classes.search}>
            <InputBase
                value={filter}
                onChange={handleTextFieldChange(setFilter)}
                placeholder="Search"
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                }}
                inputProps={{"aria-label": "search"}}
            />
            <div className={classes.searchIcon}>
                <SearchIcon color="secondary" />
            </div>
        </div>
    );
};
