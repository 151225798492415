import CustomTextInputField from "bot-dashboard/ui-kit/CustomTextInputField";
import React from "react";

const AddAlternate: React.FC<{onAdd: (v: string) => void; answers?: string[]}> = ({onAdd}) => {
    return (
        <CustomTextInputField
            onEnter={onAdd}
            onBlur={() => {}}
            dependencies={[]}
            placeholder={"Type your alternate here"}
            fullWidth
        />
    );
};

export default AddAlternate;
