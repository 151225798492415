import {CanvasStore} from "bots-studio/store/canvas/createCanvasStore";
import {GraphStore} from "bots-studio/store/graph/createGraphStore";
import {TutorialStore} from "../createTutorialStore";
import {markComplete} from "../utils/markComplete";
import {reaction} from "mobx";

export const stage1 = (store: TutorialStore, graph: GraphStore, canvas: CanvasStore) => {
    // stage 1-0 completion reaction
    reaction(
        () => {
            return {stage: store.stage, isDone: store.isChatDone};
        },
        ({stage, isDone}) => {
            if (stage === 1 && isDone) {
                markComplete(store, stage, 0);
            }
        }
    );
};
