import {fetchWrapper} from "data/api/rest/fetchWrapper";

export const setUserState = async (key: string, value: Object) => {
    if ((globalThis as {[key: string]: any}).isGuest) return;

    try {
        const r = await fetchWrapper(`/v2/participant/user/state/${key}`, {
            method: "POST",
            body: JSON.stringify(value)
        });
        return r.ok;
    } catch (e) {
        console.error(e);
        return false;
    }
};
