import {CanvasStore, makeDefaultDimesions} from "../canvas/createCanvasStore";
import {GlueStateV2, NodeV2} from "../types";

import {GraphStore} from "../graph/createGraphStore";
import {action, observable} from "mobx";
import {studioVersion} from "dangerous/staticStrings";

const centerPosition = 500000;

export const isEntryLocationMisplaced = (graph: GraphStore) => {
    const entryPosition = graph.nodes["entry"].position;
    if (entryPosition.x > centerPosition * 1.5 || entryPosition.x < centerPosition * 0.5) {
        return true;
    }
    return false;
};

export const moveNodesToCenter = action((graph: GraphStore) => {
    const entryPosition = graph.nodes["entry"].position;
    const deltaCenterX = entryPosition.x - centerPosition;
    const deltaCenterY = entryPosition.y - centerPosition;
    for (let item in graph.nodes) {
        action(() => {
            graph.nodes[item].position.x -= deltaCenterX;
            graph.nodes[item].position.y -= deltaCenterY;
        })();
    }
});

export const migrateProject = (graph: GraphStore, canvas: CanvasStore, project: GlueStateV2) => {
    setProjectCanvas(canvas, project);
    setProjectsGraph(graph, project);
};

export const isProjectVersionOutdated = (version: string) => {
    if (version < studioVersion || typeof version == "undefined") {
        return true;
    }
    return false;
};

const setProjectCanvas = (canvas: any, project: GlueStateV2) => {
    canvas.dimensions = project.dimensions || makeDefaultDimesions();
    canvas.version = studioVersion;
};

const setProjectsGraph = (graph: GraphStore, project: GlueStateV2) => {
    graph.nodes = updateNodesPosition(project.nodes);
    graph.edges = observable({...project.edges});
};

const updateNodesPosition = action((nodes: {[key: string]: NodeV2}) => {
    const updatedNodes = {...nodes};
    for (let key in updatedNodes) {
        if (updatedNodes.hasOwnProperty(key)) {
            updatedNodes[key].position.x += centerPosition;
            updatedNodes[key].position.y += centerPosition;
        }
    }
    return observable(updatedNodes);
});
