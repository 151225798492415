import { useEffect, useState } from "react";

export type BotDataReply = {
  name: string;
  avatar_id: string;
  tts_id: string;
  preset: string;
};

export const useBotDataFromChannel = (channel: string) => {
  const [data, setData] = useState<BotDataReply>({
    name: "",
    avatar_id: "",
    tts_id: "",
    preset: "",
  });

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (!channel) return;
      try {
        setLoading(true);
        const result = await fetch(`/v2/channel/${channel}/bot`, {
          method: "GET",
        });
        if (!result.ok) throw new Error(result.statusText);
        const reply = (await result.json()) as BotDataReply;

        setData(reply);

        setLoading(false);
        setError("");
        return reply;
      } catch (e: any) {
        setLoading(false);
        setError(e.message || "Unknown error");
        return e.message;
      }
    })();
  }, [channel]);

  return { ...data, loading, error };
};
