import {
  ApolloCache,
  DefaultContext,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
  useQuery,
} from "@apollo/client";

import { AnalyticType } from "analytics/AnalyticType";
import { CREATE_CHANNEL } from "../mutations/createChannel";
import { DELETE_CHANNEL } from "../mutations/deleteChannel";
import { GET_BOT_DASHBOARD } from "../queries/getBotDashboard";
import { UPDATE_CHANNEL } from "../mutations/updateChannel";
import { analytics } from "analytics/analytics";
import { getBotDashboard } from "../queries/__generated__/getBotDashboard";
import { removePhone } from "channels/phone/functions/removePhone";
import { useCallback } from "react";

export const useChannelFunctions = ({ botId }: { botId: string }) => {
  const {
    loading: qLoading,
    error: qError,
    data: qData,
  } = useQuery<getBotDashboard>(GET_BOT_DASHBOARD, {
    variables: { id: Number(botId) },
  });

  const [create, { data: cData, loading: cLoading, error: cError }] =
    useMutation(CREATE_CHANNEL, {
      refetchQueries: [GET_BOT_DASHBOARD],
      errorPolicy: "all",
    });

  const [remove, { data: rData, loading: rLoading, error: rError }] =
    useMutation(DELETE_CHANNEL, {
      refetchQueries: [GET_BOT_DASHBOARD],
      errorPolicy: "all",
    });

  const [update, { data: uData, loading: uLoading, error: uError }] =
    useMutation(UPDATE_CHANNEL, {
      refetchQueries: [GET_BOT_DASHBOARD],
      errorPolicy: "all",
    });

  const wrappedRemove = useCallback(
    async (
      options:
        | MutationFunctionOptions<
            any,
            OperationVariables,
            DefaultContext,
            ApolloCache<any>
          >
        | undefined
    ) => {
      const botId = qData?.bot?.id;
      const id = options?.variables?.id || -1;
      const phone = qData?.bot?.channels.edges.find(
        ({ node }) => node.id === id
      )?.node.jsonConfig.phone_number;
      const isOk = phone && botId ? await removePhone({ phone, botId }) : true;
      if (!isOk) {
        console.log("Tried to remove phone when removing channel and failed");
      }
      return await remove(options);
    },
    [remove, qData?.bot]
  );

  const wrappedCreate = useCallback(
    async (
      options?: MutationFunctionOptions<
        any,
        OperationVariables,
        DefaultContext,
        ApolloCache<any>
      >
    ) => {
      analytics({
        type: AnalyticType.channel_create,
        data: { channel: options?.variables?.channelName },
      });
      return await create(options);
    },
    [create]
  );

  return {
    create: wrappedCreate,
    remove: wrappedRemove,
    update,
    error: cError || rError || uError,
    loading: cLoading || rLoading || uLoading,
  };
};
