import React, {useCallback, useEffect, useState} from "react";

import {CocoResponse} from "marketplace-2/pages/hub/chat-window/types";
import {sendMessage} from "data/api/rest/requests/chatComm";

export const useServerAgt = (componentId: string, sessionId: string) => {
    const [result, setResult] = useState({done: false, success: false});

    const reset = useCallback(() => {
        setResult({done: false, success: false});
    }, []);

    useEffect(() => {
        reset();
    }, [sessionId, componentId]);

    const call = useCallback(
        async (userInput: string) => {
            const r: CocoResponse = await sendMessage({
                componentId,
                message: userInput,
                newSessionId: sessionId
            });
            (r.component_done || r.component_failed) &&
                setResult({done: r.component_done, success: !r.component_failed});
            return r;
        },
        [componentId, sessionId]
    );

    return {call, result, reset} as const;
};
