import { useCallback, useEffect, useState } from "react";
import {SignalingEvents, SignalingMessage } from "./types";
import { useSignaling } from "./useSignaling";
import { usePeerConnection } from "./usePeerConnection";
import { launchBot } from "./launchBot";

export const useWebRtc = ({
  channelId,
  sceneId,
  context,
  userId,
}: {
  channelId: string;
  sceneId: string;
  context?: { [key: string]: any };
  userId: string;
}) => {
  const { transport, sendEvent } = useSignaling()

  const { 
    getConnection, 
    disconnect: disconnectPeerConnection, 
    remoteStream,
    iceCandidate,
    isLoading: isConnectionLoading,
    isReady,
  } = usePeerConnection();

  const [error, setError] = useState("");

  useEffect(() => {
    if (error) {
      console.log('ERROR: ', error);
    }
  }, [error]);

  const [loading, setLoading] = useState(true);

  const closeWebRTC = useCallback(() => {
    disconnectPeerConnection();
  }, [disconnectPeerConnection]);

  const disconnect = useCallback(() => {
    transport?.socket.disconnect();
    closeWebRTC();
  }, [closeWebRTC, transport]);


  useEffect(() => disconnect, []);

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  useEffect(() => {
      if (iceCandidate !== null) {
        sendEvent(SignalingEvents.Candidate, iceCandidate);
      }
  }, [iceCandidate])

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      disconnect();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [disconnect]);

  useEffect(() => {
    if (!transport || !isReady) {
      return;
    }

    const { socket, room } = transport;

    socket.on('connect', async () => {
      console.log('WS CONNECTED');
      
        const { error: launchError } = await launchBot({
          channelId,
          room,
          sceneId,
          userId,
          context
        });
  
        if (launchError) {
          setError(launchError);
          return;
        }
    })

    //SIGNALING
    socket.on(SignalingEvents.Error, ({ message }: SignalingMessage) => {
      console.error(message);
    })
    
    //do offer
    socket.on(SignalingEvents.BotConnected, () => { 
      (async () => {
        console.log('WS: Bot connected');
        
        const { connection, error: connectionError } = await getConnection();

        if (!connection) {
          setError(connectionError?.message || 'An unknown error occurred during peer connection');
          return;
        }

        if (connection.iceConnectionState !== 'new') {
          return;
        }
      
        try {
          console.log('WEBRTC: try to create offer');
          const offer = await connection.createOffer({
            offerToReceiveAudio: true,
            offerToReceiveVideo: true,
          });
          console.log('WEBRTC: offer created');
          await connection.setLocalDescription(offer);

          sendEvent(SignalingEvents.Offer, offer);
          console.log('WEBRTC: send offer');
        } catch (e: any) {
          setError(e.message);
        }
      })()
        .then(() =>  setLoading(false));
    })

    //handle answer
    socket.on(SignalingEvents.Answer, async (answer: RTCSessionDescriptionInit) => {
      console.log('WS: Answer');
      const { connection, error: connectionError } = await getConnection();

      if (!connection || connectionError) {
        setError(connectionError?.message || 'An unknown error occurred during peer connection')
        return;
      }

      connection.setRemoteDescription(new RTCSessionDescription(answer));
    })
  
    //handle candidate
    socket.on(SignalingEvents.Candidate, async (candidate: RTCIceCandidate) => {
      console.log('WS: Candidate');
      const { connection, error: connectionError } = await getConnection();

      if (!connection || connectionError) {
        setError(connectionError?.message || 'An unknown error occurred during peer connection')
        return;
      }

      try {
        await connection.addIceCandidate(new RTCIceCandidate(candidate));
      } catch (e: any) {
          setError('Error adding received ice candidate: ' + e.message ?? 'unknown error');
      }
    })

    //handle ping
    socket.on(SignalingEvents.Ping, (callback: (response: { ok: boolean }) => void) => {
      callback({ ok: true });
    });

    //dandle peer disconnect
    socket.on(SignalingEvents.BotDisconnected, () => {
      console.log('WS: Bot disconnected');
      closeWebRTC();
    })

    if (!socket.connected) {
      socket.connect();
    }

    return () => { socket.disconnect() };
  }, [transport, isReady]);

  return { 
    remoteStream, 
    myStream: remoteStream, 
    loading: isConnectionLoading || loading, 
    error, 
    disconnect,
  };
};