import {fetchWrapper} from "data/api/rest/fetchWrapper";

export const deleteUserState = async (key: string) => {
    try {
        const r = await fetchWrapper(`/v2/participant/user/state/${key}`, {method: "DELETE"});
        if (r.status === 404) {
            return null;
        }
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
};
