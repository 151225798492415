import React, {CSSProperties} from "react";
import {Theme, makeStyles} from "@material-ui/core";

import {CreateCSSProperties} from "@material-ui/core/styles/withStyles";

type SliderProps = {positionX: number; width: number};

const getBorderRadius = (type: HighlightType, theme: Theme) => {
    let a = theme.spacing(0, 0, 0, 0);

    switch (type) {
        case HighlightType.tab:
            a = theme.spacing(3, 3, 0, 0);
            break;
        case HighlightType.radio:
            a = theme.spacing(3, 3, 3, 3);
            break;
        default:
            break;
    }

    return a;
};

const getPositioning = (type: HighlightType, theme: Theme) => {
    let a: CSSProperties = {top: "50%", transform: "translateY(-50%)"};

    switch (type) {
        case HighlightType.tab:
            a = {bottom: "0px", transform: "none"};
            break;
        case HighlightType.radio:
            a = {top: "50%", transform: "translateY(-50%)"};
            break;
        default:
            break;
    }

    return a;
};

const getShadow = (type: HighlightType, theme: Theme) => {
    let a = theme.shadows[1];

    switch (type) {
        case HighlightType.tab:
            a = theme.shadows[2];
            break;
        case HighlightType.radio:
            a = theme.shadows[0];
            break;
        default:
            break;
    }

    return a;
};

const getBackground = (color: HighlightColor, theme: Theme) => {
    let a = theme.custom.palette.e.main;

    switch (color) {
        case HighlightColor.white_black:
            a = theme.custom.palette.e.main;
            break;
        case HighlightColor.white_blue:
            a = theme.custom.palette.a.main;
            break;
        default:
            break;
    }

    return a;
};

const useStyles = ({type, color}: {type: HighlightType; color: HighlightColor}) =>
    makeStyles((theme: Theme) => {
        const borderRadius = getBorderRadius(type, theme);
        const background = getBackground(color, theme);
        const positioning = getPositioning(type, theme);
        const boxShadow = getShadow(type, theme);

        return {
            container: {
                position: "absolute",
                transition: theme.transitions.create("all", {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen
                }),
                left: (props: SliderProps) => props.positionX || 0,
                background,
                borderRadius,
                width: (props: SliderProps) => {
                    if (props.width) return props.width;
                    else return theme.spacing(14);
                },
                height: theme.spacing(3.6),
                boxShadow,
                ...positioning
            } as CreateCSSProperties<SliderProps>
        };
    });

export enum HighlightType {
    "tab" = "tab",
    "radio" = "radio"
}

export enum HighlightColor {
    "white_black" = "white_black",
    "white_blue" = "white_blue"
}

const SlidingHighlighter: React.FC<{left: number; width: number; type?: HighlightType; color?: HighlightColor}> = ({
    left,
    width,
    type = HighlightType.tab,
    color = HighlightColor.white_black
}) => {
    const classes = useStyles({type, color})({positionX: left, width});

    return <div className={classes.container}></div>;
};

export default SlidingHighlighter;
