import {AuthStore, UserStore} from "../auth/createAuthStore";
import {action, autorun, observable, reaction} from "mobx";

import {ContainerStore} from "../container/ContainerStore";
import {UserComponent} from "./types";
import {createCrateCopy} from "./createCrateCopy";
import {createCreateFromDialogflow} from "./createCreateFromDialogflow";
import {createCreateFromUrl} from "./createCreateFromUrl";
import {createEditComponent} from "./createEditComponent";
import {deleteBlueprint} from "data/api/rest/requests/deleteBlueprint";
import {fetchWrapper} from "data/api/rest/fetchWrapper";
import {getBlueprint} from "data/api/rest/requests/getBlueprint";
import {getComponents} from "data/api/rest/requests/getComponents";
import {getConfig} from "data/api/rest/requests/getConfig";
import {getSearchResultsElastic} from "data/api/rest/requests/getSearchResultsElastic";
import {getUserState} from "data/api/rest/requests/getUserState";
import {makeErrorBlueprint} from "data/api/rest/requests/makeErrorBlueprint";
import {setUserState} from "data/api/rest/requests/setUserState";

const MY_COMPONENTS_STATE_KEY = "my-components";

export const blueprintPutKeys = [
    "human_id",
    "description",
    "name",
    "goal",
    "image_url",
    "input_language",
    "output_language",
    "categories"
];

export enum ConfigEditTool {
    "json",
    "actions",
    "qna",
    "quiz"
}

export const getBlueprintAndConfig = async (id: string) => {
    if (!id) return makeErrorBlueprint();

    const blueprint = await getBlueprint(id);
    const config = await getConfig({humanId: id});
    blueprint.json_config = config;
    return blueprint;
};

const getValidLanguages = async (): Promise<Languages> => {
    try {
        const r = await fetchWrapper("/v2/available_languages");
        const j = await r.json();
        return j.languages;
    } catch (e) {
        console.error(e);
        return {};
    }
};

export type ComponentType = "Public" | "Private" | "Both";

export type TableFilter = {
    query: string;
    type: ComponentType;
};

export type Languages = {[languageName: string]: string};

export const createMyComponentsStore = (userStore: UserStore, container: ContainerStore, auth: AuthStore) => {
    const components = observable.array<UserComponent>([]);
    const allOrgsComponents = observable.array<UserComponent>([]);
    const blueprintsList = observable.array<string>([]);
    const selectedParticipant = observable.box(userStore.profile.handle);
    const isInitialized = observable.box(false);
    const tableFilter = observable({query: "", type: "Both"} as TableFilter);

    const initialize = action(async (handle: string) => {
        isInitialized.set(false);

        updateBlueprintsList();

        getUserState(MY_COMPONENTS_STATE_KEY).then((result?: {rowsPerPage: number}) => {
            result && result.rowsPerPage && (store.rowsPerPage = result.rowsPerPage);
        });

        getAllOrgsComponents();

        isInitialized.set(true);
    });

    const getAllOrgsComponents = async () => {
        store.allOrgsComponents.replace(
            (
                await Promise.all(
                    [
                        userStore.profile.handle,
                        ...Array.from(userStore.organizations.values()).map((o) => o.handle)
                    ].map(async (h) => {
                        const c = await (getComponents(h) as Promise<Array<UserComponent>>);
                        h === userStore.profile.handle && components.replace(c);
                        return c;
                    })
                )
            ).flat()
        );
    };

    const updateBlueprintsList = async () => {
        const r = await getSearchResultsElastic({query: ""});
        blueprintsList.replace([
            ...r.hits.map((b: any) => b.human_id),
            ...components.filter((c) => !c.public).map((c) => c.human_id)
        ]);
    };

    const reloadComponents = action(async () => {
        try {
            await getAllOrgsComponents();
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    });

    const fromUrl = createCreateFromUrl(selectedParticipant, container, initialize);
    const dialogflow = createCreateFromDialogflow(selectedParticipant, container, initialize);
    const copy = createCrateCopy(selectedParticipant, container, initialize, updateBlueprintsList, tableFilter);
    const editComponent = createEditComponent(container, components);

    const _private = observable({
        _validLanguages: {} as Languages
    });

    const store = observable({
        isInitialized,
        tableFilter,
        selectedParticipant,
        rowsPerPage: 20,
        reloadComponents,
        tryComponent: "",
        get validLanguages() {
            if (!Object.keys(_private._validLanguages).length) {
                getValidLanguages().then((l: Languages) => (_private._validLanguages = l));
            }
            return _private._validLanguages as Languages;
        },
        components,
        allOrgsComponents,
        editComponent,
        createComponent: {
            blueprintsList,
            fromUrl,
            dialogflow,
            copy
        },
        delete: {
            deleteNow: action((id: string) => {
                store.delete.id.set(id);
                store.delete.onConfirm();
            }),
            id: observable.box(""),
            onConfirm: action(async () => {
                const idToDelete = store.delete.id.get();
                const reply = await deleteBlueprint(idToDelete);

                if (reply) {
                    const componentToRemove = components.find((c) => c.human_id === idToDelete);
                    if (!componentToRemove) return reply;
                    components.remove(componentToRemove);
                    const allOrgsComponent = store.allOrgsComponents.find(
                        (c) => c.human_id === componentToRemove.human_id
                    );
                    allOrgsComponent && store.allOrgsComponents.remove(allOrgsComponent);
                }
                store.delete.id.set("");
                return reply;
            }),
            onCancel: action(() => {
                store.delete.id.set("");
            })
        }
    });

    autorun(() => {
        if (!auth.isReady || !auth.isAuthenticated) return;
        const p = selectedParticipant.get();
        p && initialize(p);
    });

    autorun(() => {
        userStore.profile.handle && selectedParticipant.set(userStore.profile.handle);
    });

    reaction(
        () => {
            return store.rowsPerPage;
        },
        (rowsPerPage) => {
            if (!auth.isAuthenticated || auth.isLoading || !auth.isReady) return;
            setUserState(MY_COMPONENTS_STATE_KEY, {rowsPerPage});
        }
    );

    autorun(() => {
        auth.isLoading && store.isInitialized.set(false);
    });

    return store;
};

export type MyComponentsStore = ReturnType<typeof createMyComponentsStore>;
