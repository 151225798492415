import {fetchWrapper} from "data/api/rest/fetchWrapper";

export const getWholeConversation = async (conversationId: string, fromEntry: number, size: number) => {
    try {
        const r = await fetchWrapper(
            `/v2/rest_of_conv?conv_id=${conversationId}&from_entry=${fromEntry}&size=${size}`,
            {
                method: "GET",
                mode: "cors",
                credentials: "include"
            }
        );
        const reply = await r.json();
        return [reply.messages, reply.total];
    } catch (e) {
        console.error(e);
        return "";
    }
};
