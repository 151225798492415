import {makeStyles} from "@material-ui/core";
import React from "react";

export type SparkleParams = {
    duration: number;
    left: number;
    top: number;
    maxSize: number;
    isOn?: boolean;
    onComplete?: () => void;
};

const useStyles = ({left, top, maxSize, duration}: SparkleParams) =>
    makeStyles((theme) => {
        return {
            sparkle: {
                pointerEvents: "none",
                position: "absolute",
                width: "0px",
                height: "0px",
                left: `${left}px`,
                top: `${top}px`,
                transformOrigin: "center center",
                animation: `$sparkle ${duration}ms linear`,
                color: "yellow"
            },
            "@keyframes sparkle": {
                "0%": {
                    opacity: 0,
                    width: `0px`,
                    height: `0px`,
                    left: `${left}px`,
                    top: `${top}px`,
                    transform: "rotate(0deg)"
                },
                "40%": {
                    opacity: 0.6,
                    left: `${left - maxSize / 2}px`,
                    top: `${top - maxSize / 2}px`,
                    width: `${maxSize}px`,
                    height: `${maxSize}px`,
                    transform: "rotate(90deg)"
                },
                "60%": {
                    opacity: 1,
                    left: `${left - maxSize / 2}px`,
                    top: `${top - maxSize / 2}px`,
                    width: `${maxSize}px`,
                    height: `${maxSize}px`,
                    transform: "rotate(180deg)"
                },
                "100%": {
                    opacity: 0,
                    left: `${left}px`,
                    top: `${top}px`,
                    width: `0px`,
                    height: `0px`,
                    transform: "rotate(240deg)"
                }
            }
        };
    });

export const Sparkle: React.FC<SparkleParams> = (p) => {
    const classes = useStyles(p)();

    if (!p.isOn) return null;

    return (
        <svg onAnimationEnd={p.onComplete} className={classes.sparkle} viewBox="0 0 24 24">
            <path fill="currentColor" d="M12,1L9,9L1,12L9,15L12,23L15,15L23,12L15,9L12,1Z" />
        </svg>
    );
};
