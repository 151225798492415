import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GET_BOT_DASHBOARD } from "../queries/getBotDashboard";
import { getBotDashboard } from "../queries/__generated__/getBotDashboard";

const botToData = (data?: getBotDashboard) => {
  return {
    subscription: data?.bot?.botsSubscription?.planName || "",
    name: data?.bot?.firstName || "",
    avatar: (data?.bot?.avatar as {
      id: string;
      voice_id: string;
      preset: string;
    }) || {
      id: "",
      voice_id: "",
      preset: "",
    },
    extras: data?.bot?.extras,
    brain: data?.bot?.entrypoint,
    language_code: data?.bot?.languageCode || "en-US",
  };
};

export const useBotInfo = ({ botId }: { botId: string }) => {
  const { loading, error, data } = useQuery<getBotDashboard>(
    GET_BOT_DASHBOARD,
    {
      variables: { id: Number(botId) },
      skip: !botId,
    }
  );

  const [info, setInfo] = useState(botToData(data));

  useEffect(() => {
    setInfo(botToData(data));
  }, [data]);

  return { info, loading, error: error?.message };
};
