import { AppBar, createStyles, makeStyles } from "@material-ui/core";

import { AnalyticType } from "analytics/AnalyticType";
import { BeyondVirtualLogo } from "./BeyondVirtualLogo";
import React from "react";
import { TextButton } from "theme/components/elements/buttons/TextButton";
import { UserProfileButton } from "./participant/UserProfileButton";
import clsx from "clsx";
import { useNavDrawerContext } from "nav-drawer/context/useNavDrawerContext";
import { useResponsive } from "@a-i/ui";

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      background: theme.custom.palette.e.main,
      height: theme.appbar.height,
      width: `100%`,
      zIndex: theme.zIndex.drawer + 1,
      padding: `0px ${theme.spacing(5)}px`,
      borderRadius: theme.spacing(0, 0, 48, 48),
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
      height: "100%",
    },
    leftContainer: {
      display: "flex",
      height: "100%",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "space-between",
      },
    },
    rightContainer: {
      display: "flex",
      paddingRight: theme.spacing(1),
    },
    helpButtonContainer: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      paddingRight: theme.spacing(1),
    },
    hidden: {
      transform: `translateY(${-theme.appbar.height}px)`,
      transition: theme.transitions.create("all"),
    },
    shown: {
      transform: `translateY(0px)`,
      transition: theme.transitions.create("all"),
    },
  })
);

export const CocoAppbar = () => {
  const classes = useStyles();
  const { isMobile } = useResponsive();

  const { select, dispatch, actions } = useNavDrawerContext();
  const isHidden = select((s) => s.ui.isHidden);

  const liveHelp = () =>
  // @ts-ignore
  window.$chatwoot?.toggle();

  return (
    <AppBar
      component="div"
      elevation={0}
      position="relative"
      className={clsx(classes.appBar, {
        [classes.shown]: !isHidden,
        [classes.hidden]: isHidden,
      })}
    >
      <div className={classes.toolbar}>
        <BeyondVirtualLogo />
        <div className={classes.leftContainer}>
          <div className={classes.helpButtonContainer}>
            <TextButton
              variant={"alt"}
              analytics_event={AnalyticType.gitter_clicked}
              onClick={liveHelp}
            >
              {isMobile ? "Help" : "Live help"}
            </TextButton>
          </div>
          <UserProfileButton source="toolbar" isOnboarding={false} />
        </div>
      </div>
    </AppBar>
  );
};
