import { useState, useRef, useEffect } from "react";

export const useMediaStream = () => {
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isReady, setIsReady] = useState(false);

    const syncStream = useRef<MediaStream | null>(null);
    const syncError = useRef<Error | null>(null);

    useEffect(() => {
     navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
     })
      .then((stream) => {
        stream.getTracks().forEach((track) => track.stop());
      })
      .catch(e => null)
      .finally(() => setIsReady(true));
    }, []);

    const createStream = () => {
      const getStream = async () => {
        setIsLoading(true);

        return navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false,
        });
      }

      const task = getStream();

      task
        .then((stream) => {
          syncStream.current = stream;
        })
        .catch((err) => {
            const preparedErr = err instanceof Error ? err : new Error('Unknown error');
            setError(preparedErr);
            syncError.current = preparedErr;
        })
        .finally(() => {
            setIsLoading(false);
        })

      return task;
  }

    const streamPromise = useRef<Promise<MediaStream> | null>(null);

    const getMediaStream = async (): Promise<{
      mediaStream: MediaStream | null;
      error: Error | null;
    }> => {
        if (syncStream.current) {
          return {
            mediaStream: syncStream.current,
            error: syncError.current,
          };
        }

        if (streamPromise.current) {
          await streamPromise.current;
        } else {
            streamPromise.current = createStream();
            await streamPromise.current;
            streamPromise.current = null;
        }

        return {
            mediaStream: syncStream.current,
            error: syncError.current,
        };
    }

    const destroyMediaStream = () => {
        if (syncStream.current) {
            syncStream.current?.getTracks().forEach((track) => track.stop());
            
            syncStream.current = null;
        }
    }

    return {
        getMediaStream,
        destroyMediaStream,
        isLoading,
        isReady,
        error,
    }
};
