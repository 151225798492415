import {action, observable, reaction} from "mobx";

export const createNameStore = () => {
    const store = observable({
        viableBotNames: [] as string[],
        filteredBotNames: [] as string[],
        botName: "",
        isNameValid: false,
        isRecording: false,
        onToggleRecording: () => {},
        addNewName: (x: string) => {},
        handleCustomRecordedName: action((name: string) => {
            store.botName = name;
            if (store.viableBotNames.includes(name)) return;
            store.addNewName(name);
            store.viableBotNames.push(name);
        })
    });

    reaction(
        () => {
            return {botName: store.botName};
        },
        ({botName}) => {
            if (store.viableBotNames.includes(botName)) return (store.isNameValid = true);
            return (store.isNameValid = false);
        }
    );

    return store;
};
