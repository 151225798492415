import { analytics, user_id } from "analytics/analytics";
import { AnalyticType } from "analytics/AnalyticType";
import { useWebRtc } from "bot-dashboard/test-bot/video2/webrtc/useWebRtc";
import { useEffect, useRef } from "react";
import { useBotNameFromChannel } from "./useBotNameFromChannel";

export const useStandaloneVideo = () => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const params = new URLSearchParams(window.location.search);
  const sceneId = params.get("sceneId") || "Forest";
  const channelId = params.get("channelId") || "";

  const context = Object.fromEntries(
    [...params.entries()].filter(
      ([k, v]) => k !== "sceneId" && k !== "channelId"
    )
  );

  const botName = useBotNameFromChannel(channelId);

  const {
    remoteStream,
    loading,
    error: wrError,
  } = useWebRtc({
    sceneId,
    channelId,
    context,
    userId: user_id,
  });

  const error = wrError || (!channelId ? "Missing channel id" : "");

  useEffect(() => {
    analytics({ type: AnalyticType.standalone_video_page });
  }, []);

  useEffect(() => {
    if (!remoteStream || !videoRef.current) return;
    videoRef.current.srcObject = remoteStream;
    videoRef.current.play();
  }, [remoteStream, videoRef.current]);

  return { botName, error, loading, videoRef } as const;
};