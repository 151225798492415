import {Resources} from "marketplace-2/store/billing-plans/BillingPlansStore";
import {fetchWrapper} from "data/api/rest/fetchWrapper";

const makeDefaultResult = () => {
    return {
        seconds: 0,
        conversations: 0,
        phone_numbers: 0
    } as Resources;
};

export const getBotResources = async (botId: string) => {
    if (!botId) return makeDefaultResult();
    try {
        const result = await fetchWrapper(`/v2/bot/${botId}/usages`, {method: "GET"});
        if (!result.ok) throw new Error(result.statusText);
        const reply = await result.json();
        return {
            seconds: reply.zoom_seconds,
            conversations: reply.api_calls,
            phone_numbers: reply.phone_purchases
        } as Resources;
    } catch (e) {
        return makeDefaultResult();
    }
};
