import { Typography, makeStyles } from "@material-ui/core";

import { BotZoomDisplay } from "./BotZoomDisplay";
import ImageCard from "./ImageCard";
import React from "react";
import { scenes } from "bots-studio/store/bots/data/data";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      paddingTop: theme.spacing(2),
      overflowY: "auto",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      widht: "100%",
    },
    backgroundsContainer: {
      paddingTop: theme.spacing(1),
      display: "grid",
      overflowY: "auto",
      overflowX: "hidden",
      height: theme.spacing(45),
      gridGap: theme.spacing(3),
      [theme.breakpoints.down("lg")]: {
        height: "auto",
        gridGap: theme.spacing(1),
      },
      gridTemplateColumns: "repeat(3, 150px)",
      gridTemplateRows: "repeat(2, 140px)",
    },
    boldText: {
      fontWeight: "bold",
    },
  };
});

export const BackgroundSelection: React.FC<{
  bg?: string;
  avatar: string;
  onBgSelected: (bg: string) => void;
}> = ({ bg = "BGR_Bedroom", avatar, onBgSelected }) => {
  const classes = useStyles();

  const selectedScene = scenes.find((s) => s.ids.includes(bg))?.key || bg;

  return (
    <div className={classes.container}>
      <div>
        <Typography>
          <b>Choose scene</b>
        </Typography>
        <div className={classes.backgroundsContainer}>
          {scenes.map((scene) => {
            return (
              <ImageCard
                key={scene.key}
                id={scene.key}
                selectedScene={selectedScene}
                image={scene.scene}
                onClick={() => onBgSelected(scene.key)}
              />
            );
          })}
        </div>
      </div>
      <BotZoomDisplay background={selectedScene} avatar={avatar} />
    </div>
  );
};
