import { GUEST_DATA_KEY } from "dangerous/staticStrings";
import { action } from "mobx";
import { CanvasStore, makeDefaultDimesions } from "../canvas/createCanvasStore";
import { ProjectsStore, updateSaved } from "./createProjects";


export const initializeUnauthenticated = action(async (store: ProjectsStore, canvas: CanvasStore) => {
    store.isLoading = true;
    store.isInitializing = true;

    const guestProjString = localStorage.getItem(GUEST_DATA_KEY);
    if (guestProjString) {
        try {
            action(async () => {
                const d = JSON.parse(guestProjString);
                const processed = d.proj || d;
                canvas.dimensions = d.proj.dimensions || makeDefaultDimesions();
                store.projToComponentMap[processed.id] = {id: processed.id, name: processed.name};
                store.projects[processed.id] = processed;
                store.forcedChannel = d.forcedChannel || "";
                // localStorage.removeItem(GUEST_DATA_KEY);
                store.selected = processed.id;
                store.isLoading = false;
                updateSaved(store);
            })();
        } catch (e) {
            console.error("Could not process saved guest glue");
        }
    } else {
        store.isLoading = false;
    }

    store.isInitializing = false;
});
