import Lottie from "react-lottie-player";
import { Reload } from "./Reload";
import { Typography } from "@material-ui/core";
import loader from "./loader.json";

interface ILoader {
  color?: string;
  size?: number;
  rounded?: boolean;
  title?: JSX.Element | string;
  background?: string;
  width?: string;
  height?: string;
  contained?: boolean;
  error?: string;
  reload?: () => void;
  isLarge?: boolean;
  zIndex?: number;
}

export type LoaderParams = ILoader | string;

const defaultParams = {
  height: "100%",
  width: "100%",
  background: "inherit",
  size: 32,
  color: "inherit",
};

const Loader = (p: LoaderParams) => {
  const data =
    typeof p === "string"
      ? {
          ...defaultParams,
          title: <Typography variant="body2">{p}</Typography>,
        }
      : {
          ...defaultParams,
          ...p,
          title:
            typeof p.title === "string" ? (
              <Typography variant="body2">{p.title}</Typography>
            ) : (
              p.title
            ),
        };
  return (
    <div
      style={{
        borderRadius: data.rounded ? 24 : 0,
        height: data.height,
        width: data.width,
        position: data.contained ? "relative" : "absolute",
        display: "flex",
        top: 0,
        left: 0,
        justifyContent: "center",
        zIndex: data.zIndex || 9999,
        alignItems: "center",
        background: data.background,
      }}
    >
      {data.error ? (
        <Reload {...data} />
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Lottie
            loop
            speed={1.5}
            animationData={loader}
            play
            style={{
              height: data.isLarge === undefined ? "50%" : "100%",
              width: "100%",
            }}
          />
          <div style={{ height: "16px" }} />
          {data.title}
        </div>
      )}
    </div>
  );
};

export default Loader;
