import {Bot} from "../createBotsStore";
import {DEFAULT_LANGUAGE} from "dangerous/staticStrings";
import {getVoices} from "services/audio/getVoices";
import {humanReadableId} from "utils/humanReadableId";

export const voices = getVoices()[DEFAULT_LANGUAGE];

export const makeDefaultBot = (isWithFakeData?: boolean) => {
    const name = "";
    const componentId = "";
    const gid = isWithFakeData ? humanReadableId() : "";
    const avatar = "";
    const voice = "";
    const presetId = avatar;

    return {
        name,
        componentId,
        gid,
        channels: {},
        integrations: {},
        extras: {
            lastUpdate: Date.now(),
            isFirstEdit: true,
            fallback: ""
        },
        avatar: {
            image: avatar,
            voice,
            presetId
        }
    } as Bot;
};
