import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import ForwardOutlinedIcon from "@material-ui/icons/ForwardOutlined";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { useWindowSize } from "utils/hooks/useWindowSize";

export type ArrowDirection = "left" | "bottom" | "right" | "top";
type Rect = { x: number; y: number; height: number; width: number };

const useStyles = makeStyles((theme) => {
  return {
    container: { position: "relative", height: "100%", pointerEvents: "none" },
    children: { pointerEvents: "all", height: "100%" },
    tooltip: {
      padding: "0px",
      backgroundColor: "transparent",
      position: "fixed",
      color: theme.custom.palette.a.main,
      zIndex: 30000,
    },
    left: {
      animation: "$boing_left 450ms ease-out",
      animationIterationCount: "infinite",
      animationDirection: "alternate",
      right: "100%",
      top: ({ rect }: { rect: Rect }) =>
        `calc(${rect.y + rect.height / 2}px - 20px)`, // 20px is half own height
      left: ({ rect }: { rect: Rect }) => `calc(${rect.x - 32}px)`, // 17.5px is half the width of the arrow
    },
    right: {
      animation: "$boing_right 450ms ease-out",
      animationIterationCount: "infinite",
      animationDirection: "alternate",
      bottom: `0px`,
      left: `100%`,
    },
    top: {
      animation: "$boing_top 450ms ease-out",
      animationIterationCount: "infinite",
      animationDirection: "alternate",
      bottom: ({ rect }: { rect: Rect }) => `calc(${rect.y - 96}px)`, // 20px is half own height
      left: ({ rect }: { rect: Rect }) =>
        `calc(${rect.x + rect.width / 2}px + 17.5px)`, // 17.5px is half the width of the arrow
    },
    bottom: {
      animation: "$boing_bottom 450ms ease-out",
      animationIterationCount: "infinite",
      animationDirection: "alternate",
      left: ({ rect }: { rect: Rect }) =>
        `calc(${rect.x + rect.width / 2}px - 17.5px)`, // 17.5px is half the width of the arrow
    },
    "@keyframes boing_left": {
      from: {
        transform: "translate(0px, 0px)",
      },
      to: {
        transform: "translate(-50px, 0px)",
      },
    },
    "@keyframes boing_right": {
      from: {
        transform: "translate(0px, 0px)",
      },
      to: {
        transform: "translate(50px, 0px)",
      },
    },
    "@keyframes boing_top": {
      from: {
        transform: "translate(0px, 0px)",
      },
      to: {
        transform: "translate(0px, 50px)",
      },
    },
    "@keyframes boing_bottom": {
      from: {
        transform: "translate(0px, 0px)",
      },
      to: {
        transform: "translate(0px, 50px)",
      },
    },
  };
});

const getRotate = (direction?: ArrowDirection) => {
  let r = "rotate(0deg)";

  switch (direction) {
    case "right":
      r = "rotate(180deg)";
      break;
    case "top":
      r = "rotate(90deg)";
      break;
    case "bottom":
      r = "rotate(-90deg)";
      break;
    default:
      break;
  }

  return r;
};

let timer: any = 0;

export const IndicatorArrow: React.FC<{
  isOpen?: boolean;
  delay?: number;
  direction?: ArrowDirection;
}> = ({ isOpen = false, direction, delay = 0, children }) => {
  const rectRef = useRef<HTMLDivElement | null>(null);
  const [rect, setRect] = useState({ x: 0, y: 0, width: 0, height: 0 });
  const classes = useStyles({ rect });
  const [isActive, setIsActive] = useState(false);
  const size = useWindowSize();

  useLayoutEffect(() => {
    const c = rectRef.current;
    if (!c) return;
    setRect(c.getBoundingClientRect());
  }, [rectRef, rectRef.current, size]);

  useEffect(() => {
    if (!isOpen) {
      timer && clearTimeout(timer);
      setIsActive(false);
      return;
    }
    timer = setTimeout(() => setIsActive(true), delay);
  }, [isOpen]);

  return (
    <div ref={rectRef} id="indicator-arrow" className={classes.container}>
      <div className={classes.children}>{children}</div>
      {isActive ? (
        <div
          className={clsx(classes.tooltip, {
            [classes.left]: direction === "left",
            [classes.right]: direction === "right",
            [classes.top]: direction === "top",
            [classes.bottom]: direction === "bottom",
          })}
        >
          <ForwardOutlinedIcon
            style={{ transform: getRotate(direction) }}
            fontSize="large"
          />
        </div>
      ) : null}
    </div>
  );
};
