import { action, observable } from "mobx";

import { AuthStore } from "marketplace-2/store/auth/createAuthStore";
import { ContainerStore } from "marketplace-2/store/container/ContainerStore";
import { deleteBot } from "data/api/rest/requests/bots/v2/deleteBot";
import { fetchBotDataAndUpdate } from "./functions/fetchBotDataAndUpdate";
import { getBotResources } from "data/api/rest/requests/billing/getBotResources";
import { getBots } from "data/api/rest/requests/bots/v2/getBots";
import { getBotsResources } from "data/api/rest/requests/billing/getBotsResources";
import { makeDefaultBot } from "./data/makeDefaultBot";
import { runFetchBotData } from "./reactions/runFetchBotData";
import { runPopulateOnStart } from "./reactions/runPopulateOnStart";
import { runUpateBotOnSelected } from "./reactions/runUpateBotOnSelected";
import { sortBotsByLastUpdated } from "./functions/sortBotsByLastUpdated";
import { updateBot } from "data/api/rest/requests/bots/v2/updateBot";
import { updateBotOnFinish } from "./functions/updateBotOnFinish";
import { urls } from "dangerous/staticStrings";

const _private = observable({
  editor: {
    current: makeDefaultBot(false),
    prevState: "",
    stats: {
      resources: { seconds: 0, conversations: 0, phone_numbers: 0 },
      resourcesTable: [] as BotResources[],
      isFetched: false,
      isResourcesTableFetched: false,
    },
  },
});

export const createBotsStore = (container: ContainerStore, auth: AuthStore) => {
  const store = observable({
    isLoading: false,
    isBotsLoaded: false,
    resetCurrentToRandom: action(() => {
      const firstBot =
        store.bots[store.currentId] ||
        Object.values(store.bots).sort(sortBotsByLastUpdated)?.[0];
      _private.editor.current = firstBot || makeDefaultBot();
    }),
    update: action(async (isKeepingEditorOpen?: boolean) => {
      const bot = _private.editor.current;
      if (!bot) {
        throw new Error(
          "Tried to edit a virtual human without an active one in editor"
        );
      }
      bot.extras.lastUpdate = Date.now();
      const response = await updateBot(bot);
      if (!response) {
        container.messages.addMessage(
          "Could not update virtual human",
          "error"
        );
      }
      !isKeepingEditorOpen && store.editor.close();
    }),
    delete: action(async (botToDelete?: Bot) => {
      const bot = botToDelete || _private.editor.current;
      if (!bot) {
        throw new Error(
          "Tried to delete a virtual human without an active virtual human in editor"
        );
      }
      _private.editor.stats.isResourcesTableFetched = false;
      const response = await deleteBot(bot);
      if (response) {
        delete store.bots[bot.gid];
      } else {
        container.messages.addMessage(
          "Could not delete virtual human",
          "error"
        );
      }
      const bots = Object.values(store.bots);

      if (bots.length) {
        _private.editor.current = bots[0];
        store.editor.isOpen && store.editor.close();
      } else {
        store.resetCurrentToRandom();
      }
    }),
    create: action(async () => {
      store.editor.open(observable(makeDefaultBot()));
      return store.editor.current;
    }),
    inspect: action((bot: Bot) => {
      _private.editor.current = bot;
    }),
    inspectByGid: action((gid: string) => {
      const bot = store.bots[gid];
      if (bot) {
        _private.editor.current = bot;
        store.isLoading = true;
        fetchBotDataAndUpdate({
          store,
          currentId: gid,
          isBotsLoaded: true,
        }).then((v) => {
          store.isLoading = false;
        });
      }
    }),
    refetchBots: action(async () => {
      try {
        store.bots = await getBots();
      } catch (e: any) {
        container.messages.addMessage(
          "Could not retrieve bots list",
          e.message
        );
      }
    }),
    populate: action(async () => {
      if (!auth.isAuthenticated || !auth.isReady) return;
      store.isLoading = true;
      await store.refetchBots();
      store.isBotsLoaded = true;
      store.isLoading = false;
      const botsArr = Object.values(store.bots).sort(sortBotsByLastUpdated);
      botsArr.length &&
        !_private.editor.current.gid &&
        (_private.editor.current = botsArr[0]);
    }),
    get currentId() {
      return _private.editor.current?.gid || "";
    },
    bots: {} as { [key: string]: Bot },
    editor: {
      isOpen: false,
      stats: {
        get resources() {
          _private.editor.current &&
            !_private.editor.stats.isFetched &&
            getBotResources(_private.editor.current.gid).then(
              (r) => (_private.editor.stats.resources = r)
            );
          _private.editor.stats.isFetched = true;
          return _private.editor.stats.resources;
        },
        get resourcesTable() {
          if (!auth.isReady || !auth.isAuthenticated)
            return _private.editor.stats.resourcesTable;
          if (!_private.editor.stats.isResourcesTableFetched)
            getBotsResources().then((r) => {
              _private.editor.stats.resourcesTable = r;
            });
          _private.editor.stats.isResourcesTableFetched = true;
          return _private.editor.stats.resourcesTable;
        },
        fetchResources: action(() => {
          if (!auth.isReady || !auth.isAuthenticated) return;
          getBotsResources().then((r) => {
            _private.editor.stats.resourcesTable = r;
          });
          _private.editor.stats.isResourcesTableFetched = true;
        }),
      },
      createOrUpdateBot: action(async () => {
        await updateBotOnFinish(store, _private, auth.isAuthenticated);
        _private.editor.stats.isResourcesTableFetched = false;
      }),
      close: action(async (isAbort?: boolean) => {
        const c = store.editor.current;

        if (isAbort) {
          store.editor.isOpen = false;
          if (!c.avatar.image || !c.avatar.voice || !c.name) {
            // @ts-ignore
            window.router_history?.push(
              urls.dashboard + window.location.search
            );
          }
          return;
        }

        await store.editor.createOrUpdateBot();
        store.editor.isOpen = false;

        _private.editor.prevState = "";

        await store.refetchBots();
      }),
      open: action((newCurrent?: Bot) => {
        newCurrent && (_private.editor.current = newCurrent);
        _private.editor.prevState = JSON.stringify(_private.editor.current);
        store.editor.isOpen = true;
      }),
      get current() {
        return _private.editor.current;
      },
    },
  });

  runUpateBotOnSelected({ store });

  runPopulateOnStart({ auth, store });

  runFetchBotData({ store });
  return store;
};

export type BotsStore = ReturnType<typeof createBotsStore>;
export type BotsStorePrivate = typeof _private;
export type BotThirdPartyConnection = {
  id: string;
  isActive?: boolean;
  metadata?: { [key: string]: any };
};
export type Bot = {
  name: string;
  componentId: string;
  gid: string;
  channels: { [key: string]: BotThirdPartyConnection };
  integrations: { [key: string]: BotThirdPartyConnection };
  language_code: string;
  avatar: {
    image: string;
    voice: string;
    presetId: string;
  };
  extras: {
    defaultName: string;
    lastUpdate: number;
    isFirstEdit: boolean;
    fallback?: string;
    description?: string;
  };
};
export type BotResources = {
  api_calls: number;
  avatar: { voice_id: string; id: string };
  gid: string;
  name: string;
  phone_purchases: number;
  zoom_seconds: number;
};
