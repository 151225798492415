import {COMPONENT_DONE, componentDefaultActions} from "dangerous/staticStrings";
import {NodeAction, NodeTypeV2} from "../types";

import {humanReadableId} from "utils/humanReadableId";

export const makeDefaultActions = (type: NodeTypeV2) => {
    let actions = [] as NodeAction[];
    switch (type) {
        case NodeTypeV2.say:
        case NodeTypeV2.audiofile:
        case NodeTypeV2.email:
        case NodeTypeV2.saveInput:
        case NodeTypeV2.saveAggregated:
        case NodeTypeV2.saveParams:
        case NodeTypeV2.saveParamsAggregated:
            actions.push({id: humanReadableId(), index: 0, exitPosition: {x: 0, y: 0}});
            break;
        case NodeTypeV2.component:
        case NodeTypeV2.faq:
        case NodeTypeV2.own:
        case NodeTypeV2.glue:
        case NodeTypeV2.scheduleNegotiator:
        case NodeTypeV2.scheduleCommit:
            actions = Array.from(componentDefaultActions, (a, index) => {
                return {id: humanReadableId(), index, exitPosition: {x: 0, y: 0}, data: a};
            });
            break;
        case NodeTypeV2.out_of_context:
            actions = [{id: humanReadableId(), index: 0, exitPosition: {x: 0, y: 0}, data: COMPONENT_DONE}];
            break;
        default:
            break;
    }
    return actions;
};
