import { Dialog, Icon, Typography } from "@a-i/ui";
import { ChannelType } from "channels/types";
import { useBotChannels } from "data/api/gql/hooks/useBotChannels";
import { useBotInfo } from "data/api/gql/hooks/useBotInfo";
import { useBots } from "data/api/gql/hooks/useBots";
import { iconMaps } from "logs/data";
import { cancelBotSubcription } from "pricing/comm/cancelBotSubcription";
import React, { useState } from "react";
import { SecondaryButton } from "theme/components/elements/buttons/SecondaryButton";
import { DividerElement } from "utils/elments/DividerElement";
import { useDialogContext } from "./context/useDialogContext";
import { UnsubscribeBotDialogHeader } from "./UnsubscribeBotDialogHeader";

export const UnsubscribeBotDialog = () => {
  const { select, dispatch, actions } = useDialogContext();
  const [isLoading, setIsLoading] = useState(false);

  const { refetch } = useBots();

  const unsubscribingId = select((s) => s.bot.unsubscribing);

  const isOpen = !!unsubscribingId;

  const onCancel = () => dispatch(actions.bot.cancelUnsubscribe());

  const {
    info: { name, subscription },
    loading: infoLoading,
    error: infoError,
  } = useBotInfo({ botId: unsubscribingId });
  const { botChannels } = useBotChannels({ botId: unsubscribingId });

  const onOk = async () => {
    setIsLoading(true);
    await cancelBotSubcription(parseInt(unsubscribingId));
    refetch();
    onCancel();
    setIsLoading(false);
  };

  const loading = isLoading || infoLoading;

  return (
    <Dialog
      modal={{
        isOpen,
        onClose: onCancel,
        isFitContent: undefined,
      }}
      header={{ title: <UnsubscribeBotDialogHeader /> }}
      footer={{
        buttons: [
          <SecondaryButton
            disabled={loading}
            onClick={onOk}
            variant="outlined-inverse"
            key="disconnect">
            Unsubscribe
          </SecondaryButton>,
          <SecondaryButton
            disabled={loading}
            onClick={onCancel}
            variant="outlined-inverse"
            key="keep channel">
            Keep plan
          </SecondaryButton>,
        ],
      }}>
      <DividerElement h={3} />
      <Typography>
        Your subscription will automatically expire at the next bill date.
      </Typography>
      <DividerElement h={3} />
      <Typography>{`Cancelling ${name} plan will have a critical effect on some features.`}</Typography>
      <DividerElement h={3} />
      {botChannels ? (
        <>
          <Typography variant="description" fontWeight={600}>
            Channels:
          </Typography>
          <DividerElement h={3} />

          {botChannels
            .filter(
              (channel) =>
                channel.jsonConfig.channel_name !== ChannelType.dashboard
            )
            .map((channel) => {
              const channelName = channel.jsonConfig.channel_name;
              return (
                <div
                  key={channel.id}
                  style={{ display: "flex", alignItems: "center" }}>
                  <Icon size={3}>
                    {iconMaps.channels.get(channelName as ChannelType)}
                  </Icon>
                  <DividerElement w={1} />
                  <Typography fontWeight={600}>
                    {channelName[0]
                      .toUpperCase()
                      .concat(channelName.slice(1, channelName.length))}
                  </Typography>
                </div>
              );
            })}
        </>
      ) : null}

      <DividerElement h={3} />
    </Dialog>
  );
};
