import {action, observable, reaction} from "mobx";

import {AuthStore} from "../auth/createAuthStore";
import {DrawerOption} from "../../container/drawer/drawerOptions";
import {ReportType} from "marketplace-2/container/BugReportDialog";
import {getUserState} from "data/api/rest/requests/getUserState";
import {setUserState} from "data/api/rest/requests/setUserState";

export type MessageType = "error" | "success" | "info" | "warning" | undefined;
export type Message = {type: MessageType; value: string};

const CONFIG_STATE_KEY = "app_config";
const MESSAGE_DURATION = 3000;
let messageTimeout = 0 as unknown as NodeJS.Timeout;

const makeDefaultConfig = () => {
    return {isDark: true};
};

const dummyFunc = () => {};

export const createContainerStore = (auth: AuthStore) => {
    const messages = observable([] as Message[]);
    const config = observable(makeDefaultConfig());

    const _private = observable({
        deletion: {
            text: "",
            onConfirm: dummyFunc,
            isOpen: false
        }
    });

    const store = observable({
        bugReport: {
            isOpen: observable.box(false),
            type: "bug_report" as ReportType
        },
        options: {
            get darkMode() {
                return true; //!!config.isDark;
            },
            set darkMode(isDark: boolean) {
                config.isDark = isDark;
                auth.isReady && setUserState(CONFIG_STATE_KEY, config);
            },
            isOpen: false
        },
        drawer: {isHidden: false, isOpen: false, selected: DrawerOption.botsStudio},
        search: {isOpen: false},
        messages: {
            currentMessage: undefined as Message | undefined,
            addMessage: (value: string, type: MessageType = "info") => {
                messages.push({value, type});
                !store.messages.currentMessage && store.messages.onMessageTimeout();
            },
            onMessageTimeout: () => {
                messageTimeout && clearTimeout(messageTimeout);
                store.messages.currentMessage = messages.pop();
                messages.length === 1 && store.messages.doTimeout();
            },
            doTimeout: () => {
                messageTimeout && clearTimeout(messageTimeout);
                messageTimeout = setTimeout(store.messages.onMessageTimeout, MESSAGE_DURATION);
            },
            deletion: {
                get isOpen() {
                    return _private.deletion.isOpen;
                },
                get text() {
                    return _private.deletion.text;
                },
                get onConfirm() {
                    return _private.deletion.onConfirm;
                },
                open: action((text: string, onConfirm: () => void) => {
                    _private.deletion.isOpen = true;
                    _private.deletion.text = text;
                    _private.deletion.onConfirm = () => {
                        store.messages.deletion.close();
                        onConfirm();
                    };
                }),
                close: action(() => {
                    _private.deletion.isOpen = false;
                    _private.deletion.text = "";
                    _private.deletion.onConfirm = dummyFunc;
                })
            }
        },
        isSystemPending: false
    });

    reaction(
        () => {
            return {isLoggedIn: auth.isAuthenticated, isAuthComplete: !auth.isLoading, isReady: auth.isReady};
        },
        async ({isLoggedIn, isAuthComplete, isReady}) => {
            if (!isAuthComplete || !isLoggedIn || !isReady) return;
            const reply = await getUserState(CONFIG_STATE_KEY);
            if (!reply) return;
            action(() => Object.keys(reply).forEach((key) => (config[key as keyof AppConfigState] = reply[key])))();
        }
    );

    return store;
};

export type ContainerStore = ReturnType<typeof createContainerStore>;
export type AppConfigState = ReturnType<typeof makeDefaultConfig>;
