export const VideoOffIcon = () => {
  return (
    <svg
      width="56"
      height="53"
      viewBox="0 0 56 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="56" height="52.45" fill="#141326" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4025 37.1375C36.1155 36.3777 36.572 35.4131 36.7075 34.38L44.4825 37.835C44.8631 38.0046 45.28 38.0763 45.6953 38.0438C46.1107 38.0112 46.5113 37.8754 46.8608 37.6486C47.2103 37.4218 47.4976 37.1113 47.6966 36.7452C47.8955 36.3791 47.9998 35.9691 48 35.5525V16.8975C47.9996 16.4812 47.8953 16.0716 47.6965 15.7058C47.4977 15.34 47.2107 15.0297 46.8615 14.803C46.5124 14.5762 46.1121 14.4403 45.6971 14.4075C45.2821 14.3746 44.8655 14.446 44.485 14.615L36.7075 18.07C36.5486 16.8677 35.9582 15.7642 35.0462 14.9648C34.1342 14.1655 32.9627 13.7249 31.75 13.725H18.68L20.465 16.225H31.75C32.413 16.225 33.0489 16.4884 33.5178 16.9572C33.9866 17.4261 34.25 18.062 34.25 18.725V33.725C34.2505 34.1814 34.126 34.6293 33.89 35.02L35.4025 37.1375ZM11.57 16.675C11.2396 16.9051 10.9698 17.2118 10.7834 17.5687C10.5971 17.9257 10.4999 18.3224 10.5 18.725V33.725C10.5 34.388 10.7634 35.0239 11.2322 35.4928C11.7011 35.9616 12.337 36.225 13 36.225H25.535L27.32 38.725H13C11.6739 38.725 10.4021 38.1982 9.46447 37.2605C8.52678 36.3229 8 35.0511 8 33.725V18.725C8 17.0375 8.835 15.545 10.1175 14.64L11.5675 16.675H11.57ZM45.5 35.55L36.75 31.6625V20.7875L45.5 16.8975V35.5525V35.55ZM34.4825 44.45L9.4825 9.45L11.5175 8L36.5175 43L34.4825 44.45Z"
        fill="#F4556F"
      />
    </svg>
  );
};
