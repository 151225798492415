import {Typography, makeStyles} from "@material-ui/core";

import {AnalyticType} from "analytics/AnalyticType";
import {CHANNEL_NAME} from "dangerous/staticStrings";
import {ChannelDialogParams} from "channels/channelsMap";
import {ChannelType} from "channels/types";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import {InfoBadge} from "theme/components/elements/InfoBadge";
import {MainButton} from "theme/components/elements/buttons/MainButton";
import React from "react";
import Subheader from "./Subheader";
import {TextButton} from "theme/components/elements/buttons/TextButton";
import {embeddedInfographics} from "channels/infographics";
import {makeDefaultEmbeddedData} from "./data";
import {useChannelFunctions} from "data/api/gql/hooks/useChannelFunctions";

const useStyles = makeStyles((theme) => {
    return {
        container: {
            display: "flex",
            flexDirection: "column",
            marginTop: theme.spacing(4),
            height: "100%",
            overflow: "auto"
        },
        logo: {display: "flex"},
        logoText: {display: "flex"},
        body: {
            paddingTop: theme.spacing(3),
            display: "flex",
            width: "100%",
            justifyContent: "space-between"
        },
        explanation: {width: "100%"},
        buttonContainer: {
            display: "flex",
            justifyContent: "center",
            position: "absolute",
            bottom: `${theme.spacing(2)}px`,
            left: "0px",
            width: "100%"
        },
        footer: {marginTop: theme.spacing(22)},
        settingsMenu: {
            display: "flex"
        },
        comingSoon: {
            color: theme.custom.palette.e.alt
        },
        divider: {
            borderTop: "0.5px solid",
            borderColor: theme.custom.palette.d.secondary
        },
        infographics: {
            textAlign: "center",
            paddingTop: theme.spacing(6)
        }
    };
});

const data = makeDefaultEmbeddedData();

export const EmbeddedBody: React.FC<ChannelDialogParams> = ({botId, botName, botChannels, close}) => {
    const classes = useStyles();

    const {create, remove, update, loading} = useChannelFunctions({botId});

    const channel = botChannels.find((n) => {
        return n.jsonConfig.channel_name === ChannelType.embedded;
    });

    const isConnected = !!channel;

    const confirmDelete = () => {
        const id = botChannels.find((n) => {
            return n.jsonConfig.channel_name === ChannelType.embedded;
        })?.id;

        if (id === undefined) return;

        remove({variables: {id}});
        close();
    };

    const confirmCreate = () => {
        create({
            variables: {
                jsonConfig: {...data, channel_name: ChannelType.embedded, channel_id: `02-${channel?.nodeId}`},
                botId: parseInt(botId),
                channelName: CHANNEL_NAME.EMBEDDED
            }
        });
    };

    return (
        <div className={classes.container}>
            <div className={classes.logo}>
                <div className={classes.logoText}>
                    <Typography variant="h6">Embed your bot in a</Typography>&nbsp;
                    <Typography variant="h6">
                        <b>Website</b>
                    </Typography>
                </div>
            </div>
            <div>
                <div className={classes.body}>
                    <Typography className={classes.explanation}>
                        {isConnected ? (
                            <Subheader
                                data={{
                                    ...data,
                                    channel_id: `02-${channel?.nodeId}`
                                }}
                                {...{isConnected, botName}}
                            />
                        ) : (
                            `Click the button below to connect. Then, simply copy the html code to your website.`
                        )}
                    </Typography>
                </div>
                <div className={classes.footer}>
                    <div className={classes.settingsMenu}>
                        <Typography>Settings</Typography>
                        <TextButton
                            analytics_event={AnalyticType["embedded-channel-dialog-customize"]}
                            variant={"secondary"}
                            disabled={true}>
                            <div style={{display: "flex"}}>
                                Customize
                                <div style={{paddingLeft: 4}}>
                                    <InfoBadge text="soon" variant="soon" />
                                </div>
                            </div>
                        </TextButton>
                    </div>
                    <hr className={classes.divider} />
                    <div className={classes.infographics}>{embeddedInfographics}</div>
                </div>
            </div>
            <div className={classes.buttonContainer}>
                {isConnected ? (
                    <TextButton
                        variant={"secondary"}
                        analytics_event={AnalyticType["embed-channel-dialog-disconnect"]}
                        onClick={confirmDelete}>
                        Disconnect
                    </TextButton>
                ) : (
                    <MainButton
                        analytics_event={AnalyticType["embed-channel-dialog-connect"]}
                        onClick={confirmCreate}
                        isLoading={loading}
                        hoverContent={<CheckRoundedIcon fontSize="small" />}>
                        Connect
                    </MainButton>
                )}
            </div>
        </div>
    );
};
