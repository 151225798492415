import {fetchWrapper} from "data/api/rest/fetchWrapper";

export const getAvailableNumbers = async (iso_language_code: string | undefined) => {
    try {
        const r = await fetchWrapper(`/v2/bot/channel/phone/available_numbers?iso_language_code=${iso_language_code}`);
        if (!r.ok) throw new Error(r.statusText);
        const data = (await r.json()) as {numbers: {phone_number: string}[]};
        return data.numbers.map((v) => v.phone_number);
    } catch (e) {
        console.error(e);
        return [];
    }
};
