import {Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            display: "flex",
            gap: theme.spacing(1),
            alignItems: "center"
        }
    };
});

export const DialogHeaderContainer: React.FC = ({children}) => {
    const classes = useStyles();

    return (
        <div id="DialogHeaderContainer" className={classes.container}>
            {children}
        </div>
    );
};
