import { gql } from "@apollo/client";
import { pagination } from "./pagination";

export const GET_BOTS = gql`
    query getBots($cursor: Cursor) {
        bots(first: 1000, after: $cursor) {
            edges {
                node {
                    id
                    nodeId
                    firstName
                    avatar
                    extras
                    entrypoint {
                        name
                        id
                        humanId
                    }
                    phoneSeconds
                    sessionsCount
                    zoomSeconds
                    botsSubscription {
                        planName
                    }
                    channels {
                        edges {
                            node {
                                id
                                createdAt
                                jsonConfig
                                nodeId
                            }
                        }
                    }
                }
            }
            ${pagination}
        }
    }
`;
