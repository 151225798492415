import {gql} from "@apollo/client";

export const UPDATE_CHANNEL = gql`
    mutation updateChannel($jsonConfig: JSON!, $id: Int!) {
        updateChannel(input: {patch: {jsonConfig: $jsonConfig}, id: $id}) {
            channel {
                id
                nodeId
            }
        }
    }
`;
