import { action } from "mobx";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useRootStore } from "marketplace-2/store/useRootStore";

export const useAuthenticate = () => {
  const { auth } = useRootStore((store) => {
    return {
      auth: store.auth,
    };
  });

  const auth0 = useAuth0();

  useEffect(
    action(() => {
      Object.keys(auth0).forEach((k) => {
        // @ts-ignore
        (auth as Object).hasOwnProperty(k) && (auth[k] = auth0[k]);
      });
    }),
    [auth0]
  );
};
