export enum AnalyticType {
  "payment_success" = "payment_success",
  "plan-action-clicked" = "plan-action-clicked",
  "wordpress_channel_dialog_disconnect" = "wordpress_channel_dialog_disconnect",
  "shared" = "shared",
  "share-clicked" = "share-clicked",
  "plan-opened" = "plan-opened",
  "billing-cycle-changed" = "billing-cycle-changed",
  "pricing-page-landed" = "pricing-page-landed",
  "personality-chosen" = "personality-chosen",
  "personality-details-page-landed" = "personality-details-page-landed",
  "template-selected" = "template-selected",
  "template-page-landed" = "template-page-landed",
  "personality-page-landed" = "personality-page-landed",
  "plan-purchase-success" = "plan-purchase-success",
  "embed-channel-dialog-disconnect" = "embed-channel-dialog-disconnect",
  "embed-channel-dialog-connect" = "embed-channel-dialog-connect",
  "onboarding-finish" = "onboarding-finish",
  "node_added" = "node_added",
  "auth0-bug" = "auth0-bug",
  "name_recording_stop_clicked" = "name_recording_stop_clicked",
  "name_recording_start_clicked" = "name_recording_start_clicked",
  "outro-dialog-dashboard" = "outro-dialog-dashboard",
  "create-bot" = "create-bot",
  "zapier-intergration-dialog-integrate" = "zapier-intergration-dialog-integrate",
  "twilio-intergration-dialog-integrate" = "twilio-intergration-dialog-integrate",
  "sendgrid-intergration-dialog-integrate" = "sendgrid-intergration-dialog-integrate",
  "contact-expert-dialog-start-building" = "contact-expert-dialog-start-building",
  "wordpress-channel-dialog-go-to-plugin" = "wordpress-channel-dialog-go-to-plugin",
  "channels-test-button-clicked" = "channels-test-button-clicked",
  "test-button-clicked" = "test-button-clicked",
  "channels-test-button-invited" = "channels-test-button-invited",
  "video-channel-dialog-disconnect" = "video-channel-dialog-disconnect",
  "video-channel-dialog-save" = "video-channel-dialog-save",
  "telegram-dialog-disconnect" = "telegram-dialog-disconnect",
  "telegram-dialog-update-token" = "telegram-dialog-update-token",
  "telegram-dialog-save" = "telegram-dialog-save",
  "phone-dialog-disconnect" = "phone-dialog-disconnect",
  "phone-dialog-save" = "phone-dialog-save",
  "facebook-channel-dialog-update-permissions" = "facebook-channel-dialog-update-permissions",
  "facebook-dialog-allow-permissions" = "facebook-dialog-allow-permissions",
  "audiocodes-dialog-disconnect" = "audiocodes-dialog-disconnect",
  "audiocodes-dialog-manage" = "audiocodes-dialog-manage",
  "audiocodes-dialog-connect" = "audiocodes-dialog-connect",
  "alexa-dialog-allow-permissions" = "alexa-dialog-allow-permissions",
  "alexa-dialog-disconnect" = "alexa-dialog-disconnect",
  "alexa-dialog-save" = "alexa-dialog-save",
  "alexa-channel-dialog-try-it-out" = "alexa-channel-dialog-try-it-out",
  "billing-plan-enterprise-clicked" = "billing-plan-enterprise-clicked",
  "billing-plan-1-clicked" = "billing-plan-1-clicked",
  "billing-plan-2-clicked" = "billing-plan-2-clicked",
  "billing-plan-3-clicked" = "billing-plan-3-clicked",
  "builder-tutorial-last-stage-create-a-bot" = "builder-tutorial-last-stage-create-a-bot",
  "builder-tutorial-last-stage-publish" = "builder-tutorial-last-stage-publish",
  "component-card-clicked-delete" = "component-card-clicked-delete",
  "guest-badge-sign-in" = "guest-badge-sign-in",
  "guest-badge-sign-up" = "guest-badge-sign-up",
  "component-card-clicked" = "component-card-clicked",
  "section-header-Create new component" = "section-header-Create new component",
  "section-header-Create new bot" = "section-header-Create new bot",
  "bot-card-clicked" = "bot-card-clicked",
  "intro-dialog-sign-in" = "intro-dialog-sign-in",
  "intro-appbar-sign-up" = "intro-appbar-sign-up",
  "intro-appbar-sign-in" = "intro-appbar-sign-in",
  "twilio-channel-dialog-disconnect" = "twilio-integration-dialog-disconnect",
  "twilio-channel-dialog-connect" = "twilio-channel-dialog-connect",
  "twilio-integration-dialog-disconnect" = "twilio-integration-dialog-disconnect",
  "sengrid-integration-dialog-disconnect" = "sengrid-integration-dialog-disconnect",
  "voice-selection-card-clicked" = "voice-selection-card-clicked",
  "integrations-box-item-disconnect" = "integrations-box-item-disconnect",
  "integrations-box-item-edit" = "integrations-box-item-edit",
  "integration-card-connect-clicked" = "integration-card-connect-clicked",
  "channels-card-connect-clicked" = "channels-card-connect-clicked",
  "channels-box-entry-disconnect" = "channels-box-entry-disconnect",
  "channels-box-entry-edit" = "channels-box-entry-edit",
  "brain-component-actions-disconnect" = "brain-component-actions-disconnect",
  "brain-component-actions-edit" = "brain-component-actions-edit",
  "brain-component-actions-change" = "brain-component-actions-change",
  "tooltip-action-dismiss" = "tooltip-action-dismiss",
  "tooltip-action" = "tooltip-action",
  "component-card-close-options" = "component-card-close-options",
  "component-card-open-options" = "component-card-open-options",
  "component-card-delete" = "component-card-delete",
  "component-card-edit" = "component-card-edit",
  "component-card-click" = "component-card-click",
  "back-button-clicked" = "back-button-clicked",
  "image-card-selected-scene" = "image-card-selected-scene",
  "embedded-channel-dialog-customize" = "embedded-channel-dialog-customize",
  "embedded-channel-dialog-settings" = "embedded-channel-dialog-settings",
  "embedded-channel-dialog-copy-embed-code" = "embedded-channel-dialog-copy-embed-code",
  "template-card-clicked" = "template-card-clicked",
  "create-new-project-card-clicked" = "create-new-project-card-clicked",
  "upload-component-file-card-clicked" = "upload-component-file-card-clicked",
  "contact-expert-card-clicked" = "contact-expert-card-clicked",
  "registered_user_sign_in" = "registered_user_sign_in",
  "application_loaded" = "application_loaded",
  "live_help_clicked" = "live_help_clicked",
  "name_recording_done" = "name_recording_done",
  "recorded_name_rejected" = "recorded_name_rejected",
  "recorded_name_accepted" = "recorded_name_accepted",
  "brain-component-options-close" = "brain-component-options-close",
  "page_opened" = "page_opened",
  "tutorial_stage_completed" = "tutorial_stage_completed",
  "tutorial_stage_changed" = "tutorial_stage_changed",
  "tutorial_hidden" = "tutorial_hidden",
  "tutorial_shown" = "tutorial_shown",
  "project_created" = "project_created",
  "project_deleted" = "project_deleted",
  "project_config_downloaded" = "project_config_downloaded",
  "undo" = "undo",
  "update_component" = "update_component",
  "channel_open" = "channel_open",
  "channel_create" = "channel_create",
  "channel_delete" = "channel_delete",
  "integration_open" = "integration_open",
  "integration_create" = "integration_create",
  "integration_delete" = "integration_delete",
  "sign_in" = "sign_in",
  "sign_up" = "sign_up",
  "error" = "error",
  "gitter_clicked" = "gitter_clicked",
  "learning_center_opened" = "learning_center_opened",
  "tut_clicked_onboarding" = "tut_clicked_onboarding",
  "tut_clicked_navigation" = "tut_clicked_navigation",
  "tut_clicked_unhappy_path" = "tut_clicked_unhappy_path",
  "tut_clicked_parameters" = "tut_clicked_parameters",
  "tut_clicked_multi_project" = "tut_clicked_multi_project",
  "dfToZoom-start" = "dfToZoom-start",
  "dfToZoom-save-step" = "dfToZoom-save-step",
  "dfToZoom-signUp" = "dfToZoom-signUp",
  "intent-created" = "intent-created",
  "intent-deleted" = "intent-deleted",
  "intent-edited" = "intent-edited",
  "landing_page_landed" = "landing_page_landed",
  "landing_page_create_bot" = "landing_page_create_bot",
  "bot_avatar_changed" = "bot_avatar_changed",
  "bot_avatar_next" = "bot_avatar_next",
  "bot_name_changed" = "bot_name_changed",
  "bot_name_next" = "bot_name_next",
  "bot_voice_changed" = "bot_voice_changed",
  "bot_voice_next" = "bot_voice_next",
  "bot_editor_skip" = "bot_editor_skip",
  "bot_editor_finish" = "bot_editor_finish",
  "dashboard_opened" = "dashboard_opened",
  "guest_video_channel_opened" = "guest_video_channel_opened",
  "guest_video_channel_saved" = "guest_video_channel_saved",
  "guest_video_channel_invite" = "guest_video_channel_invite",
  "dashboard_chat_opened" = "dashboard_chat_opened",
  "dashboard_chat_closed" = "dashboard_chat_closed",
  "dashboard_chat_input" = "dashboard_chat_input",
  "dashboard_chat_completed" = "dashboard_chat_completed",
  "dashboard_integrations_clicked" = "dashboard_integrations_clicked",
  "dashboard_brain_clicked" = "dashboard_brain_clicked",
  "dashboard_channels_clicked" = "dashboard_channels_clicked",
  "dashboard_edit_plan_clicked" = "dashboard_edit_plan_clicked",
  "brain_create_new_card_clicked" = "brain_create_new_card_clicked",
  "brain_create_new_from_library_clicked" = "brain_create_new_from_library_clicked",
  "brain_contact_expert_clicked" = "brain_contact_expert_clicked",
  "contact_expert_plan_basic_clicked" = "contact_expert_plan_basic_clicked",
  "contact_expert_plan_pro_clicked" = "contact_expert_plan_pro_clicked",
  "contact_expert_plan_custom_clicked" = "contact_expert_plan_custom_clicked",
  "marketing-create-bot2" = "marketing-create-bot2",
  "google_calendar_save" = "google_calendar_save",
  "scheduler_onboarding_start" = "scheduler_onboarding_start",
  "google_allow_permissions_clicked" = "google_allow_permissions_clicked",
  "bot_onboarding_completed_step_brain0" = "bot_onboarding_completed_step_brain0",
  "bot_onboarding_completed_step_brain1" = "bot_onboarding_completed_step_brain1",
  "bot_onboarding_completed_step_brain2" = "bot_onboarding_completed_step_brain2",
  "bot_onboarding_completed_step_brain3" = "bot_onboarding_completed_step_brain3",
  "bot_onboarding_completed_step_brain4" = "bot_onboarding_completed_step_brain4",
  "bot_onboarding_completed_step_brain5" = "bot_onboarding_completed_step_brain5",
  "bot_onboarding_completed_step_test" = "bot_onboarding_completed_step_test",
  "bot_onboarding_completed_step_calendar" = "bot_onboarding_completed_step_calendar",
  "bot_onboarding_completed_step_channels" = "bot_onboarding_completed_step_channels",
  "test-button-clicked-phone" = "test-button-clicked-phone",
  "test-button-clicked-video" = "test-button-clicked-video",
  "test-button-clicked-text" = "test-button-clicked-text",
  brain_changed = "brain_changed",
  blueprint_created = "blueprint_created",
  bot_created = "bot_created",
  test_complete = "test_complete",
  bot_edit_info_clicked = "bot_edit_info_clicked",
  bot_change_component_clicked = "bot_change_component_clicked",
  disconnect_brain_clicked = "disconnect_brain_clicked",
  edit_brain_project_clicked = "edit_brain_project_clicked",
  replace_brain_clicked = "replace_brain_clicked",
  bot_fab_clicked = "bot_fab_clicked",
  add_channels_clicked = "add_channels_clicked",
  channels_box_edit_channel_clicked = "channels_box_edit_channel_clicked",
  channels_box_remove_channel_clicked = "channels_box_remove_channel_clicked",
  "webrtc-video-invited" = "webrtc-video-invited",
  webrtc_invited = "webrtc_invited",
  user_menu_clicked = "user_menu_clicked",
  standalone_video_page = "standalone_video_page",
  video_fullscreen = "video_fullscreen",
  fallback_skill_open = "fallback_skill_open",
  scheduler_skill_open = "scheduler_skill_open",
  faq_action = "faq_action",
  product_tour_finished = "product_tour_finished",
  quiz_skill_open = "quiz_skill_open",
  knowledge_clicked = "knowledge_clicked",
  click_end_video = "click_end_video",
  personality_tab_clicked = "personality_tab_clicked",
  create_bot_clicked = "create_bot_clicked",
  profile_pic_clicked = "profile_pic_clicked",
  quiz_add_question = "quiz_add_question",
  quiz_delete_question = "quiz_delete_question",
  quiz_skill_delete = "quiz_skill_delete",
  skill_open = "skill_open",
  skills_progress_clicked = "skills_progress_clicked",
}
