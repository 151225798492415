import {CreateFromUrlParams} from "marketplace-2/store/components/createCreateFromUrl";
import {fetchWrapper} from "data/api/rest/fetchWrapper";

export const createComponentFromUrl = async (data: CreateFromUrlParams) => {
    try {
        const r = await fetchWrapper(`/v2/blueprint/url`, {
            method: "POST",
            body: JSON.stringify(data),
            mode: "cors",
            credentials: "include"
        });
        return r.ok;
    } catch (e) {
        console.error(e);
        return false;
    }
};
