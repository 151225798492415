import {
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import CountryList from "./CountryList";
import { countryCodes } from "./countryCodes";
import { handleTextFieldChange } from "utils/handleTextFieldChange";
import { localStorageKeys } from "dangerous/staticStrings";
import { validatePhone } from "utils/validatePhone";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      paddingRight: theme.spacing(2),
      height: theme.spacing(9),
    },
    title: {
      color: theme.custom.palette.a.main,
    },
    textField: {
      paddingTop: theme.spacing(1),
      width: theme.spacing(32),
    },
    dialCode: {
      color: theme.custom.palette.e.secondary,
      opacity: "50%",
    },
    helperText: {
      width: theme.spacing(50),
      space: "nowrap",
      paddingLeft: theme.spacing(1),
      fontWeight: 500,
    },
  };
});

const useInputClasses = makeStyles((theme) => {
  return {
    root: {
      padding: theme.spacing(0.5),
      paddingLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      borderRadius: theme.spacing(5),
      backgroundColor: theme.custom.palette.d.alt,

      textAlign: "center",
    },
  };
});

const PhoneNumberInput: React.FC<{
  phone: string;
  setPhone: (p: string) => void;
  setError?: (p: string) => void;
  countryId: string;
  setCountryId: (id: string) => void;
}> = ({ phone, countryId, setPhone, setError, setCountryId }) => {
  const classes = useStyles();
  const inputClasses = useInputClasses();
  const [activeNumber, setActiveNumber] = useState(
    phone ||
      localStorage.getItem(localStorageKeys["phone-channel-last-number"]) ||
      ""
  );
  const [helperText, setHelperText] = useState("");

  useEffect(() => {
    const isValid = validatePhone(activeNumber);
    const helperT = !activeNumber
      ? "Please provide a valid phone number"
      : !isValid
      ? "Please provide a valid phone number"
      : "";
    setHelperText(helperT);
    setError?.(helperT);
    (!activeNumber || isValid) && setPhone(activeNumber);
  }, [activeNumber]);

  return (
    <div className={classes.container}>
      <Typography variant={"body1"} className={classes.title}>
        Phone number
      </Typography>
      <TextField
        value={activeNumber}
        placeholder={"000 000000"}
        className={classes.textField}
        error={!!helperText}
        fullWidth={true}
        onChange={handleTextFieldChange(setActiveNumber)}
        helperText={helperText}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CountryList {...{ countryId, setCountryId }} />
              <Typography className={classes.dialCode}>
                {countryCodes.find((cc) => cc.code == countryId)?.dial_code ||
                  "+1"}
              </Typography>
            </InputAdornment>
          ),
          disableUnderline: true,
          style: { textAlign: "center" },
          classes: inputClasses,
        }}
        FormHelperTextProps={{
          className: classes.helperText,
        }}
      />
    </div>
  );
};

export default PhoneNumberInput;
