import { ChannelType, ZoomData } from "channels/types";
import React, { useEffect, useState } from "react";

import { ActiveIndicator } from "utils/elments/ActiveIndicator";
import { AnalyticType } from "analytics/AnalyticType";
import { BackgroundSelection } from "./BackgroundSelection";
import { CHANNEL_NAME } from "dangerous/staticStrings";
import { ChannelDialogParams } from "channels/channelsMap";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import Disclaimer from "./test-invite/Disclaimer";
import FurtherInstructions from "./test-invite/FurtherInstructions";
import { MainButton } from "theme/components/elements/buttons/MainButton";
import { TextButton } from "theme/components/elements/buttons/TextButton";
import { VideoChannelHeader } from "./VideoChannelHeader";
import { VideoChannelSubheader } from "./subheader/VideoChannelSubheader";
import { makeStyles } from "@material-ui/core";
import { randomGoodWords } from "utils/randomGoodWords";
import { useChannelFunctions } from "data/api/gql/hooks/useChannelFunctions";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      overflow: "auto",
      position: "relative",
      marginTop: theme.spacing(1),
    },
    logoContainer: {
      alignSelf: "flex-start",
    },
    bodyContainer: {
      flexGrow: 2,
    },
    footer: {
      marginTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      [theme.breakpoints.up("xl")]: {
        marginTop: theme.spacing(4),
      },
      width: "100%",
      justifyContent: "center",
    },
    btnContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    divider: {
      width: theme.spacing(1),
    },
  };
});

export const VideoChannelBody: React.FC<ChannelDialogParams> = ({
  bot,
  botName,
  botId,
  botChannels,
  close,
}) => {
  const classes = useStyles();

  const defaultData = {
    mail: "",
    scene_id: "Forest",
    wake_phrases: [] as string[],
    sleep_phrases: [] as string[],
  };

  const channel = botChannels.find(
    (e) => e.jsonConfig.channel_name === ChannelType.videoChannel
  );
  const channelId = String(channel?.id);
  const isConnected = channel?.id !== undefined;

  const { create, update, remove, loading } = useChannelFunctions({ botId });

  const [data, setData] = useState<ZoomData>(
    channel?.jsonConfig || { ...defaultData }
  );

  const [emailSuffix, setEmailSuffix] = useState(
    data?.mail.includes("@")
      ? data.mail.slice(data.mail.indexOf("@"))
      : "@bot.beyondvirtual.ai"
  );

  const [initialEmail, setInitialEmail] = useState(data.mail);

  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    setIsDirty(
      JSON.stringify(data) !== JSON.stringify(channel?.jsonConfig || {})
    );
  }, [data]);

  useEffect(() => {
    const isWithSuffix = data.mail.includes("@");
    const suffix = isWithSuffix
      ? data.mail.slice(data.mail.indexOf("@"))
      : "@bot.beyondvirtual.ai";
    setEmailSuffix(suffix);
  }, [data.mail]);

  useEffect(() => {
    channelId && channelId !== "undefined" && setIsDirty(false);
  }, [channelId]);

  useEffect(() => {
    channel?.jsonConfig && setData(channel.jsonConfig);
  }, [channel]);

  useEffect(() => {
    if (!botName || data?.mail) return;
    data.mail = `${botName.toLowerCase()}-${randomGoodWords(2)}`;
    setInitialEmail(data.mail);
  }, [botName]);

  useEffect(() => {
    setInitialEmail(data.mail);
  }, [data]);

  const connect = async () => {
    if (!channelId || channelId === "undefined") {
      await create({
        variables: {
          channelName: CHANNEL_NAME.ZOOM,
          botId: parseInt(botId),
          jsonConfig: {
            channel_name: ChannelType.videoChannel,
            ...data,
            mail: data.mail + emailSuffix,
          },
        },
      });
    } else if (data.mail === initialEmail) {
      await update({
        variables: {
          id: parseInt(channelId),
          jsonConfig: {
            ...data,
            mail: data.mail.includes("@") ? data.mail : data.mail + emailSuffix,
          },
        },
      });
    } else {
      await remove({ variables: { id: parseInt(channelId) } });
      await create({
        variables: {
          channelName: CHANNEL_NAME.ZOOM,
          botId: parseInt(botId),
          jsonConfig: {
            channel_name: ChannelType.videoChannel,
            ...data,
            mail: data.mail + emailSuffix,
          },
        },
      });
    }
    setIsDirty(false);
  };

  const disconnect = () => {
    remove({ variables: { id: parseInt(channelId) } });
    close();
  };

  const isEmailEditable = true;

  return (
    <div className={classes.container}>
      <VideoChannelHeader {...{ isTesting: false, isConnected }} />
      <div className={classes.bodyContainer}>
        <VideoChannelSubheader
          {...{
            isEditable: isEmailEditable,
            channelId,
            botName,
            email: data.mail.includes("@")
              ? data.mail.slice(0, data.mail.indexOf("@"))
              : data.mail,
            emailSuffix,
            initialEmail,
            setEmail: (s: string) => {
              setData({ ...data, mail: s.toLowerCase() });
            },
          }}
        />
        <BackgroundSelection
          {...{
            avatar: bot?.avatar.id || "",
            bg: data.scene_id as string,
            onBgSelected: (scene_id: string) => {
              setData({ ...data, scene_id });
            },
          }}
        />
        <Disclaimer />
        <FurtherInstructions boldCta />
      </div>
      <div className={classes.footer}>
        <div className={classes.btnContainer}>
          <MainButton
            analytics_event={AnalyticType["video-channel-dialog-save"]}
            disabled={!data.mail || (isConnected && !isDirty)}
            onClick={connect}
            isLoading={loading}
            hoverContent={<CheckRoundedIcon fontSize="small" />}
          >
            {!isConnected ? "Save" : "Update"}
          </MainButton>
          {isConnected && <span className={classes.divider} />}
          {isConnected && (
            <TextButton
              variant={"secondary"}
              analytics_event={AnalyticType["video-channel-dialog-disconnect"]}
              onClick={disconnect}
            >
              Disconnect
            </TextButton>
          )}
        </div>
      </div>
      <ActiveIndicator isActive={isConnected} text={"Connected"} />
    </div>
  );
};
