import {fetchWrapper} from "data/api/rest/fetchWrapper";

export const getIsHumanIdAvailable = async (id: string) => {
    if (!/^[a-z0-9-_]+$/.test(id)) {
        return "Please use only lowercase alphanumeric, - and _";
    }
    // const h = window.location.hostname;
    // if (h === "localhost" || h === "127.0.0.1" || h === "") return "";
    try {
        const reply = await fetchWrapper(`/v2/blueprint/${id}/available`, {method: "POST"});
        if (!reply.ok) return "Could not validate";
        const data = await reply.json();
        return data.available ? "" : "ID taken, please use a different one";
    } catch (e) {
        console.error(e);
        return "Could not validate";
    }
};
