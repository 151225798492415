import { IconButton, Tooltip } from "@material-ui/core";

import AssignmentIcon from "@material-ui/icons/Assignment";
import copy from "clipboard-copy";

export const CopyToClipboardButton = (p: {
  onClick?: () => void;
  value: string;
  style?: any;
}) => {
  return (
    <Tooltip title="Copy to clipboard" style={p.style}>
      <IconButton
        size="small"
        onClick={() => {
          copy(p.value);
          p.onClick && p.onClick();
        }}
      >
        <AssignmentIcon />
      </IconButton>
    </Tooltip>
  );
};
