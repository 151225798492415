import { useState, useRef } from "react";

export const useRemoteStream = () => {
  const [remoteStream, setRemoteStream] = useState<MediaStream | null>(null);
  const remoteStreamCurrent = useRef(remoteStream);
  remoteStreamCurrent.current = remoteStream;

  const getRemoteStream = () => {
    const remoteStream = remoteStreamCurrent.current || new MediaStream();
    if (!remoteStreamCurrent.current) {
      setRemoteStream(remoteStream);
      remoteStreamCurrent.current = remoteStream;
    }

    return remoteStream;
  }

  const destroyRemoteStream = () => {
    remoteStream?.getTracks().forEach((track) => track.stop());

    setRemoteStream(null);
  }

  return {
    remoteStream,
    destroyRemoteStream,
    getRemoteStream,
  }
}