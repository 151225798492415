import {observable, reaction} from "mobx";

import {AuthStore} from "marketplace-2/store/auth/createAuthStore";
import {MyComponentsStore} from "marketplace-2/store/components/MyComponentsStore";
import {createAudioStore} from "../audio/createAudioStore";
import {createCanvasStore} from "../canvas/createCanvasStore";
import {createGraphStore} from "../graph/createGraphStore";
import {createLearningCenterStore} from "../learning-center/createLearningCenterStore";
import {createProjects} from "../projects/createProjects";
import {createRemindersStore} from "../reminders/createRemindersStore";
import {createTutotialStore} from "../tutorial/createTutorialStore";
import {createUndoRedo} from "../undoRedo/createUndoRedo";
import {runRepopulateGraphOnSelected} from "./reactions/runRepopulateGraphOnSelected";
import {runShowTutorialOnStart} from "./reactions/runShowTutorialOnStart";
import {runUpdateProjectsFromGraph} from "./reactions/runUpdateProjectsFromGraph";

export const createBotsStudioStore = (auth: AuthStore, componentsStore: MyComponentsStore) => {
    const sharedGraph = observable({nodes: {}, edges: {}});
    const canvas = createCanvasStore();
    const undoRedo = createUndoRedo(sharedGraph);
    const graph = createGraphStore(sharedGraph, undoRedo, canvas);
    const audio = createAudioStore();
    const projects = createProjects(graph, auth, componentsStore, undoRedo, canvas);
    const tutorial = createTutotialStore(graph, canvas, projects);
    const reminders = createRemindersStore(projects, auth);
    const learningCenter = createLearningCenterStore(graph, projects, auth);

    const store = observable({canvas, graph, projects, audio, undoRedo, tutorial, learningCenter, reminders});

    runRepopulateGraphOnSelected(store, undoRedo, projects, graph);
    runUpdateProjectsFromGraph(store, projects);
    // runShowTutorialOnStart(auth, tutorial);

    return store;
};

export type BotsStudioStore = ReturnType<typeof createBotsStudioStore>;
