import {Message} from "./types";

export const groupMessages = async (messages: Array<Message>) => {
    const conversations = new Map<string, Array<Message>>();
    messages.map((i, index) => {
        const senderFirstKey = `${i.sender.room_id}:${i.recipient.room_id}`;
        const recipientFirstKey = `${i.recipient.room_id}:${i.sender.room_id}`;
        if (conversations.has(senderFirstKey)) {
            conversations.set(senderFirstKey, (conversations.get(senderFirstKey) || []).concat([i]));
        } else {
            if (conversations.has(recipientFirstKey) && !(recipientFirstKey == senderFirstKey)) {
                conversations.set(recipientFirstKey, (conversations.get(recipientFirstKey) || []).concat([i]));
            } else {
                conversations.set(senderFirstKey, (conversations.get(senderFirstKey) || []).concat([i]));
            }
        }
    });

    conversations.forEach((messages, key) => {
        conversations.set(key, messages.reverse());
    });

    return conversations;
};
