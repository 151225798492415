import {action, observable} from "mobx";

import {AuthStore} from "../auth/createAuthStore";
import {getBillingPlan} from "data/api/rest/requests/billing/getBillingPlan";
import {getResources} from "data/api/rest/requests/billing/getResources";
import {getBotsResources} from "data/api/rest/requests/billing/getBotsResources";
import { getSubscriptionData } from "data/api/rest/requests/billing/getSubscriptionData";

export type Resources = {seconds: number; conversations: number; phone_numbers: number};
export type ResourcesReport = {total: Resources; used: Resources};
export type SubscriptionData = {
    nextBilling: string;
    firstBilling: string;
    status: string;
    planGid: string;
};

export const createBillingPlanStore = (auth: AuthStore) => {
    const _private = observable({
        plan: "",
        isInitialized: false,
        isResourcesFetched: false,
        resources: {
            total: {seconds: 0, conversations: 0, phone_numbers: 0},
            used: {seconds: 0, conversations: 0, phone_numbers: 0}
        },
        subscriptionDetails: {} as SubscriptionData
    });

    const store = observable.object({
        isReady: true,
        isShowingEnterprise: false,
        get resources() {
            if (!auth.isReady || !auth.isAuthenticated) return _private.resources;
            !_private.isResourcesFetched && getResources().then((r) => (_private.resources = r));
            _private.isResourcesFetched = true;
            return _private.resources;
        },
        get plan() {
            if (!_private.isInitialized) {
                _private.isInitialized = true;
                store.refetch();
            }
            return _private.plan;
        },
        get subscriptionDetails() {
            if (!_private.isInitialized) {
                _private.isInitialized = true;
                store.refetch();
            }
            return _private.subscriptionDetails;
        },
        refetch: action(async () => {
            const plan = await getBillingPlan();
            _private.subscriptionDetails = await getSubscriptionData(plan);
            if (plan === "Not Found") {
                return;
            }
            _private.plan = plan.gid;
        })
    });

    return store;
};

export type BillingPlansStore = ReturnType<typeof createBillingPlanStore>;
