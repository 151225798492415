import {action, observable, reaction} from "mobx";

import {V2Category} from "./types";
import {getBlueprint} from "data/api/rest/requests/getBlueprint";
import {getFrontpage} from "data/api/rest/requests/getFrontpage";

export enum CategoriesDisplay {
    grid,
    list
}

const initialize = action(async (store: FrontpageStore) => {
    const cats = await getFrontpage();
    store.categories.replace(cats);
    store.isInitialized = true;
});

export const createFrontpageStore = () => {
    const store = observable({
        isInitialized: false,
        filter: "",
        categoriesDisplay: CategoriesDisplay.grid,
        categories: observable.array([] as V2Category[]),
        tryComponent: observable({
            get isOpen() {
                return !!this.human_id;
            },
            close: () => {
                store.tryComponent.human_id = "";
            },
            human_id: "",
            blueprintData: {} as {description?: string}
        }),
        initialize: () => initialize(store)
    });

    reaction(
        () => store.tryComponent.human_id,
        action(async (human_id) => {
            if (!human_id) {
                store.tryComponent.blueprintData = {};
                return;
            }
            store.tryComponent.blueprintData = await getBlueprint(human_id);
        })
    );

    return store;
};

export type FrontpageStore = ReturnType<typeof createFrontpageStore>;
