import {Typography, makeStyles} from "@material-ui/core";

import {MainButton} from "theme/components/elements/buttons/MainButton";

const useStyles = makeStyles((theme) => {
    return {
        outerContainer: {
            position: "relative" as "relative",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        container: {
            background: theme.custom.palette.special.bad,
            color: theme.custom.palette.e.main,
            width: "fit-content",
            height: "fit-content",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: theme.spacing(3),
            borderRadius: theme.spacing(3)
        }
    };
});

export const Reload: React.FC<{reload?: () => void; error?: string}> = ({reload, error = ""}) => {
    const classes = useStyles();
    return (
        <div className={classes.outerContainer}>
            <div className={classes.container}>
                <Typography>{error}</Typography>
                {reload && <MainButton onClick={reload}>Reload</MainButton>}
            </div>
        </div>
    );
};
