import styled, { css } from "styled-components";
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps,
} from "styled-system";

export const Container = styled.div<LayoutProps>`
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: ${({ theme }) => theme.spacing(0, 3)};
  /* media queries for responsive */
  @media only screen and (min-width: ${({ theme }) =>
      `${theme.responsive.mobile}px`}) {
    max-width: 540px;
  }
  @media only screen and (min-width: ${({ theme }) =>
      `${theme.responsive.tablet}px`}) {
    max-width: 768px;
  }
  @media only screen and (min-width: ${({ theme }) =>
      `${theme.responsive.laptop}px`}) {
    max-width: 924px;
    padding: 0;
  }
  @media only screen and (min-width: ${({ theme }) =>
      `${theme.responsive.laptopL}px`}) {
    max-width: 1080px;
    padding: 0;
  }
  @media only screen and (min-width: ${({ theme }) =>
      `${theme.responsive.desktop}px`}) {
    max-width: 1236px;
    padding: 0;
  }
  ${layout}
`;

export const Box = styled.div<
  LayoutProps &
    FlexboxProps &
    SpaceProps &
    TextAlignProps & { gap?: number | string }
>`

  ${layout}
  ${flexbox}
  ${space}
  ${textAlign}
  display: flex;
  gap: ${(props) => (props.gap ? `${props.gap}px` : "10px")};
`;

export const OnboardingSection = styled.section`
  background-color: ${({ theme }) => theme.colors.background.light.main};
  height: 100%;
  width: 100%;
`;

export const Divider = styled.div<{ height: number }>`
  border-left: 4px solid #fcfdff;
  height: ${(props) => `${props.height}px`};
  border-radius: ${({ theme }) => `${theme.spacing(3)}px`};
  @media (max-width: ${({ theme }) => `${theme.responsive.tablet}px`}) {
    display: none;
  }
`;

// reusable css

export const flexColBetween = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
export const flexColCenter = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const flexCenter = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
