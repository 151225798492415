import {UserAuth0, UserStore} from "../auth/createAuthStore";
import {UserProfile, UserProfileCore} from "./types";

import {AFFILITE_KEY} from "dangerous/staticStrings";
import {action} from "mobx";
import {getUserProfile} from "data/api/rest/requests/getUserProfile";

export const initializeUser = action(async (store: UserStore, user?: UserAuth0) => {
    const affiliate_code = localStorage.getItem(AFFILITE_KEY) || "";
    affiliate_code && localStorage.removeItem(AFFILITE_KEY);
    try {
        const profile = await getUserProfile({user, affiliate_code});
        action(() => {
            // populate store from profile
            (Object.keys(profile) as Array<keyof UserProfile>).forEach((key: keyof UserProfile) => {
                // @ts-ignore - I am not sure how to tell typescript that store[key] and profile[key] will always have the same type
                profile[key] !== undefined && (store[key] = profile[key]);
            });

            (globalThis as {[key: string]: any}).user_handle = profile.profile.handle;

            //@ts-ignore
            profile.profile.email && window._loq?.push(["tag", "registered-user"]);
            //@ts-ignore
            window._loq?.push(["custom", {userHandle: profile.profile.handle}]);

            Object.keys(store.profile).forEach((k) => {
                const key = k as keyof UserProfileCore;
                store.profile[key] = store.profile[key] || "";
            });
        })();
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
});
