import { Theme, makeStyles } from "@material-ui/core";

import { ActionsEditTool } from "./actions-tool/ActionsEditTool";
import { ConfigEditTool } from "../../../store/components/MyComponentsStore";
import { QnATool } from "./qna-tool/QnATool";
import React from "react";
import { ToolSelect } from "./ToolSelect";
import { useRootStore } from "../../../store/useRootStore";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    padding: `${theme.spacing(1)}px 0px`,
  },
}));

export const ConfigEditor = () => {
  const classes = useStyles();

  const { selectedTool } = useRootStore((store) => {
    return {
      selectedTool: store.myComponents.editComponent.selectedTool,
    };
  });

  return (
    <div className={classes.container}>
      {selectedTool !== ConfigEditTool.qna ? <ToolSelect /> : null}
      {/* {selectedTool === ConfigEditTool.json ? <RawJsonEditTool /> : null} */}
      {selectedTool === ConfigEditTool.actions ? <ActionsEditTool /> : null}
      {selectedTool === ConfigEditTool.qna ? <QnATool /> : null}
    </div>
  );
};
