import { BackgroundContainer } from "./standalone.text.styled";
import Loader from "utils/elments/loader/Loader";
import { StandaloneChatWindow } from "./StandaloneChatWindow";
import { Typography } from "@a-i/ui";
import { useBotDataFromChannel } from "./useBotDataChannel";

export const StandaloneText = () => {
  const params = new URLSearchParams(window.location.search);
  const channelId = params.get("channel") || "";
  const botId = params.get("id") || "";
  const { loading, error, name, avatar_id } = useBotDataFromChannel(channelId);

  return (
    <BackgroundContainer>
      {!error || <Typography>{error}</Typography>}
      {!loading || <Loader />}
      <StandaloneChatWindow
        botId={botId}
        botAvatar={avatar_id}
        name={name}
        // voice={bot.avatar.voice}
        channelId={channelId}
        {...{
          convoEndMessage: "Conversation Completed",
          toggleChat: close,
          onSubmitCallback: () => {
            console.log("StandaloneText onSubmitCallback");
          },
          onCompletedCallback: () => {
            console.log("StandaloneText onCompletedCallback");
          },
          isShowingChat: true,
        }}
      />
    </BackgroundContainer>
  );
};
