import { fetchWrapper } from "data/api/rest/fetchWrapper";

export const syncExchange = async (data: Object) => {
  try {
    const reply = await fetchWrapper(`/v2/bot/channel/sync_exchange`, {
      method: "POST",
      body: JSON.stringify(data),
    });
    return reply.json();
  } catch (e: any) {
    return { error: e.message };
  }
};
