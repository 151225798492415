import {ProjectsStore, lastOpened, updateUserProjectsKeysState} from "../createProjects";

import {reaction} from "mobx";

// update project keys with lastOpened
export const runUpdateProjectKeysOnSelected = (store: ProjectsStore) =>
    reaction(
        () => ({selected: store.selected}),
        ({selected}) =>
            !store.isInitializing &&
            !store.isLoading &&
            selected &&
            lastOpened !== selected &&
            updateUserProjectsKeysState(store)
    );
