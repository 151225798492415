import { fetchWrapper } from "data/api/rest/fetchWrapper";

export const removePhone = async ({
  phone,
  botId,
}: {
  phone: string;
  botId: number;
}) => {
  try {
    const buy = await fetchWrapper(`/v2/channel/phone/release`, {
      method: "DELETE",
      body: JSON.stringify({
        bot_id: botId,
        phone_number: phone,
      }),
    });
    if (!buy.ok) throw new Error();
    return true;
  } catch (e) {
    return false;
  }
};
