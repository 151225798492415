import {AnalyticType} from "./AnalyticType";

const valuesMap = new Map<string, Map<number, string[]>>([
    [
        "AW-697286529/ZMUKCLihvswCEIH_vswC", //System interaction conversion
        new Map<number, string[]>([
            [
                0.1,
                [
                    //Content generation
                    AnalyticType.bot_edit_info_clicked,
                    AnalyticType.bot_change_component_clicked,
                    AnalyticType["brain-component-actions-change"],
                    AnalyticType["brain-component-actions-edit"],
                    AnalyticType["brain-component-actions-disconnect"],
                    AnalyticType["channels-test-button-clicked"],
                    //Test call intention
                    AnalyticType["test-button-clicked-phone"],
                    AnalyticType["test-button-clicked-video"],
                    AnalyticType["test-button-clicked-text"],
                    AnalyticType["image-card-selected-scene"],
                    AnalyticType["dashboard_chat_input"],
                    //Channels and integration connection
                    AnalyticType.integration_open,
                    AnalyticType.dashboard_integrations_clicked,
                    AnalyticType.add_channels_clicked,
                    AnalyticType.brain_create_new_card_clicked,
                    AnalyticType["share-clicked"],
                    //Skills
                    AnalyticType.scheduler_skill_open,
                    AnalyticType.fallback_skill_open
                ]
            ],
            [
                1.0,
                [
                    //Test call invitation
                    AnalyticType.webrtc_invited,
                    AnalyticType["channels-test-button-invited"],
                    AnalyticType.gitter_clicked,
                    //Content generation
                    AnalyticType["intent-created"],
                    AnalyticType["intent-deleted"],
                    AnalyticType["intent-edited"],
                    AnalyticType.node_added,
                    //Channels and integration connection
                    AnalyticType["channels-card-connect-clicked"],
                    AnalyticType["integration-card-connect-clicked"],
                    AnalyticType.integration_create,
                    AnalyticType.google_allow_permissions_clicked,
                    AnalyticType.faq_action,
                    AnalyticType.shared,
                    AnalyticType.standalone_video_page,
                    AnalyticType["pricing-page-landed"],
                    AnalyticType["plan-opened"],
                    AnalyticType["plan-action-clicked"]
                ]
            ]
        ])
    ],
    [
        "AW-697286529/FO-qCK3m4swCEIH_vswC", // User registration conversion event
        new Map<number, string[]>([[3.0, [AnalyticType["onboarding-finish"]]]])
    ],
    [
        "AW-697286529/rufyCPqVvswCEIH_vswC", // plan purchased conversion event
        new Map<number, string[]>([[50.0, [AnalyticType["payment_success"]]]])
    ],
    [
        "AW-697286529/TCELCOWMvswCEIH_vswC", // Experts plan intention conversion
        new Map<number, string[]>([
            [
                3.0,
                [
                    AnalyticType.brain_contact_expert_clicked,
                    AnalyticType.contact_expert_plan_basic_clicked,
                    AnalyticType.contact_expert_plan_pro_clicked,
                    AnalyticType.contact_expert_plan_custom_clicked
                ]
            ],
            [6.0, [AnalyticType["contact-expert-dialog-start-building"]]]
        ])
    ]
]);
export const getConversionValue = (type: string) => {
    for (let entry of valuesMap.entries()) {
        for (let subEntry of entry[1].entries()) {
            if (subEntry[1].includes(type)) {
                return {send_to: entry[0], value: subEntry[0]};
            }
        }
    }
};
