import {analytics} from "analytics/analytics";
import {AnalyticType} from "analytics/AnalyticType";
import {queryKeys} from "dangerous/staticStrings";
import {getQueryParam} from "utils/query-string/getQueryParam";
import {removeQueryParam} from "utils/query-string/removeQueryParam";

export const reportOnPurchaseFromQuery = () => {
    const d = getQueryParam(queryKeys["plan-purchase-success"]);
    d && analytics({type: AnalyticType["plan-purchase-success"]});
    removeQueryParam(queryKeys["plan-purchase-success"]);
};
