import { GlobalStyle, StyledThemeProvider } from "@a-i/ui";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";

import { AnalyticType } from "analytics/AnalyticType";
import { LogsView } from "logs-view/LogsView";
import { Main } from "./marketplace-2/Main";
import { MarketingPrice } from "marketing-price/MarketingPrice";
import { MuiThemeProvider } from "@material-ui/core";
import { PuppetAuth } from "puppet-auth/PuppetAuth";
import { StandaloneText } from "standalone/text/StandaloneText";
import { TestComponent } from "./test-component/TestComponent";
import { analytics } from "analytics/analytics";
import { getQueryParam } from "utils/query-string/getQueryParam";
import { makeCocoTheme } from "theme/makeCocoTheme";
import { removeQueryParam } from "utils/query-string/removeQueryParam";
import { reportOnPurchaseFromQuery } from "data/api/rest/requests/functions/useReportOnPurchaseFromQuery";
import { report_event } from "./dangerous/staticStrings";
import { StandaloneVideo } from "standalone/video/StandaloneVideo";
import { StandaloneNaked } from "standalone/video/StandaloneNaked";

const theme = makeCocoTheme(false);

function Routing() {
  window.onerror = function myErrorHandler(message, url, lineNumber) {
    analytics({ type: AnalyticType.error, data: { message, url, lineNumber } });
  };

  reportOnPurchaseFromQuery();

  const reportEvent = getQueryParam(report_event);
  if (reportEvent) {
    // @ts-ignore - enum is the regular thins
    analytics({ type: reportEvent, data: {} });
    removeQueryParam(report_event);
  }

  return (
    <MuiThemeProvider theme={theme}>
      <StyledThemeProvider>
        <GlobalStyle />
        <Router>
          <Switch>
            <Route path="/marketing_pricing" exact component={MarketingPrice} />
            <Route path="/video" exact component={StandaloneVideo} />
            <Route path="/text" exact component={StandaloneText} />
            <Route path="/vid_clean" exact component={StandaloneNaked} />
            <Route path="/message_logs" exact component={LogsView} />
            <Route
              path="/testcomponent/:component"
              exact
              component={TestComponent}
            />
            <Route
              path="/auth/confirm_service_account"
              exact
              component={PuppetAuth}
            />
            <Route path="/blueprint/:id/:tab" component={Main} />
            <Route path="/login" component={Main} />
            <Redirect
              from="/index.html"
              exact
              to={`/${window.location.search}`}
            />
            <Route path="/" component={Main} />
          </Switch>
        </Router>
      </StyledThemeProvider>
    </MuiThemeProvider>
  );
}

export default Routing;
