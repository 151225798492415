import React, {useState} from "react";

import {ActiveIndicator} from "utils/elments/ActiveIndicator";
import {AnalyticType} from "analytics/AnalyticType";
import BotToken from "./BotToken";
import {CHANNEL_NAME} from "dangerous/staticStrings";
import {ChannelDialogParams} from "channels/channelsMap";
import {ChannelType} from "channels/types";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import HowTo from "./HowTo";
import {MainButton} from "theme/components/elements/buttons/MainButton";
import {TextButton} from "theme/components/elements/buttons/TextButton";
import {makeStyles} from "@material-ui/core";
import {useChannelFunctions} from "data/api/gql/hooks/useChannelFunctions";

const useStyles = makeStyles((theme) => {
    return {
        container: {
            marginTop: theme.spacing(4),
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%"
        },
        footerContainer: {
            display: "flex",
            justifyContent: "center",
            marginBottom: theme.spacing(3)
        },
        connectedButtons: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
        },
        body: {
            position: "relative"
        }
    };
});

const setWebhook = async (token: string, channel_id: string) => {
    await fetch(`https://api.telegram.org/bot${token}/setWebhook`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
            url: `${window.location.origin}/v2/bot/webhook/telegram/channel/02-${channel_id}`
        })
    });
};

export const TelegramBody: React.FC<ChannelDialogParams> = ({botId, botName, botChannels, close}) => {
    const {create, remove, update, loading} = useChannelFunctions({botId});
    const channel = botChannels.find((b) => b.jsonConfig.channel_name === ChannelType.telegram);
    const channelId = channel?.id;
    const savedToken = channel?.jsonConfig.token;
    const isConnected = !!channelId;

    const classes = useStyles();

    const [token, setToken] = useState<string>("");

    const [isTokenValid, setTokenValidation] = useState<boolean>(false);

    const confirmDelete = () => {
        if (!channelId) return;
        remove({variables: {id: channelId}});
        close();
    };

    const confirmCreate = () => {
        create({
            variables: {
                channelName: CHANNEL_NAME.TELEGRAM,
                jsonConfig: {channel_name: ChannelType.telegram, token},
                botId: parseInt(botId)
            }
        }).then((v) => {
            !v.errors && setWebhook(token, v.data.createChannel.channel.nodeId);
        });
    };

    const confirmUpdate = () => {
        channelId !== undefined &&
            update({variables: {jsonConfig: {channel_name: ChannelType.telegram, token}, id: channelId}}).then((v) => {
                !v.errors && setWebhook(token, v.data.updateChannel.channel.nodeId);
            });
    };

    return (
        <div className={classes.container}>
            <div className={classes.body}>
                <ActiveIndicator isActive={isConnected} text={"Connected"} />
                <BotToken
                    setValidation={setTokenValidation}
                    onChange={setToken}
                    defaultToken={savedToken}
                    {...{botName, isConnected}}
                />
                {!isConnected ? <HowTo /> : null}
            </div>
            <div className={classes.footerContainer}>
                {!isConnected ? (
                    <div>
                        <MainButton
                            analytics_event={AnalyticType["telegram-dialog-save"]}
                            disabled={!isTokenValid || !token}
                            onClick={confirmCreate}
                            isLoading={loading}
                            hoverContent={<CheckRoundedIcon fontSize="small" />}>
                            Save
                        </MainButton>
                    </div>
                ) : (
                    <div className={classes.connectedButtons}>
                        <MainButton
                            analytics_event={AnalyticType["telegram-dialog-update-token"]}
                            onClick={confirmUpdate}
                            disabled={token === savedToken || !isTokenValid || !token}>
                            Update Token
                        </MainButton>
                        <TextButton
                            analytics_event={AnalyticType["telegram-dialog-disconnect"]}
                            onClick={confirmDelete}
                            variant="secondary">
                            Disconnect
                        </TextButton>
                    </div>
                )}
            </div>
        </div>
    );
};
