import {fetchWrapper} from "data/api/rest/fetchWrapper";

export const getBotsResources = async () => {
    try {
        const result = await fetchWrapper(`/v2/resources`, {method: "GET"});
        if (!result.ok) throw new Error(result.statusText);
        const reply = await result.json();
        return reply;
    } catch (e) {
        console.log(e);
    }
};
