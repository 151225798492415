import {Category} from "./types";
import {fetchWrapper} from "data/api/rest/fetchWrapper";

export const getCategories = async () => {
    try {
        const reply = await fetchWrapper(`/v2/intent/categories`);
        if (!reply.ok) throw new Error(`Could not get categories because ${reply.statusText}`);
        const data = await reply.json();
        return data as Category[];
    } catch (e) {
        console.error(e);
        return;
    }
};
