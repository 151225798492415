import { theme, Typography } from "@a-i/ui";
import React from "react";
import { SubtitleLink } from "./share.styled";
import { ENVIRONMENT } from "const";

export const NoConnectionNoMic = () => (
  <>
    <Typography color={theme.colors.text.light} textAlign="center">
      Please allow microphone and video permissions, refresh the page to restart
      the conversation.
    </Typography>
    <Typography
      textAlign="center"
      variant="description"
      fontWeight={600}
      color={theme.colors.text.light}>
      See how to allow permissions
      <a
        href={ENVIRONMENT.ABOUT_URL + "/support-center-allowing-microphone-permissions/"}
        target="_blank">
        <SubtitleLink color={theme.colors.text.light}>here</SubtitleLink>
      </a>
    </Typography>
  </>
);
