import {IntentsStore} from "./IntentsStore";
import {action} from "mobx";
import {debounce} from "lodash";

export const repopulateIntents = debounce(
    action(
        async ({
            store,
            filter,
            filterTags,
            owner_handle,
            selectedCategory
        }: {
            store: IntentsStore;
            filter: string;
            filterTags: string[];
            owner_handle: string;
            selectedCategory?: string;
        }) => {
            store.ui.isLoading = true;
            store.searchResults.intents.clear();
            const selectedCat = store.categories.find((cat) => cat.gid === selectedCategory);

            action(async () => {
                const reply = await store.findIntents({
                    offset: 0,
                    limit: 100,
                    query: selectedCat ? "" : filter,
                    tags: filterTags,
                    owner_handle: selectedCat ? undefined : owner_handle,
                    categories: selectedCat ? [selectedCat] : undefined
                });
                store.ui.isLoading = false;
                if (!reply) return;
                action(() => {
                    store.searchResults.intents.replace(reply.intents);
                    store.searchResults.total_count = reply.total_count;
                })();
            })();
        }
    ),
    300,
    {trailing: true}
);
