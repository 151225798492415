import {useCallback, useEffect, useState} from "react";

import {useVoiceRecorder} from "marketplace-2/pages/hub/chat-window/hooks/useVoiceRecorder";

const MAX_RECORDING_LENGTH = 2;

export enum VoiceRecordError {
    "empty" = "empty",
    "undefined" = "undefined"
}

const validateRecordedName = (name: string) => {
    if (name === "" || name === " ") {
        return VoiceRecordError.empty;
    }
    if (typeof name == ("undefined" || "null")) {
        return VoiceRecordError.undefined;
    }
    return;
};

let t: ReturnType<typeof setTimeout>;

export const useRecordMicrophone = () => {
    const [isRecording, setIsRecording] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const setRecordingTimer = async (seconds: number, onResult: (text: string, error?: VoiceRecordError) => void) => {
        t = setTimeout(() => {
            onVoiceConfirm((r) => onResult(r || "", validateRecordedName(r)));
            setIsRecording(false);
        }, seconds * 1000);
    };

    const [isVoiceOn, setIsVoiceOn] = useState(false);
    const [isCanRecord, setIsCanRecord] = useState(false);

    const onInput = (text: string) => {
        setIsProcessing(true);
    };

    const {onVoiceDown, onVoiceConfirm, checkIfCanRecord} = useVoiceRecorder(onInput);

    useEffect(() => {
        if (!isVoiceOn || isCanRecord) return;
        const updateIsCanRecord = async () => {
            const isVoiceAllowed = await checkIfCanRecord();
            setIsCanRecord(isVoiceAllowed);
            if (!isVoiceAllowed) {
                setIsVoiceOn(false);
            }
        };
        updateIsCanRecord();
    }, [isVoiceOn, isCanRecord]);

    const startRecording = useCallback(
        async (onResult: (text: string, error?: VoiceRecordError) => void) => {
            if (isRecording || isProcessing) return;
            if (!isCanRecord) {
                const isVoiceAllowed = await checkIfCanRecord();
                if (!isVoiceAllowed) return;
            }
            setRecordingTimer(MAX_RECORDING_LENGTH, (p1, p2) => {
                onResult(p1, p2);
                setIsProcessing(false);
            });
            onVoiceDown();
            setIsRecording(true);
        },
        [isRecording, isProcessing]
    );

    const stopRecoding = useCallback((onResult: (text: string, error?: VoiceRecordError) => void) => {
        onVoiceConfirm((r) => {
            onResult(r || "", validateRecordedName(r));
            setIsProcessing(false);
        });
        clearTimeout(t);
        setIsRecording(false);
    }, []);

    return {isRecording, isProcessing, startRecording, stopRecoding} as const;
};
