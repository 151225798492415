import {ArrowDirection, IndicatorArrow} from "./IndicatorArrow";

import React from "react";
import {Sparkles} from "utils/sparkle/Sparkles";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core";
import {useGlueStore} from "bots-studio/store/useBotsStudioStore";

const useStyles = makeStyles((theme) => {
    return {
        container: {
            pointerEvents: "none",
            height: "100%",
            transition: "box-shadow 250ms linear",
            borderRadius: ({borderRadius}: {borderRadius: string}) => borderRadius,
            overflow: "hidden"
        },
        selected: {
            boxShadow: "0px 0px 0px 3px white"
        },
        unselected: {
            boxShadow: "0px 0px 0px 0px white"
        },
        children: {
            pointerEvents: "all",
            height: "100%"
        }
    };
});

export const TutSparklesWrapper: React.FC<{
    isActive?: boolean;
    id: string;
    borderRadius?: string;
    showOnStage?: number;
    arrow?: {direction?: ArrowDirection; delay?: number; isOpen?: boolean};
}> = ({id, children, borderRadius = `16px`, arrow, showOnStage, isActive}) => {
    const classes = useStyles({borderRadius});

    const {isOn} = useGlueStore((store) => {
        const isOn =
            isActive ||
            (!store.tutorial.isTutorialHidden &&
                (id === store.tutorial.sparkleId ||
                    (!isNaN(showOnStage as number) && store.tutorial.stage === showOnStage)));
        return {
            isOn
        };
    });

    return (
        <IndicatorArrow {...{...arrow, isOpen: !!arrow && isOn}}>
            <div
                className={clsx(classes.container, {[classes.selected]: isOn, [classes.unselected]: !isOn})}
                id="sparkles-wrapper">
                <Sparkles isOn={isOn}>
                    <div className={classes.children}>{children}</div>
                </Sparkles>
            </div>
        </IndicatorArrow>
    );
};
