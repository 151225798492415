import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import React from "react";
import {useRootStore} from "../../../store/useRootStore";
import {ConfigEditor} from "./ConfigEditor";
import {useEditorContext} from "./EditorContext";
import EditorTitle from "./EditorTitle";

export const EditComponentConfigDialog = () => {
    const {isOpen, isCanSave, setEditComponentId, updateBlueprint, closeEdit} = useRootStore((store) => {
        const edit = store.myComponents.editComponent;
        return {
            isOpen: !!edit.editComponentId.get() && edit.isEditingConfig,
            isCanSave: edit.isCanSaveUpdate,
            setEditComponentId: (id: string) => edit.editComponentId.set(id),
            updateBlueprint: edit.updateBlueprint,
            closeEdit: edit.closeEdit
        };
    });

    const {actions} = useEditorContext();

    const confirm = () => {
        updateBlueprint();
    };

    return (
        <Dialog maxWidth={"lg"} fullWidth open={isOpen} onClose={closeEdit}>
            <EditorTitle />
            <DialogContent style={{height: "100%", display: "flex", flexDirection: "column"}}>
                <ConfigEditor />
            </DialogContent>
            <DialogActions>
                {actions}
                <Button onClick={closeEdit} color="primary">
                    Cancel
                </Button>
                <Button disabled={!isCanSave} onClick={confirm} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
