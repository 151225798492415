import {GlueNodeParam, GlueNodeV2} from "marketplace-2/store/components/types";

export const getLineFromParams = (node: GlueNodeV2) => {
    const params = node.parameters || {};
    const keys = Object.keys(params).filter((key) => key === "line");

    if (!keys.length) return undefined;

    return keys.reduce((acc, key) => {
        try {
            const s = params[key] as string;
            acc[key] = JSON.parse(s);
        } catch (e) {
            // console.error(e);
            acc[key] = params[key];
        }
        return acc;
    }, {} as {[key: string]: GlueNodeParam});
};
