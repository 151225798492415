import {GraphStore} from "../graph/createGraphStore";
import {Tutorial} from "./tutorials/types";
import {reaction} from "mobx";
import {tutorialsData} from "./tutorialsData";

const processStep = (tut: Tutorial, last: number, completed: number) => {
    const data = (tutorialsData[tut?.id || ""] || {steps: []}).steps;
    if (completed <= last) {
        tut.completions.push({isCelebrated: true, isCompleted: true});
    }
    if (data.length <= tut.completions.length) {
        tut.isCompleted = true;
    }
};

export const defaultCompletionFunc = (graph: GraphStore, tut: Tutorial) => {
    processStep(tut, tut.lastStage, tut.completions.length);

    return reaction(
        () => {
            return {completed: tut.completions.length, last: tut.lastStage};
        },
        ({completed, last}) => {
            processStep(tut, last, completed);
        }
    );
};
