import { PLAN, PricingPage } from "@a-i/ui";

export const MarketingPrice = () => {
  const gotoOnboarding = () =>
    (window.location.href = "https://beyondvirtual.ai/");
  return (
    <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
      <PricingPage
        isMarketing={true}
        guest={{ startNowClicked: gotoOnboarding }}
        onPlanClicked={(plan: PLAN) => {}}
        onExpertsClicked={gotoOnboarding}
      />
    </div>
  );
};
