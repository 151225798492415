import { getRecorder, speechToMessage } from "coco-with-voice";
import { ENVIRONMENT } from "const";
import { useCallback, useEffect } from "react";

const sttUrl = ENVIRONMENT.STT_URL;

let recorder: {
  start: () => Promise<void>;
  stop: () => Promise<Blob>;
};

export const useVoiceRecorder = (
  onChange: (text: string) => void,
  onSubmit?: (text: string) => void
) => {
  useEffect(() => {
    if (recorder) return;
    const getRec = async () => {
      try {
        recorder = await getRecorder();
      } catch (e) {
        console.error(e);
      }
    };
    getRec();
  }, []);

  const onVoiceDown = useCallback(() => {
    if (!recorder) return;
    recorder.start();
    onChange("voice value");
  }, [recorder, onChange]);

  const onVoiceConfirm = useCallback(
    async (handleResult?: (text: string) => void) => {
      if (!recorder) return;
      try {
        const blob = await recorder.stop();
        const reply = await speechToMessage(sttUrl || '', blob); //TODO! Handle undefined sttUrl
        const json = await reply.json();
        (handleResult ?? onSubmit)?.(json.error || json.result);
      } catch (err) {
        //
      }
    },
    [recorder, onSubmit]
  );

  const checkIfCanRecord = useCallback(async () => {
    if (!recorder) return false;
    try {
      await recorder.start();
      await recorder.stop();
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }, [recorder]);

  const cancelRecording = useCallback(() => {
    recorder && recorder.stop();
  }, [recorder]);

  return { onVoiceDown, onVoiceConfirm, checkIfCanRecord, cancelRecording };
};
