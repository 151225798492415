import React, { useState } from "react";
import { Typography, makeStyles } from "@material-ui/core";

import { SecondaryButton } from "theme/components/elements/buttons/SecondaryButton";
import { WhatsappConfirmationDialog } from "./WhatsappConfirmationDialog";
import WhatsappTitle from "./WhatsappTitle";
import { sendBugReport } from "data/api/rest/requests/sendBugReport";
import { useHistory } from "react-router";
import { useUserData } from "data/api/gql/hooks/useUserData";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    explanation: {
      paddingTop: theme.spacing(4),
    },
    title: {
      paddingTop: theme.spacing(3),
      display: "flex",
      alignItems: "center",
    },
    logo: {
      height: theme.spacing(6),
      width: theme.spacing(6),
    },
    body: { paddingTop: theme.spacing(3) },
    connect: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  };
});

const CHANNEL_REQUEST =
  "I would like to create a whatsapp channel. Please contact me at the provided email.";

export const WhatsappBody = () => {
  const classes = useStyles();
  const history = useHistory();

  const { email } = useUserData();

  const [isConfirming, setIsConfirming] = useState(false);

  const contactUs = async () => {
    const result = await sendBugReport({
      email: email || "",
      report: CHANNEL_REQUEST,
      reportType: "whatsapp_channel_request",
    });
    if (result) {
      setIsConfirming(true);
    }
  };

  const close = () => {
    const p = window.location.pathname.split("/");
    p.pop();
    history.push(p.join("/"));
  };

  return (
    <div>
      <WhatsappTitle />
      <div className={classes.body}>
        <div className={classes.container}>
          <Typography>
            Click on the button, and we'll reach out to you to start the
            process.
          </Typography>
          <SecondaryButton
            onClick={contactUs}
            variant={"outlined-inverse"}
            color={"primary"}
          >
            Connect to Whatsapp
          </SecondaryButton>
        </div>
        <Typography className={classes.explanation}>
          In order to use this channel you must sign up for a WhatsApp Business
          Profile and submit a request to enable your virtual human
          <br /> number for WhatsApp.
        </Typography>
      </div>
      <WhatsappConfirmationDialog isOpen={isConfirming} close={close} />
    </div>
  );
};
