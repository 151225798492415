import { fetchWrapper } from "data/api/rest/fetchWrapper";

export const buyAndAssignPhone = async ({
  phone,
  channelId,
  botId,
}: {
  phone: string;
  channelId: string;
  botId: string;
}) => {
  try {
    const buy = await fetchWrapper(`/v2/channel/phone/purchase`, {
      method: "POST",
      body: JSON.stringify({
        phone_number: phone,
        bot_id: botId,
      }),
    });
    if (!buy.ok) throw new Error("Could not buy phone");
    const assign = await fetchWrapper(`/v2/channel/phone/assign`, {
      method: "POST",
      body: JSON.stringify({
        phone_number: phone,
        channel_id: channelId,
        bot_id: botId,
      }),
    });
    if (!assign.ok) throw new Error("Could not assign phone");
    return "";
  } catch (e: any) {
    return e.message || "Unknown error";
  }
};
