import {
  Avatar,
  Button,
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Popover,
  Theme,
} from "@material-ui/core";
import { useSetActiveBotId } from "active-bot-context/useSetActiveBotId";
import { analytics } from "analytics/analytics";
import { AnalyticType } from "analytics/AnalyticType";
import { TutSparklesWrapper } from "bots-studio/components/tutorial/indicators/TutSparklesWrapper";
import { signUpId } from "bots-studio/store/tutorial/tutorialStagesData";
import clsx from "clsx";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSignUpOrLogin } from "utils/hooks/useSignUpOrLogin";
import { useRootStore } from "../../store/useRootStore";
import { ENVIRONMENT } from "const";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      pointerEvents: "none",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      width: theme.spacing(4),
      height: theme.spacing(4),
      border: `2px solid white`,
    },
    text: {
      pointerEvents: "none",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      transition: theme.transitions.create("all", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    container: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      paddingLeft: theme.spacing(0.5),
    },
    img: {
      width: "auto",
    },
    hidden: {
      display: "hidden",
    },
    popoverEntry: {
      padding: `0px ${theme.spacing(1)}px`,
    },
    logoutButton: {
      height: (isInCard) => (isInCard ? "fit-content" : "100%"),
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    signInButton: {
      paddingRight: theme.spacing(1),
    },
  })
);

const useListIconStyles = makeStyles((theme) => {
  return createStyles({
    root: {
      minWidth: "0px",
    },
  });
});

export const UserProfileButton: React.FC<{
  onSignUpClick?: () => void;
  isSparkling?: boolean;
  showOnStage?: number;
  source?: string;
  isOnboarding?: boolean;
  isInCard?: boolean;
}> = ({
  onSignUpClick,
  source = "appbar",
  showOnStage,
  isSparkling,
  isInCard = false,
  isOnboarding = true,
}) => {
  const useListIconClasses = useListIconStyles();

  const history = useHistory();

  const { logIn, signUp } = useSignUpOrLogin();

  const setActiveBotId = useSetActiveBotId();

  const {
    picture,
    openEditor,
    logout,
    isAuthenticated,
    isLoading,
    openBugReport,
  } = useRootStore((store) => {
    return {
      picture: store.user.profile.image_url,
      openEditor: (atTab: string) => {
        if (atTab === "pricing") {
          setActiveBotId("");
          history.push(`/pricing` + window.location.search);
          return;
        }
        setActiveBotId("");
        history.push(`/account/${atTab}` + window.location.search);
      },
      ...store.auth,
      openBugReport: () => {
        store.container.bugReport.isOpen.set(true);
      },
    };
  });

  const classes = useStyles(isInCard);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    analytics({ type: AnalyticType.profile_pic_clicked });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openEditorAndClosePopover = (tab: string) => {
    openEditor(tab);
    handleClose();
  };

  const openUrlInNewTab = (url: string) => {
    window?.open(url, "_blank")?.focus();
  };

  return !isLoading && isAuthenticated ? (
    <div>
      <ListItem
        disableGutters
        onClick={handleClick}
        button
        className={clsx(classes.container)}>
        <ListItemIcon classes={useListIconClasses}>
          <Avatar
            classes={{ img: classes.img }}
            alt="Image"
            src={picture}
            className={classes.avatar}
          />
        </ListItemIcon>
      </ListItem>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <List>
          <ListItem
            onClick={() => {
              analytics({
                type: AnalyticType.user_menu_clicked,
                data: { type: "settings" },
              });
              openEditorAndClosePopover("settings");
            }}
            button>
            <ListItemText primary={"Account settings"} />
          </ListItem>
          <ListItem
            onClick={() => {
              analytics({
                type: AnalyticType.user_menu_clicked,
                data: { type: "plans" },
              });
              openEditorAndClosePopover("plans");
            }}
            button>
            <ListItemText primary={"Subscriptions"} />
          </ListItem>
          <ListItem
            onClick={() => {
              analytics({
                type: AnalyticType.user_menu_clicked,
                data: { type: "pricing" },
              });
              openEditorAndClosePopover("pricing");
            }}
            button>
            <ListItemText primary={"Pricing"} />
          </ListItem>
          <ListItem
            onClick={() => {
              analytics({
                type: AnalyticType.user_menu_clicked,
                data: { type: "documentation" },
              });
              openUrlInNewTab(ENVIRONMENT.DOCS_URL ?? '');
            }}
            button>
            <ListItemText primary={"Documentation"} />
          </ListItem>
          <ListItem
            onClick={() => {
              analytics({
                type: AnalyticType.user_menu_clicked,
                data: { type: "videos" },
              });
              openUrlInNewTab("https://www.youtube.com/c/CoCoHub");
            }}
            button>
            <ListItemText primary={"Videos"} />
          </ListItem>
          <ListItem
            onClick={() => {
              analytics({
                type: AnalyticType.user_menu_clicked,
                data: { type: "feedback" },
              });
              openBugReport();
            }}
            button>
            <ListItemText primary={"Feedback"} />
          </ListItem>
          <ListItem
            onClick={() => {
              analytics({
                type: AnalyticType.user_menu_clicked,
                data: { type: "logout" },
              });
              logout({
                logoutParams : { federated: true, returnTo: window.location.origin }
              });
            }}
            button>
            <ListItemText primary={"Log out"} />
          </ListItem>
        </List>
      </Popover>
    </div>
  ) : (
    <>
      <div className={clsx(classes.logoutButton, classes.signInButton)}>
        <Button
          onClick={async () => {
            onSignUpClick && onSignUpClick();
            logIn();
            analytics({
              type: isOnboarding ? AnalyticType.sign_up : AnalyticType.sign_in,
              data: { source },
            });
          }}
          fullWidth
          variant="outlined"
          style={{ color: "rgba(255,255,255,0.87", whiteSpace: "nowrap" }}>
          Sign In
        </Button>
      </div>
      <div className={classes.logoutButton}>
        <div>
          <TutSparklesWrapper
            {...{
              isActive: isSparkling,
              showOnStage,
              arrow: { direction: "right", isOpen: true },
              borderRadius: "0px",
            }}
            id={signUpId}>
            <Button
              onClick={() => {
                onSignUpClick && onSignUpClick();
                signUp();
              }}
              fullWidth
              variant="outlined"
              style={{ color: "rgba(255,255,255,0.87", whiteSpace: "nowrap" }}>
              Sign up
            </Button>
          </TutSparklesWrapper>
        </div>
      </div>
    </>
  );
};
