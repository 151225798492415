import { Link, TextField, Typography, makeStyles } from "@material-ui/core";
import React, { useState } from "react";

import { handleTextFieldChange } from "utils/handleTextFieldChange";

const useStyles = makeStyles((theme) => {
  return {
    dataContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: theme.spacing(10),
      marginTop: theme.spacing(2),
    },
    botsToken: {
      display: "flex",
      flexDirection: "column",
      width: "50%",
      paddingRight: theme.spacing(16),
    },
    description: {
      display: "flex",
      flexDirection: "column",
      width: "50%",
    },
    textExplanation: {
      marginTop: theme.spacing(2),
      paddingRight: theme.spacing(20),
    },
    botTokenTitle: {
      color: theme.custom.palette.a.alt,
    },
    linkToTelegram: {
      marginTop: theme.spacing(2),
      fontWeight: "bold",
    },
  };
});

const telegramTokenPattern = /[0-9]{9}:[a-zA-Z0-9_-]{35}/;

const BotToken: React.FC<{
  botName: string;
  isConnected: boolean;
  defaultToken: string;
  onChange: (input: string) => void;
  setValidation: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ onChange, botName, isConnected, defaultToken, setValidation }) => {
  const classes = useStyles();

  const [token, setToken] = useState<string>("");
  const [isValid, setisValid] = useState(true);

  const handleValidation = (val: boolean) => {
    setisValid(val);
    setValidation(val);
  };

  const isTokenValid = (token: string) => {
    if (telegramTokenPattern.test(token)) {
      return handleValidation(true);
    }
    return handleValidation(false);
  };

  const getHelperText = () => {
    if (!token) return "Add your Telegram token to create channel";
    if (!isValid) return "Please provide a valid token";
    return " ";
  };

  return (
    <div className={classes.dataContainer}>
      <div className={classes.description}>
        <Typography className={classes.textExplanation}>
          Publish <b>{botName}</b> to Telegram. This will enable your audience
          to chat to your Cocohub-built-virtual-human easily on the popular
          messaging app.
        </Typography>
        {!isConnected ? (
          <div className={classes.linkToTelegram}>
            <Link
              target="_blank"
              href="https://core.telegram.org/bots#6-botfather"
            >
              view telegram official info
            </Link>
          </div>
        ) : null}
      </div>
      <div className={classes.botsToken}>
        <div className={classes.botTokenTitle}>Virtual Human's token</div>
        <TextField
          error={!isValid}
          helperText={getHelperText()}
          defaultValue={defaultToken}
          placeholder={`Insert token here`}
          fullWidth={true}
          onChange={handleTextFieldChange((val: string) => {
            setToken(val);
            isTokenValid(val);
            onChange(val);
          })}
        ></TextField>
      </div>
    </div>
  );
};

export default BotToken;
