import { makeStyles, useTheme } from "@material-ui/core";

import { ActiveIndicator } from "utils/elments/ActiveIndicator";
import { AnalyticType } from "analytics/AnalyticType";
import { CardWithButton } from "components/card-with-button/CardWithButton";
import { HolidayBadge } from "./HolidayBadge";
import React from "react";
import { Scene } from "./getScenes"; //TODO! Check import
import { useScreenSizes } from "utils/hooks/useScreenSizes";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      position: "relative",
      zIndex: 2,
      pointerEvents: "none",
    },
    image: {
      position: "absolute",

      height: (props: { height: number; width: number }) => {
        return props.height + 4;
      },
      width: (props: { height: number; width: number }) => {
        return props.width;
      },

      clipPath: `inset(0% 0% 0% 0% round ${theme.spacing(2)}px)`,
    },
    indicatorContainer: {
      position: "absolute",
      top: 2,
      right: 12,
      [theme.breakpoints.down("lg")]: {
        right: 28,
      },
    },
  };
});

const ImageCard: React.FC<{
  id: string;
  image: string;
  selectedScene: Scene;
  onClick: () => void;
}> = ({ id, image, onClick, selectedScene }) => {
  const theme = useTheme();
  const reply = useScreenSizes();
  const cardSizes = {
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    height: theme.spacing(reply.isSm || reply.isMd ? 11 : 14),
    width: theme.spacing(reply.isSm || reply.isMd ? 15 : 17.5),
    borderRadius: 2,
  };
  const classes = useStyles({
    height: cardSizes.height,
    width: cardSizes.width,
  });

  return (
    <CardWithButton
      analytics_event={AnalyticType["image-card-selected-scene"]}
      cardSizes={cardSizes}
      isSelected={selectedScene.url === image}
      select={onClick}
      actionButtonText="Select"
    >
      <div className={classes.container} onClick={onClick}>
        <img src={image} className={classes.image} />
        <div className={classes.indicatorContainer}>
          <ActiveIndicator isActive={selectedScene.url === image} />
        </div>
      </div>
      {id === "BGR_Holiday" && <HolidayBadge />}
    </CardWithButton>
  );
};

export default ImageCard;
