import {makeStyles, Typography} from "@material-ui/core";
import React from "react";
import ActionTooltip, {Placement} from "theme/components/elements/ActionTooltip";

const useStyles = makeStyles((theme) => {
    return {
        title: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        },
        description: {
            marginBottom: theme.spacing(1)
        }
    };
});

const ChannelsTooltip: React.FC<{
    title?: string;
    description?: string;
    actions?: any;
    isDisabled?: boolean;
    arrowMargin?: number;
}> = ({children, title, description, actions, isDisabled = false, arrowMargin = 5}) => {
    const classes = useStyles();

    return (
        <ActionTooltip
            placement={"top" as Placement}
            arrowMargin={arrowMargin}
            title={
                <div>
                    {title ? (
                        <Typography className={classes.title} variant="h6">
                            {title}
                        </Typography>
                    ) : null}
                    {description ? <Typography className={classes.description}>{description}</Typography> : null}
                    {actions ? actions : null}
                </div>
            }>
            {children}
        </ActionTooltip>
    );
};

export default ChannelsTooltip;
