import { TutorialData } from "../types";
import stage1 from "./assets/navigation-1.gif";
import stage2 from "./assets/navigation-2.gif";
import stage3 from "./assets/navigation-3.gif";
import stage4 from "./assets/navigation-4.gif";
import stage5 from "./assets/navigation-5.gif";
import stage6 from "./assets/navigation-6.gif";
import stage7 from "./assets/navigation-7.gif";

export const navigationData: TutorialData = {
  card: {
    text: "How to navigate conversations based on what users say",
    image: stage1,
    header: "Navigation",
  },
  steps: [
    {
      text: `This component will decide how to navigate the conversation, according to what the users says

- Open the search menu by clicking anywhere on the builder
- Choose Navigation
- Connect “Entry” to your navigation component`,
      image: stage1,
      header: "Navigation Node",
    },
    {
      text: `Intents are the options for what your user intends on saying
- Click on “Add intent”
- Choose an intent from the list. 
- Connect the intent to a another component, to continue the conversation
            `,
      image: stage2,
      header: "What are Intents?",
    },
    {
      text: `
- Couldn’t find the perfect intent? You can also click on “Create”
- If you want your virtual human to understand the user’s intention through a list of phrases, questions and sentences, click on “Create Learned intent”
`,
      image: stage3,
      header: "Create your own Intents",
    },
    {
      text: `
If you want your virtual human to pick up on exact matches of specificecific phrases or words, you can click on “Create keywords intent”
`,
      image: stage5,
      header: "Keywords Intent",
    },
    {
      text: `
 The virtual human will choose the best matching answer in order,
  starting from the top intent going down. Drag and drop intents to change their order
`,
      image: stage4,
      header: "Order matters!",
    },
    {
      text: `To delete an intent, drag and drop it outside the component`,
      image: stage6,
      header: "Delete an Intent",
    },
    {
      text: `Pay attention:
            If the user says something not covered in the intents in your navigation component,
             it will go to “Anything else” - make sure this option is connected to a fallback.
            `,
      image: stage7,
      header: "Final notes",
    },
  ],
};
