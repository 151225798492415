import {
  ButtonBase,
  Theme,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";

import { AnalyticType } from "analytics/AnalyticType";
import { Sparkles } from "utils/sparkle/Sparkles";
import { analytics } from "analytics/analytics";

const transparentVariants = ["outlined", "outlined-inverse", "text"];
const nonTransparentColors = [
  "tertiary",
  "quaternary",
  "quinary",
  "white-blue",
  "blue-white",
  "black-white",
  "white-black",
  "red-white",
  "gradient-purple",
];

const shadowlessVariants = [
  "outlined",
  "outlined-inverse",
  "contained-w-border",
  "text",
];

const getColor = (
  color: SecondaryButtonColor,
  disabled: boolean,
  theme: Theme,
  isHover: boolean,
  variant: SecondaryButtonVariant
) => {
  if (disabled) return theme.custom.palette.e.secondary;
  let a = theme.custom.palette.a.main;

  switch (color) {
    case "gradient-purple":
      a = theme.custom.palette.d.main;
      break;
    case "red-white":
      a = isHover
        ? theme.custom.palette.special.bad
        : theme.custom.palette.d.secondary;
      break;
    case "primary":
      if (variant === "outlined-inverse" || variant === "text") {
        a = isHover
          ? theme.custom.palette.a.secondary
          : theme.custom.palette.a.main;
      } else {
        a = isHover ? theme.custom.palette.a.main : theme.custom.palette.d.alt;
      }
      break;
    case "white-blue":
      a = isHover ? theme.custom.palette.d.main : theme.custom.palette.a.main;
      break;
    case "blue-white":
      a = isHover ? theme.custom.palette.a.main : theme.custom.palette.d.main;
      break;
    case "secondary":
    case "black-white":
      a = isHover
        ? theme.custom.palette.d.alt
        : theme.custom.palette.e.secondary;
      break;
    case "tertiary":
      a = isHover ? theme.custom.palette.d.alt : theme.custom.palette.e.main;
      break;
    case "quaternary":
      a = isHover ? theme.custom.palette.e.main : theme.custom.palette.d.alt;
      break;
    case "quinary":
      a = isHover
        ? theme.custom.palette.e.secondary
        : theme.custom.palette.d.alt;
      break;
    case "alt":
    case "black-black":
      a = isHover
        ? theme.custom.palette.e.secondary
        : variant === "outlined"
        ? theme.custom.palette.e.secondary
        : theme.custom.palette.d.alt;
      break;

    default:
      break;
  }

  return a;
};

const getBgColor = (
  color: SecondaryButtonColor,
  disabled: boolean,
  theme: Theme,
  isHover: boolean,
  variant: SecondaryButtonVariant
) => {
  if (
    transparentVariants.includes(variant) ||
    (!isHover && !nonTransparentColors.includes(color))
  ) {
    return "transparent";
  }

  if (disabled) return theme.custom.palette.d.alt;

  let a = theme.custom.palette.a.main;

  switch (color) {
    case "gradient-purple":
      a = isHover
        ? theme.custom.palette.gradient.alt
        : theme.custom.palette.gradient.secondary;
      break;
    case "red-white":
      a = isHover
        ? theme.custom.palette.d.secondary
        : theme.custom.palette.special.bad;
      break;
    case "primary":
      a = isHover ? theme.custom.palette.d.alt : theme.custom.palette.a.main;
      break;
    case "white-blue":
      a = isHover ? theme.custom.palette.a.main : theme.custom.palette.d.main;
      break;
    case "blue-white":
      a = isHover ? theme.custom.palette.d.main : theme.custom.palette.a.main;
      break;
    case "black-white":
      a = isHover ? theme.custom.palette.e.main : theme.custom.palette.d.main;
      break;
    case "white-black":
      a = isHover ? theme.custom.palette.d.main : theme.custom.palette.e.main;
      break;
    case "secondary":
      a = isHover ? theme.custom.palette.e.main : theme.custom.palette.d.alt;
      break;
    case "tertiary":
      a = isHover ? theme.custom.palette.e.main : theme.custom.palette.d.alt;
      break;
    case "quaternary":
      a = isHover ? theme.custom.palette.d.alt : theme.custom.palette.e.main;
      break;
    case "quinary":
      a = isHover ? theme.custom.palette.d.main : theme.custom.palette.a.main;
      break;
    case "alt":
      a = isHover
        ? theme.custom.palette.d.alt
        : theme.custom.palette.e.secondary;
      break;

    default:
      break;
  }

  return a;
};

const getBorder = (variant: SecondaryButtonVariant, color: string) => {
  if (
    variant === "outlined" ||
    variant === "outlined-inverse" ||
    variant === "contained-w-border"
  )
    return `1px solid ${color}`;
  else return "0px solid";
};

const getBoxShadow = (variant: SecondaryButtonVariant) => {
  if (shadowlessVariants.includes(variant)) {
    return 0;
  } else return 2;
};

const useStyles = (
  buttonColor: SecondaryButtonColor,
  variant: SecondaryButtonVariant,
  disabled: boolean,
  untouchable: boolean,
  size: SecondaryButtonSize,
  isHover: boolean,
  isWide?: boolean
) =>
  makeStyles((theme) => {
    const color = getColor(buttonColor, disabled, theme, isHover, variant);
    const background = getBgColor(
      buttonColor,
      disabled,
      theme,
      isHover,
      variant
    );
    const border = getBorder(variant, color);
    const boxShadow = theme.shadows[getBoxShadow(variant)];
    const isLarge = size === "large";

    return {
      container: {
        fill: color,
        padding: theme.spacing(0, isWide ? 1 : 0),
        opacity: disabled ? 0.6 : 1,
        width: "fit-content",
        border,
        borderRadius: theme.spacing(4),
        position: "relative",
        fontWeight: 500,
        boxShadow,
        textTransform: "none",
        cursor: "pointer",
        pointerEvents: disabled || untouchable ? "none" : "all",
        display: "flex",
        justifyContent: "center",
        transition: theme.transitions.create(
          ["font-weight", "color", "background", "opacity"],
          {
            duration: theme.transitions.duration.short,
            easing: theme.transitions.easing.sharp,
          }
        ),
        background,
        color,
      },
      innerContainer: {
        transition: theme.transitions.create("padding", {
          duration: theme.transitions.duration.short,
        }),
        pointerEvents: "none",
        padding: theme.spacing(isLarge ? 1 : 0.5, isLarge ? 4 : 1),
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        overflow: "hidden",
        alignItems: "center",
      },
      typography: {
        fontWeight: "inherit",
        whiteSpace: "nowrap",
        color: "inherit",
      },
      iconContainer: {
        transition: theme.transitions.create("all", {
          duration: theme.transitions.duration.short,
          easing: theme.transitions.easing.sharp,
        }),
        paddingRight: theme.spacing(0.4),
        display: "flex",
      },
    };
  });

export type SecondaryButtonVariant =
  | "text"
  | "outlined"
  | "contained"
  | "outlined-inverse"
  | "contained-w-border";
export type SecondaryButtonSize = "small" | "large";
export type SecondaryButtonColor =
  | "gradient-purple"
  | "primary"
  | "secondary"
  | "tertiary"
  | "quaternary"
  | "quinary"
  | "alt"
  | "white-blue"
  | "black-white"
  | "white-black"
  | "blue-white"
  | "black-black"
  | "red-white";

export const SecondaryButton: React.FC<{
  onClick?: () => void;
  variant?: SecondaryButtonVariant;
  color?: SecondaryButtonColor;
  size?: SecondaryButtonSize;
  tooltip?: React.ReactChild | React.ReactFragment | React.ReactPortal;
  disabled?: boolean;
  untouchable?: boolean;
  isSparkles?: boolean;
  icon?: React.ReactNode;
  analytics_event?: AnalyticType;
  isWide?: boolean;
}> = ({
  tooltip,
  color = "primary",
  size = "large",
  variant = "contained",
  onClick,
  disabled = false,
  untouchable = false,
  children,
  isSparkles = false,
  icon,
  isWide,
  analytics_event,
}) => {
  const [isHover, setIsHover] = useState(false);
  const classes = useStyles(
    color,
    variant,
    disabled,
    untouchable,
    size,
    isHover,
    isWide
  )();

  const hoverOn = () => setIsHover(true);
  const hoverOff = () => setIsHover(false);

  const handleClick = () => {
    analytics_event && analytics({ type: analytics_event });
    onClick && onClick();
  };

  return (
    <Tooltip title={tooltip || ""}>
      <span>
        <Sparkles isOn={isSparkles}>
          <ButtonBase
            onClick={handleClick}
            onMouseOver={hoverOn}
            onMouseOut={hoverOff}
            onMouseLeave={hoverOff}
            className={classes.container}
          >
            <div className={classes.innerContainer}>
              {icon ? (
                <div className={classes.iconContainer}>{icon}</div>
              ) : null}
              <Typography
                component="span"
                className={classes.typography}
                variant={size === "small" ? "caption" : "body1"}
              >
                {children}
              </Typography>
            </div>
          </ButtonBase>
        </Sparkles>
      </span>
    </Tooltip>
  );
};
