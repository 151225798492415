import {Divider, makeStyles} from "@material-ui/core";

import {ActiveIndicator} from "utils/elments/ActiveIndicator";
import React from "react";
import {VideoChannelTestLogo} from "../channelLogos";

const useStyles = makeStyles((theme) => {
    return {
        container: {
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column"
        },
        logo: {
            paddingBottom: theme.spacing(1)
        }
    };
});

export const VideoChannelHeader: React.FC<{isTesting?: boolean; isConnected: boolean}> = ({isTesting, isConnected}) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <ActiveIndicator isActive={isConnected && !isTesting} text={"Connected"} />
            {isTesting ? <div className={classes.logo}>{VideoChannelTestLogo("logo")}</div> : null}
            {isTesting ? <Divider variant="fullWidth" /> : null}
        </div>
    );
};
