import {UserProfileCore} from "marketplace-2/store/user/types";
import {fetchWrapper} from "data/api/rest/fetchWrapper";

export const setUserData = async (userData: UserProfileCore) => {
    try {
        const r = await fetchWrapper(`/v2/participant/user`, {
            method: "PUT",
            body: JSON.stringify({...userData}),
            mode: "cors",
            credentials: "include"
        });
        return r.ok;
    } catch (e) {
        console.error(e);
        return false;
    }
};
