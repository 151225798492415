import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from "@apollo/client";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { AnalyticType } from "analytics/AnalyticType";
import { AudioServiceProvider } from "services/audio/AudioServiceProvider";
import { DialogContextProvider } from "dialogs/context/DialogContext";
import { MainSwitchContainer } from "./MainSwitchContainer";
import { analytics } from "analytics/analytics";
import { client } from "data/api/gql/client";
import { useAuthenticate } from "../hooks/useAuthenticate";
import { useRootStore } from "../store/useRootStore";
import { GuestSwitch } from "./GuestSwitch";
import { AuthSwitch } from "./AuthSwitch";

export const MainSwitch = () => {
  useAuthenticate();

  const [apolloClient, setApolloClient] =
    useState<ApolloClient<NormalizedCacheObject>>();

  const history = useHistory();


  useEffect(() => {
    // @ts-ignore - this is so that history would be available outside of React render loop
    window.router_history = history;
  }, [history]);

  const { isAuthReady, isGuest, token, refetchToken } = useRootStore((store) => {
    return {
      isAuthReady: store.auth.isReady,
      isGuest: !store.auth.isAuthenticated,
      token: store.auth.jwtToken,
      refetchToken: store.auth.refetchToken,
    };
  });

  const isReady = isAuthReady;

  useEffect(() => {
    const ac = client(token, refetchToken);
    (globalThis as { [key: string]: any }).apolloClient = ac;
    setApolloClient(ac);
  }, [isReady]);

  useEffect(() => {
    if (isReady && !isGuest) {
      analytics({ type: AnalyticType["registered_user_sign_in"] });
    };
  }, [isReady, isGuest]);

  if (!isReady || !apolloClient) {
    return null;
  }

  if (isReady && isGuest) {
    return (
     <GuestSwitch />
    );
  }

  return (
    <ApolloProvider client={apolloClient!}>
      <AudioServiceProvider>
        <DialogContextProvider>
          <MainSwitchContainer>
            <AuthSwitch />
          </MainSwitchContainer>
        </DialogContextProvider>
      </AudioServiceProvider>
    </ApolloProvider>
  );
};
