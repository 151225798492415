import {CanvasStore} from "bots-studio/store/canvas/createCanvasStore";
import {GraphStore} from "bots-studio/store/graph/createGraphStore";
import {reaction} from "mobx";
import {TutorialStore} from "../createTutorialStore";
import {markComplete} from "../utils/markComplete";

export const stage4 = (store: TutorialStore, graph: GraphStore, canvas: CanvasStore) => {
    // stage 4 completion reaction
    reaction(
        () => {
            return {
                stage: store.stage
            };
        },
        ({stage}) => {
            if (stage === 4) {
                markComplete(store, stage, 0);
            }
        }
    );
};
