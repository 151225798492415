export const positiveAdjectives = [
  "able",
  "acceptable",
  "accepting",
  "accidental",
  "accomplished",
  "actual",
  "acute",
  "adept",
  "adequate",
  "admiring",
  "adoring",
  "adulatory",
  "advanced",
  "advantageous",
  "adventurous",
  "advisory",
  "affable",
  "affectionate",
  "agreeable",
  "alluring",
  "amazing",
  "ambitious",
  "amiable",
  "ample",
  "amusing",
  "angelic",
  "animating",
  "annealed",
  "appeased",
  "appetizing",
  "applauding",
  "appreciative",
  "approachable",
  "approbatory",
  "approving",
  "ascending",
  "aromatic",
  "articulate",
  "athletic",
  "attractive",
  "auspicious",
  "authoritative",
  "awesome",
  "balanced",
  "banner",
  "beaming",
  "beatific",
  "beautiful",
  "beefy",
  "beloved",
  "beneficial",
  "benign",
  "best",
  "blameless",
  "blessed",
  "blissful",
  "blithe",
  "blithesome",
  "boffo",
  "bonny",
  "bonified",
  "boss",
  "bountiful",
  "brave",
  "brawny",
  "bright",
  "brilliant",
  "bumper",
  "buoyant",
  "burly",
  "calming",
  "capable",
  "capital",
  "captivating",
  "celebrated",
  "certain",
  "certified",
  "chance",
  "charismatic",
  "charming",
  "cheerful",
  "cheery",
  "choice",
  "classic",
  "clean",
  "cogent",
  "coincidental",
  "colorable",
  "comfortable",
  "comforting",
  "commendable",
  "commendatory",
  "companionate",
  "compelling",
  "competent",
  "competitive",
  "complimentary",
  "conclusive",
  "condign",
  "confident",
  "confirmed",
  "congruous",
  "considerate",
  "consonant",
  "convenient",
  "convincing",
  "cool",
  "corking",
  "correct",
  "corroborated",
  "courageous",
  "crackerjack",
  "cracking",
  "creative",
  "credible",
  "creditable",
  "dandy",
  "dazzling",
  "decent",
  "decisive",
  "decorous",
  "definitive",
  "delectable",
  "delicious",
  "delighted",
  "deserved",
  "desirable",
  "desirebale",
  "determined",
  "devoted",
  "diligent",
  "diplomatic",
  "distinguished",
  "diverting",
  "divine",
  "dominant",
  "dope",
  "durable",
  "dynamic",
  "dynamite",
  "ecstatic",
  "educated",
  "effective",
  "efficient",
  "elated",
  "elating",
  "elegant",
  "eminent",
  "enchanting",
  "encomiastic",
  "encouraging",
  "enduring",
  "energetic",
  "energized",
  "energizing",
  "engaging",
  "enjoyable",
  "enlightened",
  "enraptured",
  "entertaining",
  "entranced",
  "enviable",
  "epic",
  "esteemed",
  "eternal",
  "ethical",
  "eulogistic",
  "euphoric",
  "everlasting",
  "excellent",
  "exciting",
  "exemplary",
  "exhilarated",
  "exhilarating",
  "expert",
  "exuberant",
  "exultant",
  "fab",
  "fabulous",
  "fair",
  "faithful",
  "fancy",
  "famed",
  "famous",
  "fantabulous",
  "fantastic",
  "fascinating",
  "favorable",
  "favored",
  "fearless",
  "fecund",
  "felicific",
  "fertile",
  "fine",
  "fit",
  "flattering",
  "flavorful",
  "flawless",
  "flexible",
  "flinty",
  "flourishing",
  "focused",
  "forceful",
  "forcible",
  "founded",
  "fortified",
  "fortuitous",
  "frank",
  "friendly",
  "frontline",
  "fruitful",
  "funny",
  "galvanizing",
  "gay",
  "generous",
  "genial",
  "genius",
  "gifted",
  "giving",
  "glad",
  "gladsome",
  "gleaming",
  "gleeful",
  "glimmering",
  "glistening",
  "glittering",
  "glorious",
  "glowing",
  "golden",
  "good",
  "goodly",
  "gorgeous",
  "gracious",
  "grand",
  "great",
  "gregarious",
  "groovy",
  "guiltless",
  "hagiographic",
  "halcyon",
  "hale",
  "happy",
  "hard",
  "hardworking",
  "harmonious",
  "healthy",
  "heartening",
  "hearty",
  "heavenly",
  "helpful",
  "honest",
  "honorable",
  "hopeful",
  "hospitable",
  "hot",
  "humorous",
  "husky",
  "hype",
  "idolizing",
  "illustrious",
  "imaginative",
  "immaculate",
  "immense",
  "immortal",
  "impeccable",
  "imperishable",
  "important",
  "incorruptible",
  "incredible",
  "independent",
  "indomitable",
  "innocent",
  "inoffensive",
  "inquisitive",
  "insightful",
  "interesting",
  "intoxicated",
  "intoxicating",
  "inured",
  "invigorated",
  "invigorating",
  "invincible",
  "inviting",
  "irreproachable",
  "jocund",
  "jolly",
  "jovial",
  "joyful",
  "joyous",
  "jubilant",
  "just",
  "justified",
  "keen",
  "kind",
  "kindly",
  "knowledgeable",
  "kosher",
  "lasting",
  "laudative",
  "laudatory",
  "laughing",
  "lawful",
  "leathery",
  "legendary",
  "legitimate",
  "light",
  "lighthearted",
  "likable",
  "lovable",
  "loved",
  "lovely",
  "loving",
  "loyal",
  "lustrous",
  "lusty",
  "magical",
  "magisterial",
  "magnificent",
  "marvelous",
  "masculine",
  "material",
  "menschy",
  "merry",
  "mesmerizing",
  "mighty",
  "mirthful",
  "mollified",
  "moral",
  "muscular",
  "neat",
  "needed",
  "nice",
  "nifty",
  "nirvanic",
  "noble",
  "notable",
  "noteworthy",
  "openminded",
  "opportune",
  "optimistic",
  "organized",
  "outstanding",
  "overjoyed",
  "pacified",
  "palatable",
  "panegyrical",
  "passionate",
  "patient",
  "peachy",
  "perfect",
  "permanent",
  "persistent",
  "personable",
  "persuasive",
  "pertinent",
  "phat",
  "philosophical",
  "pithy",
  "placated",
  "plausible",
  "plucky",
  "polite",
  "positive",
  "potent",
  "powerful",
  "praiseful",
  "precocious",
  "preeminent",
  "prestigious",
  "prime",
  "primo",
  "principled",
  "privileged",
  "prize",
  "prizewinning",
  "productive",
  "proficient",
  "profitable",
  "prominent",
  "promising",
  "proper",
  "propitious",
  "prospering",
  "provocative",
  "puissant",
  "pure",
  "qualified",
  "quality",
  "radical",
  "rapt",
  "rapturous",
  "ravishing",
  "real",
  "ready",
  "recommendatory",
  "recreative",
  "redolent",
  "rejoicing",
  "relaxed",
  "relevant",
  "relishable",
  "remarkable",
  "renowned",
  "reputable",
  "required",
  "requisite",
  "resilient",
  "resourceful",
  "respectable",
  "respected",
  "respectful",
  "responsible",
  "rhapsodic",
  "right",
  "righteous",
  "rightful",
  "rising",
  "robust",
  "romantic",
  "rosy",
  "rousing",
  "sanguine",
  "satisfactory",
  "satisfying",
  "savory",
  "scrupulous",
  "seasonable",
  "seasoned",
  "seemly",
  "senior",
  "sensational",
  "sensible",
  "serendipitous",
  "serviceable",
  "shimmering",
  "significant",
  "sincere",
  "sleek",
  "slick",
  "smiling",
  "soothing",
  "sound",
  "sparkling",
  "spectacular",
  "spellbinding",
  "splendid",
  "spotless",
  "stable",
  "stalwart",
  "standing",
  "staunch",
  "staying",
  "stellar",
  "sterling",
  "storied",
  "strong",
  "straight",
  "strapping",
  "strengthened",
  "stunning",
  "stupendous",
  "sturdy",
  "substantiated",
  "successful",
  "smooth",
  "sunny",
  "super",
  "superb",
  "superior",
  "superlative",
  "supernal",
  "superluminal",
  "supportive",
  "sure",
  "sweet",
  "swell",
  "sympathetic",
  "tasty",
  "tall",
  "technological",
  "telling",
  "tempered",
  "tempting",
  "tenacious",
  "tender",
  "terrific",
  "thoughtful",
  "thrilled",
  "thriving",
  "tickled",
  "timely",
  "tolerable",
  "toothsome",
  "top",
  "topflight",
  "topping",
  "tough",
  "toughened",
  "towering",
  "trained",
  "trim",
  "true",
  "truthful",
  "twinkling",
  "uncorrupted",
  "unerring",
  "unexpected",
  "unforeseen",
  "unique",
  "unobjectionable",
  "unsurpassed",
  "unyielding",
  "upbeat",
  "upright",
  "upstanding",
  "utmost",
  "valid",
  "validated",
  "verified",
  "veritable",
  "vibrant",
  "vigorous",
  "virile",
  "virtuous",
  "vitalized",
  "vivacious",
  "vivid",
  "wanted",
  "warm",
  "warmhearted",
  "weighty",
  "willing",
  "wizard",
  "wonderful",
  "wondrous",
  "worshipful",
  "worshipping",
  "worthy",
];
