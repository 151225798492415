import {PayloadAction, createSlice} from "@reduxjs/toolkit";

export const initialState = {
    id: "",
    needAskBot: false,
};

export type BotState = typeof initialState;

export const botSlice = createSlice({
    name: "bot",
    initialState,
    reducers: {
        setActiveBot(state, action: PayloadAction<string>) {
            state.id = action.payload;
        },
        setNeedAskBot(state, action: PayloadAction<boolean>) {
            state.needAskBot = action.payload;
        }
    }
});
