import { ENVIRONMENT, UserRoles } from "const"
import { useUserData, useUserRoleMutation } from 'data/api/gql/v2';

const checkEnv = () => {
  if (!ENVIRONMENT.SHOW_UM) {
    return UserRoles.Visitor;
  }

  if (ENVIRONMENT.TEST_USER_ROLE === 'teacher') {
    return UserRoles.Teacher;
  }

  if (ENVIRONMENT.TEST_USER_ROLE === 'visitor') {
    return UserRoles.Visitor;
  }

  return null;
}

export const useUserRole = () => {
  const { user, error, loading }  = useUserData();
  const { setUserRole, error: updatingError, loading: updating } = useUserRoleMutation();

  const getUserRole = () => {
    if (loading) {
      return null;
    }

    const devModeRole = checkEnv();
    if (devModeRole !== null) {
      return devModeRole;
    }

    if (!user || user.userType === null) {
      return null;
    }

    if ((user.userType as  UserRoles) === UserRoles.Visitor) {
      return UserRoles.Visitor;
    }

    if (!user.userOrganizationAssociations.nodes.length) {
      return UserRoles.Visitor;
    }

    return UserRoles.Teacher;
  }

  return {
    loading,
    updating,
    role: getUserRole(),
    error: error || updatingError,
    setUserRole,
  };
}
