import {
  CanvasStore,
  makeDefaultDimesions,
} from "bots-studio/store/canvas/createCanvasStore";
import {
  ProjectsStore,
  updateSaved,
  updateUserProjectsKeysState,
} from "../createProjects";
import { action, observable, reaction } from "mobx";
import { isProjectVersionOutdated, migrateProject } from "../migrateProjects";

import { AuthStore } from "marketplace-2/store/auth/createAuthStore";
import { GlueStateV2 } from "bots-studio/store/types";
import { GraphStore } from "bots-studio/store/graph/createGraphStore";
import { fromConfig } from "bots-studio/schema/fromConfig";
import { getConfig } from "data/api/rest/requests/getConfig";
import { getUserState } from "data/api/rest/requests/getUserState";
import { setUserState } from "data/api/rest/requests/setUserState";

// on proj selected, get project data and populate stores with it
export const runSwitchSelectedData = (
  auth: AuthStore,
  store: ProjectsStore,
  graph: GraphStore,
  canvas: CanvasStore
) =>
  reaction(
    () => ({
      selected: store.selected,
      isReady: auth.isReady,
      isGuest: store.isGuest,
    }),
    action(async ({ selected, isReady, isGuest }) => {
      if (isGuest || !selected || !isReady) return;
      updateSaved(store);
      store.isLoading = true;
      let proj =
        ((await getUserState(store.selected)) as GlueStateV2) ||
        store.projects[store.selected];

      // try to import project if it doesn't exist
      if (!proj) {
        try {
          const config = await getConfig({ humanId: store.selected });
          if (!config.glue_v2) throw new Error("Fetched config has no glue_v2");
          const processed = fromConfig(config.glue_v2);
          await store.add({
            ...processed,
            id: store.selected,
            componentId: store.selected,
          });
          await setUserState(store.selected, processed);
          proj = processed;
        } catch (e) {
          console.error(`Could not add ${store.selected} to user state`);
        }
      }

      if (!proj) {
        console.error(
          `Selected project ${store.selected} was not found, aborting`
        );
        delete store.projToComponentMap[store.selected];
        store.selected = Object.keys(store.projToComponentMap)?.[0] || "";
        updateUserProjectsKeysState(store);
        store.isLoading = false;
        return;
      }
      if (Object.values(proj.edges).some((e) => !proj.nodes[e.from.nodeId])) {
        console.error("Found an erroneous project!");
        store.delete(store.selected);
        return;
      }
      if (proj) {
        action(() => {
          if (isProjectVersionOutdated(proj.version || "")) {
            migrateProject(graph, canvas, proj);
          } else {
            canvas.version = proj.version || "";
            canvas.dimensions = proj.dimensions || makeDefaultDimesions();
            graph.nodes = observable({ ...proj.nodes });
            graph.edges = observable({ ...proj.edges });
          }
          store.projects[selected] = proj;
          if (!proj.componentId) store.publish(selected);
        })();
        updateSaved(store);
      }
      store.isLoading = false;
    })
  );
