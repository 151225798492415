import {BotState, initialState as bot, botSlice} from "./botSlice";
import React, {useReducer} from "react";

import {DeleteBotDialog} from "dialogs/DeleteBotDialog";
import {UnsubscribeBotDialog} from "dialogs/UnsubscribeBotDialog";
import {combineReducers} from "redux";

export type DialogState = {
    bot: BotState;
};

export const DialogContext = React.createContext({
    getValue: function <T>(callback: (s: DialogState) => T) {
        return callback({bot});
    },
    dispatch: (() => {}) as React.Dispatch<{
        type: string;
        payload: any;
    }>,
    actions: {
        bot: botSlice.actions
    }
});

const reduce = combineReducers({
    [botSlice.name]: botSlice.reducer
});

export const DialogContextProvider: React.FC<{
    initial?: {
        bot?: Partial<BotState>;
    };
}> = ({children, initial}) => {
    const initialState = {
        bot: {...bot, ...initial?.bot}
    };

    const [state, dispatch] = useReducer(reduce, initialState);

    const dispatchWithMw = (action: {type: string; payload: any}) => {
        dispatch(action);
    };

    const getValue = function <T>(callback: (s: DialogState) => T) {
        return callback(state);
    };

    return (
        <DialogContext.Provider
            value={{
                getValue,
                dispatch: dispatchWithMw,
                actions: {
                    bot: botSlice.actions
                }
            }}>
            {children}
            <DeleteBotDialog />
            <UnsubscribeBotDialog />
        </DialogContext.Provider>
    );
};
