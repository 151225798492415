import {BotThirdPartyConnection, BotsStore} from "../createBotsStore";

import {action} from "mobx";
import {getBot} from "data/api/rest/requests/bots/v2/getBot";

export const fetchBotDataAndUpdate = action(
    async ({isBotsLoaded, currentId, store}: {store: BotsStore; isBotsLoaded: boolean; currentId: string}) => {
        if (!isBotsLoaded || !currentId) return;
        const b = await getBot(currentId);
        if (!b) return;
        store.editor.current.channels = b.channels?.reduce(
            (acc: {[key: string]: BotThirdPartyConnection}, cur: any) => {
                if (!cur.metadata || !cur.metadata.channel_name) return acc;
                acc[cur.metadata.channel_name] = {id: cur.gid, metadata: cur.metadata};
                return acc;
            },
            {} as {[key: string]: BotThirdPartyConnection}
        );
        store.editor.current.integrations = b.integrations?.reduce(
            (acc: {[key: string]: BotThirdPartyConnection}, cur: any) => {
                if (!cur.metadata || !cur.metadata.type) return acc;
                acc[cur.metadata.type] = {id: cur.gid, metadata: cur.metadata};
                return acc;
            },
            {} as {[key: string]: BotThirdPartyConnection}
        );
    }
);
