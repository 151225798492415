import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import React, {useEffect, useState} from "react";

import mousetrap from "mousetrap";

// @ts-ignore
const version = (window.Config || {version: ""}).version;

export const HiddenOptionsDialog = () => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        mousetrap.bind(["command+shift+option+v"], (e) => {
            e.preventDefault();
            setIsOpen(true);
        });
        return () => {
            mousetrap.unbind(["command+shift+option+v"]);
        };
    }, []);

    const close = () => setIsOpen(false);

    return (
        <Dialog open={isOpen} onClose={close}>
            <DialogTitle>{"Hidden Options"}</DialogTitle>
            <DialogContent>
                <DialogContentText>{`version ${version}`}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color="primary" autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
