import {Typography, makeStyles} from "@material-ui/core";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React from "react";
import clsx from "clsx";

const useStyles = makeStyles((theme) => {
    const indicator = {
        position: "absolute" as "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        borderRadius: theme.spacing(3)
    };

    return {
        indicator,
        iconContainer: {
            position: "relative" as "relative",
            width: theme.spacing(2),
            height: theme.spacing(2)
        },
        selectedIconBg: {
            ...indicator,
            width: theme.spacing(2),
            height: theme.spacing(2),
            right: `${theme.spacing(0) + 4}px`,
            top: `${theme.spacing(-0.5) + 4}px`,
            background: theme.palette.common.white,
            zIndex: 0
        },
        emptyCircle: {
            ...indicator,
            border: `2px solid ${theme.custom.palette.a.main}`,
            width: theme.spacing(2),
            height: theme.spacing(2),
            right: `${theme.spacing(0) + 4}px`,
            top: `${theme.spacing(-0.5) + 4}px`,
            background: "transparent",
            zIndex: 0
        },
        selectedIcon: {
            ...indicator,
            width: theme.spacing(2),
            height: theme.spacing(2),
            right: `${theme.spacing(1)}px`,
            top: `${theme.spacing(-0.5)}px`,
            zIndex: 1
        },
        visible: {
            transition: theme.transitions.create("opacity", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen
            }),
            opacity: 1
        },
        hidden: {
            transition: theme.transitions.create("opacity", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            }),
            opacity: 0
        },
        text: {
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(0.2),
            color: theme.custom.palette.a.main
        }
    };
});

export const ActiveIndicator: React.FC<{isActive: boolean; text?: string; isShowingEmptyCircle?: boolean}> = ({
    isActive,
    text,
    isShowingEmptyCircle
}) => {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.indicator} style={{display: "flex", alignItems: "center"}}>
                <div className={classes.iconContainer}>
                    <div
                        className={clsx(classes.emptyCircle, {
                            [classes.visible]: isShowingEmptyCircle && !isActive,
                            [classes.hidden]: !isShowingEmptyCircle || isActive
                        })}
                    />
                    <div
                        className={clsx(classes.selectedIconBg, {
                            [classes.visible]: isActive,
                            [classes.hidden]: !isActive
                        })}
                    />
                    <div className={classes.selectedIcon}>
                        <CheckCircleIcon
                            color="primary"
                            fontSize="medium"
                            className={clsx({
                                [classes.visible]: isActive,
                                [classes.hidden]: !isActive
                            })}
                        />
                    </div>
                </div>
                {text ? (
                    <Typography
                        className={clsx(classes.text, {
                            [classes.visible]: isActive,
                            [classes.hidden]: !isActive
                        })}
                        variant={"caption"}>
                        {text}
                    </Typography>
                ) : null}
            </div>
        </div>
    );
};
