import {AnalyticType} from "analytics/AnalyticType";
import {analytics} from "analytics/analytics";
import {createAuthStore} from "./auth/createAuthStore";
import {createBillingPlanStore} from "./billing-plans/BillingPlansStore";
import {createBillingStore} from "./billing/BillingStore";
import {createBotsStore} from "bots-studio/store/bots/createBotsStore";
import {createBotsStudioStore} from "bots-studio/store/root/botsStudioStore";
import {createContainerStore} from "./container/ContainerStore";
import {createFrontpageStore} from "./frontpage/FrontpageStore";
import {createIntentsStore} from "./intents/IntentsStore";
import {createMyComponentsStore} from "./components/MyComponentsStore";
import {observable} from "mobx";

export const createRootStore = () => {
    const auth = createAuthStore();
    const user = auth.userStore;
    const billing = createBillingStore(auth);
    const container = createContainerStore(auth);
    const frontpage = createFrontpageStore();
    const myComponents = createMyComponentsStore(user, container, auth);
    const botsStudio = createBotsStudioStore(auth, myComponents);
    const billingPlans = createBillingPlanStore(auth);
    const intents = createIntentsStore(user, container, botsStudio.graph);
    const bots = createBotsStore(container, auth);

    const store = observable.object({
        user,
        bots,
        container,
        frontpage,
        myComponents,
        intents,
        botsStudio,
        auth,
        billing,
        billingPlans
    });

    // runConnectComponentToBot({auth, bots, projects: botsStudio.projects});

    // addGuestBotOnRegister({auth, store: bots});

    analytics({type: AnalyticType.application_loaded});

    return store;
};

export type RootStore = ReturnType<typeof createRootStore>;
