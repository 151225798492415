import React, { useEffect, useRef } from "react";
import { Theme, Typography, makeStyles } from "@material-ui/core";

import { HighlightColor } from "./SlidingHighlighter";
import { ItemInfo } from "./SlidingSelector";
import { useResponsive } from "@a-i/ui";

const getColor = (color: HighlightColor, isSelected: boolean, theme: Theme) => {
  let a = theme.custom.palette.e.main;

  switch (color) {
    case HighlightColor.white_black:
      a = isSelected ? theme.custom.palette.d.alt : theme.custom.palette.e.main;
      break;
    case HighlightColor.white_blue:
      a = isSelected ? theme.custom.palette.d.alt : theme.custom.palette.a.main;
      break;
    default:
      break;
  }

  return a;
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      justifyContent: "center",
      whiteSpace: "nowrap",
      zIndex: 2,
    },
    menuItem: {
      width: "fit-content",
      display: "flex",
      justifyContent: "center",
      transition: theme.transitions.create("all", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      borderRadius: theme.spacing(3),
      padding: theme.spacing(0, 2),
      color: ({
        color,
        isSelected,
      }: {
        isSelected: boolean;
        color: HighlightColor;
      }) => getColor(color, isSelected, theme),
      cursor: "pointer",
    },
    text: {
      userSelect: "none",
      fontWeight: (props: { isSelected: boolean }) => {
        if (props.isSelected) return 500;
        return 500;
      },
    },
  };
});

export const SelectorItem: React.FC<{
  color: HighlightColor;
  id?: string;
  isSelected?: boolean;
  setPosition: (data: ItemInfo) => void;
  onClick?: () => void;
}> = ({ children, color, id, setPosition, isSelected = false, onClick }) => {
  const classes = useStyles({ isSelected, color });
  const ref = useRef<HTMLDivElement | null>(null);
  const { isMobile } = useResponsive();

  useEffect(() => {
    if (!ref.current) return;
    const obs = new ResizeObserver(() => {
      if (!ref.current) return;
      setPosition({
        positionX: ref.current.offsetLeft,
        width: ref.current.offsetWidth,
      });
    });
    obs.observe(ref.current);
    return () => {
      ref.current && obs.unobserve(ref.current);
    };
  }, [ref.current]);

  return (
    <div className={classes.container} id="menu item container">
      <div ref={ref} id={id} className={classes.menuItem} onClick={onClick}>
        <Typography
          variant={isMobile ? "caption" : "body1"}
          className={classes.text}
        >
          {children}
        </Typography>
      </div>
    </div>
  );
};
