import {Configuration} from "marketplace-2/store/components/types";
import {fetchWrapper} from "data/api/rest/fetchWrapper";

export async function setConfig(human_id: string, config: {config: Configuration}) {
    try {
        const r = await fetchWrapper(`/api/config/${human_id}`, {
            method: "POST",
            body: JSON.stringify(config),
            mode: "cors",
            credentials: "include"
        });
        return r.ok;
    } catch (e) {
        console.error(e);
        return false;
    }
}
