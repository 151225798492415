import {makeStyles} from "@material-ui/core";

export type ImageVariant = "logo" | "card" | "box" | "list";

const getHeight = (variant: ImageVariant) => {
    if (variant === "logo") return 40;
    if (variant === "card") return 30;
    if (variant === "list") return 18;
    return 22;
};

const useStyles = ({variant}: {variant: ImageVariant}) =>
    makeStyles((theme) => {
        const height = getHeight(variant);
        return {
            imageContainer: {
                height: height,
                verticalAlign: "sub",
                maxWidth: "100%"
            }
        };
    });

export const imageContainer = (image?: string) => {
    return (variant: ImageVariant) => {
        return <ImageLogo variant={variant} image={image || ""} />;
    };
};

const ImageLogo: React.FC<{variant: ImageVariant; image: string}> = ({image, variant = "card"}) => {
    const classes = useStyles({variant})();

    return <img src={image} className={classes.imageContainer} />;
};

export default ImageLogo;
