import {
  Banner,
  BannerImage,
  BannerSubheader,
  MainHeading,
} from "ui-kit/components/share/share.styled";
import { Box, PrimaryButton } from "ui-kit/components";

import AvatarsCard from "assets/avatars.png";
import AvatarsCardResponsive from "assets/responsive-avatar.png";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { useTheme } from "styled-components";
import { ENVIRONMENT } from "const";

export const StandaloneDisplayBanner = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery({ maxWidth: theme.responsive.tablet });
  return (
    <Banner id="Banner">
      <BannerImage 
        src={isMobile ? AvatarsCardResponsive : AvatarsCard}
        alt="avatars"
      />
      <Box
        flexDirection="column"
        padding={theme.spacing(4, 0, 1, 0)}
        alignItems={isMobile ? "center" : "start"}
      >
        <Box alignItems={"center"} flexDirection={isMobile ? "column" : "row"}>
          <MainHeading color={theme.colors.text.light}>
            Wasn’t that something?
          </MainHeading>
          <MainHeading color={theme.colors.primary.yellow.shade}>
            You can have one too!
          </MainHeading>
        </Box>
        <BannerSubheader>
          In just a few clicks, you can create a virtual human for any purpose.
        </BannerSubheader>
        <Box mt={theme.spacing(3)}>
          <a
            href={ENVIRONMENT.BV_ADDR}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <PrimaryButton variant={"share"}>Create Now</PrimaryButton>
          </a>
        </Box>
      </Box>
    </Banner>
  );
};
