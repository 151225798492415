import { ENVIRONMENT } from "const";
import { fetchWrapper } from "data/api/rest/fetchWrapper";

export const launchBot = async ({
  channelId,
  sceneId,
  context,
  userId,
  room,
}: {
  channelId: string;
  sceneId: string;
  context?: { [key: string]: any };
  userId: string;
  room: string;
}) => {
  const meetingUrl = `${ENVIRONMENT.CLOUDFRONT_WEBRTC_URL}/webrtc-client/index.html?room=${room}${
    ENVIRONMENT.WEBRTC_URL ? `&socket=${ENVIRONMENT.WEBRTC_URL}` : ''
  }`;

  try {
    const response = await fetchWrapper(
      `/v2/bot/channel/video/launch/${channelId}`,
      {
        method: "POST",
        body: JSON.stringify({
          meeting_url: meetingUrl,
          scene_id: sceneId,
          user_id: context?.userId || userId || room, // custom -> cookie -> generated userId
          context,
        }),
        mode: "cors",
        credentials: "include",
      }
    );

    if (response.ok === false || (response.status >= 300 && response.status <= 499)) {
      const errorMessage = ((await response.json()) as { detail: string }).detail;

      return {
        error: errorMessage || 'Unknown error',
      }
    }

    return {
      error: null,
    }
  } catch (error: any) {
    const errorMessage = error instanceof Error ? error.message : 'Unknown error';

    return {
      error: errorMessage || 'Unknown error',
    }
  }
}