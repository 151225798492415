import React, {useEffect, useRef, useState} from "react";

import {Sparkle} from "./Sparkle";
import {randomInt} from "utils/randomInt";
import {useRandomInterval} from "utils/hooks/useRandomInterval";
import {uuid} from "utils/uuid";

export const Sparkles: React.FC<{isOn: boolean; isNoPointerEvents?: boolean}> = ({
    isOn,
    isNoPointerEvents,
    children
}) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const [stars, setStars] = useState<Array<JSX.Element>>([]);
    const [rect, setRect] = useState({w: 0, h: 0, x: 0, y: 0});
    const [maxStars, setMaxStars] = useState(6);
    const [minDuration, setMinDuration] = useState(400);
    const [maxDuration, setMaxDuration] = useState(1000);
    const [minDelay, setMinDelay] = useState(10);
    const [maxDelay, setMaxDelay] = useState(1000);
    const [minSize, setMinSize] = useState(12);
    const [maxSize, setMaxSize] = useState(24);
    const [spacing, setSpacing] = useState(10);

    const addStar = (num: number = 1) =>
        setStars((prev) => {
            return [
                ...prev,
                ...Array.from({length: num}, () => {
                    const id = uuid();

                    return (
                        <Sparkle
                            key={id}
                            {...{
                                isOn,
                                id,
                                left: randomInt(0, rect.w),
                                top: randomInt(0, rect.h),
                                duration: randomInt(minDuration, maxDuration),
                                maxSize: randomInt(minSize, maxSize),
                                onComplete: () =>
                                    setStars((prev) => {
                                        return prev.filter((s) => s.key !== id);
                                    })
                            }}
                        />
                    );
                })
            ];
        });

    const dismiss = useRandomInterval({
        callback: addStar,
        minDelay: minDelay,
        maxDelay: maxDelay,
        isActive: isOn && stars.length < maxStars
    });

    useEffect(() => dismiss, []);

    useEffect(() => {
        if (!isOn || !rect.w || !rect.h || stars.length) return;
        const area = rect.w * rect.h;
        const newMax = area / (maxSize * spacing);
        const initialStars = Math.ceil(newMax / 16);
        setMaxDelay(Math.max(minDelay, maxDelay));
        setMaxDelay(Math.max(minDelay, maxDelay / (initialStars || 1)));
        addStar(Math.max(10, initialStars));
        setMaxStars(newMax);
    }, [rect, isOn]);

    useEffect(() => {
        // this is an ugly hack instead of using resizeObserver
        setTimeout(() => {
            if (!ref.current) return;
            const r = ref.current.getBoundingClientRect();
            setRect({x: r.x, y: r.y, w: r.width, h: r.height});
        }, 500);
    }, [ref.current]);

    return (
        <span
            style={{
                position: "relative",
                height: "100%",
                width: "100%",
                pointerEvents: "none"
            }}>
            <span
                ref={ref}
                style={{
                    height: "fit-content",
                    width: "fit-content",
                    pointerEvents: isNoPointerEvents === undefined || false ? "all" : "none"
                }}>
                {children}
            </span>
            <span style={{position: "absolute", pointerEvents: "none", left: "0px", top: "0px"}}>{stars}</span>
        </span>
    );
};
