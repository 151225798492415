import React from "react";
import {useTheme} from "@material-ui/core";

export const DividerElement: React.FC<{w?: number; h?: number}> = ({w, h}) => {
    const theme = useTheme();
    return (
        <div>
            <div style={{pointerEvents: "none", height: theme.spacing(h || 0), width: theme.spacing(w || 0)}} />
        </div>
    );
};
