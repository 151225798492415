import {makeStyles, Typography} from "@material-ui/core";
import React from "react";
import FaceTimeIcon from "../assets/FaceTimeIcon";
import GoogleMeetDisclaimer from "../assets/GoogleMeetDisclaimer";
import ZoomIcon from "../assets/ZoomIcon";

const useStyles = makeStyles((theme) => {
    return {
        icon: {
            flexDirection: "column",
            display: "flex",
            paddingRight: theme.spacing(0.5),
            color: theme.custom.palette.e.main
        },
        disclaimer: {
            paddingTop: theme.spacing(1.2),
            display: "flex",
            alignItems: "center"
        },
        link: {
            fontWeight: 600,
            color: theme.custom.palette.a.main,
            "&:visited": {
                color: theme.custom.palette.a.main
            }
        }
    };
});

const Disclaimer: React.FC<{googleIcon?: boolean}> = ({googleIcon = false}) => {
    const classes = useStyles();
    return (
        <div className={classes.disclaimer}>
            <Typography>You can invite your bot to</Typography>
            <ZoomIcon />,
            <FaceTimeIcon/>
            <Typography>
                <span>&nbsp;</span>& <span>&nbsp;</span>
            </Typography>
            <GoogleMeetDisclaimer />
        </div>
    );
};

export default Disclaimer;
