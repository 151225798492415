import {useEffect, useState} from "react";

import {ServiceAccount} from "./PuppetAuth";
import {fetchWrapper} from "data/api/rest/fetchWrapper";

export const useGetServiceAccount = () => {
    const [error, setError] = useState("");
    const [serviceAccount, setServiceAccount] = useState<ServiceAccount>();
    const [isWaitingForConfirm, setIsWaitingForConfirm] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState("");

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const acc = searchParams.get("serviceAccount");
        if (!acc) {
            setError("No service account present");
            return;
        }
        try {
            const data = window.atob(acc);
            const jd = JSON.parse(data) as ServiceAccount;
            if (!jd.name) {
                setError("No client name found");
                return;
            }
            if (!jd.client_id) {
                setError("No client id found");
                return;
            }
            if (!jd.client_secret) {
                setError("No client secret found");
                return;
            }
            setServiceAccount({name: jd.name, client_id: jd.client_id, client_secret: jd.client_secret});
        } catch (e) {
            console.error(e);
            setError("Could not parse service account");
        }
    }, []);

    const confirm = async () => {
        if (!serviceAccount) return;
        try {
            setIsWaitingForConfirm(true);
            const reply = await fetchWrapper("/v2/service_account/authorize", {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(serviceAccount)
            });
            setIsWaitingForConfirm(false);
            reply.ok ? setConfirmationMessage("Confirmed") : setError(`Could not confirm, error ${reply.statusText}`);
        } catch (e) {
            console.error(e);
            setError((e as Error).message);
        }
    };

    return {serviceAccount, error, confirm, isWaitingForConfirm, confirmationMessage};
};
