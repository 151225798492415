import {Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            position: "relative",
            display: "flex",
            flexDirection: "column",
            borderRadius: theme.spacing(3),
            background: theme.custom.palette.d.main,
            padding: theme.spacing(3),
            minWidth: theme.spacing(80),
            whiteSpace: "pre"
        }
    };
});

export const DialogBodyInnerContainer: React.FC = ({children}) => {
    const classes = useStyles();

    return (
        <div id="DialogBodyInnerContainer" className={classes.container}>
            {children}
        </div>
    );
};
