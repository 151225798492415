import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
  button, input, p, span {
    font-family: ${({ theme }) => theme.fonts.default.name};
    font-weight: 300;
  }
  html, body, .App, #app-root{
    height: 100%;
    scroll-behavior: smooth;
  }
  body {
    margin: 0;
    padding: 0;
    font-family: ${({ theme }) => theme.fonts.default.name};
  }
`;

export default GlobalStyle;
