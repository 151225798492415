import {useEffect} from "react";
import {useQueryData} from "utils/query-string/useQueryData";
import {useRootStore} from "marketplace-2/store/useRootStore";

export const Login = () => {
    const [data] = useQueryData<string>("redirect");
    const { login } = useRootStore((store) => {
        return {
            login: store.auth.loginWithRedirect
        };
    });

    useEffect(() => {
        if (!login) {
            return;
        }

        if (typeof data === 'string') {
            login({
                authorizationParams: {
                    redirect_uri: data, 
                }
            });
        } else {
            login();
        }
    }, [!login]);

    return null;
};
