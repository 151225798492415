import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useEffect, useState } from "react";

import { CopyToClipboardButton } from "utils/elments/CopyToClipboardButton";
import mousetrap from "mousetrap";
import { useNotification } from "utils/hooks/useNotification";

export const HiddenTokenDialog = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    mousetrap.bind(["command+shift+option+t"], (e) => {
      e.preventDefault();
      setIsOpen(true);
    });
    return () => {
      mousetrap.unbind(["command+shift+option+t"]);
    };
  }, []);

  const close = () => setIsOpen(false);
  const addMessage = useNotification();

  return (
    <Dialog open={isOpen} onClose={close}>
      <DialogTitle>{"Token"}</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ whiteSpace: "nowrap" }}>{`Bearer ${
          (globalThis as { [key: string]: any }).accessToken
        }`}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <CopyToClipboardButton
          onClick={() => {
            addMessage("Bearer token copied");
            close();
          }}
          value={`Bearer ${(globalThis as { [key: string]: any }).accessToken}`}
        />
        <Button onClick={close} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
