import {useEffect, useState} from "react";

import {ServiceAccount} from "./PuppetAuth";
import {getUserProfile} from "data/api/rest/requests/getUserProfile";
import {useAuth0} from "@auth0/auth0-react";

export const usePuppetAuth = (serviceAccount?: ServiceAccount) => {
    const {isLoading, isAuthenticated, user, loginWithRedirect, getAccessTokenSilently} = useAuth0();

    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        (globalThis as {[key: string]: any}).getToken = getAccessTokenSilently;
        (globalThis as {[key: string]: any}).isGuest = !isAuthenticated;
        if (isAuthenticated) setIsReady(true);
    }, [isLoading, isAuthenticated]);

    useEffect(() => {
        if (isLoading) return;
        if (isAuthenticated) return;
        if (!serviceAccount) return;
        (async () => {
            await getUserProfile({user});
            setIsReady(true);
        })();
    }, [isLoading, isAuthenticated, serviceAccount]);

    return {isReady, isAuthenticated, login: loginWithRedirect};
};
