export const CHR_Female_02_NEW = {
    version: 3,
    packedSlotDataList: [],
    slotsV2: [],
    slotsV3: [
        {
            id: "CHR_Female_Eyelashes_Emma",
            scale: 100,
            copyIdx: -1,
            overlays: [
                {
                    id: "CHR_Female_Eyelashes_Emma_Overlay",
                    colorIdx: 3,
                    rect: [0.0, 0.0, 0.0, 0.0],
                    isTransformed: false,
                    scale: {
                        x: 1.0,
                        y: 1.0,
                        z: 1.0
                    },
                    rotation: 0.0
                }
            ],
            Tags: [],
            Races: []
        },
        {
            id: "CHR_Female_Face_Emma",
            scale: 100,
            copyIdx: -1,
            overlays: [
                {
                    id: "CHR_Female_Face_Emma_Overlay",
                    colorIdx: 3,
                    rect: [0.0, 0.0, 0.0, 0.0],
                    isTransformed: false,
                    scale: {
                        x: 1.0,
                        y: 1.0,
                        z: 1.0
                    },
                    rotation: 0.0
                }
            ],
            Tags: [],
            Races: []
        },
        {
            id: "CHR_Female_Mouth_Emma",
            scale: 100,
            copyIdx: -1,
            overlays: [
                {
                    id: "CHR_Female_Mouth_Emma_Overlay",
                    colorIdx: 3,
                    rect: [0.0, 0.0, 0.0, 0.0],
                    isTransformed: false,
                    scale: {
                        x: 1.0,
                        y: 1.0,
                        z: 1.0
                    },
                    rotation: 0.0
                }
            ],
            Tags: [],
            Races: []
        },
        {
            id: "Female_Eye_URP.L_Rig_Emma",
            scale: 100,
            copyIdx: -1,
            overlays: [
                {
                    id: "Female_Eye_URP.L_Rig_Emma_Overlay",
                    colorIdx: 3,
                    rect: [0.0, 0.0, 0.0, 0.0],
                    isTransformed: false,
                    scale: {
                        x: 1.0,
                        y: 1.0,
                        z: 1.0
                    },
                    rotation: 0.0
                }
            ],
            Tags: [],
            Races: []
        },
        {
            id: "Female_Eye_URP.R_Rig_Emma",
            scale: 100,
            copyIdx: -1,
            overlays: [
                {
                    id: "Female_Eye_URP.R_Rig_Emma_Overlay",
                    colorIdx: 3,
                    rect: [0.0, 0.0, 0.0, 0.0],
                    isTransformed: false,
                    scale: {
                        x: 1.0,
                        y: 1.0,
                        z: 1.0
                    },
                    rotation: 0.0
                }
            ],
            Tags: [],
            Races: []
        },
        {
            id: "CHR_Female_EyeMoisture_Emma",
            scale: 100,
            copyIdx: -1,
            overlays: [
                {
                    id: "CHR_Female_EyeMoisture_Emma_Overlay",
                    colorIdx: 3,
                    rect: [0.0, 0.0, 0.0, 0.0],
                    isTransformed: false,
                    scale: {
                        x: 1.0,
                        y: 1.0,
                        z: 1.0
                    },
                    rotation: 0.0
                }
            ],
            Tags: [],
            Races: []
        },
        {
            id: "CHR_Female_Hands_Emma",
            scale: 100,
            copyIdx: -1,
            overlays: [
                {
                    id: "CHR_Female_Hands_Emma_Overlay",
                    colorIdx: 3,
                    rect: [0.0, 0.0, 0.0, 0.0],
                    isTransformed: false,
                    scale: {
                        x: 1.0,
                        y: 1.0,
                        z: 1.0
                    },
                    rotation: 0.0
                }
            ],
            Tags: [],
            Races: []
        },
        {
            id: "CHR_Female_Neck_Emma",
            scale: 100,
            copyIdx: -1,
            overlays: [
                {
                    id: "CHR_Female_Neck_Emma_Overlay",
                    colorIdx: 3,
                    rect: [0.0, 0.0, 0.0, 0.0],
                    isTransformed: false,
                    scale: {
                        x: 1.0,
                        y: 1.0,
                        z: 1.0
                    },
                    rotation: 0.0
                }
            ],
            Tags: [],
            Races: []
        },
        {
            id: "CHR_Female_Shoulders_Emma",
            scale: 100,
            copyIdx: -1,
            overlays: [
                {
                    id: "CHR_Female_Shoulders_Emma_Overlay",
                    colorIdx: 3,
                    rect: [0.0, 0.0, 0.0, 0.0],
                    isTransformed: false,
                    scale: {
                        x: 1.0,
                        y: 1.0,
                        z: 1.0
                    },
                    rotation: 0.0
                }
            ],
            Tags: [],
            Races: []
        },
        {
            id: "CHR_Female_Preset_02_Hair_Cap",
            scale: 100,
            copyIdx: -1,
            overlays: [
                {
                    id: "CHR_Female_Preset_02_Hair_Cap_Overlay",
                    colorIdx: 3,
                    rect: [0.0, 0.0, 0.0, 0.0],
                    isTransformed: false,
                    scale: {
                        x: 1.0,
                        y: 1.0,
                        z: 1.0
                    },
                    rotation: 0.0
                }
            ],
            Tags: [],
            Races: []
        },
        {
            id: "CHR_Female_Preset_02_Hair",
            scale: 100,
            copyIdx: -1,
            overlays: [
                {
                    id: "CHR_Female_Preset_02_Hair_Overlay",
                    colorIdx: 3,
                    rect: [0.0, 0.0, 0.0, 0.0],
                    isTransformed: false,
                    scale: {
                        x: 1.0,
                        y: 1.0,
                        z: 1.0
                    },
                    rotation: 0.0
                }
            ],
            Tags: [],
            Races: []
        },
        {
            id: "CHR_Female_Preset_02_Jacket",
            scale: 100,
            copyIdx: -1,
            overlays: [
                {
                    id: "CHR_Female_Preset_02_Jacket_Overlay",
                    colorIdx: 3,
                    rect: [0.0, 0.0, 0.0, 0.0],
                    isTransformed: false,
                    scale: {
                        x: 1.0,
                        y: 1.0,
                        z: 1.0
                    },
                    rotation: 0.0
                }
            ],
            Tags: [],
            Races: []
        },
        {
            id: "CHR_Female_Preset_02_Shirt",
            scale: 100,
            copyIdx: -1,
            overlays: [
                {
                    id: "CHR_Female_Preset_02_Shirt_Overlay",
                    colorIdx: 3,
                    rect: [0.0, 0.0, 0.0, 0.0],
                    isTransformed: false,
                    scale: {
                        x: 1.0,
                        y: 1.0,
                        z: 1.0
                    },
                    rotation: 0.0
                }
            ],
            Tags: [],
            Races: []
        }
    ],
    colors: [],
    fColors: [
        {
            name: "Skin",
            colors: [
                255, 255, 255, 255, 0, 0, 0, 0, 255, 255, 255, 255, 0, 0, 0, 0, 255, 255, 255, 255, 0, 0, 0, 54, 255,
                255, 255, 255, 0, 0, 0, 0
            ],
            ShaderParms: [],
            alwaysUpdate: false
        },
        {
            name: "Hair",
            colors: [
                226, 61, 92, 255, 0, 0, 0, 0, 255, 255, 255, 255, 0, 0, 0, 0, 255, 255, 255, 255, 0, 0, 0, 0, 255, 255,
                255, 255, 0, 0, 0, 0
            ],
            ShaderParms: [],
            alwaysUpdate: false
        },
        {
            name: "Eyes",
            colors: [
                176, 110, 0, 255, 0, 0, 0, 0, 255, 255, 255, 255, 0, 0, 0, 0, 255, 255, 255, 255, 0, 0, 0, 0, 255, 255,
                255, 255, 0, 0, 0, 0
            ],
            ShaderParms: [],
            alwaysUpdate: false
        },
        {
            name: "-",
            colors: [255, 255, 255, 255, 0, 0, 0, 0],
            ShaderParms: [],
            alwaysUpdate: false
        }
    ],
    sharedColorCount: 3,
    race: "CHR_Female_Emma",
    packedDna: [
        {
            dnaType: "DynamicUMADna",
            dnaTypeHash: 392022294,
            packedDna:
                '{"bDnaAsset":{"instanceID":32212},"bDnaAssetName":"CHR_Female_DNA","bDnaSettings":[{"name":"Skin Tone","value":128},{"name":"Eyes Color R","value":128},{"name":"Eyes Color G","value":128},{"name":"Eyes Color B","value":128},{"name":"Hair Color R","value":128},{"name":"Hair Color G","value":128},{"name":"Hair Color B","value":128},{"name":"Body Fat","value":127},{"name":"Face Old","value":129},{"name":"Body Skinny","value":122},{"name":"Shirt Color R","value":128},{"name":"Shirt Color G","value":128},{"name":"Shirt Color B","value":128},{"name":"Face Race","value":128},{"name":"Hair Length","value":129},{"name":"Eyes Cross","value":128},{"name":"Breast Flatten","value":128},{"name":"Shape Lakeisha","value":128},{"name":"Shape Lakeisha Body","value":128},{"name":"Shape Lakeisha Head","value":128},{"name":"Shape Emma","value":255},{"name":"Shape Joanna","value":128},{"name":"Emma Smile","value":135},{"name":"Joanna Dress","value":128},{"name":"Joanna Bangs","value":128},{"name":"Raaji Shape","value":128},{"name":"Breast Hang","value":128},{"name":"Breast Fix","value":255}]}'
        }
    ],
    wardrobeSet: [
        {
            slot: "Hair",
            recipe: "CHR_Female_Preset_02_Hair"
        },
        {
            slot: "Chest",
            recipe: "CHR_Female_Preset_02_Jacket"
        }
    ],
    packedRecipeType: "Standard"
};
