import {fetchWrapper} from "data/api/rest/fetchWrapper";

export const SyncUserPages = async (facebookUserId: string, facebookAccessToken: string) => {
    const response = await fetchWrapper(
        `/v2/bot/channel/facebook/sync_pages?facebook_user_id=${facebookUserId}&facebook_user_access_token=${facebookAccessToken}`,
        {
            method: "POST",
            mode: "cors",
            cache: "no-cache"
        }
    );
    const pages = await response.json();
    return pages["pages"];
};
