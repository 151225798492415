import { makeStyles } from "@material-ui/core";

import {Link} from "react-router-dom";
import React from "react";
import { BVLogo } from "shared-components/logo/BVLogo";
import { Box, Styles } from "ui-kit/wrappers";

const useStyles = makeStyles((theme) => {
    return {
        appBar: {
            background: theme.custom.palette.a.main,
            height: theme.appbar.height,
            width: `100%`,
            zIndex: theme.zIndex.drawer + 1,
            padding: `0px ${theme.spacing(1)}px`
        },
        logoWrapper: {
            height: "100%",
            display: "flex",
            alignItems: "center",
        },
        logo: {
            height: "100%",
            objectFit: "contain",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        },
        toolbar: {
            display: "flex",
            justifyContent: "space-between",
            height: "100%"
        },
        leftContainer: {
            display: "flex",
            height: "100%",
            [theme.breakpoints.down("sm")]: {
                justifyContent: "space-between"
            }
        },
        rightContainer: {
            display: "flex",
            paddingRight: theme.spacing(1)
        },
        helpButton: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingRight: theme.spacing(1),
            textDecoration: "none"
        }
    };
});

export const BeyondVirtualLogo: React.FC<{isLink?: boolean}> = ({isLink = true}) => {
    const classes = useStyles();

    return (
        <Box className={classes.logoWrapper}>
            {isLink 
                ? (
                    <Link to={`/${window.location.search}`} className={classes.logo}>
                        <Styles color="#FCFDFF">
                            <BVLogo />
                        </Styles>
                    </Link>
                ) 
                : <Styles color="#FCFDFF"><BVLogo /></Styles>
            }
        </Box>
    );
};
