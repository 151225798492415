import { useActiveBotId } from "active-bot-context/useActiveBotId";
import { useChat } from "bots-studio/components/container/chatWindow/useChat";
import { useServerAgt } from "./hooks/useServerAgt";
import { useState } from "react";

const sessionIdContainer = { sessionId: "" };

export const useHubChat = (
  sessionId: string,
  componentId: string,
  onError: (e: Error) => void
) => {
  const botId = useActiveBotId();
  const {
    chat,
    reset,
    onUserInput,
    onUserSubmit,
    onBotEntryStart,
    onBotEntryComplete,
  } = useChat(componentId, botId);

  sessionIdContainer.sessionId = sessionId;

  const [isDisabled, setIsDisabled] = useState(false);

  const {
    call,
    result,
    reset: resetServerAgt,
  } = useServerAgt(componentId, sessionId);

  const handleSubmit = (v: string) => {
    onUserSubmit(v, sessionIdContainer);
    if (!v) return;
    onBotEntryStart();
    setIsDisabled(true);
    call(v)
      .then((r) => {
        onBotEntryComplete(
          sessionIdContainer,
          r.response || "",
          r.updated_context
        );
        setIsDisabled(false);
      })
      .catch(onError);
  };

  const localReset = () => {
    setIsDisabled(false);
    resetServerAgt();
    reset();
  };

  return {
    onInput: onUserInput,
    onSubmit: handleSubmit,
    chat,
    isDisabled,
    reset: localReset,
    result,
  };
};
