import {AnalyticType} from "analytics/AnalyticType";
import ChannelsTooltip from "channels/ChannelsTooltip";
import {InfoBadge} from "theme/components/elements/InfoBadge";
import React from "react";
import {SecondaryButton} from "theme/components/elements/buttons/SecondaryButton";
import {TextButton} from "./buttons/TextButton";
import {makeStyles} from "@material-ui/core";
import {useIsGuest} from "utils/hooks/useIsGuest";
import {useSignUpOrLogin} from "utils/hooks/useSignUpOrLogin";

const useStyles = makeStyles((theme) => {
    return {
        buttonContainer: {
            padding: 2,
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center"
        },
        button: {
            padding: theme.spacing(0, 1),
            display: "flex",
            alignContent: "center"
        },
        infoBadge: {
            paddingLeft: theme.spacing(1)
        }
    };
});

const GuestSignInBadge: React.FC<{title?: string; description?: string}> = ({
    title = "This feature is locked for guest user",
    description = "You need to be signed in to access this feature",
    children
}) => {
    const classes = useStyles();

    const isGuest = useIsGuest();

    const {signUp, logIn} = useSignUpOrLogin();

    if (!isGuest) return null;

    return (
        <ChannelsTooltip
            title={title}
            description={description}
            actions={
                <div className={classes.buttonContainer}>
                    <div className={classes.button}>
                        <SecondaryButton
                            analytics_event={AnalyticType["guest-badge-sign-up"]}
                            color="tertiary"
                            onClick={signUp}>
                            Sign up
                        </SecondaryButton>
                    </div>
                    <div className={classes.button}>
                        <TextButton
                            analytics_event={AnalyticType["guest-badge-sign-in"]}
                            onClick={logIn}
                            variant={"secondary"}>
                            Sign in
                        </TextButton>
                    </div>
                </div>
            }>
            <div className={classes.infoBadge}>
                <InfoBadge text={"FOR REGISTERED USERS"} variant={"alt"} />
            </div>
        </ChannelsTooltip>
    );
};

export default GuestSignInBadge;
