import { useCallback, useState } from "react";

import { ChatEntry } from "./newChat/types";
import { chatLog } from "data/api/rest/requests/chatLog";
import { uuid } from "utils/uuid";

export const useChat = (componentId: string, botId?: string) => {
  const [chat, setChat] = useState([] as ChatEntry[]);

  const reset = useCallback(() => {
    setChat([
      {
        id: uuid(),
        text: "Type anything to get started",
        isOwn: false,
        isLoading: false,
        data: {},
      },
    ]);
  }, [chat]);

  const onUserInput = useCallback(
    (v: string) => {
      // if (!chat.length || !chat[chat.length - 1].isOwn) {
      //     setChat((prev) => [
      //         ...prev,
      //         {
      //             id: uuid(),
      //             text: v,
      //             isOwn: true,
      //             isLoading: true,
      //             data: {bubble_type: "user input"}
      //         }
      //     ]);
      // } else if (chat.length && !v) {
      //     setChat(chat.slice(0, -1));
      // }
    },
    [chat]
  );

  const onUserSubmit = useCallback(
    (v: string, { sessionId }) => {
      if (!v) {
        setChat((prev) => {
          const last = prev[prev.length - 1];
          if (!last || !last.isLoading) return prev;
          prev.pop();
          return [...prev];
        });
      } else if (!chat.length || !chat[chat.length - 1].isOwn) {
        chatLog({
          chatSessionId: sessionId,
          componentId,
          text: v,
          from: "user",
          botId,
        });
        setChat((prev) => [
          ...prev,
          {
            id: uuid(),
            text: v,
            isOwn: true,
            isLoading: false,
            data: {},
          },
        ]);
      } else {
        setChat((prev) => {
          prev[prev.length - 1].isLoading = false;
          prev[prev.length - 1].text = v;
          return [...prev];
        });
      }
    },
    [chat]
  );

  const onBotEntryStart = useCallback(
    (v?: string) => {
      if (!chat.length || chat[chat.length - 1].isOwn) {
        setChat((prev) => [
          ...prev,
          {
            id: uuid(),
            text: v || "",
            isOwn: false,
            isLoading: true,
            data: {},
          },
        ]);
      }
    },
    [chat]
  );

  const onBotEntryComplete = useCallback(
    ({ sessionId }, v: string = "", data: Object = {}, media: string = "") => {
      chatLog({
        chatSessionId: sessionId,
        componentId,
        text: v,
        from: "bot",
        botId,
      });

      setChat((prev) => {
        const newChat = [
          ...prev,
          {
            id: uuid(),
            text: v || "",
            isOwn: false,
            isLoading: false,
            data: { ...data },
            media,
          },
        ];
        return newChat;
      });
    },
    []
  );

  return {
    chat,
    reset,
    onUserInput,
    onUserSubmit,
    onBotEntryStart,
    onBotEntryComplete,
  };
};
