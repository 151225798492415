import {Blueprint} from "marketplace-2/store/components/types";
import {fetchWrapper} from "data/api/rest/fetchWrapper";

export const setBlueprint = async (blueprint: Partial<Blueprint> & {human_id: string}) => {
    try {
        const result = await fetchWrapper(`/v2/blueprint/${blueprint.human_id}`, {
            method: "PUT",
            body: JSON.stringify({...blueprint}),
            mode: "cors",
            credentials: "include"
        });
        return result.ok;
    } catch (e) {
        console.error(e);
        return false;
    }
};
