import {
  ApolloClient,
  ApolloQueryResult,
  NormalizedCacheObject,
} from "@apollo/client";
import { FindIntentsParams, Intent, IntentType } from "./types";

import { Intenttypes } from "types/__generated__/globalTypes";
import { SEARCH_INTENTS } from "data/api/gql/queries/searchIntents";
import { searchIntents } from "data/api/gql/queries/__generated__/searchIntents";

export async function findIntents(data: FindIntentsParams) {
  const client = (globalThis as { [key: string]: any }).apolloClient as
    | ApolloClient<NormalizedCacheObject>
    | undefined;

  if (!client) return false;

  return new Promise<{ intents: Intent[]; total_count: number }>(
    (resolve, reject) => {
      client
        .watchQuery({
          query: SEARCH_INTENTS,
          variables: { ...data },
        })
        .subscribe((r: ApolloQueryResult<searchIntents>) => {
          r.error && reject(r.error.message);

          resolve({
            intents:
              r.data?.searchIntents?.edges.map(({ node }) => ({
                public: node.public,
                owner_handle:
                  node.owner?.handle || node.ownerId?.toString() || "Not me",
                name: node.name || "",
                type:
                  node.type === Intenttypes.KEYWORDS
                    ? IntentType.keywords
                    : IntentType.learned,
                description: node.description || "",
                tags: [],
                body: node.body,
              })) || [],
            total_count: r.data?.searchIntents?.totalCount || 0,
          });
        });
    }
  );
}
