export enum NavTab {
  "avatar" = "avatar",
  "name" = "name",
  "voice" = "voice",
}

export const comingSoonAvatars = {
  cowboy: "/assets/profiles/placeholders/cowboy.png",
  woman1: "/assets/profiles/placeholders/default_woman1.png",
  woman2: "/assets/profiles/placeholders/default_woman2.png",
} as { [key: string]: string };

export const scenes = [
  {
    key: "Street",
    ids: ["Street", "BGR_Bedroom", "BGR_Office", "Archviz", "Grey Wall"],
    scene: "/assets/avatars/scenes/Street.png",
  },
  {
    key: "Forest",
    ids: ["Forest", "BGR_Holiday", "Skyde"],
    scene: "/assets/avatars/scenes/Forest.png",
  },
  {
    key: "Palace",
    ids: [
      "Palace",
      "BGR_Library",
      "BGR_Living_Room",
      "Barber Shop",
      "Dressing Room",
    ],
    scene: "/assets/avatars/scenes/Palace.png",
  },
  {
    key: "School",
    ids: ["School", "BGR_Window", "Classroom"],
    scene: "/assets/avatars/scenes/School.png",
  },
];

export type AvatarEntry = {
  key: string;
  ids: string[];
  profile: string;
  body: string;
};

export const avatars = [
  {
    key: "Preston",
    ids: [
      "Dylan",
      "Marcus",
      "Fernando",
      "Colin",
      "owen",
      "Ben",
      "diego",
      "Preston",
    ],
    profile: "/assets/avatars/profiles/Preston.png",
    body: "/assets/avatars/bodies/Preston.png",
  },
  {
    key: "Urvi",
    ids: ["Rose", "kala", "Urvi"],
    profile: "/assets/avatars/profiles/Urvi.png",
    body: "/assets/avatars/bodies/Urvi.png",
  },
  {
    key: "Angelina",
    ids: ["Angelina", "Sara", "lali"],
    profile: "/assets/avatars/profiles/Angelina.png",
    body: "/assets/avatars/bodies/Angelina.png",
  },
  {
    key: "Allison",
    ids: ["Naomi", "Allison"],
    profile: "/assets/avatars/profiles/Allison.png",
    body: "/assets/avatars/bodies/Allison.png",
  },
  {
    key: "Jaylen",
    ids: ["Miles", "DeAndre", "leroy", "Jaylen"],
    profile: "/assets/avatars/profiles/Jaylen.png",
    body: "/assets/avatars/bodies/Jaylen.png",
  },
  {
    key: "Lakeisha",
    ids: ["Simone", "lakeisha", "Lakeisha"],
    profile: "/assets/avatars/profiles/Lakeisha.png",
    body: "/assets/avatars/bodies/Lakeisha.png",
  },
  {
    key: "Aaron",
    ids: ["Aaron"],
    profile: "/assets/avatars/profiles/AAron.png",
    body: "/assets/avatars/bodies/AAron.png",
  },
  {
    key: "Alfred",
    ids: ["Alfred"],
    profile: "/assets/avatars/profiles/Alfred.png",
    body: "/assets/avatars/bodies/Alfred.png",
  },
  {
    key: "Mabel",
    ids: ["Mabel"],
    profile: "/assets/avatars/profiles/Mabel.png",
    body: "/assets/avatars/bodies/Mabel.png",
  },
  {
    key: "Sophie",
    ids: ["Sophie"],
    profile: "/assets/avatars/profiles/Sophie.png",
    body: "/assets/avatars/bodies/Sophie.png",
  },
  {
    key: "Gloria",
    ids: ["Gloria"],
    profile: "/assets/avatars/profiles/Gloria.png",
    body: "/assets/avatars/bodies/Gloria.png",
  },
  {
    key: "GraceLin",
    ids: ["GraceLin"],
    profile: "/assets/avatars/profiles/GraceLin.png",
    body: "/assets/avatars/bodies/GraceLin.png",
  },
] as AvatarEntry[];

export const incognitoBody = "/assets/avatars/bodies/incognito.png";

export const voiceDescriptions = {
  Ivy: [" High", "Young"],
  Kimberly: [" Medium", "Mature"],
  Joanna: [" High-medium", "Mature"],
  Joey: [" Low", "Mature"],
  Kendra: [" Mid-low", "Mature"],
  Justin: [" High", "Young"],
  Matthew: [" Low", "Mature"],
  Salli: [" Medium", "Mature"],
  Kevin: [" High", "Young"],
  Amy: [" British", "Medium", "Mature"],
  Emma: [" British", "Medium", "Mature"],
  Brian: [" British", "Medium", "Mature"],
  "he-IL-Wavenet-A": [" High", "Young"],
  "he-IL-Wavenet-B": [" Medium", "Mature"],
  "he-IL-Wavenet-C": [" High", "Mature"],
  "he-IL-Wavenet-D": [" Medium", "Young"],
} as { [key: string]: string[] };

export const bodyStage =
  "https://storage.googleapis.com/coco_public/avatars/bodies/stage.png";
