import {fetchWrapper} from "data/api/rest/fetchWrapper";

export const cancelBotSubcription = async (bot_id: number) => {
    try {
        const result = await fetchWrapper(`/v2/billing/delete_subscription`, {
            method: "DELETE",
            body: JSON.stringify({
                bot_id
            })
        });
        if (!result.ok) throw new Error(result.statusText);
        return result.json();
    } catch (e: any) {
        console.error(e.message);
        return "";
    }
};
