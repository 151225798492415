import React from "react";
import { useTheme } from "@material-ui/core";

export type colorVariant = "black" | "blue";
const EditButton: React.FC<{
  color?: colorVariant;
  height?: number;
  width?: number;
}> = ({ color = "black", height = 22, width = 54 }) => {
  const theme = useTheme();

  const getColor = () => {
    if (color === "blue") return theme.custom.palette.a.main;
    if (color === "black") return theme.custom.palette.e.main;
  };

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 54 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7675 4C17.4744 4 17.1841 4.05773 16.9133 4.16991C16.6425 4.28208 16.3965 4.44649 16.1892 4.65376L11.2196 9.62337C10.8756 9.96683 10.6411 10.4044 10.5454 10.881L10.0863 13.1795C10.0454 13.384 10.1095 13.5953 10.257 13.7428C10.4044 13.8902 10.6159 13.9542 10.8203 13.9133L13.1182 13.4533C13.5946 13.358 14.0319 13.1243 14.3759 12.7803L19.3458 7.81039C19.5531 7.60312 19.7175 7.35706 19.8297 7.08625C19.9418 6.81544 19.9996 6.52519 19.9996 6.23207C19.9996 5.93895 19.9418 5.6487 19.8297 5.37789C19.7175 5.10709 19.5531 4.86103 19.3458 4.65376C19.1385 4.44649 18.8925 4.28208 18.6217 4.16991C18.3509 4.05773 18.0606 4 17.7675 4ZM17.3907 5.32244C17.5102 5.27296 17.6382 5.24749 17.7675 5.24749C17.8968 5.24749 18.0248 5.27296 18.1443 5.32244C18.2637 5.37192 18.3723 5.44444 18.4637 5.53587C18.5551 5.62729 18.6277 5.73583 18.6771 5.85529C18.7266 5.97474 18.7521 6.10277 18.7521 6.23207C18.7521 6.36137 18.7266 6.4894 18.6771 6.60885C18.6277 6.72831 18.5551 6.83685 18.4637 6.92828L17.5049 7.88704L16.1122 6.49496L17.0713 5.53587C17.1627 5.44444 17.2713 5.37192 17.3907 5.32244ZM15.2301 7.37707L16.6228 8.76915L13.4938 11.8982C13.3241 12.0679 13.1087 12.183 12.8735 12.2301L11.4929 12.5064L11.7686 11.1264C11.8157 10.8914 11.9315 10.6755 12.1011 10.5061L15.2301 7.37707Z"
        fill={getColor()}
      />
      <path
        d="M5.5729 7.30858C5.78142 7.10007 6.06423 6.98292 6.35911 6.98292H8.96253C9.30701 6.98292 9.58627 6.70366 9.58627 6.35918C9.58627 6.01469 9.30701 5.73543 8.96253 5.73543H6.35911C5.73337 5.73543 5.13326 5.98401 4.6908 6.42647C4.24833 6.86894 3.99976 7.46905 3.99976 8.09479V17.6406C3.99976 18.2664 4.24833 18.8665 4.6908 19.309C5.13326 19.7514 5.73337 20 6.35911 20H15.905C16.5307 20 17.1308 19.7514 17.5733 19.309C18.0157 18.8665 18.2643 18.2664 18.2643 17.6406V15.0372C18.2643 14.6927 17.9851 14.4135 17.6406 14.4135C17.2961 14.4135 17.0168 14.6927 17.0168 15.0372V17.6406C17.0168 17.9355 16.8997 18.2183 16.6912 18.4269C16.4827 18.6354 16.1999 18.7525 15.905 18.7525H6.35911C6.06423 18.7525 5.78142 18.6354 5.5729 18.4269C5.36439 18.2183 5.24725 17.9355 5.24725 17.6406V8.09479C5.24725 7.7999 5.36439 7.5171 5.5729 7.30858Z"
        fill={getColor()}
      />
      <path
        d="M27.416 15.18V12.912H31.966V11.148H27.416V9.02H32.568V7.2H25.162V17H32.75V15.18H27.416ZM39.8339 6.612V10.266C39.2879 9.65 38.5039 9.356 37.5799 9.356C35.4519 9.356 33.8139 10.868 33.8139 13.234C33.8139 15.6 35.4519 17.112 37.5799 17.112C38.5879 17.112 39.3859 16.79 39.9319 16.132V17H42.0179V6.612H39.8339ZM37.9579 15.32C36.8659 15.32 36.0259 14.536 36.0259 13.234C36.0259 11.932 36.8659 11.148 37.9579 11.148C39.0359 11.148 39.8759 11.932 39.8759 13.234C39.8759 14.536 39.0359 15.32 37.9579 15.32ZM45.1313 8.418C45.9433 8.418 46.4893 7.886 46.4893 7.158C46.4893 6.486 45.9433 5.982 45.1313 5.982C44.3193 5.982 43.7733 6.514 43.7733 7.2C43.7733 7.886 44.3193 8.418 45.1313 8.418ZM44.0393 17H46.2233V9.468H44.0393V17ZM52.5202 15.096C52.2822 15.278 51.9602 15.376 51.6382 15.376C51.0502 15.376 50.7002 15.026 50.7002 14.382V11.316H52.5762V9.636H50.7002V7.802H48.5162V9.636H47.3542V11.316H48.5162V14.41C48.5162 16.202 49.5522 17.112 51.3302 17.112C52.0022 17.112 52.6602 16.958 53.1082 16.636L52.5202 15.096Z"
        fill={getColor()}
      />
    </svg>
  );
};

export default EditButton;
