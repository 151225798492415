import {NodeV2} from "./../../../bots-studio/store/types";
import {Blueprint, Configuration, QnAEntry, UserComponent} from "./types";
import {ConfigEditTool, blueprintPutKeys, getBlueprintAndConfig} from "./MyComponentsStore";
import {IObservableArray, action, autorun, observable, toJS} from "mobx";

import {ContainerStore} from "../container/ContainerStore";
import {makeErrorBlueprint} from "data/api/rest/requests/makeErrorBlueprint";
import {setBlueprint} from "data/api/rest/requests/setBlueprint";
import {setConfig} from "data/api/rest/requests/setConfig";

export const createEditComponent = (container: ContainerStore, components: IObservableArray<UserComponent>) => {
    const editComponentId = observable.box("");
    const isUpdating = observable.box(false);
    const isLoading = observable.box(false);

    const edit = observable({
        isEditingConfig: false,
        isEditingAnswer: false,
        editFallbackDialog: false,
        editComponentId,
        nodeComponent: {} as NodeV2,
        editedQnAEntry: {} as QnAEntry,
        isUpdating,
        isLoading,
        get availableTools() {
            const config = this.editedBlueprint.json_config as Configuration;
            const tools = [ConfigEditTool.json] as ConfigEditTool[];
            config.action_config && tools.push(ConfigEditTool.actions);
            config.qa_config && tools.push(ConfigEditTool.qna);
            config.quiz_survey_config && tools.push(ConfigEditTool.quiz);
            return tools;
        },
        selectedTool: ConfigEditTool.json,
        editedBlueprint: makeErrorBlueprint(),
        updatedBlueprint: makeErrorBlueprint(),
        get isCanSaveUpdate() {
            return JSON.stringify(toJS(this.editedBlueprint)) !== JSON.stringify(toJS(this.updatedBlueprint));
        },
        updateBlueprint: action(async () => {
            isUpdating.set(true);
            const isBlueprintChanged = JSON.stringify(edit.updatedBlueprint) !== JSON.stringify(edit.editedBlueprint);
            const isBlueprintOk = !isBlueprintChanged
                ? true
                : await setBlueprint(
                      blueprintPutKeys.reduce((acc, cur) => {
                          //@ts-ignore - not sure how to tell TS this is OK
                          acc[cur] = edit.updatedBlueprint[cur];
                          return acc;
                      }, {} as Blueprint)
                  );
            const isConfigChanged =
                JSON.stringify(toJS(edit.editedBlueprint.json_config)) !==
                JSON.stringify(toJS(edit.updatedBlueprint.json_config));
            const isConfigOk = isConfigChanged
                ? await setConfig(editComponentId.get(), {config: edit.updatedBlueprint.json_config})
                : true;
            const isOk = isBlueprintOk && isConfigOk;
            if (isOk) {
                const component = components.find((c) => c.human_id === edit.updatedBlueprint.human_id);
                component &&
                    Object.keys(edit.updatedBlueprint).forEach((key) => {
                        // @ts-ignore
                        component[key] = edit.updatedBlueprint[key];
                    });
            }
            isUpdating.set(false);
        }),
        closeEdit: action(() => {
            editComponentId.set("");
            edit.isEditingConfig = false;
        })
    });

    autorun(async () => {
        isLoading.set(true);
        const id = editComponentId.get();
        const blueprint = await getBlueprintAndConfig(id);
        isLoading.set(false);
        action(() => {
            edit.editedBlueprint = {...blueprint};
            edit.updatedBlueprint = {...blueprint};
        })();
    });

    autorun(() => {
        const config = edit.editedBlueprint.json_config as Configuration;
        if (config.action_config) {
            return (edit.selectedTool = ConfigEditTool.actions);
        }
        if (config.qa_config) {
            return (edit.selectedTool = ConfigEditTool.qna);
        }
        edit.selectedTool = edit.availableTools[0];
    });

    return edit;
};
