import {makeStyles, Typography} from "@material-ui/core";
import {AnalyticType} from "analytics/AnalyticType";
import {ChannelDialogParams} from "channels/channelsMap";
import {audioCodesInfographics} from "channels/infographics";
import {ChannelType} from "channels/types";
import {cocohubAudiocodesURL} from "dangerous/staticStrings";
import {useChannelFunctions} from "data/api/gql/hooks/useChannelFunctions";
import {MainButton} from "theme/components/elements/buttons/MainButton";
import {TextButton} from "theme/components/elements/buttons/TextButton";
import {Subtitle} from "theme/components/elements/Subtitle";
import {DividerElement} from "utils/elments/DividerElement";

const useStyles = makeStyles((theme) => {
    return {
        container: {
            position: "relative",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginTop: theme.spacing(4)
        },
        bodyContainer: {paddingTop: theme.spacing(3), display: "flex", height: "100%"},
        explanation: {},
        buttonContainer: {
            width: "50%",
            display: "flex",
            justifyContent: "flex-end"
        },
        button: {
            width: "100%",
            alignSelf: "flex-end"
        },
        footerContainer: {
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            paddingTop: theme.spacing(2)
        },
        infographics: {
            height: "auto",
            width: "auto",
            paddingTop: theme.spacing(4),
            textAlign: "center",
            paddingBottom: theme.spacing(2)
        }
    };
});

export const AudiocodesBody: React.FC<ChannelDialogParams> = ({botId, botName, botChannels, close}) => {
    const classes = useStyles();

    const {create, remove, update, loading} = useChannelFunctions({botId});

    const channel = botChannels.find((b) => b.jsonConfig.channel_name === ChannelType.audiocodes);
    const channelId = channel?.id;

    const confirmDelete = () => {
        if (!channelId) return;
        remove({variables: {id: channelId}});
        close();
    };

    const manage = () => {
        window.open(`${cocohubAudiocodesURL}/manage/?vaic_id=${" no id"}`, "_blank");
    };

    return (
        <div className={classes.container}>
            <div className={classes.bodyContainer}>
                <Typography className={classes.explanation}>
                    Connect <b>{botName}</b> to an Audiocodes phone number. Once connected, your audience will be able
                    to make/receive calls.
                </Typography>
            </div>
            <div>
                <Subtitle title={"How To"} color={"primary"} />
                <div className={classes.infographics}>{audioCodesInfographics}</div>
            </div>
            <div className={classes.footerContainer}>
                <MainButton onClick={channelId ? manage : manage}>{channelId ? "Manage" : "Connect"}</MainButton>
                <DividerElement w={2} />
                {channelId ? (
                    <TextButton
                        variant={"secondary"}
                        analytics_event={AnalyticType["embed-channel-dialog-disconnect"]}
                        onClick={confirmDelete}>
                        Disconnect
                    </TextButton>
                ) : null}
            </div>
        </div>
    );
};
