import {Divider, Typography, makeStyles} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {SelectorDataItem, SlidingSelector} from "theme/components/elements/menu/SlidingSelector";

import AlternatesEditor from "./alternatesEditor/AlternatesEditor";
import {QnAEntry} from "marketplace-2/store/components/types";
import QuestionExamplesEditor from "./questionsEditor/QuestionExamplesEditor";
import useOnFirstRender from "utils/hooks/useOnFirstRender";

const QUESTION_EXAMPLE = "questions";
const ALTERNATES = "alternatives";

const tabToElement = (tab: string, data?: QnAEntry) => {
    let reply: JSX.Element | null = null;

    switch (tab) {
        case QUESTION_EXAMPLE:
            reply = <QuestionExamplesEditor />;
            break;
        case ALTERNATES:
            reply = <AlternatesEditor />;
            break;
        default:
            break;
    }

    return reply;
};

const menuEntries: SelectorDataItem[] = [
    {key: QUESTION_EXAMPLE, title: "Questions Examples", id: 0},
    {key: ALTERNATES, title: "Alternatives", id: 1}
];

const useStyles = makeStyles((theme) => {
    return {
        container: {
            paddingTop: theme.spacing(0),
            display: "flex",
            flexDirection: "column"
        },
        titleBar: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
        },
        SelectedAnswerContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            boxShadow: theme.shadows[1],
            borderRadius: theme.spacing(3),
            padding: theme.spacing(1, 3),
            marginLeft: 4,
            marginRight: 4,
            marginTop: theme.spacing(2)
        },
        menuContainer: {width: "100%"}
    };
});

const AnswerEditor: React.FC<{entry: QnAEntry}> = ({entry}) => {
    const classes = useStyles();
    const questionsTab = menuEntries[0];
    const [selectedTab, setSelectedTab] = useState(questionsTab);
    const [positionData, setPosition] = useState({positionX: 0, width: 0});
    useEffect(() => {
        setSelectedTab(questionsTab);
    }, []);

    const containerRef = document.getElementById("answer-sliding-menu");
    useOnFirstRender(() => {
        if (containerRef) {
            const dimensions = containerRef.getBoundingClientRect();
            setPosition({positionX: dimensions.left, width: dimensions.width});
        }
    }, [containerRef?.getBoundingClientRect().left]);

    return (
        <div className={classes.container}>
            <div className={classes.titleBar}>
                <Typography>
                    <b>Customize answer</b>
                </Typography>
            </div>
            <Divider></Divider>
            <div className={classes.SelectedAnswerContainer}>
                <Typography>{entry.answers[0]}</Typography>
            </div>
            <div className={classes.menuContainer} id={"answer-sliding-menu"}>
                <SlidingSelector
                    items={menuEntries}
                    onItemChanged={(item: SelectorDataItem) => {
                        setSelectedTab(item);
                    }}
                    item={selectedTab.id}
                />
                {tabToElement(selectedTab.key, entry)}
            </div>
        </div>
    );
};

export default AnswerEditor;
