import {action, reaction} from "mobx";

import {BotsStore} from "../createBotsStore";
import {fetchBotDataAndUpdate} from "../functions/fetchBotDataAndUpdate";

/** Fetch extended bot data when bot is selected */
export const runFetchBotData = ({store}: {store: BotsStore}) =>
    reaction(
        () => {
            return {currentId: store.currentId, isBotsLoaded: store.isBotsLoaded};
        },
        action(async ({currentId, isBotsLoaded}) => {
            store.isLoading = true;
            await fetchBotDataAndUpdate({store, currentId, isBotsLoaded});
            store.isLoading = false;
        })
    );
