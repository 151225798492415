import {History} from "history";

export const upsertQueryParam = (key: string, value: string) => {
    const params = new URLSearchParams(window.location.search);
    params.set(key, value);
    const s = params.toString();
    // @ts-ignore
    const history = window.router_history as History;
    if (history) {
        history.replace(window.location.pathname + (s.length ? "?" : "") + s);
    } else window.history.replaceState(undefined, "", window.location.pathname + (s.length ? "?" : "") + s);
};
