import React, {useEffect, useState} from "react";
import {Theme, useMediaQuery} from "@material-ui/core";
import {clearAllBodyScrollLocks, disableBodyScroll} from "body-scroll-lock";

import CustomCssBaseline from "./../theme/CustomCssBaseline";
import {HubChatWindow} from "marketplace-2/pages/hub/chat-window/HubChatWindow";
import {MuiThemeProvider} from "@material-ui/core/styles";
import {makeMarketplaceTheme} from "./../theme/makeMarketplaceTheme";
import {useParams} from "react-router-dom";

type TestComponentParams = {
    component: string;
};

export const TestComponent = () => {
    const theme = makeMarketplaceTheme(true);
    const params = useParams<TestComponentParams>();

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

    const [height, setHeight] = useState(window.innerHeight);
    const [windowHeight, setWindowHeight] = useState<number>();

    const humanId = params.component ? params.component : "TEST";

    const getCurrentWindowHeight = () => {
        return window.innerHeight;
    };

    useEffect(() => {
        setWindowHeight(window.innerHeight);
    }, []);

    const chatBody = document.getElementById("coco_chat_window_body") as HTMLElement;
    const textarea = document.getElementById("coco_chat_window_textarea") as HTMLElement;
    const jsonReplyModal = document.getElementById("coco_chat_window_json_reply_data_modal") as HTMLElement;

    const onFocus = () => {
        if (isMobile) {
            setTimeout(() => {
                setHeight(() => getCurrentWindowHeight());
                disableBodyScroll(chatBody);
                disableBodyScroll(textarea);
                disableBodyScroll(jsonReplyModal);
            }, 150);
        }
    };

    const onBlur = (e: React.FormEvent) => {
        if (isMobile) {
            if (typeof windowHeight === "number") setHeight(windowHeight);
            clearAllBodyScrollLocks();
        }
    };

    return (
        <MuiThemeProvider theme={theme}>
            <CustomCssBaseline />
            <div
                style={{
                    height: "100vh",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                <div
                    style={{
                        height: isMobile ? `${height}px` : `calc( 100vh - 40px )`,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        position: isMobile ? "fixed" : "relative",
                        left: "0",
                        bottom: "0",
                        transition: "all 0.3s linear",
                        WebkitUserSelect: "none"
                    }}
                    id="boxContainer">
                    <div
                        style={{
                            height: "100%",
                            width: isMobile ? "100%" : "360px",
                            borderRadius: "10px",
                            boxShadow: isMobile ? "none" : "rgba(0,0,0,0.5) 0px 0px 3px 3px"
                        }}>
                        <HubChatWindow
                            {...{
                                name: humanId,
                                componentId: humanId
                            }}
                        />
                    </div>
                </div>
            </div>
        </MuiThemeProvider>
    );
};
