import {action, observable, reaction, toJS} from "mobx";

import {AuthStore} from "marketplace-2/store/auth/createAuthStore";
import {ProjectsStore} from "../projects/createProjects";
import {REMINDERS_KEY} from "dangerous/staticStrings";
import {getUserState} from "data/api/rest/requests/getUserState";
import {setUserState} from "data/api/rest/requests/setUserState";

export enum REMINDERS_KEYS {
    NEED_TO_PUBLISH = "need-to-publish",
    ZOOM_CHANNEL_PUBLISH_EXPLANATION = "zoom-channel-publish-explanation"
}

export const createRemindersStore = (projects: ProjectsStore, auth: AuthStore) => {
    const store = observable({
        isReady: false,
        dismissed: observable.array([] as REMINDERS_KEYS[]),
        showing: undefined as REMINDERS_KEYS | undefined,
        dismiss: (id: REMINDERS_KEYS, isShowAgain?: boolean) => {
            store.showing = undefined;
            if (!isShowAgain) return;
            store.dismissed.push(id);
            setUserState(REMINDERS_KEY, {dismissed: toJS(store.dismissed)});
        },
        reset: () => {
            setUserState(REMINDERS_KEY, {dismissed: []});
            store.dismissed.replace([]);
        }
    });

    reaction(
        () => auth.isReady && auth.isAuthenticated,
        async (isNeedToInitialize) => {
            if (!isNeedToInitialize) return;
            const data = (await getUserState(REMINDERS_KEY)) as {dismissed?: REMINDERS_KEYS[]};
            action(() => {
                data && data.dismissed && store.dismissed.replace(data.dismissed);
                store.isReady = true;
            })();
        }
    );

    // show NEED_TO_PUBLISH
    reaction(
        () => ({isReady: store.isReady, isShowingPublish: !projects.isPublshSynced}),
        async ({isReady, isShowingPublish}) => {
            if (
                !isReady ||
                !isShowingPublish ||
                store.dismissed.includes(REMINDERS_KEYS.NEED_TO_PUBLISH) ||
                store.showing === REMINDERS_KEYS.NEED_TO_PUBLISH
            )
                return;

            store.showing = REMINDERS_KEYS.NEED_TO_PUBLISH;
        }
    );

    // hide NEED_TO_PUBLISH
    reaction(
        () => ({
            isShowing: store.showing === REMINDERS_KEYS.NEED_TO_PUBLISH,
            isShowingPublish: !projects.isPublshSynced
        }),
        async ({isShowing, isShowingPublish}) => {
            if (!isShowing || isShowingPublish) return;

            store.showing = undefined;
        }
    );

    return store;
};

export type ReminderStore = ReturnType<typeof createRemindersStore>;
