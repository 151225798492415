import {useTheme} from "@material-ui/core";
import React from "react";

export type colorVariant = "blue" | "purple";

const DotIcon: React.FC<{color: colorVariant}> = ({color = "blue"}) => {
    const theme = useTheme();

    const getColor = () => {
        if (color === "blue") return theme.custom.palette.a.main;
        if (color === "purple") return theme.custom.palette.b.main;
    };

    return (
        <div style={{paddingRight:4}}>
            <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="4.5" r="4" fill={getColor()} />
        </svg>
        </div>
    );
};

export default DotIcon;
