import React from "react";

const random = (min: number, max: number) => Math.floor(Math.random() * (max - min)) + min;

export const useRandomInterval = ({
    callback,
    minDelay,
    maxDelay,
    isActive = true
}: {
    callback: () => void;
    minDelay: number;
    maxDelay: number;
    isActive: boolean;
}) => {
    const timeoutId = React.useRef(0);
    const savedCallback = React.useRef(callback);

    React.useEffect(() => {
        savedCallback.current = callback;
    });

    React.useEffect(() => {
        let isEnabled = typeof minDelay === "number" && typeof maxDelay === "number";
        if (isEnabled && isActive) {
            const handleTick = () => {
                const nextTickAt = random(minDelay, maxDelay);
                timeoutId.current = window.setTimeout(() => {
                    savedCallback.current();
                    handleTick();
                }, nextTickAt);
            };
            handleTick();
        }
        return () => window.clearTimeout(timeoutId.current);
    }, [minDelay, maxDelay, isActive]);

    const cancel = React.useCallback(function () {
        window.clearTimeout(timeoutId.current);
    }, []);

    return cancel;
};
