export const CHANNELS_STATE_KEY = "channels-state-key";

export enum ChannelType {
  "alexa" = "alexa",
  "cocohub" = "cocohub",
  "email" = "email",
  "facebook" = "facebook",
  "sms" = "sms",
  "telegram" = "telegram",
  "whatsapp" = "whatsapp",
  "telephone" = "telephone",
  "videoChannel" = "zoom",
  "audiocodes" = "audiocodes",
  "wordpress" = "wordpress",
  "embedded" = "embedded",
  "twilio" = "twilio_async_msg",
  "none" = "",
  "dashboard" = "dashboard",
  "browser" = "browser"
}

export type EmbeddedData = {
  bot_name: string;
  fab_right?: number;
  fab_bottom?: number;
  channel_id: string;
  bot_greeting?: string;
  source_language_code?: string;
  is_fabless?: boolean;
  is_rtl?: boolean;
  is_open_on_start?: boolean;
  height?: number;
  width?: number;
};

export type AlexaChannelData = {
  component_id: string;
  polly_voice_id: string;
  end_statement: string;
  help_statement: string;
  reprompt_statement: string;
  skill_id: string;
};

export type AudioCodesChannelData = {
  vaic_id: string | undefined;
};

export type AlexaChannelFields = {
  polly_voice_id: string;
  end_statement: string;
  help_statement: string;
  reprompt_statement: string;
};

export type AlexaSkillManifestParams = {
  name: string;
  description: string;
};

export type AlexaSkillInteractionModelParams = {
  invocation_name: string;
};

export type AlexaDeployskillResponse = {
  channelId: string;
  succeed: boolean;
};

export type AlexaFieldErros = {
  end_statement: string;
  help_statement: string;
  reprompt_statement: string;
  invocation_name: string;
  name: string;
  description: string;
};

export type PhoneData = {
  component_id: string;
  tts_language: string;
  stt_language: string;
  polly_voice_id: string;
  phone_number: string;
};

export type FacebookData = {
  component_id: string;
  page_id: string;
  page_picture_url: string;
  page_name: string;
};

export type ZoomData = {
  mail: string; // email for the bot
  scene_id: string; // id of scene (avatar background), selected from static scenes list
  wake_phrases: string[]; // phrases to wake bot up, must have at least 1 non-empty
  sleep_phrases: string[]; // phrases to put bot back to sleep, must have at least 1 non-empty
};

export type TelegramData = {
  token: string;
};

export type TwilioData = {
  phone: string;
  authToken: string;
  accountSID: string;
};
