import { Modal, Typography } from "@material-ui/core";

import { CloseButton } from "utils/elments/CloseButton";
import { DialogBodyContainer } from "./kit/DialogBodyContainer";
import { DialogBodyInnerContainer } from "./kit/DialogBodyInnerContainer";
import { BotIcon } from "./components/BotIcon";
import { DialogFooter } from "./components/DialogFooter";
import { DialogHeader } from "./components/DialogHeader";
import { DividerElement } from "utils/elments/DividerElement";
import { MainButton } from "theme/components/elements/buttons/MainButton";
import React from "react";
import { SecondaryButton } from "theme/components/elements/buttons/SecondaryButton";
import { useBotInfo } from "data/api/gql/hooks/useBotInfo";
import { useDeleteBot } from "data/api/gql/hooks/useDeleteBot";
import { useDialogContext } from "./context/useDialogContext";

export const DeleteBotDialog = () => {
  const { select, dispatch, actions } = useDialogContext();

  const deletingId = select((s) => s.bot.deleting);

  const isOpen = !!deletingId;

  const onCancel = () => dispatch(actions.bot.cancelDeleteBot());

  const {
    info: { name, subscription },
    loading: infoLoading,
    error: infoError,
  } = useBotInfo({ botId: deletingId });

  const {
    deleteBot,
    loading: deleteLoading,
    error: deleteError,
  } = useDeleteBot();

  const onOk = () => {
    !!deletingId && deleteBot(parseInt(deletingId), subscription);
    onCancel();
  };

  return (
    <Modal onClose={onCancel} open={isOpen}>
      <DialogBodyContainer>
        <DialogBodyInnerContainer>
          <DialogHeader Icon={BotIcon} title="Delete virtual human?" />
          <DividerElement h={3} />
          <Typography>{`When deleting ${name}, the data gathered by the virtual human will be lost 
without the option to retrieve it.`}</Typography>
          <DividerElement h={2} />
          {subscription && (
            <Typography>
              <b>Pay attention</b>
              {` the virtual human's subscription will also be discontinued at the end of current billing period`}
            </Typography>
          )}
          <DividerElement h={3} />
          <CloseButton onClick={onCancel} />
          <DialogFooter>
            <SecondaryButton
              onClick={onOk}
              variant="outlined-inverse"
              key="disconnect"
            >
              Delete the virtual human
            </SecondaryButton>
            <MainButton onClick={onCancel} key="keep channel">
              I'll keep the virtual human
            </MainButton>
          </DialogFooter>
        </DialogBodyInnerContainer>
      </DialogBodyContainer>
    </Modal>
  );
};
