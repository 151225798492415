import React, {useContext} from "react";
import {observable} from "mobx";
import {MessageType} from "marketplace-2/store/container/ContainerStore";

export type ConfirmationDialogData = {
    icon?: string;
    title?: string;
    description?: string;
    onClick?: () => void;
    buttonText?: string;
    notification?: {message: string; type?: MessageType};
};

const _private = observable({
    isShowing: false,
    data: {} as ConfirmationDialogData
});

const store = observable({
    get confirmationDialogData() {
        return _private.data;
    },

    get showingConfirmationDialog() {
        return _private.isShowing;
    },

    setConfirmationDialog(data: ConfirmationDialogData) {
        _private.data = data;
    },

    openConfirmationDialog() {
        _private.isShowing = true;
    },

    closeConfirmationDialog() {
        _private.isShowing = false;
        _private.data = {};
    }
});

export const ConfirmationDialogsContext = React.createContext(store);
export const ConfirmationDialogsContextProvider: React.FC = ({children}) => {
    return <ConfirmationDialogsContext.Provider value={store}>{children}</ConfirmationDialogsContext.Provider>;
};

export const useConfirmationDialogsContext = () => useContext(ConfirmationDialogsContext);

export type ConfirmationDialogsStore = typeof store;
