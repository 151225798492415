import {makeStyles, Typography} from "@material-ui/core";
import React from "react";
import googleImage from "./google_meet.svg";

const useStyles = makeStyles((theme) => {
    return {
        container: {
            display: "flex",
            alignItems: "center"
        },
        icon: {
            paddingBottom: theme.spacing(0.2),
            flexDirection: "column",
            display: "flex",
            color: theme.custom.palette.e.main
        },
        link: {color:theme.custom.palette.a.main}
    };
});
const GoogleMeetDisclaimer = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.icon}>
                <img src={googleImage}></img>
            </div>
            <span>&nbsp;</span>
            <Typography>
                Meet (for{" "}
                <a className={classes.link} href="https://workspace.google.com/" target="_blank">
                    Google Workspace
                </a>{" "}
                users only)
            </Typography>
        </div>
    );
};

export default GoogleMeetDisclaimer;
