import {ButtonBase, Typography, makeStyles} from "@material-ui/core";

import {AnalyticType} from "analytics/AnalyticType";
import React from "react";
import {analytics} from "analytics/analytics";

export type CardSizes = {padding: number; height: number; width: number; borderRadius?: number};

const useStyles = (cardSizes: CardSizes) =>
    makeStyles((theme) => {
        return {
            outerContainer: {
                borderRadius: theme.spacing(cardSizes.borderRadius || 3),
                position: "relative"
            },
            actionButton: {
                ...cardSizes,
                borderRadius: theme.spacing(cardSizes.borderRadius || 3),
                borderTop: `1px solid white`,
                left: 0,
                top: 1,
                position: "absolute",
                transition: theme.transitions.create(["height", "background"], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.standard
                }),
                height: cardSizes.height + theme.spacing(4) - 1,
                "&:hover": {
                    height: cardSizes.height + theme.spacing(5) - 1,
                    background: ({isSelected}: {isSelected: boolean}) =>
                        isSelected ? theme.custom.palette.a.main : theme.custom.palette.e.main
                },
                background: ({isSelected}: {isSelected: boolean}) =>
                    isSelected ? theme.custom.palette.a.main : theme.custom.palette.e.secondary
            },
            actionButtonText: {
                color: theme.custom.palette.d.alt,
                position: "absolute",
                width: "100%",
                bottom: theme.spacing(0.5)
            }
        };
    });

export const CardWithButton: React.FC<{
    disabled?: boolean;
    isSelected: boolean;
    actionButtonText: string;
    select: () => void;
    cardSizes?: CardSizes;
    analytics_event?: AnalyticType;
    analytics_data?: any;
}> = ({
    children,
    isSelected,
    disabled,
    select,
    actionButtonText,
    analytics_event,
    analytics_data,
    cardSizes = {padding: 0, width: 0, height: 0, borderRadius: 0}
}) => {
    const classes = useStyles(cardSizes)({isSelected});

    const handleClick = () => {
        const analyticsObject = {} as {type: AnalyticType; data?: any};
        analytics_data && (analyticsObject.data = analytics_data);
        analytics_event && (analyticsObject.type = analytics_event) && analytics(analyticsObject);
        select();
    };

    return (
        <div className={classes.outerContainer}>
            {children}
            {!disabled && (
                <ButtonBase onClick={handleClick} className={classes.actionButton}>
                    <Typography variant="body2" className={classes.actionButtonText}>
                        {isSelected ? actionButtonText + "ed" : actionButtonText}
                    </Typography>
                </ButtonBase>
            )}
        </div>
    );
};
