import React from "react";
import botIcon from "assets/bot/botIcon.svg";

export const BotIcon = () => {
    return (
        <div>
            <img src={botIcon}></img>
        </div>
    );
};
