import { InfoBadge } from "theme/components/elements/InfoBadge";
import Loader from "utils/elments/loader/Loader";
import { MainButton } from "theme/components/elements/buttons/MainButton";
import { useBots } from "data/api/gql/hooks/useBots";
import { useTheme } from "@material-ui/core";

export enum PLAN {
  basicMonthly = "CLASSIC_PLAN_M",
  proMonthly = "PRO_PLAN_M",
  basicAnnually = "CLASSIC_PLAN_Y",
  proAnnually = "PRO_PLAN_Y",
  businessMonthly = "BUSINESS_PLAN_M",
  businessAnnually = "BUSINESS_PLAN_Y",
  custom = "custom",
  test = "test",
}

export const planKeyToName = new Map<PLAN, string>([
  [PLAN.basicMonthly, "Basic"],
  [PLAN.proMonthly, "Pro"],
  [PLAN.basicAnnually, "Basic"],
  [PLAN.proAnnually, "Pro"],
  [PLAN.businessAnnually, "Business"],
  [PLAN.businessMonthly, "Business"],
  [PLAN.custom, "Enterprise"],
]);

export const ChoosePlan: React.FC<{
  onClick: () => void;
  isActive: boolean;
  planId: PLAN;
}> = ({ isActive, onClick, planId }) => {
  const theme = useTheme();

  const { bots, loading, error } = useBots();

  if (loading || error) return <Loader error={error} />;
  const content = planId == PLAN.custom ? "Contact Sales" : "Choose Plan";

  return isActive ? (
    <div style={{ padding: theme.spacing(1, 0) }}>
      <InfoBadge variant="alt" size="normal" text="current plan" />
    </div>
  ) : (
    <MainButton
      onClick={() => {
        onClick();
      }}
    >
      {content}
    </MainButton>
  );
};
