import {useRootStore} from "marketplace-2/store/useRootStore";
import React from "react";

export const useNotification = () => {
    const {sendNotification} = useRootStore((store) => {
        return {sendNotification: store.container.messages.addMessage};
    });

    return sendNotification;
};
