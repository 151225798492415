import {defaultEmailValue, emailHumanId} from "dangerous/staticStrings";

import {CanvasStore} from "bots-studio/store/canvas/createCanvasStore";
import {GraphStore} from "bots-studio/store/graph/createGraphStore";
import {TutorialStore} from "../createTutorialStore";
import {markComplete} from "../utils/markComplete";
import {namerHumanId} from "../tutorialStagesData";
import {reaction} from "mobx";

export const stage3 = (store: TutorialStore, graph: GraphStore, canvas: CanvasStore) => {
    // stage 3-0 completion reaction
    reaction(
        () => {
            const isEmailOnStage = Object.values(graph.nodes).some((v) => v.component === emailHumanId);

            return {stage: store.stage, substage: store.substage, isEmailOnStage};
        },
        ({stage, substage, isEmailOnStage}) => {
            if (stage === 3 && substage === 0 && isEmailOnStage) {
                markComplete(store, stage, 0);
            }
        }
    );

    // stage 3-1 completion reaction
    reaction(
        () => {
            const inspectedNode = graph.nodes[graph.inspectedNodeId];
            const isEmailInspected = inspectedNode?.component === emailHumanId;
            const isEmailFilledIn =
                isEmailInspected &&
                inspectedNode.options.email &&
                inspectedNode.options.email.email !== defaultEmailValue;

            const isPrevComplete = store.completions.get(3)?.stages.get(0)?.isFinished || false;
            return {stage: store.stage, substage: store.substage, isPrevComplete, isEmailFilledIn};
        },
        ({stage, substage, isPrevComplete, isEmailFilledIn}) => {
            if (stage === 3 && substage === 1 && isPrevComplete && isEmailFilledIn) {
                markComplete(store, stage, 1);
            }
        }
    );

    // stage 3-2 completion reaction
    reaction(
        () => {
            const node = Object.values(graph.nodes).find((n) => n.component === emailHumanId);
            const edges =
                (node && Object.values(graph.edges).filter((e) => e && !e.to.position && e.to.nodeId === node.id)) ||
                [];
            const isDoneConnected = edges.some((edge) => {
                const node = graph.nodes[edge.from.nodeId];
                if (!node) return;
                const isRightNode = node.component === namerHumanId;
                if (!isRightNode) return false;
                return node.actions.findIndex((a) => a.id === edge.action) === 0;
            });
            const isPrevComplete = store.completions.get(3)?.stages.get(1)?.isFinished || false;
            return {stage: store.stage, substage: store.substage, isPrevComplete, isDoneConnected};
        },
        ({stage, substage, isPrevComplete, isDoneConnected}) => {
            if (stage === 3 && substage === 2 && isPrevComplete && isDoneConnected) {
                markComplete(store, stage, 2);
                const stageRef = store.completions.get(store.stage);
                stageRef && (stageRef.isFinished = true);
            }
        }
    );
};
