import {Loader, PrimaryStyledButton} from "./styled";

import {FC} from "react";
import {PrimaryButtonProps} from "./api/types";

const PrimaryButton: FC<PrimaryButtonProps> = ({children, ...rest}) => {
    return (
        <PrimaryStyledButton {...rest}>
            {rest.isLoading && <Loader />}
            {children}
        </PrimaryStyledButton>
    );
};

PrimaryButton.defaultProps = {
    size: "MEDIUM"
};

export default PrimaryButton;
