import {ButtonBase, Typography, makeStyles} from "@material-ui/core";
import {QAConfig, QnAEntry} from "marketplace-2/store/components/types";
import React, {useState} from "react";

import DeleteIcon from "./assets/DeleteIcon";
import DotIcon from "./assets/DotIcon";
import EditButton from "./assets/EditButton";
import {action} from "mobx";
import {useRootStore} from "marketplace-2/store/useRootStore";

const useStyles = makeStyles((theme) => {
    return {
        container: {
            marginLeft: 4,
            marginRight: 4,
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            padding: theme.spacing(1, 2),
            borderRadius: theme.spacing(5),
            boxShadow: theme.shadows[2],
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between"
        },
        entryDetails: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
        },
        singleDetailContainer: {
            display: (props: {hover: Boolean}) => {
                if (props.hover) return "none";
                else return "flex";
            },
            flexDirection: "row"
        },
        divider: {
            margin: `${theme.spacing(2)}px 0px`
        },
        header: {
            width: "100%",
            display: "flex"
        },
        center: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
        },
        headerText: {
            flexBasis: "100%"
        },
        actionsContainer: {
            display: (props: {hover: Boolean}) => {
                if (!props.hover) return "none";
                else return "block";
            }
        },
        editButton: {
            paddingLeft: theme.spacing(1)
        },
        deleteButton: {
            paddingLeft: theme.spacing(1)
        }
    };
});

const QnASingleEntry: React.FC<{entry: QnAEntry; onClick: (v: QnAEntry) => void}> = ({entry, onClick}) => {
    const [hover, setHover] = useState(false);
    const classes = useStyles({hover});

    const {deleteEntry, updateBlueprint} = useRootStore((store) => {
        const edit = store.myComponents.editComponent;
        const conf = edit.updatedBlueprint.json_config.qa_config || ([] as QAConfig);
        return {
            deleteEntry: action(() => {
                let indexOfEntry = conf.indexOf(entry);
                edit.updatedBlueprint.json_config.qa_config?.splice(indexOfEntry, 1);
                edit.updateBlueprint();
            }),
            updateBlueprint: edit.updateBlueprint
        };
    });

    const handleClick = () => {
        onClick(entry);
        updateBlueprint();
    };

    return (
        <div
            className={classes.container}
            onMouseEnter={() => {
                setHover(true);
            }}
            onMouseLeave={() => {
                setHover(false);
            }}>
            <Typography>{entry.answers[0]}</Typography>
            <div className={classes.entryDetails}>
                <div className={classes.singleDetailContainer}>
                    <DotIcon color={"blue"}></DotIcon>
                    <Typography variant={"caption"}>Question Examples {entry.questions.length}</Typography>
                </div>
                <div style={{paddingRight: 16}}></div>
                <div className={classes.singleDetailContainer}>
                    <DotIcon color={"purple"}></DotIcon>
                    <Typography variant={"caption"}>alternatives {entry.answers.length - 1}</Typography>
                </div>
                <div className={classes.actionsContainer}>
                    <ButtonBase onClick={handleClick} className={classes.editButton}>
                        <EditButton />
                    </ButtonBase>
                    <ButtonBase onClick={deleteEntry} className={classes.deleteButton}>
                        <DeleteIcon />
                    </ButtonBase>
                </div>
            </div>
        </div>
    );
};

export default QnASingleEntry;
