import {fromb64} from "./base64";
import {getQueryParam} from "./getQueryParam";

export const getQueryData = <W extends {}>(id: string) => {
    const d = fromb64(getQueryParam(id) || "");
    let a = {} as W;
    try {
        a = JSON.parse(d);
    } catch (e) {
        // console.error(`Could not process ${id} query with value ${d}`);
    }
    return a as W;
};
