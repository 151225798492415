import {Bot} from "bots-studio/store/bots/createBotsStore";
import {fetchWrapper} from "data/api/rest/fetchWrapper";

export async function deleteBot(bot: Bot) {
    try {
        const r = await fetchWrapper(`/v2/bot/${bot.gid}`, {
            method: "DELETE",
            mode: "cors",
            credentials: "include"
        });
        if (!r.ok) return false;
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
}
