import { imageContainer } from "./ImageLogo";
import alexaLogo from "./logos/logo_amazon_alexa.svg";
import audioCodes from "./logos/logo_audiocodes.svg";
import embedLogo from "./logos/logo_embed.svg";
import messengerLogo from "./logos/logo_messenger.svg";
import phoneLogo from "./logos/logo_phone.svg";
import telegramLogo from "./logos/logo_telegram.svg";
import twilioLogo from "./logos/logo_twilio.svg";
import videoChannelLogo from "./logos/logo_video_channel.svg";
import whatsappLogo from "./logos/logo_whatsapp.svg";
import wordpressLogo from "./logos/logo_wordpress.svg";
import videoChannelTestLogo from "./logos/test_logo_video_channel.svg";

export const TelegramLogo = imageContainer(telegramLogo);
export const WhatsappLogo = imageContainer(whatsappLogo);

export const WordpressLogo = imageContainer(wordpressLogo);
export const VideoChannelLogo = imageContainer(videoChannelLogo);
export const VideoChannelTestLogo = imageContainer(videoChannelTestLogo);

export const PhoneLogo = imageContainer(phoneLogo);
export const MessengerLogo = imageContainer(messengerLogo);
export const TwilioLogo = imageContainer(twilioLogo);
export const EmbedLogo = imageContainer(embedLogo);
export const AudioCodesLogo = imageContainer(audioCodes);
export const AlexaLogo = imageContainer(alexaLogo);
