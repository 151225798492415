import {Configuration} from "marketplace-2/store/components/types";

export const makeErrorBlueprint = () => {
    return {
        human_id: "error",
        name: "",
        goal: "",
        description: "",
        json_config: {blueprint_id: ""} as Configuration,
        input_language: "",
        output_language: "",
        coming_soon: false,
        image_url: "",
        creation_date: "",
        categories: [] as string[]
    };
};
