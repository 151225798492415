import {Divider, Typography, makeStyles} from "@material-ui/core";

import React from "react";

export type SubtitleStyling = {color: SubtitleColor; bold: boolean; isInvisibleLine: boolean};

const useStyles = makeStyles((theme) => {
    return {
        titleContainer: {paddingBottom: theme.spacing(1), display: "flex"},
        title: {
            color: (props: SubtitleStyling) => {
                if (props.color === "primary") return theme.custom.palette.a.main;
                if (props.color === "secondary") return theme.custom.palette.a.alt;
                else return theme.custom.palette.d.alt;
            },
            whiteSpace: "nowrap",
            paddingRight: theme.spacing(1),
            fontWeight: (props: SubtitleStyling) => {
                if (props.bold) return 600;
                else return 500;
            }
        },
        divider: {
            flexBasis: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            opacity: ({isInvisibleLine}: SubtitleStyling) => (isInvisibleLine ? 0 : 1)
        }
    };
});

export type SubtitleColor = "primary" | "secondary" | "alt";

export const Subtitle = ({
    title,
    color = "alt",
    bold = false,
    isInvisibleLine = false
}: {
    title: string;
    color?: SubtitleColor;
    bold?: boolean;
    isInvisibleLine?: boolean;
}) => {
    const classes = useStyles({color, bold, isInvisibleLine});

    return (
        <div className={classes.titleContainer}>
            <Typography className={classes.title} variant="caption">
                {title}
            </Typography>
            <div className={classes.divider}>
                <Divider variant="fullWidth" />
            </div>
        </div>
    );
};
