import {Dialog, DialogContent, DialogTitle, Typography, makeStyles} from "@material-ui/core";

import {CloseButton} from "utils/elments/CloseButton";
import React from "react";
import whatsappIcon from "./assets/whatsappIcon.svg";

const useStyles = makeStyles((theme) => {
    return {
        icon: {
            width: theme.spacing(5),
            height: theme.spacing(5)
        },
        confirmationTitle: {
            display: "flex",
            alignItems: "center",
            fontWeight: "bold"
        },
        confirmationBody: {
            paddingBottom: theme.spacing(1),
            fontWeight: 100
        }
    };
});

const useDialogStyles = makeStyles((theme) => {
    return {
        paper: {
            overflow: "visible",
            position: "relative",
            backgroundColor: theme.custom.palette.d.main
        }
    };
});

export const WhatsappConfirmationDialog: React.FC<{isOpen: boolean; close: () => void}> = ({isOpen, close}) => {
    const classes = useStyles();
    const dialogClasses = useDialogStyles();

    return (
        <Dialog classes={dialogClasses} open={isOpen} onClose={close}>
            <DialogTitle>
                <Typography className={classes.confirmationTitle} variant={"h6"}>
                    <img className={classes.icon} src={whatsappIcon}></img>The beginning of a wonderful friendship
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography className={classes.confirmationBody} variant={"body1"}>
                    We'll contact you shortly to start the setup process
                </Typography>
            </DialogContent>
            <CloseButton onClick={close} />
        </Dialog>
    );
};
