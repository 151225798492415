import {Divider, makeStyles, Typography} from "@material-ui/core";
import {ArrowBack} from "@material-ui/icons";
import {ConfigEditTool} from "marketplace-2/store/components/MyComponentsStore";
import {useRootStore} from "marketplace-2/store/useRootStore";
import {action} from "mobx";
import React from "react";

const useStyles = makeStyles((theme) => {
    return {
        container: {
            padding: theme.spacing(3, 3, 1, 3)
        },
        titleContainer: {display: "flex", flexDirection: "row", justifyContent: "space-between"},
        backButton: {
            color: theme.custom.palette.a.main,
            display: "flex",
            alignItems: "center",
            cursor: "pointer"
        }
    };
});

const EditorTitle = () => {
    const classes = useStyles();

    const {selectedTool, isEditingAnswer, component, setEditingAnswer} = useRootStore((store) => {
        return {
            selectedTool: store.myComponents.editComponent.selectedTool,
            isEditingAnswer: store.myComponents.editComponent.isEditingAnswer,
            component: store.myComponents.editComponent.nodeComponent,
            setEditingAnswer: action((val: boolean) => {
                store.myComponents.editComponent.isEditingAnswer = val;
            })
        };
    });

    const getHeaderText = () => {
        if (selectedTool === ConfigEditTool.qna) return component.name || "FAQ component";
        else return "Edit Component Configuration";
    };

    return (
        <div className={classes.container}>
            <div className={classes.titleContainer}>
                <Typography variant={"h5"}>{getHeaderText()}</Typography>
                {isEditingAnswer ? (
                    <Typography
                        onClick={() => {
                            setEditingAnswer(false);
                        }}
                        variant={"caption"}
                        className={classes.backButton}>
                        <ArrowBack fontSize={"small"} />
                        Back to answers
                    </Typography>
                ) : null}
            </div>
            <Divider></Divider>
        </div>
    );
};

export default EditorTitle;
