import React, {useEffect, useState} from "react";
import {TablePagination, makeStyles} from "@material-ui/core";

import {ActionEditor} from "./ActionEditor";
import {FilterActions} from "./FilterActions";
import {useEditorContext} from "../EditorContext";
import {useRootStore} from "../../../../store/useRootStore";

const useStyles = makeStyles((theme) => {
    return {
        container: {
            paddingTop: theme.spacing(1),
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            overflow: "hidden"
        },
        bodyContainer: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            overflow: "auto"
        },
        filterContainer: {
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
        },
        vContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
        },
        bottomControls: {display: "flex", width: "100%"}
    };
});

export const ActionsEditTool = () => {
    const classes = useStyles();

    const {setActions} = useEditorContext();

    const {actions} = useRootStore((store) => {
        return {
            actions: store.myComponents.editComponent.updatedBlueprint.json_config.action_config || {}
        };
    });
    const [filter, setFilter] = useState("");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const actionsKeys = Object.keys(actions);

    const filterFunction = (action: string) => {
        if (!filter) return true;
        const f = filter.toLowerCase();
        const name = action.toLowerCase();
        const responses = (actions[action] || []).map((r) => r.toLowerCase());
        return f.includes(name) || name.includes(f) || responses.find((r) => r.includes(f) || f.includes(r));
    };

    const filteredActions = actionsKeys.filter(filterFunction);

    useEffect(() => {
        setActions([
            <div key={"Action editor actions"} className={classes.bottomControls}>
                <div className={classes.vContainer}>
                    <div className={classes.filterContainer}>
                        <FilterActions {...{filter, setFilter}} />
                    </div>
                </div>
                <div className={classes.vContainer}>
                    <table>
                        <tbody>
                            <tr>
                                <TablePagination
                                    rowsPerPageOptions={[20, 50, 100, 200]}
                                    count={filteredActions.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        ]);
    }, [filteredActions.length, rowsPerPage, page, filter]);

    return (
        <div className={classes.container}>
            <div className={classes.bodyContainer}>
                {filteredActions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((actionName) => (
                    <ActionEditor key={actionName} {...{actionName}} />
                ))}
            </div>
        </div>
    );
};
