import {TutorialData} from "../types";
import stage1 from "./assets/parameters-1.gif";
import stage2 from "./assets/parameters-2.gif";

export const parametersData: TutorialData = {
    card: {
        text: "How to use parameters for dynamic responses, navigation and actions",
        image: stage2,
        header: "Parameters"
    },
    steps: [
        {
            text: `Parameters are named pieces of information that passed between components.
1. Click anywhere on the canvas to pop the *Search-Menu*
2. Type in "get name" and select the *BV Name* component
3. Connect *Entry* node to the newly added **Node**
`,
            image: stage1,
            header: "Parameters - Intro"
        },
        {
            text: `*BV Name* component produces 2 different parameters in context, "user.firstName" and "user.lastName"
1. Add a **Say Node**
2. Set the **Say Node**'s line to \${context.user.firstName}
3. Connect done action of the **BV Name Node** to the **Say Node**
4. Test the flow to completion`,
            image: stage2,
            header: "Parameters - Usage"
        }
    ]
};
