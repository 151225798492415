import { AnalyticsIcon, BuilderIcon, HomeIcon, SideBar } from "@a-i/ui";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useNavDrawerContext } from "./context/useNavDrawerContext";
import { useUserRole } from "data/hooks";
import { UserRoles } from "const";

export const NavDrawer = () => {
  const { pathname } = useLocation();
  const { select, dispatch, actions } = useNavDrawerContext();
  const history = useHistory();
  const { role } = useUserRole();

  const slugToOption = (slug: string | undefined, userRole: UserRoles) => {
    switch (slug) {
      case '':
      case 'bots':
      case 'dashboard':
        return userRole === UserRoles.Teacher ? 'dashboard' : 'bots';
      case 'logs':
        return 'logs';
      case 'builder':
        return 'builder';
      default: 
        return '';
    }
  };
  
  const optionToUrl = {
    bots: "/bots",
    logs: "/logs",
    builder: "/builder",
    dashboard: '/dashboard',
  };

  const setSelectedDrawerOption = (option: string) =>
    dispatch(actions.ui.setSelected(option));
  const selectedItemId = select((s) => s.ui.selected);
  const isHidden = select((s) => s.ui.isHidden);

  
  
  useEffect(() => {
    const neededPath = optionToUrl[selectedItemId];

    if (!neededPath || pathname.startsWith(neededPath)) {
      return
    };

    history.push(neededPath);
  }, [selectedItemId]);

  const setOptionAndNavigate = (option: string) => {
    setSelectedDrawerOption(option);
    const path = optionToUrl[option];
    path && history.push(path);
  };

  const [items] = useState([
    {
      id: role === UserRoles.Teacher ? "dashboard" : "bots",
      url: role === UserRoles.Teacher ? "/dashboard" : "/bots",
      onItemClick: setOptionAndNavigate,
      iconSvg: <HomeIcon />,
      text: "Home",
    },
    {
      id: "builder",
      url: "/builder",
      onItemClick: setOptionAndNavigate,
      iconSvg: <BuilderIcon />,
      text: "Builder",
    },
    {
      id: "logs",
      url: "/logs",
      onItemClick: setOptionAndNavigate,
      iconSvg: <AnalyticsIcon />,
      text: "Analytics",
    },
  ]);

  useEffect(() => {
    if (!role) {
      return;
    }

    const slug = pathname.split('/')[1] || '';
    
    const option = slugToOption(slug, role);

    setSelectedDrawerOption(option);
  }, [pathname, role]);

  if (isHidden) return null;

  return <SideBar {...{ selectedItemId, items }} />;
};
