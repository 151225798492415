import { ActiveBotContextProvider } from "active-bot-context/ActiveBotContext";
import { CssBaseline } from "@material-ui/core";
import { DrawerContextProvider } from "bot-dashboard/experts/dialog/DrawerContext";
import { GlueStoreProvider } from "bots-studio/store/GlueStoreContext";
import { MainSwitch } from "./MainSwitch";
import { Messages } from "./common/Messages";
import { RootStoreProvider } from "./store/RootStoreContext";

export const Main = () => {
  return (
    <RootStoreProvider>
      <GlueStoreProvider>
        <DrawerContextProvider>
          <ActiveBotContextProvider>
            <Messages />
            <CssBaseline />
            <MainSwitch />
          </ActiveBotContextProvider>
        </DrawerContextProvider>
      </GlueStoreProvider>
    </RootStoreProvider>
  );
};
