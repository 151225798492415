import { useCallback, useEffect, useState } from "react";

import { useChat } from "bots-studio/components/container/chatWindow/useChat";
import { useSyncExchange } from "./useSyncExchange";

const sessionIdContainer = { sessionId: "" };

export const useBotChat = ({
  sessionId,
  botId,
  onError,
  channelId = "",
}: {
  sessionId: string;
  botId: string;
  onError: (e: Error) => void;
  channelId?: string;
}) => {
  const {
    chat,
    reset,
    onUserInput,
    onUserSubmit,
    onBotEntryStart,
    onBotEntryComplete,
  } = useChat(botId, botId);

  sessionIdContainer.sessionId = sessionId;

  const [isDisabled, setIsDisabled] = useState(false);

  const {
    call,
    result,
    reset: resetServerAgt,
  } = useSyncExchange({ botId, sessionId, channelId });

  const callAndProcess = useCallback(
    (v: string) => {
      call(v)
        .then((r) => {
          const responses = r.responses || [];
          responses.forEach((entry: { text: string; media?: string }) => {
            (entry.text || entry.media) &&
              onBotEntryComplete(
                sessionIdContainer,
                entry.text || "",
                r.updated_context,
                entry.media
              );
          });
          setIsDisabled(false);
        })
        .catch(onError);
    },
    [call, onBotEntryComplete, onError]
  );

  const handleSubmit = useCallback(
    (v: string) => {
      onUserSubmit(v, sessionIdContainer);
      if (!v) return;
      onBotEntryStart();
      setIsDisabled(true);
      callAndProcess(v);
    },
    [callAndProcess, onBotEntryStart, onUserSubmit]
  );

  const localReset = () => {
    setIsDisabled(false);
    resetServerAgt();
    reset();
  };

  useEffect(() => {
    callAndProcess("wake up");
  }, [call]);

  return {
    onInput: onUserInput,
    onSubmit: handleSubmit,
    chat,
    isDisabled,
    reset: localReset,
    result,
  };
};
