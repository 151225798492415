import { fetchWrapper } from "data/api/rest/fetchWrapper";

export const getComponents = async (handle: string) => {
  try {
    const result = await fetchWrapper(
      `/v2/participant/${handle}/list/blueprint`
    );
    if (!result.ok) throw new Error(`Could not get components`);
    const data = await result.json();
    return data;
  } catch (e) {
    console.error(e);
    return [];
  }
};
