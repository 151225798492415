import {V2Category} from "marketplace-2/store/frontpage/types";
import {fetchWrapper} from "data/api/rest/fetchWrapper";
import {uuid} from "utils/uuid";
import { ENVIRONMENT } from "const";

export const getFrontpage = async () => {
    if (ENVIRONMENT.IS_FAKING) {
        return [
            {
                blueprints: [
                    {
                        human_id: "namer_vp3",
                        name: "Namer",
                        goal: "To get a name",
                        participant_image: "",
                        participant_handle: "Menashe",
                        metrics: {
                            sessions_count: 1000,
                            avg_session_len: 3,
                            response_time: 0.001
                        }
                    }
                ],
                description: "Moishe Cat",
                name: "Moishe",
                id: uuid()
            }
        ];
    }
    try {
        const result = await fetchWrapper("/v2/frontpage");
        if (!result.ok) throw new Error(`Could not get frontpage`);
        const data = await result.json();
        return data.categories as V2Category[];
    } catch (e) {
        console.error(e);
        return [] as V2Category[];
    }
};
