import audiocodes from "./audiocodes/infographics/audioCodesInfographics.svg";
import embedded from "./embedded/infographics/embeddedInfographics.svg";
import facebook from "./facebook/infographics/fb_infographics.svg";
import telegram_infographics from "./telegram/infographics/telegram_infographics.svg";
import wordpress from "./wordpress/infographics/wordpress_infographic.svg";

export const telegramInfographics = <img src={telegram_infographics}></img>;

export const wordpressInfographics = <img src={wordpress}></img>;

export const facebookInfographics = <img src={facebook}></img>;

export const audioCodesInfographics = <img src={audiocodes}></img>;

export const embeddedInfographics = <img src={embedded}></img>;
