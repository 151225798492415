import { useEffect, useState } from "react";

export const useBotNameFromChannel = (channel: string) => {
  const [name, setName] = useState("BV Bot");

  useEffect(() => {
    (async () => {
      if (!channel) return;
      try {
        const result = await fetch(`/v2/channel/${channel}/bot`, {
          method: "GET",
        });
        if (!result.ok) throw new Error(result.statusText);
        const reply = (await result.json()) as {
          avatar_id: string;
          name: string;
        };
        reply.name && setName(reply.name);

        return reply;
      } catch (e: any) {
        return e.message;
      }
    })();
  }, [channel]);

  return name;
};
