import {useActiveBotContext} from "./useActiveBotContext";
import {useCallback} from "react";

export const useSetActiveBotId = () => {
    const {dispatch, actions} = useActiveBotContext();

    const notify = useCallback((v: string) => {
        dispatch(actions.bot.setActiveBot(v));
    }, []);

    return notify;
};
