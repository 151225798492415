import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import React, {useEffect, useState} from "react";

export const AreYouSureDeleteDialog = (p: {
    name: string;
    onConfirm: () => void;
    onCancel: () => void;
    isOpen: boolean;
}) => {
    const [name, setName] = useState(p.name);

    useEffect(() => {
        if (p.name) setName(p.name);
    }, [p.name]);

    return (
        <Dialog open={p.isOpen} onClose={p.onCancel}>
            <DialogTitle>{"Confirm Deletion"}</DialogTitle>
            <DialogContent>
                <DialogContentText>{`Delete ${name}?`}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={p.onCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={p.onConfirm} color="primary" autoFocus>
                    Agree
                </Button>
            </DialogActions>
        </Dialog>
    );
};
