import {TutorialData} from "../types";
import stage1 from "./assets/multiprojects-1.gif";
import stage2 from "./assets/multiprojects-2.gif";
import stage3 from "./assets/multiprojects-3.gif";


export const multipleProjects: TutorialData = {
    card: {
        text: "How to organize bigger projects",
        image: stage1,
        header: "Multi-Projects"
    },
    steps: [
        {
            text: `A project can be published as a component, and then used as such
1. Click the ADD A NEW PROJECT button
2. Choose DEFAULT and save
3. Add a **Say Node** to canvas, and set a memorable line for it
4. Connect **Entry** to **Say Node**
5. Click Publish`,
            image: stage1,
            header: "Multi-Projects - intro"
        },
        {
            text: `Your project is published and ready for use
1. Click the COPY COMPONENT ID button
2. Select a different project from projects list
3. Click anywhere on the canvas to pop the *Search-Menu*
4. Paste the component ID you copied to "Find anything" field
5. Select your project`,
            image: stage2,
            header: "Multi-Projects - use"
        },
        {
            text: `All the pieces are in place. Let's test it.
1. Connect the **Entry** node to your ***Project Node***
2. Add another ***Say Node*** and connect your ***Project Node*** done action to it
3. Test it
`,
            image: stage3,
            header: "Multi-Projects - wrap up"
        }
    ]
};
