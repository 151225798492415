import {CreateFromDialogflowParams} from "marketplace-2/store/components/createCreateFromDialogflow";
import {fetchWrapper} from "data/api/rest/fetchWrapper";

export type DfCompleteParams = CreateFromDialogflowParams & {
    component_states_mapping: {
        COMPLETE_ACTION?: string;
        FAILED_ACTION?: string;
        OUT_OF_CONTEXT_ACTION?: string;
    };
};

export const createComponentFromDialogflow = async (data: DfCompleteParams) => {
    try {
        const r = await fetchWrapper(`/v2/blueprint/dialogflow`, {
            method: "POST",
            body: JSON.stringify(data),
            mode: "cors",
            credentials: "include"
        });
        return r.ok;
    } catch (e) {
        console.error(e);
        return false;
    }
};
