import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(
  (theme) => ({
    "@global": {
      "*::-webkit-scrollbar": {
        width: "1em",
      },
      "*::-webkit-scrollbar-track": {
        backgroundColor: "rgba(255,255,255, 0.2)",
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(255,255,255, 0.3)",
        outline: "1px solid rgba(255,255,255, 0.9)",
      },
      html: {
        WebkitFontSmoothing: "antialiased", // Antialiasing.
        MozOsxFontSmoothing: "grayscale", // Antialiasing.
        // Change from `box-sizing: content-box` so that `width`
        // is not affected by `padding` or `border`.
        boxSizing: "border-box",
      },
      "*, *::before, *::after": {
        boxSizing: "inherit",
      },
      "strong, b": {
        fontWeight: "bolder",
      },
      body: {
        margin: 0, // Remove the margin in all browsers.
        color: theme.palette.text.primary,
        ...theme.typography.body2,
        backgroundColor: theme.palette.background.default,
        "@media print": {
          // Save printer ink.
          backgroundColor: theme.palette.common.white,
        },
      },
    },
  }),
  { name: "MuCustomCssBaseline" }
);

/**
 * Kickstart an elegant, consistent, and simple baseline to build upon.
 */
function CustomCssBaseline(props: any) {
  const { children = null } = props;
  useStyles();
  return <React.Fragment>{children}</React.Fragment>;
}

// if (ENVIRONMENT.NODE_ENV !== "production") {
//   // eslint-disable-next-line
//   CustomCssBaseline["propTypes" + ""] = exactProp(CustomCssBaseline.propTypes);
// }

export default CustomCssBaseline;
