import {GlueNodeV2} from "marketplace-2/store/components/types";
import {NodeTypeV2} from "bots-studio/store/types";
import {emailParams} from "./fromEmailParams";
import {humanReadableId} from "utils/humanReadableId";
import {saveParamsParamId} from "dangerous/staticStrings";

const customIdNodes: string[] = [
    NodeTypeV2.saveAggregated,
    NodeTypeV2.saveInput,
    NodeTypeV2.saveParams,
    NodeTypeV2.saveParamsAggregated
];

export const fromGenericParams = (node: GlueNodeV2) => {
    const params = node.parameters || {};
    const keys = Object.keys(params).filter(
        (key) => key !== "line" && key !== "branches" && !emailParams.includes(key)
    );

    if (!keys.length) return undefined;

    return keys.reduce((acc, key) => {
        const id = customIdNodes.includes(node.type) ? saveParamsParamId : humanReadableId();
        let value = "";
        try {
            const v = params[key] as unknown as string;
            value = v ? JSON.parse(v) : "";
        } finally {
            acc[id] = {id, name: key, value};
        }
        return acc;
    }, {} as {[key: string]: {id: string; name: string; value: string}});
};
