import {Portal, Theme, Tooltip, Typography, TypographyClassKey, makeStyles} from "@material-ui/core";
import React, {useRef, useState} from "react";

import {AnalyticType} from "analytics/AnalyticType";
import {Variant} from "@material-ui/core/styles/createTypography";
import {analytics} from "analytics/analytics";
import clsx from "clsx";
import useOnFirstRender from "utils/hooks/useOnFirstRender";

const getColor = (variant: TextButtonVariant, disabled: boolean, theme: Theme) => {
    if (disabled) return theme.custom.palette.e.alt;
    switch (variant) {
        case "primary":
        case "primary-thin":
            return theme.custom.palette.a.main;
        case "black-thin":
            return theme.custom.palette.e.main;
        case "white-thin":
            return theme.custom.palette.d.main;
        case "secondary":
            return theme.custom.palette.e.main;
        case "alt":
            return theme.custom.palette.d.alt;
        case "red":
            return theme.custom.palette.special.bad;
        default:
            return theme.custom.palette.e.main;
    }
};

const useStyles = (variant: TextButtonVariant, disabled: boolean, width: number) =>
    makeStyles((theme) => {
        const colorText = getColor(variant, disabled, theme);

        return {
            container: {
                cursor: "pointer",
                pointerEvents: disabled ? "none" : "all",
                borderRadius: theme.spacing(2),
                padding: theme.spacing(0.5, 1),
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "transparent",
                transition: theme.transitions.create(["font-weight", "color"], {
                    duration: theme.transitions.duration.short,
                    easing: theme.transitions.easing.sharp
                }),
                color: colorText,
                fontWeight: variant === "primary" ? "bolder" : "normal",
                "&:hover": {
                    fontWeight: "bolder"
                },
                position: "relative"
            },
            size: {
                minWidth: width,
                overflow: "hidden"
            },
            typography: {
                fontWeight: "inherit"
            },
            hidden: {visibility: "hidden", position: "absolute", left: 0, top: 0}
        };
    });

export const getTextSize = (size: TextButtonSize) => {
    let textSize = "body1";
    switch (size) {
        case "large":
            textSize = "body1";
            break;
        case "small":
            textSize = "body2";
            break;
        case "tiny":
            textSize = "caption";
            break;
        default:
            textSize = "body1";
            break;
    }
    return textSize as keyof TypographyClassKey;
};

export type TextButtonVariant = "primary" | "secondary" | "alt" | "red" | "primary-thin" | "black-thin" | "white-thin";
export type TextButtonSize = "large" | "small" | "tiny";

export const TextButton: React.FC<{
    onClick?: () => void;
    variant?: TextButtonVariant;
    textSize?: TextButtonSize;
    tooltip?: React.ReactChild | React.ReactFragment | React.ReactPortal;
    disabled?: boolean;
    analytics_event?: AnalyticType;
}> = ({tooltip, variant = "primary", onClick, disabled = false, textSize = "small", children, analytics_event}) => {
    const sizer = useRef<HTMLDivElement | null>(null);

    const [width, setWidth] = useState(0);
    const classes = useStyles(variant, disabled, width)();

    useOnFirstRender(() => {
        if (!sizer.current) return;
        const box = sizer.current.getBoundingClientRect();
        setWidth(box.width);
    }, [sizer.current]);

    const handleClick = () => {
        analytics_event && analytics({type: analytics_event});
        onClick && onClick();
    };

    const fontSize = getTextSize(textSize as TextButtonSize) as Variant;

    return (
        <>
            <Tooltip title={tooltip || ""}>
                <span onClick={handleClick} className={clsx(classes.container, classes.size)}>
                    <Typography component="span" className={classes.typography} variant={fontSize}>
                        {children}
                    </Typography>
                </span>
            </Tooltip>
            <Portal>
                <div ref={sizer} id="invisible-content" className={classes.hidden}>
                    <span className={classes.container}>
                        <Typography component="span" className={classes.typography} variant={fontSize}>
                            {children}
                        </Typography>
                    </span>
                </div>
            </Portal>
        </>
    );
};
